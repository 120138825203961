import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
import moment from 'moment'
import DatePicker from 'react-datepicker'
// Component
import Input from 'shared/components/Input'
import Inputs from 'shared/components/Inputs'
import RadioButton from 'shared/components/RadioButton'
import Select from 'shared/components/Select'
import Selects from 'shared/components/Selects'
import Checkbox from 'shared/components/Checkbox'
import SaveBookmark from 'shared/components/SaveBookmark'
import Textarea from 'shared/components/Textarea'
import Checkboxes from 'shared/components/Checkboxes'
import Textareas from 'shared/components/Textareas'

//Actions
import { formChangeField } from '@actions'
// Reducers
import { formSelectors } from 'reducers'
// constants
import {
	apartmentPropertiesData,
	beaches,
	heatingSystemData,
	waterPipeData,
	sewerData,
	saveField
} from '@constants'
class ApartmentInfoForm extends React.Component {
	constructor() {
		super()
	}

	render() {
		const {
			handleChangeInspectionDate,
			handleCheckInspection,
			isSectionLoading,
			isSectionSaved,
			handleClickSave,
			handleBlurInput,
			handleFocusInput,
			handleChangeField,
			handleChangeTextarea,
			handleCheckBoxGroup,
			apartmentProperties,
			apartmentBelongs,
			carPreservation,
			gardenInfo,
			landscape,
			beach,
			conditionInfo,
			inspectionInfo,
			heatingSystem,
			waterPipe,
			sewer,
			electricityConnection,
			inspections,
			conditionInspection,
			asbestosInspection,
			humidityMeasure,
			conditionInspectionDate,
			asbestosInspectionDate,
			humidityMeasureDate,
			renovationsDone,
			renovationsComing,
			validateError,
			requiredFieldHasError,
			t,
			isActive,
			ventilation
		} = this.props
		const propertiesDetailTextarea = [
			{
				name: 'apartmentBelongs',
				value: apartmentBelongs,
				placeholder: 'Esim. terassi, sälekaihtimet, varasto, vaatekaappi…',
				label: i18next.t(
					'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_ALSO_INCLUDES'
				)
			},
			{
				name: ''
			},
			{
				name: 'carPreservation',
				value: carPreservation,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.CAR_RESERVATION')
			},
			{
				name: ''
			},
			{
				name: 'gardenInfo',
				value: gardenInfo,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.GARDEN_INFO')
			},
			{
				name: ''
			},
			{
				name: 'ventilation',
				value: ventilation,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.VENTILATION')
			},
			{
				name: 'landscape',
				value: landscape,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.LANDSCAPE')
			}
		]
		const decorAndConditionInfoTextarea = [
			{
				name: 'conditionInfo',
				value: conditionInfo,
				label: i18next.t(
					'PROPERTY.BASICFORM.APARTMENTINFOFORM.CONDITION_EXTRAINFO'
				)
			}
		]
		const apartmentSystem = [
			{
				options: heatingSystemData,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.HEATING_SYSTEM'),
				name: 'heatingSystem',
				value: heatingSystem
			},
			{ name: '', value: '' },
			{
				options: waterPipeData,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.WATER_PIPE'),
				name: 'waterPipe',
				value: waterPipe
			},
			{ name: '', value: '' },
			{
				options: sewerData,
				label: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.SEWER'),
				name: 'sewer',
				value: sewer
			}
		]
		const renovations = [
			{
				name: 'renovationsDone',
				value: renovationsDone,
				label: i18next.t(
					'PROPERTY.BASICFORM.APARTMENTINFOFORM.RENOVATIONS_MADE'
				)
			},
			{
				name: 'renovationsComing',
				value: renovationsComing,
				label: i18next.t(
					'PROPERTY.BASICFORM.APARTMENTINFOFORM.INCOMING_RENOVATIONS'
				)
			}
		]

		return (
			<div
				className={
					'form-topic_content apartmentInfoForm' +
					(isActive ? ' active' : ' hidden')
				}
			>
				<div className="form-topic_content-center">
					<div className="field-content-1">
						<Checkboxes
							checkedArr={apartmentProperties}
							optionsArr={apartmentPropertiesData}
							handleCheckBoxGroup={handleCheckBoxGroup}
							nameField="apartmentProperties"
							label={t(
								'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_INCLUDES'
							)}
						/>
					</div>
					<div className="field-content">
						<Textareas
							data={propertiesDetailTextarea}
							handleChangeTextarea={handleChangeTextarea}
						/>
						<Select
							options={beaches}
							value={beach}
							label={t('PROPERTY.BASICFORM.APARTMENTINFOFORM.HAS_BEACH')}
							name="beach"
							handleSelectOption={handleChangeField}
						/>
					</div>
					<div className="field-content">
						<Textareas
							data={renovations}
							handleChangeTextarea={handleChangeTextarea}
						/>
					</div>
					<div className="field-content">
						<Textareas
							data={decorAndConditionInfoTextarea}
							handleChangeTextarea={handleChangeTextarea}
						/>
					</div>
					<div>
						{/* <div className="inspector-wrapper">
							<Checkboxes
								checkedArr={inspections}
								optionsArr={inspectionData}
								handleCheckBoxGroup={handleCheckBoxGroup}
								nameField="inspections"
								label={t('PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS')}
								customClass="threeCol"
								t={t}
							/>
						</div>
						<div className="threeCol">
							<Inputs
								type="date"
								data={inspectionsDates}
								onChange={handleChangeInspectionDate}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
							<div className="threeCol">
								<Inputs
									type="date"
									data={inspectionsDates}
									onChange={handleChangeInspectionDate}
									handleBlurInput={handleBlurInput}
									handleFocusInput={handleFocusInput}
								/> */}
						<div className="inspections">
							<div className="col">
								<Checkbox
									description={t(
										'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS_CONDITION'
									)}
									name="conditionInspection"
									value={conditionInspection}
									handleClickCheckbox={handleCheckInspection}
								/>
								<DatePicker
									selected={
										conditionInspectionDate
											? moment(
													conditionInspectionDate,
													moment.defaultFormat
											  ).toDate()
											: undefined
									}
									onChange={date =>
										handleChangeInspectionDate(date, 'conditionInspectionDate')
									}
								/>
								{requiredFieldHasError['conditionInspectionDateHasError'] ? (
									<span className="error">
										{validateError['conditionInspectionDateError']}
									</span>
								) : null}
							</div>
							<div className="col">
								<Checkbox
									description={t(
										'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS_ASBESTOS'
									)}
									name="asbestosInspection"
									value={asbestosInspection}
									handleClickCheckbox={handleCheckInspection}
								/>
								<DatePicker
									selected={
										asbestosInspectionDate
											? moment(
													asbestosInspectionDate,
													moment.defaultFormat
											  ).toDate()
											: undefined
									}
									onChange={date =>
										handleChangeInspectionDate(date, 'asbestosInspectionDate')
									}
								/>
								{requiredFieldHasError['asbestosInspectionDateHasError'] ? (
									<span className="error">
										{validateError['asbestosInspectionDateError']}
									</span>
								) : null}
							</div>
							<div className="col">
								<Checkbox
									description={t(
										'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS_HUMIDITY'
									)}
									name="humidityMeasure"
									value={humidityMeasure}
									handleClickCheckbox={handleCheckInspection}
								/>
								<DatePicker
									selected={
										humidityMeasureDate
											? moment(
													humidityMeasureDate,
													moment.defaultFormat
											  ).toDate()
											: undefined
									}
									onChange={date =>
										handleChangeInspectionDate(date, 'humidityMeasureDate')
									}
								/>
								{requiredFieldHasError['humidityMeasureDateHasError'] ? (
									<span className="error">
										{validateError['humidityMeasureDateError']}
									</span>
								) : null}
							</div>
						</div>
						<div className="field-content">
							<Textarea
								name="inspectionInfo"
								value={inspectionInfo}
								handleChangeTextarea={handleChangeField}
								label={t(
									'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTION_INFO'
								)}
							/>
						</div>
						<SaveBookmark
							isSaved={isSectionSaved.propertyInspection}
							sectionName={saveField.propertyInspection.name}
							handleClickSave={handleClickSave}
							isLoading={isSectionLoading.propertyInspection}
						/>
					</div>

					<div className="field-content">
						<Selects
							data={apartmentSystem}
							handleSelectOption={handleChangeField}
						/>
						<div></div>
						<Checkbox
							description={t(
								'PROPERTY.BASICFORM.APARTMENTINFOFORM.ELECTRICITY_CONNECTION'
							)}
							name="electricityConnection"
							value={electricityConnection}
							handleClickCheckbox={handleChangeField}
							customIcon="circle"
						/>
						<div></div>
						<SaveBookmark
							isSaved={isSectionSaved.homeSystem}
							sectionName={saveField.homeSystem.name}
							handleClickSave={handleClickSave}
							isLoading={isSectionLoading.homeSystem}
						/>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	carPreservation: formSelectors.getCarPreservation(state.formReducer),
	apartmentBelongs: formSelectors.getApartmentBelongs(state.formReducer),
	apartmentProperties: formSelectors.getApartmentProperties(state.formReducer),
	gardenInfo: formSelectors.getGardenInfo(state.formReducer),
	landscape: formSelectors.getLandscape(state.formReducer),
	beach: formSelectors.getBeach(state.formReducer),
	isShownOnSite: formSelectors.getIsShownOnSite(state.formReducer),
	conditionInfo: formSelectors.getConditionInfo(state.formReducer),
	inspectionInfo: formSelectors.getInspectionInfo(state.formReducer),
	heatingSystem: formSelectors.getHeatingSystem(state.formReducer),
	waterPipe: formSelectors.getWaterPipe(state.formReducer),
	sewer: formSelectors.getSewer(state.formReducer),
	electricityConnection: formSelectors.getElectricityConnection(
		state.formReducer
	),
	inspections: formSelectors.getInspections(state.formReducer),
	conditionInspection: formSelectors.getConditionInspection(state.formReducer),
	asbestosInspection: formSelectors.getAsbestosInspection(state.formReducer),
	humidityMeasure: formSelectors.getHumidityMeasure(state.formReducer),

	conditionInspectionDate: formSelectors.getConditionInspectionDate(
		state.formReducer
	),
	asbestosInspectionDate: formSelectors.getAsbestosInspectionDate(
		state.formReducer
	),
	humidityMeasureDate: formSelectors.getHumidityMeasureDate(state.formReducer),
	renovationsComing: formSelectors.getRenovationsComing(state.formReducer),
	renovationsDone: formSelectors.getRenovationsDone(state.formReducer),
	requiredFieldHasError: formSelectors.getRequiredField(state.formReducer),
	validateError: formSelectors.getValidateError(state.formReducer),
	ventilation: formSelectors.getVentilation(state.formReducer)
})

export default connect(mapStateToProps)(ApartmentInfoForm)
