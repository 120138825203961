/**
 * About component
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
// IMAGE_PATH is the path to assets/images
import { IMAGE_PATH } from '@constants'

const About = props => {
	return (
		<div>
			<Helmet>
				<title>Vertics | About</title>
				<meta name="about" content="Vetics boilerplate about page" />
			</Helmet>
			<h2>About us</h2>
			<p>Information of kotidiili and Jethro here!</p>
		</div>
	)
}

export default About
