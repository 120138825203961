import React from 'react'
import { withTranslation } from 'react-i18next'
import moment from 'moment'

const OfferItem = ({
	order,
	handleSelectBid,
	offer,
	t,
	isShownDetail,
	selectedOffer
}) => {
	const handleSelect = () => {
		if (handleSelectBid && typeof handleSelectBid === 'function') {
			handleSelectBid(offer)
		}
	}
	let className = ['offerItem-container']
	if (offer.id == selectedOffer.id) {
		className.push('selectedOffer')
	}
	const textColorize = text => {
		switch (text) {
			case 'declined':
				return <span className="red">{text.toUpperCase()}</span>
			case 'pending':
				return <span className="blue">{text.toUpperCase()}</span>
			case 'accepted':
				return <span className="green">{text.toUpperCase()}</span>
			default:
				return <span>{text}</span>
		}
	}
	let date = moment(offer.createdAt).format('DD/MM')
	return (
		<div className={className.join(' ')} onClick={handleSelect}>
			<div className="briefInfo">
				<div className="name">
					{t('BIDDING.BIDDING_LIST.OFFER')} {order + 1}
				</div>
				<div className="date">
					{t('BIDDING.BIDDING_LIST.SEND')} {date}{' '}
				</div>
				<div className="status">{textColorize(offer.status)}</div>
			</div>
			{isShownDetail && (
				<div className="description">
					<p>Deals: {offer.price}</p>
				</div>
			)}
		</div>
	)
}
export default withTranslation()(OfferItem)
