import React from 'react'
import FormGroup from '@material-ui/core/FormGroup'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import i18next from 'i18next'

const CheckboxesGroup = ({
	handleCheckBoxGroup,
	optionsArr,
	checkedArr,
	nameField,
	label,
	customClass,
	t
}) => {
	// Normalize options and checked array into state object
	const normalizeState = () => {
		let stateOptions = {}
		if (!optionsArr || optionsArr.lenght == 0) {
			return stateOptions
		}
		if (checkedArr.length == 0) {
			optionsArr.map(item => (stateOptions[item] = false))
		} else {
			optionsArr.map(item =>
				checkedArr.find(checkedItem =>
					checkedItem == item
						? (stateOptions[item] = true)
						: (stateOptions[item] = false)
				)
			)
		}
		return stateOptions
	}
	const [state, setState] = React.useState(normalizeState())
	const handleChange = name => event => {
		setState({ ...state, [name]: event.target.checked })
		if (handleCheckBoxGroup && typeof handleCheckBoxGroup === 'function') {
			let nameField = renderLabel(name)
			console.log(nameField)
			handleCheckBoxGroup(nameField, event.target.checked)
		}
	}
	// React.useEffect(() => {
	// 	var keys = Object.keys(state)

	// 	var filtered = keys.filter(function(key) {
	// 		return state[key]
	// 	})
	// 	if (handleCheckBoxGroup && typeof handleCheckBoxGroup === 'function') {
	// 		handleCheckBoxGroup(nameField, filtered)
	// 	}
	// }, [state])
	const renderLabel = item => {
		switch (item) {
			case i18next.t(
				'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS_CONDITION'
			):
				return 'conditionInspection'
			case i18next.t(
				'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS_ASBESTOS'
			):
				return 'asbestosInspection'
			case i18next.t(
				'PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTIONS_HUMIDITY'
			):
				return 'humidityMeasure'
			default:
				return item
		}
	}
	const renderCheckboxItem = () => {
		const items = Object.keys(state)
		return items.map((item, key) => (
			<FormControlLabel
				key={key}
				control={
					<Checkbox
						// labelStyle={{ color: 'white' }}
						// iconStyle={{ color: 'white' }}
						checked={state[item]}
						onChange={handleChange(`${item}`)}
						value={item}
					/>
				}
				label={item}
			/>
		))
	}
	let className = ['checkboxes-container']
	if (customClass) {
		className.push(customClass)
	}
	return (
		<FormGroup>
			{label && <label>{label}</label>}
			<div className={className.join(' ')}>{renderCheckboxItem()}</div>
		</FormGroup>
	)
}
export default CheckboxesGroup
