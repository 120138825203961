/**
 * Payment action
 *
 */

export const paymentLogin = propertyId => {
	return {
		type: 'PAYMENT_LOGIN',
		payload: {
			request: {
				method: 'GET',
				url: `/payment/get_url?property=${propertyId}`
			}
		}
	}
}
