/**
 * Map component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 */

import React from 'react'
import GoogleMapReact from 'google-map-react'
import Geocode from 'react-geocode'
import home_icon from 'assets/icons/home_icon.png'
class Map extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			location: null,
			map: null
		}
	}
	componentDidMount() {
		this.geoCodeByTextAddress(this.props.address)
	}
	handleMapLoaded = ({ map, maps }) => {
		const icon = {
			url: home_icon,
			size: new google.maps.Size(40, 40),
			scaledSize: new google.maps.Size(40, 40),
			labelOrigin: new google.maps.Point(20, 20),
			anchor: new google.maps.Point(20, 20)
		}
		var marker = new google.maps.Marker({
			position: this.state.location,
			map: map,
			icon
		})
		this.setState({
			map
		})
	}
	geoCodeByTextAddress = address => {
		Geocode.setApiKey(process.env.GOOGLE_MAP_API_KEY)
		// Enable or disable logs. Its optional.
		Geocode.enableDebug()
		Geocode.fromAddress(address).then(
			response => {
				if (response.results || response.results[0]) {
					const { location } = response.results[0].geometry
					this.setState({
						location
					})

					var marker = new google.maps.Marker({
						position: this.state.location,
						map: this.state.map
					})
				}
			},
			error => {
				console.error(error)
			}
		)
	}

	render() {
		const { children } = this.props
		return (
			<>
				<div className="map">
					<GoogleMapReact
						bootstrapURLKeys={{
							key: process.env.GOOGLE_MAP_API_KEY
						}}
						center={this.state.location}
						zoom={16}
						marker={this.state.location}
						onGoogleApiLoaded={this.handleMapLoaded}
						yesIWantToUseGoogleMapApiInternals={true}
					></GoogleMapReact>
				</div>
			</>
		)
	}
}

export default Map
