import React from 'react'
const PriceInfo = ({ item, classname }) => {
	if (!item) {
		return null
	}
	let defaultClassName = ['priceInfo']
	if (classname) {
		defaultClassName.push(classname)
	}
	return (
		<div className={defaultClassName.join(' ')}>
			<div className="priceInfo-description">
				<div className="priceInfo-description-item">
					<i className="material-icons">done</i>
					{item.area}
				</div>
				<div className="priceInfo-description-item">
					<i className="material-icons">done</i>
					{item.distance} metriä sijainnistasi
				</div>
			</div>
			<div className="priceInfo-price">{item.price}€</div>
		</div>
	)
}
export default PriceInfo
