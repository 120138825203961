/**
 * Main container
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Helmet } from 'react-helmet'
import { hot } from 'react-hot-loader/root'
import i18next from 'i18next'

// actions
import { clearErrorMessage } from '@actions'

// shared
import CoreLayout from 'shared/layout/CoreLayout'
import ProtectedRoute from 'shared/components/Authentication/ProtectedRoute'
import Home from 'pages/Home/Home'
import PropertiesList from 'pages/PropertiesList'
import Property from 'pages/Property'
import Dashboard from 'pages/Dashboard'
import Preview from 'pages/Preview'
import Publish from 'pages/Property/Components/Publish'
import About from 'pages/About/About'
import BankAuth from 'pages/BankAuth'
import Login from 'pages/Login/Login'
import ShowingRequest from 'pages/ShowingRequest'
import ShowingResults from 'pages/ShowingResults'
import Showing from 'pages/Showing'
import DraftProperty from 'pages/DraftProperty'
import Bidding from 'pages/Bidding'
import Trading from 'pages/Trading'
import Password from 'pages/Password'
import Admin from 'pages/Admin'
import Score from 'pages/Scoring'
// buyer bid forms
import BuyerBid from 'pages/BuyerBid'
import Register from 'pages/Register'
import NoMatch from 'pages/NoMatch/index'
import ErrorMessage from 'shared/components/ErrorMessage'
import { routerPath, normalizeUserRole } from '@constants'
import Welcome from 'pages/Welcome'
import Verification from './pages/Verification'
class App extends Component {
	componentDidMount() {
		// clear all error messages
		this.props.dispatch(clearErrorMessage())
	}

	render() {
		console.log(routerPath.buyerBid)
		return (
			<div className="app__content">
				<Helmet titleTemplate="Myy itse" defaultTitle="Myy itse">
					<meta name="description" content="Myy itse" />
				</Helmet>
				<Router onUpdate={() => window.scrollTo(0, 0)}>
					<CoreLayout>
						<Switch>
							<Route
								exact
								path={routerPath.home}
								component={props => <Home star={100000} />}
							/>
							<Route path={routerPath.properties} component={PropertiesList} />
							<Route path={routerPath.password} component={Password} />
							<Route path={routerPath.login} component={Login} />
							<Route path={routerPath.register} component={Register} />
							<Route
								path={routerPath.bankAuth}
								component={() => (
									<BankAuth
										connectLater={true}
										title={i18next.t('BANK_RECOGNISATION.TITLE')}
										desc={i18next.t('BANK_RECOGNISATION.DESC')}
									/>
								)}
							/>
							<Route
								path={routerPath.verification}
								component={() => (
									<Verification
										connectLater={true}
										title={i18next.t('VERIFICATION.TITLE')}
									/>
								)}
							/>

							<Route path={routerPath.welcome} component={Welcome} />

							<ProtectedRoute
								role={normalizeUserRole.seller}
								path={routerPath.dashboard}
								component={Dashboard}
							/>
							<ProtectedRoute
								path={routerPath.score}
								component={Score}
								role={normalizeUserRole.seller}
							/>
							<ProtectedRoute path={routerPath.admin} component={Admin} />

							<ProtectedRoute
								path={routerPath.publish}
								component={Publish}
								role={normalizeUserRole.seller}
							/>
							<ProtectedRoute
								role={normalizeUserRole.seller}
								path={routerPath.showingRequest}
								component={ShowingRequest}
							/>
							<ProtectedRoute path={routerPath.preview} component={Preview} />
							<Route path={routerPath.apartment} component={Preview} />
							<Route path={routerPath.about} component={About} />
							<ProtectedRoute
								exact
								path={routerPath.property}
								component={Property}
								role={normalizeUserRole.seller}
							/>

							<ProtectedRoute
								exact
								path={routerPath.draftList}
								component={DraftProperty}
								role={normalizeUserRole.seller}
							/>
							<ProtectedRoute
								path={routerPath.bidding}
								component={Bidding}
								role={normalizeUserRole.seller}
							/>

							<ProtectedRoute
								exact
								path={routerPath.propertyPayment}
								component={Property}
								role={normalizeUserRole.seller}
							/>

							<ProtectedRoute path={routerPath.showing} component={Showing} />

							<ProtectedRoute path={routerPath.trading} component={Trading} />
							<ProtectedRoute
								path={routerPath.showing}
								component={Showing}
								role={normalizeUserRole.seller}
							/>
							<ProtectedRoute
								path={routerPath.trading}
								component={Trading}
								role={normalizeUserRole.seller}
							/>
							<ProtectedRoute
								role={normalizeUserRole.seller}
								path={routerPath.showingResults}
								component={ShowingResults}
							/>
							{/* buyer bid */}
							<ProtectedRoute path={routerPath.buyerBid} component={BuyerBid} />

							<Route component={NoMatch} />
						</Switch>
					</CoreLayout>
				</Router>
				{/*
					Render error message right away
					Will return null if there is no such error
				*/}
				{/* <ErrorMessage /> */}
			</div>
		)
	}
}

export default hot(connect()(App))
