import React, { Component } from 'react'
import { connect } from 'react-redux'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
// img
import aninkainenLogo from 'assets/images/aninkainen_icon.png'
// component
import JethronTip from 'shared/components/JethronTip'
import Button from 'shared/components/Button'
import Rule from 'pages/BuyerBid/Components/Rule'
import DocumentCheck from 'pages/BuyerBid/Components/DocumentCheck'
import NewBid from 'pages/BuyerBid/Components/NewBid'
import PricePrivacy from 'pages/BuyerBid/Components/PricePrivacy'
import Conditions from 'pages/BuyerBid/Components/Conditions'
import Buyers from 'pages/BuyerBid/Components/Buyers'
import BuyerMessage from 'pages/BuyerBid/Components/BuyerMessage'
import BuyerInfo from 'pages/BuyerBid/Components/BuyerInfo'
import Signature from 'pages/BuyerBid/Components/Signature'
// reducers
import {
	mediaSelectors,
	buyerBidSelectors,
	priceSelectors,
	formSelectors
} from 'reducers'
// actions
import {
	buyerBidChangeField,
	propertyGetProperty,
	buyerBidChangeBuyers,
	buyerBidAddBuyer,
	buyerBidRemoveBuyer
} from '@actions'
const viewData = [
	{
		id: 0,
		name: 'rule',
		component: <Rule />,
		url: '/buyer/bid/rule',
		isNextAvailable: true
	},
	{
		id: 1,
		name: 'documentCheck',
		component: <DocumentCheck />,
		url: '/buyer/bid/documentCheck',
		isNextAvailable: false
	},
	{
		id: 2,
		name: 'newBid',
		component: <NewBid />,
		url: '/buyer/bid/newBid',
		isNextAvailable: true
	},
	{
		id: 3,
		name: 'pricePrivacy',
		component: <PricePrivacy />,
		url: '/buyer/bid/pricePrivacy',
		isNextAvailable: true
	},
	{
		id: 4,
		name: 'conditions',
		component: <Conditions />,
		url: '/buyer/bid/conditions',
		isNextAvailable: true
	},
	{
		id: 5,
		name: 'buyerMessage',
		component: <BuyerMessage />,
		url: '/buyer/bid/buyerMessage',
		isNextAvailable: true
	},
	{
		id: 6,
		name: 'buyers',
		component: <Buyers />,
		url: '/buyer/bid/buyers',
		isNextAvailable: true
	},
	{
		id: 7,
		name: 'buyerInfo',
		component: <BuyerInfo />,
		url: '/buyer/bid/buyerInfo',
		isNextAvailable: true
	},
	{
		id: 8,
		name: 'signature',
		component: <Signature />,
		url: '/buyer/bid/signature',
		isNextAvailable: true
	}
]
class BuyerBid extends Component {
	constructor(props) {
		super(props)
		this.state = {
			currentViewId: 0,
			// by default, all the view will have next button which is disabled for validation, will be filter in componentDidmount for optional view
			isNextDisabled: true
		}
	}
	componentDidMount() {
		const selectedPropertyId = localStorage.getItem('selectedPropertyId')
		this.props.dispatch(propertyGetProperty(selectedPropertyId))
		const { nameId } = this.props.match.params
		let currentView = viewData.find(item => item.name == nameId)
		// disabled button will not appear in optional views
		let isNextDisabled = this.validateNextButtonDisabled()
		this.setState({
			currentViewId: currentView.id,
			isNextDisabled
		})
	}
	componentDidUpdate(prevProps) {
		const {
			isRuleAgreed,
			isDocumentRead,
			offerPrice,
			payDate,
			moveDate,
			validDate,
			buyers,
			name,
			phone,
			email,
			address1,
			zipCode,
			municipality
		} = this.props
		const { nameId } = this.props.match.params
		if (prevProps.match.params.nameId != nameId) {
			let isNextDisabled = this.validateNextButtonDisabled()
			this.setState({
				isNextDisabled
			})
		}
		if (prevProps.isRuleAgreed != isRuleAgreed) {
			this.setState({ isNextDisabled: !isRuleAgreed })
		}
		if (prevProps.isDocumentRead != isDocumentRead) {
			this.setState({ isNextDisabled: !isDocumentRead })
		}
		if (
			prevProps.offerPrice != offerPrice ||
			prevProps.payDate != payDate ||
			prevProps.moveDate != moveDate ||
			prevProps.validDate != validDate
		) {
			this.setState({
				isNextDisabled: !(payDate && moveDate && validDate && offerPrice)
			})
		}
		if (
			prevProps.name != name ||
			prevProps.phone != phone ||
			prevProps.email != email ||
			prevProps.address1 != address1 ||
			prevProps.zipCode != zipCode ||
			prevProps.municipality != municipality
		) {
			this.setState({
				isNextDisabled: !(
					name &&
					phone &&
					email &&
					address1 &&
					zipCode &&
					municipality
				)
			})
		}
	}
	validateNextButtonDisabled = () => {
		const { nameId } = this.props.match.params
		const {
			isRuleAgreed,
			isDocumentRead,
			payDate,
			moveDate,
			validDate,
			offerPrice
		} = this.props
		const optionalViews = [
			'pricePrivacy',
			'conditions',
			'buyerMessage',
			'buyers'
		]
		if (optionalViews.includes(nameId)) {
			return false
		} else {
			switch (nameId) {
				case 'rule':
					return !isRuleAgreed
				case 'documentCheck':
					return !isDocumentRead
				case 'newBid':
					return !(payDate && moveDate && validDate && offerPrice)
				default:
					return true
			}
		}
	}
	renderComponent = () => {
		const { nameId } = this.props.match.params
		const {
			isDocumentRead,
			isRuleAgreed,
			validDate,
			payDate,
			moveDate,
			offerPrice,
			debtFree,
			message,
			buyers,
			name,
			phone,
			email,
			address1,
			address2,
			zipCode,
			municipality,
			propertyStreetAddress,
			propertyZipCode,
			propertyCity,
			propertyDefaultImage
		} = this.props
		switch (nameId) {
			case 'rule':
				return (
					<Rule
						handleAgreeRule={this.handleChangeField}
						isRuleAgreed={isRuleAgreed}
						address={propertyStreetAddress + propertyZipCode + propertyCity}
						image={propertyDefaultImage}
					/>
				)
			case 'documentCheck':
				return (
					<DocumentCheck
						handleDocumentRead={this.handleChangeField}
						isDocumentRead={isDocumentRead}
					/>
				)
			case 'newBid':
				return (
					<NewBid
						debtFree={debtFree}
						handleChangeField={this.handleChangeField}
						handleChangeDate={this.handleChangeDate}
						validDate={validDate}
						payDate={payDate}
						moveDate={moveDate}
						offerPrice={offerPrice}
					/>
				)
			case 'pricePrivacy':
				return <PricePrivacy />
			case 'conditions':
				return <Conditions />
			case 'buyerMessage':
				return (
					<BuyerMessage
						message={message}
						handleChangeMessage={this.handleChangeField}
					/>
				)
			case 'buyers':
				return (
					<Buyers
						buyers={buyers}
						handleChangeBuyersInfo={this.handleChangeBuyersInfo}
						handleAddBuyer={this.handleAddBuyer}
						handleRemoveBuyer={this.handleRemoveBuyer}
					/>
				)
			case 'buyerInfo':
				return (
					<BuyerInfo
						handleChangeField={this.handleChangeField}
						name={name}
						phone={phone}
						address1={address1}
						address2={address2}
						zipCode={zipCode}
						email={email}
						municipality={municipality}
					/>
				)
			case 'signature':
				return <Signature name={name} />
			default:
				return <Rule />
		}
	}
	handleChangeDate = (date, name) => {
		const { dispatch } = this.props
		let newDateFormat = moment(date).format()
		dispatch(buyerBidChangeField(name, newDateFormat))
	}
	handleNextDisabled = () => {
		const {
			isRuleAgreed,
			match: {
				params: { nameId }
			}
		} = this.props
		switch (nameId) {
			case 'rule':
				return !isRuleAgreed
			default:
				return true
		}
	}
	handleAddBuyer = () => {
		this.props.dispatch(buyerBidAddBuyer())
	}
	handleRemoveBuyer = pos => {
		this.props.dispatch(buyerBidRemoveBuyer(pos))
	}
	handleChangeBuyersInfo = (e, pos) => {
		const { name, value, checked, type } = e.target
		const { dispatch } = this.props
		let valueField = value
		if (type == 'number') {
			valueField = Number(valueField)
		}

		if (type == 'checkbox') {
			valueField = checked
		}
		this.props.dispatch(buyerBidChangeBuyers(name, valueField, pos))
	}
	handleChangeField = e => {
		const { name, value, checked, type } = e.target
		const { dispatch } = this.props
		let valueField = value
		if (type == 'number') {
			valueField = Number(valueField)
		}

		if (type == 'checkbox') {
			valueField = checked
		}
		dispatch(buyerBidChangeField(name, valueField))
	}

	handleBack = () => {
		const { currentViewId } = this.state
		return this.setState(
			{
				currentViewId: currentViewId - 1
			},
			() => {
				this.props.history.push(viewData[this.state.currentViewId].url)
			}
		)
	}
	handleNext = () => {
		const { currentViewId } = this.state
		if (currentViewId < viewData.length - 1) {
			return this.setState(
				{
					currentViewId: currentViewId + 1
				},
				() => {
					this.props.history.push(viewData[this.state.currentViewId].url)
				}
			)
		}
	}
	render() {
		const { t } = this.props
		const { currentViewId, isNextDisabled } = this.state
		return (
			<div className="buyerBid">
				<div className="header">
					<Link to="/" className="logo">
						<img src={aninkainenLogo} alt="img" />
					</Link>
					<Link to="/buyer/bid/documentCheck" className="navigation">
						<Button title="Back to begining" />
					</Link>
				</div>
				<div className="buyerBid-body">
					<div className="buyerBid-content">{this.renderComponent()}</div>
					<div className="buyerBid-tip">
						<JethronTip />
					</div>
				</div>
				<div className="footer">
					{currentViewId > 0 ? (
						<Button
							title={t('BUYER.BUTTON.PREVIOUS')}
							onClick={this.handleBack}
						/>
					) : (
						<div className="gap"></div>
					)}
					<Button
						title={t('BUYER.BUTTON.NEXT')}
						onClick={this.handleNext}
						disabled={isNextDisabled}
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	isRuleAgreed: buyerBidSelectors.getIsRuleAgreed(state.buyerBidReducer),
	isDocumentRead: buyerBidSelectors.getIsDocumentRead(state.buyerBidReducer),
	debtFree: priceSelectors.getFreeFromDebtPrice(state.priceFormReducer),
	validDate: buyerBidSelectors.getValidDate(state.buyerBidReducer),
	payDate: buyerBidSelectors.getPayDate(state.buyerBidReducer),
	moveDate: buyerBidSelectors.getMoveDate(state.buyerBidReducer),
	offerPrice: buyerBidSelectors.getOfferPrice(state.buyerBidReducer),
	buyers: buyerBidSelectors.getBuyers(state.buyerBidReducer),
	message: buyerBidSelectors.getMessage(state.buyerBidReducer),
	name: buyerBidSelectors.getName(state.buyerBidReducer),
	email: buyerBidSelectors.getEmail(state.buyerBidReducer),
	phone: buyerBidSelectors.getPhone(state.buyerBidReducer),
	address1: buyerBidSelectors.getAddress1(state.buyerBidReducer),
	address2: buyerBidSelectors.getAddress2(state.buyerBidReducer),
	zipCode: buyerBidSelectors.getZipCode(state.buyerBidReducer),
	municipality: buyerBidSelectors.getMunicipality(state.buyerBidReducer),
	propertyStreetAddress: formSelectors.getStreetAddress(state.formReducer),
	propertyZipCode: formSelectors.getZipCode(state.formReducer),
	propertyCity: formSelectors.getCity(state.formReducer),
	propertyDefaultImage: mediaSelectors.getDefaultImage(state.mediaFormReducer)
})
export default withRouter(withTranslation()(connect(mapStateToProps)(BuyerBid)))
