/**
 * Bidding action
 *
 */
import {
	BIDDING_CHANGE_FIELD,
	BIDDING_CHANGE_SELECTED_OFFER,
	BIDDING_GET_BIDDINGS,
	BIDDING_RESET_STATE,
	BIDDING_SEND_CONTRACT
} from '@actions'
export const biddingChangeField = (name, value) => {
	return {
		type: BIDDING_CHANGE_FIELD,
		name,
		value
	}
}

export const biddingChangeSelectedOffer = offer => {
	return {
		type: BIDDING_CHANGE_SELECTED_OFFER,
		offer
	}
}

export const biddingResetState = () => {
	return {
		type: BIDDING_RESET_STATE
	}
}
export const biddingGetBiddings = () => {
	return {
		type: BIDDING_GET_BIDDINGS,
		payload: {
			request: {
				method: 'GET',
				url: '/bids'
			}
		}
	}
}
export const biddingSendContractFile = (propertyId, bidId, data) => {
	return {
		type: BIDDING_SEND_CONTRACT,
		payload: {
			request: {
				method: 'POST',
				url: `/contracts/${propertyId}/${bidId}`,
				data
			}
		}
	}
}
