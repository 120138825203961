/**
 * dashboard reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	PROPERTY_ADD_PROPERTY,
	PROPERTY_ADD_PROPERTY_SUCCESS,
	PROPERTY_ADD_PROPERTY_FAIL,
	PROPERTY_GET_PROPERTIES,
	PROPERTY_GET_PUBLISHED_PROPERTIES,
	PROPERTY_GET_PUBLISHED_PROPERTIES_SUCCESS,
	PROPERTY_GET_PUBLISHED_PROPERTIES_FAIL,
	PROPERTY_GET_PROPERTIES_SUCCESS,
	PROPERTY_GET_PROPERTIES_FAIL,
	PROPERTY_GET_PROPERTY,
	PROPERTY_GET_PROPERTY_SUCCESS,
	PROPERTY_GET_PROPERTY_FAIL,
	PROPERTY_UPDATE,
	PROPERTY_UPDATE_SUCCESS,
	PROPERTY_UPDATE_FAIL,
	PROPERTY_CHANGE_FIELD,
	PROPERTY_DELETE_PROPERTY,
	PROPERTY_DELETE_PROPERTY_SUCCESS,
	PROPERTY_DELETE_PROPERTY_FAIL
} from '@actions'
import { normalizeError } from './utils'
import { saveAuthenticationData } from './../utils'
const initialState = {
	properties: [],
	userProperty: null,
	keyword: '',
	loading: false,
	error: null
}
export const propertyReducer = (state = initialState, action) => {
	switch (action.type) {
		case PROPERTY_ADD_PROPERTY:
			return {
				...state,
				loading: true
			}
		case PROPERTY_ADD_PROPERTY_SUCCESS:
			return {
				...state,
				loading: false
			}
		case PROPERTY_ADD_PROPERTY_FAIL:
			return {
				...state,
				loading: false
			}
		case PROPERTY_DELETE_PROPERTY:
			return {
				...state,
				loading: true
			}
		case PROPERTY_DELETE_PROPERTY_SUCCESS:
			return {
				...state,
				loading: false
			}
		case PROPERTY_DELETE_PROPERTY_FAIL:
			return {
				...state,
				loading: false
			}
		case PROPERTY_GET_PROPERTY:
			return {
				...state,
				loading: true
			}
		case PROPERTY_GET_PROPERTY_SUCCESS:
			return {
				...state,
				loading: false
			}
		case PROPERTY_GET_PROPERTY_FAIL:
			return {
				...state,
				loading: false
			}
		case PROPERTY_GET_PUBLISHED_PROPERTIES:
			return {
				...state,
				loading: true
			}
		case PROPERTY_GET_PUBLISHED_PROPERTIES_SUCCESS:
			return {
				...state,
				properties: action.payload.data,
				loading: false
			}
		case PROPERTY_GET_PUBLISHED_PROPERTIES_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case PROPERTY_GET_PROPERTIES:
			return {
				...state,
				loading: true
			}

		case PROPERTY_GET_PROPERTIES_SUCCESS:
			if (action.payload.data.length > 0) {
				saveAuthenticationData('selectedPropertyId', action.payload.data[0].id)
			}

			return {
				...state,
				userProperty: action.payload.data[0],
				properties: action.payload.data,
				loading: false
			}
		case PROPERTY_GET_PROPERTIES_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case PROPERTY_UPDATE:
			return {
				...state,
				loading: true
			}
		case PROPERTY_UPDATE_SUCCESS:
			return {
				...state,
				loading: false
			}
		case PROPERTY_UPDATE_FAIL:
			return {
				...state,
				loading: false
			}
		case PROPERTY_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		default:
			return state
	}
}

export const getPropertySelectors = {
	getProperties: (state = initialState) => state.properties,
	getUserProperty: (state = initialState) => state.userProperty,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error,
	getKeyWord: (state = initialState) => state.keyword
}
