import React from 'react'
import { withTranslation } from 'react-i18next'

const Properties = ({
	properties,
	handleAddProperty,
	handleSelectProperties,
	t
}) => {
	// const handleAdd = () => {
	// 	if (handleAddProperty && typeof handleAddProperty === 'function') {
	// 		handleAddProperty()
	// 	}
	// }
	const handleSelect = filteredProperty => {
		if (
			handleSelectProperties &&
			typeof handleSelectProperties === 'function'
		) {
			handleSelectProperties(filteredProperty)
		}
	}
	const renderPropertyAmount = (propertiesArr, isPublished) => {
		if (!propertiesArr || propertiesArr.length == 0) {
			return null
		}
		let filteredProperty = propertiesArr.filter(
			item => item.published == isPublished
		)
		return (
			<div className="item" onClick={() => handleSelect(filteredProperty)}>
				<span>
					{isPublished
						? t('DASHBOARD.BOXES.PUBLISH_DRAWING.PUBLISHED_PROPERTIES')
						: t('DASHBOARD.BOXES.PUBLISH_DRAWING.DRAFT_PROPERTIES')}
				</span>
				<span className="amount">{filteredProperty.length}</span>
			</div>
		)
	}
	return (
		<div className="dashboard-content_hover">
			<h2>{t('DASHBOARD.BOXES.PUBLISH_DRAWING.TITLE')}</h2>
			<div className="content">
				{renderPropertyAmount(properties, true)}
				{renderPropertyAmount(properties, false)}
				{/* <div className="item">
					<p onClick={handleAdd}>
						{t('DASHBOARD.BOXES.PUBLISH_DRAWING.ADD_PROPERTIES')}
					</p>
					<span className="amount">+</span>
				</div> */}
			</div>
		</div>
	)
}
export default withTranslation()(Properties)
