import {
	REGISTER_CHANGE_FIELD,
	REGISTER_VALIDATE_FIELD,
	REGISTER,
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	REGISTER_RESET_ERROR
} from '@actions'
import { normalizeError } from './utils'

const initialState = {
	isBuyer: false,
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	streetAddress: '',
	postalCode: '',
	city: '',
	phoneNumber: '+358',
	isPolicyAgreed: false,
	requiredFieldHasError: {
		firstName: false,
		lastName: false,
		email: false,
		password: false,
		phoneNumber: false,
		postalCode: false,
		streetAddress: false,
		city: false
	},
	validateError: {
		firstName: '',
		lastName: '',
		email: '',
		password: '',
		phoneNumber: '',
		postalCode: '',
		streetAddress: '',
		city: ''
	},
	error: null,
	loading: false
}

export const registerReducer = (state = initialState, action) => {
	switch (action.type) {
		case REGISTER_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case REGISTER_VALIDATE_FIELD:
			const { nameField, hasError, error } = action
			return {
				...state,
				requiredFieldHasError: {
					...state.requiredFieldHasError,
					[nameField]: hasError
				},
				validateError: {
					...state.validateError,
					[nameField]: error
				}
			}
		case REGISTER:
			return {
				...state,
				loading: true
			}
		case REGISTER_SUCCESS:
			return {
				...state,
				loading: false
			}
		case REGISTER_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case REGISTER_RESET_ERROR:
			return {
				...state,
				error: null
			}
		default:
			return state
	}
}
export const registerSelectors = {
	getIsBuyer: (state = initialState) => state.isBuyer,
	getStreetAddress: (state = initialState) => state.streetAddress,
	getPostalCode: (state = initialState) => state.postalCode,
	getCity: (state = initialState) => state.city,
	getFirstName: (state = initialState) => state.firstName,
	getLastName: (state = initialState) => state.lastName,
	getPhoneNumber: (state = initialState) => state.phoneNumber,
	getEmail: (state = initialState) => state.email,
	getPassword: (state = initialState) => state.password,
	getIsPolicyAgreed: (state = initialState) => state.isPolicyAgreed,
	getIsDisabledSubmit: (state = initialState) => {
		let isDisabled = !state.isPolicyAgreed
		const registerField = [
			'lastName',
			'firstName',
			'email',
			'password',
			'phoneNumber',
			'streetAddress',
			'postalCode',
			'city'
		]
		registerField.map(item => {
			isDisabled = isDisabled || !state[item]
		})
		return isDisabled
	},
	getLoading: (state = initialState) => state.loading,
	getRequiredFieldHasError: (state = initialState) =>
		state.requiredFieldHasError,
	getValidateError: (state = initialState) => state.validateError,
	getError: (state = initialState) => state.error
}
