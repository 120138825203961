/**
 * Admin reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	USER_UPDATE_USER,
	USER_UPDATE_USER_SUCCESS,
	USER_UPDATE_USER_FAIL,
	USER_GET_USER,
	USER_GET_USER_SUCCESS,
	USER_GET_USER_FAIL
} from '@actions'
import { normalizeError } from './utils'
const initialState = {
	user: null,
	loading: false,
	error: null
}
export const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case USER_GET_USER:
			return {
				...state,
				loading: true
			}
		case USER_GET_USER_SUCCESS:
			return {
				...state,
				user: action.payload.data,
				loading: false
			}
		case USER_GET_USER_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case USER_UPDATE_USER:
			return {
				...state,
				loading: true
			}
		case USER_UPDATE_USER_SUCCESS:
			return {
				...state,
				loading: false
			}
		case USER_UPDATE_USER_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		default:
			return state
	}
}

export const userSelectors = {
	getUser: (state = initialState) => state.user,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
