import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import bankPayment from 'assets/images/payment.png'
import Sha256 from './sha256.js'
import { connect } from 'react-redux'
import aninkainen_icon from 'assets/images/aninkainen_icon.png'
import aninkainen_icon2 from 'assets/images/aninkainen_icon2.png'

// Component
import PriceList from 'pages/Welcome/PriceList'
import BankAuth from 'pages/BankAuth'
import InfoItem from './InfoItem'
import Button from 'shared/components/Button'
// Actions
import { paymentLogin } from '@actions'
class Welcome extends Component {
	renderItem = text => {
		return (
			<div className="welcome-item">
				<i className="material-icons">done</i> <span>{text}</span>
			</div>
		)
	}
	handleLoginBank = () => {
		this.props.dispatch(paymentLogin())
	}
	render() {
		const { t } = this.props

		return (
			<>
				<div className="welcome-container">
					<div className="header">
						<h1>{t('WELCOME.TITLE')}</h1>
						<p>{t('WELCOME.DESC')}</p>
					</div>
				</div>
				<BankAuth title={t('WELCOME.IDENTITY.TITLE')} />

				<div className="welcome-container">
					<div className="content">
						<div className="info">
							<div className="welcome-text">
								<h1>{t('WELCOME.CONTENT.TITLE')}</h1>
							</div>
							<div className="info-content">
								<InfoItem info={t('WELCOME.CONTENT.GUIDE')} />
								<InfoItem info={t('WELCOME.CONTENT.MARKETING')} />
								<InfoItem info={t('WELCOME.CONTENT.TEMPLATES')} />
								<InfoItem info={t('WELCOME.CONTENT.ADS')} />
								<InfoItem info={t('WELCOME.CONTENT.TIPS')} />
							</div>
						</div>
						<div className="team">
							<h1>+ {t('WELCOME.CONTENT.TEAM')}</h1>
							<div className="teamIcon">
								<img src={aninkainen_icon} alt="" />
								<img src={aninkainen_icon2} alt="" />
							</div>
						</div>
					</div>
					<div className="payment-detail">
						<div className="payment-table">
							<PriceList />
						</div>
						<div className="payment-bankConnection">
							<img src={bankPayment} alt="" />
							{/* <form action="https://payment.paytrail.com/e2" method="post">
								<input name="MERCHANT_ID" type="hidden" value="13466" />
								<input
									name="URL_SUCCESS"
									type="hidden"
									value="http://www.facebook.com"
								/>
								<input
									name="URL_CANCEL"
									type="hidden"
									value="http://www.example.com/cancel"
								/>
								<input name="ORDER_NUMBER" type="hidden" value="123456" />
								<input
									name="PARAMS_IN"
									type="hidden"
									value="MERCHANT_ID,URL_SUCCESS,URL_CANCEL,ORDER_NUMBER,PARAMS_IN,PARAMS_OUT,AMOUNT"
								/>
								<input
									name="PARAMS_OUT"
									type="hidden"
									value="PAYMENT_ID,TIMESTAMP,STATUS"
								/>
								<input name="AMOUNT" type="hidden" value="350.00" />
								<input
									name="AUTHCODE"
									type="hidden"
									value={Sha256.hash(
										'6pKF4jkv97zmqBJ3ZL8gUw5DfT2NMQ|13466|http://www.facebook.com|http://www.example.com/cancel|123456|MERCHANT_ID,URL_SUCCESS,URL_CANCEL,ORDER_NUMBER,PARAMS_IN,PARAMS_OUT,AMOUNT|PAYMENT_ID,TIMESTAMP,STATUS|350.00'
									).toUpperCase()}
								/>
								<input type="submit" value={t('WELCOME.BUTTON')} />
							</form> */}
							<Button
								title={t('WELCOME.BUTTON')}
								onClick={this.handleLoginBank}
							/>
						</div>
					</div>
				</div>
			</>
		)
	}
}
const mapStateToProps = state => ({})
export default withTranslation()(connect(mapStateToProps)(Welcome))
