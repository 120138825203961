/**
 * Basic form component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { getAuthenticationData } from './../../../utils'
// Component
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import OptionalForm from 'pages/Property/Forms/OptionalForm'
import MandatoryForm from 'pages/Property/Forms/MandatoryForm'
import Expense from 'pages/Property/Forms/Expense'

// Actions
import {
	formChangeField,
	formValidateField,
	formChangeRadioField,
	formSaveForm,
	formIsSavedSection,
	formRemoveSavedSections,
	formResetError
} from '@actions'
// Constants
import { formTopicsBasicForm, navigationPath, saveField } from '@constants'
// Reducers

import {
	getFormOptionalForm,
	formSelectors,
	getPropertySelectors
} from 'reducers'
import { isThisSecond } from 'date-fns'
class BasicForm extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isMandatoryShown: true,
			isExpenseShown: false,
			isOptionalShown: false,
			isApartmentInfoShown: false,
			isMaterialShown: false,
			isPlotShown: false,
			isLinkShown: false,
			isServicesSaved: false,
			isSectionSaved: {
				apartment: false,
				location: false,
				roomDetail: false,
				expense: false,
				personalInfo: false,
				movingAndInfo: false,
				propertyInspection: false,
				homeSystem: false,
				plots: false,
				materials: false,
				services: false
			},
			saveSectionLoading: {
				apartment: false,
				location: false,
				roomDetail: false,
				expense: false,
				personalInfo: false,
				movingAndInfo: false,
				propertyInspection: false,
				homeSystem: false,
				plots: false,
				materials: false,
				services: false
			}
		}
	}
	componentDidUpdate(prevProps) {
		const { isSavedSections, saveSectionLoading } = this.props
		const checkArray = [
			'apartment',
			'location',
			'roomDetail',
			'expense',
			'personalInfo',
			'movingAndInfo',
			'propertyInspection',
			'homeSystem',
			'plots',
			'materials',
			'services'
		]
		if (prevProps.isSavedSections.length != isSavedSections.length) {
			const saveArray = { ...this.state.isSectionSaved }
			checkArray.map(checkItem => {
				if (isSavedSections.some(item => checkItem == item)) {
					saveArray[checkItem] = true
				} else {
					saveArray[checkItem] = false
				}
			})
			this.setState({
				isSectionSaved: saveArray
			})
		}
		if (prevProps.saveSectionLoading.length != saveSectionLoading.length) {
			const loadingArray = { ...this.state.saveSectionLoading }
			checkArray.map(checkItem => {
				if (saveSectionLoading.some(item => checkItem == item)) {
					loadingArray[checkItem] = true
				} else {
					loadingArray[checkItem] = false
				}
			})
			this.setState({
				saveSectionLoading: loadingArray
			})
		}
	}
	componentWillUnmount() {
		this.props.dispatch(formResetError())
	}
	//inspection
	handleCheckInspection = e => {
		const { name, value, checked } = e.target
		const { dispatch, optionalFormData } = this.props
		if (!checked) {
			dispatch(formChangeField(`${name}Date`, undefined))
			dispatch(formValidateField(`${name}Date`, false, ''))
		} else if (!optionalFormData[`${name}Date`]) {
			dispatch(formValidateField(`${name}Date`, true, 'This need to be filled'))
		}
		dispatch(formChangeField(name, checked))
	}
	handleChangeInspectionDate = (date, name) => {
		const { optionalFormData, dispatch } = this.props
		let newDateFormat = date
		if (!date && optionalFormData[name.replace(/Date/g, '')] == true) {
			dispatch(formValidateField(name, true, 'This need to be filled'))
			return
		}
		if (!date) {
			//remove validate error when date is filled
			dispatch(formChangeField(name, undefined))
			return
		} else {
			dispatch(formChangeField(name.replace(/Date/g, ''), true))
			dispatch(formValidateField(name, false, ''))
		}
		dispatch(formChangeField(name, newDateFormat))
	}
	handleChangeRadio = e => {
		const { value, name } = e.target
		let parseValue = Number(value)
		this.props.dispatch(formChangeField(name, parseValue))
	}
	handleChangeField = e => {
		const { name, value, checked, type } = e.target
		console.log('this is handle change field', name, value)
		const { dispatch } = this.props
		let nameField = name
		let valueField = value
		if (type == 'number') {
			valueField = Number(valueField)
		}

		if (type == 'checkbox') {
			valueField = checked
		}
		//remove saveIcon when changing fields
		let sectionName
		let keys = Object.keys(saveField)
		keys.map(key => {
			if (saveField[key].field.some(item => item == name)) {
				sectionName = key
			}
		})
		dispatch(formResetError())
		dispatch(formRemoveSavedSections(sectionName))
		dispatch(formChangeField(nameField, valueField))
	}
	handleChangeExpenseField = e => {
		const { name, value } = e.target

		this.props.dispatch(formChangeField(nameField, valueField))
	}
	handleCheckBoxGroup = (name, value) => {
		const { dispatch, optionalFormData } = this.props
		this.props.dispatch(formChangeField(name, value))
	}

	handleFocusInput = e => {
		const { name, value } = e.target
		this.props.dispatch(formValidateField(name, true, ''))
	}
	handleBlurInput = e => {
		const { name, value } = e.target
		if (value == '') {
			this.props.dispatch(
				formValidateField(name, true, this.props.t('ERRORS.REQUIRED'))
			)
		} else {
			this.props.dispatch(formValidateField(name, false, ''))
		}
	}
	findFieldSection = name => {
		keys = Object.keys(saveField)
		keys.map(item => {
			if (saveField[item].field.some(itemName => itemName == name)) {
				return item.canSaveName
			}
		})
		return null
	}
	handleClickSave = sectionName => {
		const propertyId = getAuthenticationData('selectedPropertyId')
		const userId = getAuthenticationData('userId')
		const { formField, dispatch } = this.props
		if (!sectionName) {
			return
		}
		const fields = saveField[sectionName].field
		if (!fields || fields.length == 0) {
			return
		}
		let data = {}
		fields.forEach(item => {
			if (formField[item] == undefined) {
				formField[item] = ''
			} else {
				data[item] = formField[item]
			}
		})
		this.props
			.dispatch(formSaveForm(propertyId, userId, data, sectionName))
			.then(res => {
				if (res.payload.data) {
					dispatch(formIsSavedSection(sectionName))
				}
			})
			.catch(error => console.log(error))
	}

	handleToggleFormTopic = name => {
		this.setState({
			[name]: !this.state[name]
		})
	}

	isFormTopicActive = name => {
		return this.state[name] === true
	}

	isSectionSaved = sectionName => {
		const { isSavedSections } = this.props
		if (!isSavedSections || isSavedSections.length == 0) {
			return false
		}
		if (isSavedSections.some(item => item == 'services')) {
			this.setState({
				isServicesSaved: true
			})
		}
		return isSavedSections.some(item => item == sectionName)
	}

	handleSubmitForm = () => {
		this.props.history.push(navigationPath.kuvaMediaForm)
	}

	render() {
		const { isButtonDisabled, loading, error, t, propertyLoading } = this.props
		const { isSectionSaved } = this.state
		if (propertyLoading) {
			return (
				<div className="basicForm-container form-topic_content">
					<Loading />
				</div>
			)
		}
		return (
			<div className="basicForm-container form-topic_content">
				<MandatoryForm
					handleChangeField={this.handleChangeField}
					handleBlurInput={this.handleBlurInput}
					findFieldSection={this.findFieldSection}
					handleFocusInput={this.handleFocusInput}
					handleToggleFormTopic={this.handleToggleFormTopic}
					isSectionSaved={isSectionSaved}
					handleChangeRadio={this.handleChangeRadio}
					isSectionLoading={this.state.saveSectionLoading}
					isTopicActive={this.state.isMandatoryShown}
					handleClickSave={this.handleClickSave}
				/>
				<Expense
					handleChangeField={this.handleChangeField}
					handleBlurInput={this.handleBlurInput}
					findFieldSection={this.findFieldSection}
					handleFocusInput={this.handleFocusInput}
					handleToggleFormTopic={this.handleToggleFormTopic}
					isSectionSaved={isSectionSaved}
					isSectionLoading={this.state.saveSectionLoading}
					isTopicActive={this.state.isExpenseShown}
					handleClickSave={this.handleClickSave}
				/>

				<OptionalForm
					t={t}
					handleCheckInspection={this.handleCheckInspection}
					handleToggleFormTopic={this.handleToggleFormTopic}
					isTopicActive={this.isFormTopicActive}
					handleChangeInspectionDate={this.handleChangeInspectionDate}
					handleBlurInput={this.handleBlurInput}
					handleFocusInput={this.handleFocusInput}
					handleCheckBoxGroup={this.handleCheckBoxGroup}
					handleChangeField={this.handleChangeField}
					handleChangeRadio={this.handleChangeRadio}
					handleClickSave={this.handleClickSave}
					isSectionSaved={isSectionSaved}
					isSectionLoading={this.state.saveSectionLoading}
				/>

				<div className="formButton">
					{error ? <span className="error">{error.message}</span> : null}

					{loading ? (
						<Loading />
					) : (
						<Button
							title={t('GENERAL.FORMS.SAVE_AND_CONTINUE')}
							onClick={this.props.handleSubmitForm}
						/>
					)}
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	optionalFormData: getFormOptionalForm(state.formReducer),
	isSavedSections: formSelectors.getFormIsSaved(state.formReducer),
	formField: formSelectors.getBasicFields(state.formReducer),
	saveSectionLoading: formSelectors.getSaveSectionLoading(state.formReducer),
	loading: formSelectors.getLoading(state.formReducer),
	propertyLoading: getPropertySelectors.getLoading(state.propertyReducer),
	error: formSelectors.getError(state.formReducer)
})
export default connect(mapStateToProps)(BasicForm)
