import React from 'react'
const JetthronTip = ({
	title,
	content = 'Mikä on kiinteistön ja huoneiston ero?'
}) => {
	return (
		<div className="tips">
			<div className="tips-content">
				<h3>Jethron vinkki</h3>
				<span>{content}</span>
			</div>
		</div>
	)
}
export default JetthronTip
