import React from 'react'
import moment from 'moment'

// component
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import JethronTip from 'shared/components/JethronTip'
import DatePicker from 'react-datepicker'

const Conditions = ({
	handleChangeField,
	handleChangeDate,
	offerPrice,
	otherCondition,
	conditionValid,
	mortgageValid,
	surveyValid
}) => {
	return (
		<div className="bid-conditions">
			<div className="bid-conditions_header">
				<h4>MAKE A BID</h4>
				<h1>Special conditions</h1>
			</div>
			<div className="bid-conditions_description">
				It is good practice for the buyer to have a maximum of two weeks to
				qualify for the loan.
			</div>
			<div className="bid-conditions_form">
				<div className="conditionValid">
					<span className="title">Trade Event by (Pay Date)</span>
					<DatePicker
						selected={
							conditionValid
								? moment(conditionValid, moment.defaultFormat).toDate()
								: undefined
						}
						onChange={date => handleChangeDate(date, 'time')}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
				</div>
				<div className="mortgageValid">
					<span className="title">Trade Event by (Getting loan)</span>
					<DatePicker
						selected={
							mortgageValid
								? moment(mortgageValid, moment.defaultFormat).toDate()
								: undefined
						}
						onChange={date => handleChangeDate(date, 'time')}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
				</div>
				<div className="surveyValid">
					<span className="title">Trade Event by (Survey valid)</span>
					<DatePicker
						selected={
							surveyValid
								? moment(surveyValid, moment.defaultFormat).toDate()
								: undefined
						}
						onChange={date => handleChangeDate(date, 'time')}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
				</div>

				<Input
					label="Other terms and conditions"
					name="otherCondition"
					value={otherCondition}
					onChange={handleChangeField}
				/>
			</div>
		</div>
	)
}
export default Conditions
