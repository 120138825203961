/**
 * Form reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	FORM_CHANGE_FIELD,
	FORM_VALIDATE_FIELD,
	FORM_CHANGE_RADIO_FIELD,
	FORM_IS_SAVED_SECTION,
	FORM_REMOVE_SAVE_SECTION,
	FORM_SAVE_FORM,
	FORM_SAVE_FORM_SUCCESS,
	FORM_SAVE_FORM_FAIL,
	FORM_RESET_ERROR,
	PROPERTY_ADD_PROPERTY_SUCCESS,
	PROPERTY_GET_PROPERTY_SUCCESS,
	PROPERTY_RESET_PROPERTY
} from '@actions'
import { normalizeError } from './utils'
import {
	properties,
	categories,
	mainBuildingMaterial,
	mandatoryFormField,
	optionalFormField,
	formRequiredField,
	formFieldError,
	saveField,
	formField
} from '@constants'
import { findFieldByName } from './utils'
const initialState = {
	options: {
		categories: categories,
		properties: properties,
		mainBuildingMaterials: mainBuildingMaterial,
		plotOwnerShips: [
			{
				text: 'oma',
				value: 'true'
			},
			{
				text: 'vuokra',
				value: 'false'
			}
		]
	},
	id: null,
	housingCompany: '',
	category: '',
	propertyType: '',
	houseType: 1,
	//  location
	streetAddress: '',
	zipCode: '',
	postalCity: '',
	city: '',
	region: '',
	state: '',
	country: '',
	showMap: true,
	apartmentInfo: '',
	roomQty: 0,
	areaApartment: 0,
	areaRest: 0,
	areaTotal: 0,
	areaInfo: 0,
	floorQty: 0,
	condition: 1,
	conditionInfo: '',
	brandNew: false,
	constYear: '',
	energyClass: 16,
	energyCertificate: '',
	energyInfo: '',
	informationLabel: '',
	freeForMoving: '',
	name: '',
	phone: '',
	email: '',
	query: '',
	// expense
	targetpriceFinancing: '0',
	targetpriceCare: '0',
	propertyTax: '',
	rentPerYear: '',
	targetpriceElectricuse: '0',
	targetpriceWater: '0',
	targetpriceWaterspec: '',
	targetpriceHeating: '0',
	mortgages: '',
	deptPart: '',
	priceInfo: '',
	//apartmentInfoForm
	sauna: true,
	balcony: true,
	glazedBalcony: false,
	elevator: true,
	apartmentBelongs: '',
	carPreservation: '',
	gardenInfo: '',
	ventilation: '',
	landscape: '',
	beach: '',
	renovationsDone: '',
	renovationsComing: '',
	conditionInspection: true,
	asbestosInspection: true,
	humidityMeasure: false,
	conditionInspectionDate: undefined,
	asbestosInspectionDate: undefined,
	humidityMeasureDate: undefined,
	inspectionInfo: '',
	heatingSystem: '',
	waterPipe: '',
	sewer: '',
	electricityConnection: false,
	//materialForm
	mainBuildingMaterial: '',
	surfaceMaterialDescription: '',
	kitchen: '',
	livingRoom: '',
	hallway: '',
	bedroom: '',
	toilet: '',
	bathroom: '',
	//PlotForm
	plotOwn: 'true',
	estateId: '',
	plotArea: '',
	buildingInfo: '',
	plotInfo: '',
	// links
	localServices: '',
	commutes: '',
	// updateAt
	updatedAt: '',
	// field has error
	streetAddressHasError: false,
	zipCodeHasError: false,
	postalCityHasError: false,
	cityHasError: false,
	regionHasError: false,
	stateHasError: false,
	countryHasError: false,
	askingPriceHasError: false,
	conditionInspectionDateHasError: false,
	humidityMeasureDateHasError: false,
	asbestosInspectionDateHasError: false,
	queryHasError: false,
	phoneHasError: false,
	nameHasError: false,
	emailHasError: false,
	//error
	streetAddressError: '',
	zipCodeError: '',
	postalCityError: '',
	cityError: '',
	regionError: '',
	stateError: '',
	countryError: '',
	askingPriceError: '',
	conditionInspectionDateError: '',
	humidityMeasureDateError: '',
	asbestosInspectionDateError: '',
	phoneError: '',
	nameError: true,
	emailError: true,
	queryError: '',
	//isSaved
	isSavedSections: [],
	saveSectionLoading: [],
	// form loading and error
	error: null,
	loading: false
}
export const formReducer = (state = initialState, action) => {
	switch (action.type) {
		case PROPERTY_GET_PROPERTY_SUCCESS:
			const { informationField: informationData } = action.payload.data
			return {
				...state,
				...informationData,
				updatedAt: action.payload.data.updatedAt
			}
		case PROPERTY_ADD_PROPERTY_SUCCESS:
			const { informationField, id } = action.payload.data
			localStorage.setItem('selectedPropertyId', id)
			return {
				...state,
				...informationField,
				loading: false
			}

		case FORM_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case FORM_CHANGE_RADIO_FIELD:
			return {
				...state,
				...action.result
			}
		case FORM_VALIDATE_FIELD:
			const { nameFieldHasError, hasError, nameFieldError, error } = action
			return {
				...state,
				[nameFieldHasError]: hasError,
				[nameFieldError]: error
			}
		case FORM_IS_SAVED_SECTION:
			let isSavedSectionCopy = [...state.isSavedSections]
			isSavedSectionCopy.push(action.sectionName)
			return {
				...state,
				isSavedSections: isSavedSectionCopy
			}
		case FORM_REMOVE_SAVE_SECTION:
			let isSavedSectionArray = [...state.isSavedSections]
			let removePosition = isSavedSectionArray.findIndex(
				item => item == action.sectionName
			)
			if (removePosition != -1) {
				isSavedSectionArray.splice(removePosition, 1)
			}

			return {
				...state,
				isSavedSections: isSavedSectionArray
			}
		case FORM_SAVE_FORM:
			let saveArray = [...state.saveSectionLoading]
			saveArray.push(action.sectionName)
			return {
				...state,
				saveSectionLoading: saveArray,
				loading: true
			}
		case FORM_SAVE_FORM_SUCCESS:
			let sectionLoading = action.payload.config.reduxSourceAction.sectionName
			let newLoadingArray = [...state.saveSectionLoading]
			let loadingPos = newLoadingArray.indexOf(sectionLoading)
			if (loadingPos != -1) {
				newLoadingArray.splice(loadingPos, 1)
			}
			return {
				...state,
				saveSectionLoading: newLoadingArray,
				loading: false
			}
		case FORM_SAVE_FORM_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case FORM_RESET_ERROR:
			return {
				...state,
				error: null
			}
		// case FORM_RESET_STATE:
		// 	return initialState
		case PROPERTY_RESET_PROPERTY:
			return initialState
		default:
			return state
	}
}

// options
export const getFormCategories = (state = initialState) =>
	state.options.categories
export const getFormProperties = (state = initialState) =>
	state.options.properties

//form

export const getFormPropertyId = (state = initialState) => state.id
export const getFormCategory = (state = initialState) => state.category

//optional Form
export const getFormOptionalForm = (state = initialState) => {
	let optionalForm = {}
	optionalFormField.map(item => (optionalForm[item] = state[item]))
	return optionalForm
}

export const getFormIsDisabledBasicFormButton = (state = initialState) => {
	const stateKeys = Object.keys(state)
	const keys = stateKeys.filter(item => item.includes('HasError'))
	let isDisabled = false
	keys.map(key => {
		isDisabled = isDisabled || state[key]
	})
	return isDisabled
}

export const formSelectors = {
	getFormIsSaved: (state = initialState) => state.isSavedSections,
	getBasicFields: (state = initialState) => {
		let formFields = {}
		formField.map(item => (formFields[item] = state[item]))
		return formFields
	},
	getRequiredField: (state = initialState) => {
		let formRequiredArray = {}
		formRequiredField.map(item => (formRequiredArray[item] = state[item]))
		return formRequiredArray
	},
	getValidateError: (state = initialState) => {
		let validateErrorArray = {}
		formFieldError.map(item => (validateErrorArray[item] = state[item]))
		return validateErrorArray
	},
	// mandatory form
	getMandatoryData: (state = initialState) => {
		let mandatoryForm = {}
		mandatoryFormField.map(item => (mandatoryForm[item] = state[item]))
		return mandatoryForm
	},
	getPropertyType: (state = initialState) => state.propertyType,
	getHouseType: (state = initialState) => state.houseType,
	getHousingCompany: (state = initialState) => state.housingCompany,
	getRoomQty: (state = initialState) => state.roomQty,
	getStreetAddress: (state = initialState) => state.streetAddress,
	getZipCode: (state = initialState) => state.zipCode,
	getPostalCity: (state = initialState) => state.postalCity,
	getCity: (state = initialState) => state.city,
	getRegion: (state = initialState) => state.region,
	getCountry: (state = initialState) => state.country,
	getState: (state = initialState) => state.state,
	getShowMap: (state = initialState) => state.showMap,
	getApartmentInfo: (state = initialState) => state.apartmentInfo,
	getAreaApartment: (state = initialState) => state.areaApartment,
	getAreaRest: (state = initialState) => state.areaRest,
	getAreaTotal: (state = initialState) => state.areaTotal,
	getAreaInfo: (state = initialState) => state.areaInfo,
	getFloorQty: (state = initialState) => state.floorQty,
	getName: (state = initialState) => state.name,
	getPhone: (state = initialState) => state.phone,
	getEmail: (state = initialState) => state.email,
	getQuery: (state = initialState) => state.query,
	// Expense
	getMortgages: (state = initialState) => state.mortgages,
	getDeptPart: (state = initialState) => state.deptPart,
	getTargetPriceFinancing: (state = initialState) => state.targetpriceFinancing,
	getTargetPriceCare: (state = initialState) => state.targetpriceCare,
	getTargetPriceTotal: (state = initialState) => {
		const { targetpriceFinancing, targetpriceCare } = state
		return (Number(targetpriceFinancing) + Number(targetpriceCare)).toString()
	},
	getPropertyTax: (state = initialState) => state.propertyTax,
	getRentPerYear: (state = initialState) => state.rentPerYear,
	getTargetPriceElectricuse: (state = initialState) =>
		state.targetpriceElectricuse, //
	getTargetPriceWater: (state = initialState) => state.targetpriceWater, //
	getTargetPriceWaterspec: (state = initialState) => state.targetpriceWaterspec, //
	getTargetPriceHeating: (state = initialState) => state.targetpriceHeating,
	getPriceInfo: (state = initialState) => state.priceInfo,
	getInformationLabel: (state = initialState) => state.informationLabel,
	// apartment info
	getVentilation: (state = initialState) => state.ventilation,
	getRenovationsComing: (state = initialState) => state.renovationsComing,
	getRenovationsDone: (state = initialState) => state.renovationsDone,
	getFreeForMoving: (state = initialState) => state.freeForMoving,
	getEnergyInfo: (state = initialState) => state.energyInfo,
	getConstYear: (state = initialState) => state.constYear,
	getBrandNew: (state = initialState) => state.brandNew,
	getEnergyClass: (state = initialState) => state.energyClass,
	getEnergyCertificate: (state = initialState) => state.energyCertificate,
	getConditionInfo: (state = initialState) => state.conditionInfo,
	getCondition: (state = initialState) => state.condition,
	getCarPreservation: (state = initialState) => state.carPreservation,
	getApartmentBelongs: (state = initialState) => state.apartmentBelongs,
	getApartmentProperties: (state = initialState) => {
		let properties = ['sauna', 'balcony', 'glazedBalcony', 'elevator']
		let apartmentProperties = []
		properties.map(item => {
			if (state[item]) {
				apartmentProperties.push(item)
			}
		})
		return apartmentProperties
	},
	getGardenInfo: (state = initialState) => state.gardenInfo,
	getLandscape: (state = initialState) => state.landscape,
	getBeach: (state = initialState) => state.beach,
	getIsShownOnSite: (state = initialState) => state.isShownOnSite,
	getInspectionInfo: (state = initialState) => state.inspectionInfo,
	getHeatingSystem: (state = initialState) => state.heatingSystem,
	getWaterPipe: (state = initialState) => state.waterPipe,
	getSewer: (state = initialState) => state.sewer,
	getElectricityConnection: (state = initialState) =>
		state.electricityConnection,
	getInspections: (state = initialState) => {
		let inspections = [
			'conditionInspection',
			'asbestosInspection',
			'humidityMeasure'
		]
		let apartmentInspection = []
		inspections.map(item => {
			if (state[item]) {
				apartmentInspection.push(item)
			}
		})
		return apartmentInspection
	},
	getConditionInspection: (state = initialState) => state.conditionInspection,
	getAsbestosInspection: (state = initialState) => state.asbestosInspection,
	getHumidityMeasure: (state = initialState) => state.humidityMeasure,
	getConditionInspectionDate: (state = initialState) =>
		state.conditionInspectionDate,
	getAsbestosInspectionDate: (state = initialState) =>
		state.asbestosInspectionDate,
	getHumidityMeasureDate: (state = initialState) => state.humidityMeasureDate,
	// material
	getMainBuildingMaterial: (state = initialState) => state.mainBuildingMaterial,
	getMainBuildingMaterials: (state = initialState) =>
		state.options.mainBuildingMaterials,
	getSurfaceMaterialDescription: (state = initialState) =>
		state.surfaceMaterialDescription,
	getKitchen: (state = initialState) => state.kitchen,
	getHallway: (state = initialState) => state.hallway,
	getBedroom: (state = initialState) => state.bedroom,
	getLivingRoom: (state = initialState) => state.livingRoom,
	getToilet: (state = initialState) => state.toilet,
	getBathroom: (state = initialState) => state.bathroom,
	// plot
	getPlotOwn: (state = initialState) => state.plotOwn,
	getPlotOwnerShips: (state = initialState) => state.options.plotOwnerShips,
	getEstateId: (state = initialState) => state.estateId,
	getPlotArea: (state = initialState) => state.plotArea,
	getBuildingInfo: (state = initialState) => state.buildingInfo,
	getPlotInfo: (state = initialState) => state.plotInfo,
	// service
	getLocalServices: (state = initialState) => state.localServices,
	getCommutes: (state = initialState) => state.commutes,
	getUpdatedAt: (state = initialState) => state.updatedAt,
	getSaveSectionLoading: (state = initialState) => state.saveSectionLoading,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
