import {
	SHOWING_CHANGE_FIELD,
	SHOWING_SEND_SHOWING,
	SHOWING_SEND_SHOWING_SUCCESS,
	SHOWING_SEND_SHOWING_FAIL,
	PROPERTY_GET_PROPERTY_SUCCESS,
	SHOWING_GET_SHOWINGS_FAIL,
	SHOWING_GET_SHOWINGS,
	SHOWING_GET_SHOWINGS_SUCCESS,
	SHOWING_RESET_STATE
} from '@actions'
import { normalizeError } from './utils'
import moment from 'moment'

const initialState = {
	showing: [],
	selectedShowing: '',
	time: undefined,
	isPublic: false,
	info: '',
	error: null,
	loading: false
}

export const showingReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOWING_GET_SHOWINGS:
			return {
				...state,
				loading: true
			}
		case SHOWING_GET_SHOWINGS_SUCCESS:
			return {
				...state,
				showing: action.payload.data,
				loading: false
			}
		case SHOWING_GET_SHOWINGS_FAIL:
			return {
				...state,
				loading: false
			}
		case SHOWING_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case SHOWING_SEND_SHOWING:
			return {
				...state,
				loading: true
			}
		case SHOWING_SEND_SHOWING_SUCCESS:
			return {
				...state,
				loading: false
			}
		case SHOWING_SEND_SHOWING_FAIL:
			return {
				...state,
				loading: false
			}
		case PROPERTY_GET_PROPERTY_SUCCESS:
			return {
				...state,
				showing: action.payload.data.showing
			}
		case SHOWING_RESET_STATE:
			return initialState
		default:
			return state
	}
}
export const showingSelectors = {
	getSelectedShowing: (state = initialState) => state.selectedShowing,
	getTime: (state = initialState) => state.time,
	getIsPublic: (state = initialState) => state.isPublic,
	getInfo: (state = initialState) => state.info,
	getError: (state = initialState) => state.error,
	getLoading: (state = initialState) => state.loading,
	getShowing: (state = initialState) => state.showing,
	getAvailableShowing: (state = initialState) => {
		const now = moment().format()
		let newShowing = [...state.showing].filter(item =>
			moment(item.time).isAfter(now)
		)
		return newShowing
	}
}
