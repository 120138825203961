import React from 'react'
import { withTranslation } from 'react-i18next'

const DashboardBidding = ({ handleClickBidding, t }) => {
	const handleClick = type => {
		if (handleClickBidding && typeof handleClickBidding === 'function') {
			return handleClickBidding(type)
		}
	}
	return (
		<div className="dashboard-content_hover">
			<h2>{t('DASHBOARD.BOXES.BIDDING.TITLE')}</h2>
			<div className="content">
				<div className="item" onClick={() => handleClick('offers')}>
					{t('DASHBOARD.BOXES.BIDDING.OFFERS')}
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(DashboardBidding)
