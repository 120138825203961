import React from 'react'
import moment from 'moment'

const RequestItem = ({ request, handleChooseItem, className }) => {
	let classNames = ['requestItem-container']
	if (className) {
		classNames.push(className)
	}
	const normalizeDate = date => {
		return moment(date).format('DD/MM')
	}
	const handleClick = () => {
		if (handleChooseItem && typeof handleChooseItem === 'function') {
			handleChooseItem()
		}
	}
	return (
		<div className={classNames.join(' ')} onClick={handleClick}>
			<p>Email: {request.email}</p>
			<p>Name: {request.name}</p>
			<p>
				Showing time: {normalizeDate(request.startDate)} -{' '}
				{normalizeDate(request.endDate)}
			</p>
		</div>
	)
}
export default RequestItem
