/**
 * Register action
 *
 */
import {
	REGISTER,
	REGISTER_CHANGE_FIELD,
	REGISTER_VALIDATE_FIELD,
	REGISTER_RESET_ERROR
} from '@actions'
export const register = data => {
	return {
		type: REGISTER,
		payload: {
			request: {
				method: 'POST',
				url: '/users',
				data
			}
		}
	}
}
export const registerChangeField = (name, value) => {
	return {
		type: REGISTER_CHANGE_FIELD,
		name,
		value
	}
}
export const registerResetError = () => {
	return {
		type: REGISTER_RESET_ERROR
	}
}
export const registerValidateField = (nameField, hasError, error) => {
	return {
		type: REGISTER_VALIDATE_FIELD,
		nameField,
		hasError,
		error
	}
}
