import React from 'react'
const InfoItem = ({ info }) => {
	return (
		<div className="infoItem">
			<i className="material-icons">done</i>
			<p>{info}</p>
		</div>
	)
}
export default InfoItem
