import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { Route, Redirect } from 'react-router-dom'
// actions
import { userUpdateUser, userGetUser } from '@actions'
// component
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
// reducers
import { userSelectors } from 'reducers'
class Password extends Component {
	constructor(props) {
		super(props)
		this.state = {
			bearer: this.props.match.params.bearer,
			password: '',
			isPasswordShown: false,
			passwordToggleText: this.props.t('LOGIN.TOGGLE_SHOW_PASSWORD')
		}
	}
	componentDidMount() {
		this.props.dispatch(userGetUser(this.state.bearer))
	}
	handleClickInputIcon = () => {
		const { t } = this.props
		const { isPasswordShown, passwordToggleText } = this.state
		let toggleText = isPasswordShown
			? t('LOGIN.TOGGLE_SHOW_PASSWORD')
			: t('LOGIN.TOGGLE_HIDE_PASSWORD')
		this.setState({
			isPasswordShown: !isPasswordShown,
			passwordToggleText: toggleText
		})
	}
	handleChangePassword = e => {
		this.setState({
			password: e.target.value
		})
	}
	onResetFail = () => {
		return <Redirect to="/login" />
	}
	renderResetForm = () => {
		return <Input />
	}
	handleSubmitPassword = () => {
		const { dispatch, user } = this.props
		const { bearer, password } = this.state

		dispatch(userUpdateUser(user.id, bearer, { password })).then(res => {
			if (res.payload.data) {
				this.props.history.push('/login')
			}
		})
	}
	render() {
		const { isPasswordShown, password, passwordToggleText } = this.state
		const { t, loading, error } = this.props
		const { status, bearer } = this.props.match.params
		if (status == 'success') {
			return (
				<div className="password">
					<h1>Enter new password</h1>
					<Input
						type="password"
						name="password"
						value={password}
						onChange={this.handleChangePassword}
						handleClickInputIcon={this.handleClickInputIcon}
						type={isPasswordShown ? 'text' : 'password'}
						inputText={passwordToggleText}
					/>
					{error ? <span className="error">{error.message}</span> : null}
					{loading ? (
						<Loading />
					) : (
						<Button title="Submit" onClick={this.handleSubmitPassword} />
					)}
				</div>
			)
		}
		return this.onResetFail()
	}
}
const mapStateToProps = state => ({
	user: userSelectors.getUser(state.userReducer),
	loading: userSelectors.getLoading(state.userReducer),
	error: userSelectors.getError(state.userReducer)
})
export default withRouter(withTranslation()(connect(mapStateToProps)(Password)))
