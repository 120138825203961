import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import background_dashboard from 'assets/images/background_dashboard.png'
// actions
import { propertyAddProperty } from '@actions'
// components
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import Modal from 'shared/components/Modal'
import Properties from 'pages/Dashboard/Components/Properties'
import Materials from 'pages/Dashboard/Components/Materials'
import Introduction from 'pages/Dashboard/Components/Introduction'
import DashboardBidding from 'pages/Dashboard/Components/DashboardBidding'
import Trade from 'pages/Dashboard/Components/Trade'
import Service from 'pages/Dashboard/Components/Service'
import ContactBox from 'shared/components/ContactBox'
// utils
import { getAuthenticationData } from './../../utils'
// actions
import {
	propertyGetProperties,
	formChangeField,
	propertyGetProperty
} from '@actions'
// reducers
import {
	formSelectors,
	getPropertySelectors,
	getFormPropertyId,
	mediaSelectors
} from 'reducers'
// constants
import { navigationPath, fileEndpoint } from '@constants'
import moment from 'moment'
class Dashboard extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isPropertiesShown: false,
			propertiesList: null
		}
	}

	componentDidMount() {
		// TODO better way to handle this
		const userId = getAuthenticationData('userId')
		const propertyId = getAuthenticationData('selectedPropertyId')

		const { dispatch, history } = this.props
		if (propertyId) {
			dispatch(propertyGetProperty(propertyId))
		}

		dispatch(propertyGetProperties(userId)).then(res => {
			if (res.payload.data) {
				if (res.payload.data.length > 0 && !res.payload.data[0].published) {
					history.push(navigationPath.draftList)
				}
			}
		})
	}
	// property handle
	handleAddProperty = () => {
		const { dispatch, history } = this.props
		const userId = getAuthenticationData('userId')
		dispatch(propertyAddProperty(userId))
			.then(res => {
				if (res.payload.data) {
					history.push(`/property/new/basic`)
				}
			})
			.catch(error => console.log(error))
	}
	handleSelectProperties = () => {
		const { userProperty } = this.props
		if (userProperty) {
			const id = userProperty.id
			localStorage.setItem('selectedPropertyId', id)
			this.props.history.push(`/property/${id}/basic`)
		}
	}

	handleCloseModal = () => {
		this.setState({
			isPropertiesShown: false,
			propertiesList: null
		})
	}
	handleSetProperty = id => {
		localStorage.setItem('selectedPropertyId', id)
		this.props.dispatch(formChangeField('id', id))
		this.props.history.push(`/property/${id}/basic`)
	}
	handleEditProperty = () => {
		let id = localStorage.getItem('selectedPropertyId')
		if (id) {
			this.props.history.push(`/property/${id}/basic`)
		}
	}

	// introduction
	handleClickIntro = type => {
		const { history } = this.props
		switch (type) {
			case 'showing':
				return history.push(navigationPath.showing)
			case 'material':
				return
			case 'results':
				return history.push('/showingResults/view')
			default:
				return null
		}
	}

	// Bidding
	handleClickBidding = type => {
		switch (type) {
			case 'offers':
				return
			case 'answer':
				return
			case 'acceptOffer':
				return
			default:
				return null
		}
	}
	// trade
	handleClickTrade = type => {
		//lets fill later
		switch (type) {
			case 'order':
				return
			case 'opportunity':
				return
			default:
				return null
		}
	}

	// service
	handleClickService = type => {
		switch (type) {
			case 'photography':
				return
			case 'insurance':
				return
			case 'humidityMeasure':
				return
			default:
				return null
		}
	}
	//rendering UI

	renderPropertyList = () => {
		const { propertiesList } = this.state
		const { t } = this.props

		if (!propertiesList || propertiesList.length == 0) {
			return <div>{t('DASHBOARD.MODAL.PROPERTIESLIST.NO_PROPS')}</div>
		}
		return propertiesList.map((item, key) => {
			return (
				<div
					className="propertiesList"
					key={key}
					onClick={() => this.handleSetProperty(item.id)}
				>
					<span>
						{item.informationField
							? `${item.informationField.streetAddress}, ${
									item.informationField.zipCode
							  } ${item.informationField.city} luotu ${moment(
									item.createdAt
							  ).format('DD.MM.YYYY HH:mm')}`
							: `Keskeneräinen asunto luotu ${moment(item.createdAt).format(
									'DD.MM.YYYY HH:mm'
							  )}`}
					</span>
				</div>
			)
		})
	}
	renderProperty = () => {
		const { propertyLoading, t, defaultImage, files } = this.props
		const propertyId = getAuthenticationData('selectedPropertyId')
		if (!propertyId) {
			return (
				<>
					<h1>{t('DASHBOARD.PROPERTY.ADD_PROPERTY.TITLE')}</h1>
					<p>{t('DASHBOARD.PROPERTY.ADD_PROPERTY.DESCRIPTION')}</p>
					{propertyLoading ? (
						<Loading />
					) : (
						<Button
							title={t('DASHBOARD.PROPERTY.ADD_PROPERTY.BUTTON')}
							onClick={this.handleAddProperty}
						/>
					)}
				</>
			)
		} else {
			return (
				<div className="selectedPropertyInfo">
					<div className="showingTime">
						<h1>{t('DASHBOARD.PROPERTY.EDIT_PROPERTY.TITLE')}</h1>
						<div className="showingTime-detail">
							<span className="date">Mon 15.7.2019</span>
							<span className="hour">
								{t('DASHBOARD.PROPERTY.EDIT_PROPERTY.HOUR')} 15-17
							</span>
						</div>
						<Button
							title={t('DASHBOARD.PROPERTY.EDIT_PROPERTY.BUTTON')}
							onClick={this.handleEditProperty}
						/>
					</div>
					<div className="info">
						<img
							src={
								defaultImage
									? `${fileEndpoint}/${defaultImage}`
									: background_dashboard
							}
							alt="image"
						/>
						<div className="detail">
							<p>Koskitie 5 B 12345 Pöytyä</p>
							<p>Omakotitalo 3h, k, s</p>
							<p>145 000€ / 48,5 m2</p>
						</div>
					</div>
				</div>
			)
		}
	}
	render() {
		const {
			t,
			propertyLoading,
			loading,
			error,
			properties,
			selectedPropertyId
		} = this.props
		const { isPropertiesShown } = this.state
		if (loading) {
			return <Loading />
		}
		return (
			<div className="dashboard-container">
				<div className="dashboard-container_header">
					<h1>{t('DASHBOARD.TITLE')}</h1>
					<p>{t('DASHBOARD.DESCRIPTION')}</p>
				</div>
				<div className="dashboard-container_property">
					{this.renderProperty()}
				</div>
				<div className="dashboard-container_contents">
					<div className="form-topic_content-center dashboard-boxes">
						<div className="dashboard-content ads">
							{propertyLoading ? (
								<Loading />
							) : (
								<>
									<div className="dashboard-content_title">
										<h2>{t('DASHBOARD.BOXES.PUBLISH_DRAWING.TITLE')}</h2>
									</div>
									<div
										className="dashboard-content_hover"
										onClick={this.handleSelectProperties}
									>
										Manage Property
									</div>
								</>
							)}
						</div>

						<div className="dashboard-content">
							<div className="dashboard-content_title">
								<h2>{t('DASHBOARD.BOXES.INTRODUCTION.TITLE')}</h2>
							</div>
							<Introduction handleClickIntro={this.handleClickIntro} />
						</div>
						<div className="dashboard-content aninkainen"></div>
						<div className="dashboard-content">
							<div className="dashboard-content_title">
								<h2>{t('DASHBOARD.BOXES.BIDDING.TITLE')}</h2>
							</div>
							<DashboardBidding handleClickBidding={this.handleClickBidding} />
						</div>
						<div className="dashboard-content">
							<div className="dashboard-content_title">
								<h2>{t('DASHBOARD.BOXES.TRADE.TITLE')}</h2>
							</div>
							<Trade handleClickTrade={this.handleClickTrade} />
						</div>
						<div className="dashboard-content">
							<div className="dashboard-content_title">
								<h2>{t('DASHBOARD.BOXES.SERVICE.TITLE')}</h2>
							</div>
							<Service handleClickService={this.handleClickService} />
						</div>
					</div>
					<ContactBox />
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	defaultImage: mediaSelectors.getDefaultImage(state.mediaFormReducer),
	files: mediaSelectors.getFiles(state.mediaFormReducer),
	formLoading: formSelectors.getLoading(state.formReducer),
	userProperty: getPropertySelectors.getUserProperty(state.propertyReducer),
	selectedPropertyId: getFormPropertyId(state.formReducer),
	propertyLoading: getPropertySelectors.getLoading(state.propertyReducer),
	error: getPropertySelectors.getError(state.propertyReducer)
})

export default withRouter(
	withTranslation()(connect(mapStateToProps)(Dashboard))
)
