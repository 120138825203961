import React, { Component } from 'react'
import calendarIcon from 'assets/icons/calendar.svg'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import moment from 'moment'
import { withRouter } from 'react-router-dom'
import { getAuthenticationData } from './../../utils'
// Component
import Button from 'shared/components/Button'
// reducers
import { showingSelectors, getPropertySelectors } from 'reducers'
class ShowingList extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedShowing: 0,
			showAll: false
		}
	}
	handleSelectShowing = key => {
		this.setState({
			selectedShowing: key
		})
	}
	handleCreateShowing = () => {
		this.props.history.push('/showing')
	}
	renderShowing = () => {
		const { showing, t, userProperty } = this.props
		const { selectedShowing, showAll } = this.state
		const userId = getAuthenticationData('userId')
		const propertyId = getAuthenticationData('selectedPropertyId')
		let isCreateShowingButtonShown =
			userId && (userProperty && propertyId === userProperty.id)
		if (showing.length == 0) {
			return (
				<div className="propertyPresentation-container">
					<div className="propertyPresentation-container_dateIconContainer">
						<i className="material-icons">calendar_today</i>
					</div>
					<div className="propertyPresentation-container_content">
						<div className="upcomingPresentations">
							{t('PREVIEW.NO_SHOWING')}
						</div>
						{isCreateShowingButtonShown && (
							<Button
								title={t('PREVIEW.CREATE_SHOWING')}
								onClick={this.handleCreateShowing}
							/>
						)}
					</div>
				</div>
			)
		}
		if (!showAll && showing.length != 0) {
			const { time } = showing[0]
			const timeString = moment(time).format('ddd DD.MM.YYYY')
			const date = moment(time).date()
			return (
				<div className="propertyPresentation-container">
					<div className="propertyPresentation-container_dateIconContainer">
						<i className="material-icons">calendar_today</i>
						<div className="date">{date}</div>
					</div>
					<div className="propertyPresentation-container_content">
						<div className="upcomingPresentations">
							<p className="presentationsTitle">
								{t('PREVIEW.UPCOMING_PRESENTATIONS')} 1
							</p>
							<p>{timeString}</p>
						</div>
						{this.renderShowAllButton('all', 0)}
					</div>
				</div>
			)
		}
		return showing.map((item, key) => {
			const { time } = item
			const timeString = moment(time).format('ddd DD.MM.YYYY')
			const date = moment(time).date()

			if (selectedShowing == key) {
				return (
					<div
						className="propertyPresentation-container"
						key={key}
						onClick={() => this.handleSelectShowing(key)}
					>
						<div className="propertyPresentation-container_dateIconContainer">
							<i className="material-icons">calendar_today</i>
							<div className="date">{date}</div>
						</div>
						<div className="propertyPresentation-container_content">
							<div className="upcomingPresentations">
								<p className="presentationsTitle">
									{t('PREVIEW.UPCOMING_PRESENTATIONS')} {key + 1}
								</p>
								<p>{timeString}</p>
							</div>
							{this.renderShowAllButton('less', key)}
						</div>
					</div>
				)
			} else {
				return (
					<div
						className="propertyPresentation-container_pending"
						key={key}
						onClick={() => this.handleSelectShowing(key)}
					>
						{t('PREVIEW.SHOWING')} {key + 1}
					</div>
				)
			}
		})
	}
	renderShowAllButton = (type, key) => {
		const { t, showing } = this.props
		if (showing.length == 1) {
			return null
		}
		if (key == 0 && type == 'all') {
			return (
				<Button
					onClick={() => this.handleShowShowing(true)}
					title={t('PREVIEW.SHOW_ALL')}
				/>
			)
		}
		if (key == 0 && type == 'less') {
			return (
				<Button
					onClick={() => this.handleShowShowing(false)}
					title={t('PREVIEW.SHOW_LESS')}
				/>
			)
		}
	}

	handleShowShowing = show => {
		this.setState({
			showAll: show
		})
	}
	render() {
		return <div className="showingList">{this.renderShowing()}</div>
	}
}

const mapStateToProps = state => ({
	showing: showingSelectors.getAvailableShowing(state.showingReducer),
	userProperty: getPropertySelectors.getUserProperty(state.propertyReducer)
})
export default withRouter(
	connect(mapStateToProps)(withTranslation()(ShowingList))
)
