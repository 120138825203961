import React, { useState } from 'react'
import { withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
// components
import Input from 'shared/components/Input'
import Loading from 'shared/components/Loading'
import Textarea from 'shared/components/Textarea'
import Button from 'shared/components/Button'
// utils
import { getAuthenticationData } from '../../../utils'
const CertificateRequest = ({
	inputData,
	textareaData,
	managerEmail,
	ownerEmail,
	requestDescription,
	buttonTitle = 'Submit',
	handleChangeField,
	isButtonDisabled,
	handleSubmitForm,
	submitDescription,
	submitTitle,
	streetAddress,
	zipCode,
	postalCity,
	t,
	error,
	loading
}) => {
	const [view, setView] = useState('form')
	const propertyId = getAuthenticationData('selectedPropertyId')
	const handleSubmit = () => {
		if (handleSubmitForm && typeof handleSubmitForm === 'function') {
			handleSubmitForm().then(res => {
				if (res.payload.data) {
					setView('isSubmited')
				}
			})
		}
	}
	const handleOnChange = e => {
		if (handleChangeField && typeof handleChangeField === 'function') {
			handleChangeField(e)
		}
	}
	switch (view) {
		case 'form':
			return (
				<div className="certification-request_container">
					<h3>{t('PROPERTY.CERTIFICATE.REQUEST.TITLE')}</h3>
					<h4>{t('PROPERTY.CERTIFICATE.REQUEST.INSTRUCTION')}</h4>
					<div className="property-location">
						<p>{streetAddress}</p>
						<p>{zipCode}</p>
						<p>{postalCity}</p>
					</div>
					<Link to={`/property/${propertyId}/basic`}>
						<h3 className="edit-info">
							{t('PROPERTY.CERTIFICATE.REQUEST.EDIT')}
						</h3>
					</Link>
					<Input
						label={managerEmail.label}
						name={managerEmail.name}
						value={managerEmail.value}
						onChange={handleOnChange}
					/>
					<span className="error">{error}</span>
					{/* {error ? <span className="error">{error.message}</span> : null} */}
					{loading ? (
						<Loading />
					) : (
						<Button
							title={buttonTitle}
							disabled={isButtonDisabled}
							onClick={handleSubmit}
						/>
					)}
				</div>
			)
		default:
			return (
				<div className="certification-request_container">
					<h3>{submitTitle}</h3>
					<p>{submitDescription}</p>
				</div>
			)
	}
}
export default withTranslation()(CertificateRequest)
