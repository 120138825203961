/**
 * Attachment picker generic component
 * Allows user to upload an attachment
 * Display file name and (image)
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'

function AttachmentPicker({
	handlePickAttachment,
	handleRemoveAttachment,
	icon = 'attach_file',
	iconText,
	withImage = false,
	attachmentUrl,
	id = 'attachment-picker',
	multiple = false,
	accept,
	name
}) {
	const renderPlaceHolder = () => {
		if (iconText) {
			return <div className="icon">{iconText}</div>
		}
		if (icon) {
			return (
				<div className="icon">
					<i className="material-icons">{icon}</i>
				</div>
			)
		}

		return null
	}
	/**
	 * Pick file handler
	 *
	 */

	const handlePickFile = e => {
		if (
			!e.target.files ||
			!handlePickAttachment ||
			typeof handlePickAttachment !== 'function' ||
			e.defaultPrevented
		) {
			return
		}

		let { files } = e.target
		let resultArr = []
		for (let i = 0; i < files.length; i++) {
			const attachment = files[i]
			let reader = new FileReader()

			reader.onloadend = () => {
				handlePickAttachment(attachment, e, reader.result)
			}
			reader.readAsDataURL(attachment)
		}
	}

	/**
	 * Remove attachment handlers
	 *
	 */
	const handleRemoveFile = e => {
		e.preventDefault()
		handleRemoveAttachment(e)
	}

	const renderImage = () => {
		let imgLink

		if (!attachmentUrl) {
			return null
		}

		if (withImage) {
			imgLink = attachmentUrl
		}

		return (
			<div className="attachment-image">
				<img src={imgLink} alt="avarta" />
				<i className="material-icons" onClick={handleRemoveFile}>
					remove_circle_outline
				</i>
			</div>
		)
	}
	return (
		<div className="attachment-picker_container">
			<div className="attachment-picker">
				<div
					className="attachment-picker__upload-button"
					onClick={e => {
						e.stopPropagation()
					}}
				>
					<input
						id={id}
						type="file"
						onChange={handlePickFile}
						className="attachment-picker"
						multiple={multiple}
						accept={accept}
					/>
					<label htmlFor={id}>{renderPlaceHolder()}</label>
				</div>
			</div>
			{renderImage()}
		</div>
	)
}

export default AttachmentPicker
