import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Component
import Input from 'shared/components/Input'
import Inputs from 'shared/components/Inputs'
import Textarea from 'shared/components/Textarea'
import FormTopic from 'pages/Property/Forms/FormTopic'
import SaveBookmark from 'shared/components/SaveBookmark'
// Reducers
import { formSelectors } from 'reducers'
// constants
import {
	currencySign,
	monthlyPriceSign,
	apartments,
	formCategory,
	conditions,
	saveField,
	energyClasses,
	energyCertificates
} from '@constants'
class Expense extends React.Component {
	constructor() {
		super()
	}

	render() {
		const {
			handleChangeField,
			handleBlurInput,
			handleFocusInput,
			propertyTax,
			rentPerYear,
			mortgages,
			deptPart,
			isSectionSaved,
			handleClickSave,
			targetpriceTotal,
			targetpriceFinancing,
			targetpriceCare,
			targetpriceElectricuse,
			targetpriceWater,
			targetpriceWaterspec,
			targetpriceHeating,
			handleToggleFormTopic,
			priceInfo,
			isTopicActive,
			t,
			isSectionLoading
		} = this.props

		const targetPrice = [
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.TARGET_PRICE_FINANCING')}`,
				name: 'targetpriceFinancing',
				value: targetpriceFinancing,
				inputText: currencySign
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.TARGET_PRICE_CARE')}`,
				name: 'targetpriceCare',
				value: targetpriceCare,
				inputText: currencySign
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.TARGET_PRICE_TOTAL')}`,
				name: 'targetpriceTotal',
				value: targetpriceTotal,
				inputText: currencySign
			}
		]

		const tax = [
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.PROPERTY_TAX')}`,
				name: 'propertyTax',
				value: propertyTax,
				inputText: '%'
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.RENT_PER_YEAR')}`,
				name: 'rentPerYear',
				value: rentPerYear,
				inputText: currencySign
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.MORTGAGES')}`,
				name: 'mortgages',
				value: mortgages,
				inputText: currencySign
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.DEPT_PART')}`,
				name: 'deptPart',
				value: deptPart
			}
		]
		const waterElectric = [
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.ELECTRIC_USE')}`,
				name: 'targetpriceElectricuse',
				value: targetpriceElectricuse,
				inputText: monthlyPriceSign
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.WATER_FEE')}`,
				name: 'targetpriceWater',
				value: targetpriceWater,
				inputText: monthlyPriceSign
			},
			{
				label: `${t('PROPERTY.BASICFORM.EXPENSE.WATER_INFO')}`,
				name: 'targetpriceWaterspec',
				value: targetpriceWaterspec
			}
		]
		return (
			<div className="mandatoryForm-container">
				<FormTopic
					title={t('PROPERTY.BASICFORM.TOPICS.EXPENSE')}
					onClickAction={() => handleToggleFormTopic(formCategory.expense)}
					isActive={isTopicActive}
				/>
				<div
					className={
						'form-topic_content mandatoryForm' +
						(isTopicActive ? ' active' : ' hidden')
					}
				>
					<div className="form-topic_content-center">
						<div className="field-content">
							<Inputs
								data={targetPrice}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className="field-content">
							<Inputs
								data={tax}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className="field-content">
							<Inputs
								data={waterElectric}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className="field-content">
							<Input
								label={`${t('PROPERTY.BASICFORM.EXPENSE.HEATING_FEE')}`}
								name="targetpriceHeating"
								onChange={handleChangeField}
								value={targetpriceHeating}
								inputText={monthlyPriceSign}
							/>
						</div>
						<div className="field-content">
							<Textarea
								label={t('PROPERTY.BASICFORM.EXPENSE.PRICE_INFO')}
								value={priceInfo}
								name="priceInfo"
								handleChangeTextarea={handleChangeField}
							/>
						</div>
						<SaveBookmark
							isSaved={isSectionSaved.expense}
							sectionName={saveField.expense.name}
							handleClickSave={handleClickSave}
							isLoading={isSectionLoading.expense}
						/>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	targetpriceTotal: formSelectors.getTargetPriceTotal(state.formReducer),
	targetpriceFinancing: formSelectors.getTargetPriceFinancing(
		state.formReducer
	),
	targetpriceCare: formSelectors.getTargetPriceCare(state.formReducer),
	propertyTax: formSelectors.getPropertyTax(state.formReducer),
	rentPerYear: formSelectors.getRentPerYear(state.formReducer),
	targetpriceElectricuse: formSelectors.getTargetPriceElectricuse(
		state.formReducer
	),

	targetpriceWater: formSelectors.getTargetPriceWater(state.formReducer),
	targetpriceWaterspec: formSelectors.getTargetPriceWaterspec(
		state.formReducer
	),
	mortgages: formSelectors.getMortgages(state.formReducer),
	deptPart: formSelectors.getDeptPart(state.formReducer),
	targetpriceHeating: formSelectors.getTargetPriceHeating(state.formReducer),
	priceInfo: formSelectors.getPriceInfo(state.formReducer)
})

export default withTranslation()(connect(mapStateToProps)(Expense))
