/**
 * User action
 *
 */
import { USER_UPDATE_USER, USER_GET_USER } from '@actions'
export const userGetUser = bearer => {
	return {
		type: USER_GET_USER,
		payload: {
			request: {
				method: 'GET',
				url: '/bearers',
				headers: {
					bearer
				}
			}
		}
	}
}
export const userUpdateUser = (userId, bearer, data) => {
	return {
		type: USER_UPDATE_USER,
		payload: {
			request: {
				method: 'PATCH',
				url: `/user/${userId}`,
				data,
				headers: {
					bearer
				}
			}
		}
	}
}
