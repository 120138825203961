import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// constant
import { previewShowingRequest } from '@constants'
// component
import RadioButton from 'shared/components/RadioButton'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'

// reducers
import { previewSelector } from 'reducers'
// actions
import { previewChangeField } from '@actions'
class PreviewShowingRequest extends Component {
	handleChangeField = e => {
		const { name, value } = e.target
		this.props.dispatch(previewChangeField(name, value))
	}
	render() {
		const { t, nextShowingRequest, email } = this.props
		return (
			<div className="preview-showingRequest_container">
				<p>{t('PREVIEW.SHOWING_REQUEST.TITLE')}</p>
				<RadioButton
					options={previewShowingRequest}
					value={nextShowingRequest}
					name="nextShowingRequest"
					handleCheckRadioButton={this.handleChangeField}
				/>
				<Input
					label={t('PREVIEW.SHOWING_REQUEST.EMAIL')}
					name="email"
					value={email}
					onChange={this.handleChangeField}
				/>
				<Button
					title={t('PREVIEW.SHOWING_REQUEST.BUTTON.REQUEST_NOTIFICATION')}
				/>
				<Button title={t('PREVIEW.SHOWING_REQUEST.BUTTON.ASKING_SHOWING')} />
			</div>
		)
	}
}
const mapStateToProps = state => ({
	nextShowingRequest: previewSelector.getNextShowingRequest(
		state.previewReducer
	),
	email: previewSelector.getEmail(state.previewReducer)
})

export default connect(mapStateToProps)(
	withTranslation()(PreviewShowingRequest)
)
