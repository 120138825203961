/**
 * Utils
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import validator from 'validator'
function validateFieldByName(name, value, callback) {
	switch (name) {
		case 'email':
			if (!validator.isEmail(value)) {
				return callback(name, true, 'This must be an email')
			}
			return
		case 'offersQty':
			if (!validator.isNumeric(value) || value < 0) {
				return callback(
					name,
					true,
					'Number of offers must be a positive number'
				)
			}
			return
		case 'visitorsQty':
			if (!validator.isNumeric(value) || value < 0) {
				return callback(
					name,
					true,
					'Number of offers must be a positive number'
				)
			}
			return
		default:
			return callback(name, false, '')
	}
}
export { validateFieldByName }
