import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
class PriceList extends Component {
	render() {
		const { t } = this.props

		return (
			<div className="priceList-container">
				<h1>{t('WELCOME.PAYMENT_INFO.PRICELIST.TITLE')}</h1>
				<div className="priceList-menu threeCol-layout">
					<h4>{t('WELCOME.PAYMENT_INFO.PRICELIST.MENU.NAME')}</h4>
					<h4>{t('WELCOME.PAYMENT_INFO.PRICELIST.MENU.AMOUNT')}</h4>
					<h4>{t('WELCOME.PAYMENT_INFO.PRICELIST.MENU.PRICE')}</h4>
				</div>
				<div className="priceList-content threeCol-layout">
					<p>Myy itse palvelumaksu 2 kk </p>
					<p>1</p>
					<p>600,00 €</p>
				</div>
				<div className="priceList-content threeCol-layout">
					<p></p>
					<p></p>
					<p>ALV 116,13 €</p>
				</div>
				<div className="priceList-menu threeCol-layout">
					<h4>{t('WELCOME.PAYMENT_INFO.PRICELIST.MENU.TOTAL')}</h4>
					<h4></h4>
					<h4 className="totalPrice">600,00 €</h4>
				</div>
				<p className="payee-info">
					{t('WELCOME.PAYMENT_INFO.PRICELIST.COMPANY_INFO')}
				</p>
			</div>
		)
	}
}
export default withTranslation()(PriceList)
