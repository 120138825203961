import React from 'react'
import { withTranslation } from 'react-i18next'

// component
import Textarea from 'shared/components/Textarea'
const BuyerMessage = ({ handleChangeMessage, message, t }) => {
	const handleChange = e => {
		if (handleChangeMessage && typeof handleChangeMessage === 'function') {
			handleChangeMessage(e)
		}
	}
	return (
		<div className="buyer-message">
			<div className="buyer-message_header">
				<h4>{t('BUYER.BID_FORM.MESSAGE.TITLE')}</h4>
				<h1>{t('BUYER.BID_FORM.MESSAGE.DESC')}</h1>
			</div>
			<Textarea
				label={t('BUYER.BID_FORM.MESSAGE.MESSAGE')}
				handleChangeTextarea={handleChange}
				name="message"
				value={message}
			/>
		</div>
	)
}
export default withTranslation()(BuyerMessage)
