/**
 * Declare all types and export
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

// Users
export const GET_USERS = 'GET_USERS'

// Authentication
export const LOGIN = 'LOGIN'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAIL = 'LOGIN_FAIL'
export const VERIFY_EMAIL = 'VERIFY_EMAIL'
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS'
export const VERIFY_EMAIL_FAIL = 'VERIFY_EMAIL_FAIL'
export const LOGIN_CHANGE_FIELD = 'LOGIN_CHANGE_FIELD'
export const LOGIN_VALIDATE_FIELD = 'LOGIN_VALIDATE_FIELD'
export const LOGIN_RESET_ERROR = 'LOGIN_RESET_ERROR'
export const LOGIN_RESET_FORM = 'LOGIN_RESET_FORM'
// Bank recognisation
export const BANK_CONNECT = 'BANK_CONNECT'
export const BANK_CONNECT_SUCCESS = 'BANK_CONNECT_SUCCESS'
export const BANK_CONNECT_FAIL = 'BANK_CONNECT_FAIL'

// Register
export const REGISTER = 'REGISTER'
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS'
export const REGISTER_FAIL = 'REGISTER_FAIL'

export const REGISTER_CHANGE_FIELD = 'REGISTER_CHANGE_FIELD'
export const REGISTER_VALIDATE_FIELD = 'REGISTER_VALIDATE_FIELD'
export const REGISTER_RESET_ERROR = 'REGISTER_RESET_ERROR'

// Dashboard
export const PROPERTY_GET_PROPERTIES = 'PROPERTY_GET_PROPERTIES'
export const PROPERTY_GET_PUBLISHED_PROPERTIES =
	'PROPERTY_GET_PUBLISHED_PROPERTIES'
export const PROPERTY_GET_PUBLISHED_PROPERTIES_SUCCESS =
	'PROPERTY_GET_PUBLISHED_PROPERTIES_SUCCESS'
export const PROPERTY_GET_PUBLISHED_PROPERTIES_FAIL =
	'PROPERTY_GET_PUBLISHED_PROPERTIES_FAIL'
export const PROPERTY_GET_PROPERTIES_SUCCESS = 'PROPERTY_GET_PROPERTIES_SUCCESS'
export const PROPERTY_GET_PROPERTIES_FAIL = 'PROPERTY_GET_PROPERTIES_FAIL'

//Form
// Basic Form
export const FORM_RESET_STATE = 'FORM_RESET_STATE'
export const FORM_ADD_PROPERTY = 'FORM_ADD_PROPERTY'
export const FORM_ADD_PROPERTY_SUCCESS = 'FORM_ADD_PROPERTY_SUCCESS'
export const FORM_ADD_PROPERTY_FAIL = 'FORM_ADD_PROPERTY_FAIL'
export const FORM_CHANGE_FIELD = 'FORM_CHANGE_FIELD'
export const FORM_VALIDATE_FIELD = 'FORM_VALIDATE_FIELD'
export const FORM_CHANGE_RADIO_FIELD = 'FORM_CHANGE_RADIO_FIELD'
export const FORM_SAVE_FORM = 'FORM_SAVE_FORM'
export const FORM_SAVE_FORM_SUCCESS = 'FORM_SAVE_FORM_SUCCESS'
export const FORM_SAVE_FORM_FAIL = 'FORM_SAVE_FORM_FAIL'
export const FORM_IS_SAVED_SECTION = 'FORM_IS_SAVED_SECTION'
export const FORM_REMOVE_SAVE_SECTION = 'FORM_REMOVE_SAVE_SECTION'
export const FORM_RESET_ERROR = 'FORM_RESET_ERROR'
export const FORM_ADD_ATTACHMENT = 'FORM_ADD_ATTACHMENT'
export const FORM_DELETE_ATTACHMENT = 'FORM_DELETE_ATTACHMENT'
export const FORM_GET_SELECTED_PROPERTY = 'FORM_GET_SELECTED_PROPERTY'
export const FORM_GET_SELECTED_PROPERTY_SUCCESS =
	'FORM_GET_SELECTED_PROPERTY_SUCCESS'
export const FORM_GET_SELECTED_PROPERTY_FAIL = 'FORM_GET_SELECTED_PROPERTY_FAIL'

// Media Form
export const MEDIA_FORM_CHANGE_FIELD = 'MEDIA_FORM_CHANGE_FIELD'
export const MEDIA_FORM_ADD_ATTACHMENT = 'MEDIA_FORM_ADD_ATTACHMENT'
export const MEDIA_FORM_ADD_ATTACHMENT_SUCCESS =
	'MEDIA_FORM_ADD_ATTACHMENT_SUCCESS'
export const MEDIA_FORM_ADD_ATTACHMENT_FAIL = 'MEDIA_FORM_ADD_ATTACHMENT_FAIL'
export const MEDIA_FORM_DELETE_ATTACHMENT = 'MEDIA_FORM_DELETE_ATTACHMENT'
export const MEDIA_FORM_DELETE_ATTACHMENT_SUCCESS =
	'MEDIA_FORM_DELETE_ATTACHMENT_SUCCESS'
export const MEDIA_FORM_DELETE_ATTACHMENT_FAIL =
	'MEDIA_FORM_DELETE_ATTACHMENT_FAIL'

// Property
export const PROPERTY_RESET_PROPERTY = 'PROPERTY_RESET_PROPERTY'

export const PROPERTY_ADD_PROPERTY = 'PROPERTY_ADD_PROPERTY'
export const PROPERTY_ADD_PROPERTY_SUCCESS = 'PROPERTY_ADD_PROPERTY_SUCCESS'
export const PROPERTY_ADD_PROPERTY_FAIL = 'PROPERTY_ADD_PROPERTY_FAIL'

export const PROPERTY_GET_PROPERTY = 'PROPERTY_GET_PROPERTY'
export const PROPERTY_GET_PROPERTY_SUCCESS = 'PROPERTY_GET_PROPERTY_SUCCESS'
export const PROPERTY_GET_PROPERTY_FAIL = 'PROPERTY_GET_PROPERTY_FAIL'

export const PROPERTY_UPDATE = 'PROPERTY_UPDATE'
export const PROPERTY_UPDATE_SUCCESS = 'PROPERTY_UPDATE_SUCCESS'
export const PROPERTY_UPDATE_FAIL = 'PROPERTY_UPDATE_FAIL'

export const PROPERTY_CHANGE_FIELD = 'PROPERTY_CHANGE_FIELD'
export const PROPERTY_DELETE_PROPERTY = 'PROPERTY_DELETE_PROPERTY'
export const PROPERTY_DELETE_PROPERTY_SUCCESS =
	'PROPERTY_DELETE_PROPERTY_SUCCESS'
export const PROPERTY_DELETE_PROPERTY_FAIL = 'PROPERTY_DELETE_PROPERTY_FAIL'

export const MEDIA_FORM_DELETE_IMAGE = 'MEDIA_FORM_DELETE_IMAGE'
// Price Form
export const PRICE_FORM_CHANGE_FIELD = 'PRICE_FORM_CHANGE_FIELD'
export const PRICE_FORM_VALIDATE_FIELD = 'PRICE_FORM_VALIDATE_FIELD'
// PublishForm
export const PUBLISH_CHANGE_FIELD = 'PUBLISH_CHANGE_FIELD'
// Search
export const UPDATE_SEARCH_VALUE = 'UPDATE_SEARCH_VALUE'
// preview
export const PREVIEW_SAVE_SELECTED_MEDIA = 'PREVIEW_SAVE_SELECTED_MEDIA'
export const PREVIEW_CHANGE_FIELD = 'PREVIEW_CHANGE_FIELD'
// Certificate
export const CERTIFICATE_CHANGE_FIELD = 'CERTIFICATE_CHANGE_FIELD'

export const CERTIFICATE_DELETE_CERTIFICATE = 'CERTIFICATE_DELETE_CERTIFICATE'
export const CERTIFICATE_DELETE_CERTIFICATE_SUCCESS =
	'CERTIFICATE_DELETE_CERTIFICATE_SUCCESS'
export const CERTIFICATE_DELETE_CERTIFICATE_FAIL =
	'CERTIFICATE_DELETE_CERTIFICATE_FAIL'

export const CERTIFICATE_SEND_CERTIFICATE = 'CERTIFICATE_SEND_CERTIFICATE'
export const CERTIFICATE_SEND_CERTIFICATE_SUCCESS =
	'CERTIFICATE_SEND_CERTIFICATE_SUCCESS'
export const CERTIFICATE_SEND_CERTIFICATE_FAIL =
	'CERTIFICATE_SEND_CERTIFICATE_FAIL'

export const CERTIFICATE_SEND_REQUEST = 'CERTIFICATE_SEND_REQUEST'
export const CERTIFICATE_SEND_REQUEST_SUCCESS =
	'CERTIFICATE_SEND_REQUEST_SUCCESS'
export const CERTIFICATE_SEND_REQUEST_FAIL = 'CERTIFICATE_SEND_REQUEST_FAIL'

// Showing Request
export const SHOWING_REQUEST_SEND_REQUEST = 'SHOWING_REQUEST_SEND_REQUEST'
export const SHOWING_REQUEST_SEND_REQUEST_SUCCESS =
	'SHOWING_REQUEST_SEND_REQUEST_SUCCESS'
export const SHOWING_REQUEST_SEND_REQUEST_FAIL =
	'SHOWING_REQUEST_SEND_REQUEST_FAIL'
export const SHOWING_REQUEST_CHANGE_FIELD = 'SHOWING_REQUEST_CHANGE_FIELD'
export const SHOWING_REQUEST_VALIDATE_FIELD = 'SHOWING_REQUEST_VALIDATE_ERROR'
export const SHOWING_REQUEST_RESET_ERROR = 'SHOWING_REQUEST_RESET_ERROR'
export const SHOWING_REQUEST_SUCCESS = 'SHOWING_REQUEST_SUCCESS'
export const SHOWING_REQUEST_FAIL = 'SHOWING_REQUEST_FAIL'
export const SHOWING_REQUEST_GET_REQUESTS = 'SHOWING_REQUEST_GET_REQUESTS'
export const SHOWING_REQUEST_GET_REQUESTS_SUCCESS =
	'SHOWING_REQUEST_GET_REQUESTS_SUCCESS'
export const SHOWING_REQUEST_GET_REQUESTS_FAIL =
	'SHOWING_REQUEST_GET_REQUESTS_FAIL'

// Showing results
export const SHOWING_RESULTS_GET_SHOWING_RESULTS =
	'SHOWING_RESULTS_GET_SHOWING_RESULTS'
export const SHOWING_RESULTS_GET_SHOWING_RESULTS_SUCCESS =
	'SHOWING_RESULTS_GET_SHOWING_RESULTS_SUCCESS'
export const SHOWING_RESULTS_GET_SHOWING_RESULTS_FAIL =
	'SHOWING_RESULTS_GET_SHOWING_RESULTS_FAIL'

export const SHOWING_RESULTS_SEND = 'SHOWING_RESULTS_SEND'
export const SHOWING_RESULTS_CHANGE_FIELD = 'SHOWING_RESULTS_CHANGE_FIELD'
export const SHOWING_RESULTS_VALIDATE_FIELD = 'SHOWING_RESULTS_VALIDATE_ERROR'
export const SHOWING_RESULTS_RESET_ERROR = 'SHOWING_RESULTS_RESET_ERROR'
export const SHOWING_RESULTS_SUCCESS = 'SHOWING_RESULTS_SUCCESS'
export const SHOWING_RESULTS_FAIL = 'SHOWING_RESULTS_FAIL'
// Showing
export const SHOWING_CHANGE_FIELD = 'SHOWING_CHANGE_FIELD'
export const SHOWING_SEND_SHOWING = 'SHOWING_SEND_SHOWING'
export const SHOWING_SEND_SHOWING_SUCCESS = 'SHOWING_SEND_SHOWING_SUCCESS'
export const SHOWING_SEND_SHOWING_FAIL = 'SHOWING_SEND_SHOWING_FAIL'
export const SHOWING_GET_SHOWINGS = 'SHOWING_GET_SHOWINGS'
export const SHOWING_GET_SHOWINGS_SUCCESS = 'SHOWING_GET_SHOWINGS_SUCCESS'
export const SHOWING_GET_SHOWINGS_FAIL = 'SHOWING_GET_SHOWINGS_FAIL'
export const SHOWING_RESET_STATE = 'SHOWING_RESET_STATE'
// Bidding
export const BIDDING_CHANGE_FIELD = 'BIDDING_CHANGE_FIELD'
export const BIDDING_CHANGE_SELECTED_OFFER = 'BIDDING_CHANGE_SELECTED_OFFER'
export const BIDDING_GET_BIDDINGS = 'BIDDING_GET_BIDDINGS'
export const BIDDING_GET_BIDDINGS_SUCCESS = 'BIDDING_GET_BIDDINGS_SUCCESS'
export const BIDDING_GET_BIDDINGS_FAIL = 'BIDDING_GET_BIDDINGS_FAIL'
export const BIDDING_RESET_STATE = 'BIDDING_RESET_STATE'
export const BIDDING_SEND_CONTRACT = 'BIDDING_SEND_CONTRACT'
export const BIDDING_SEND_CONTRACT_SUCCESS = 'BIDDING_SEND_CONTRACT_SUCCESS'
export const BIDDING_SEND_CONTRACT_FAIL = 'BIDDING_SEND_CONTRACT_FAIL'

// trading
export const TRADING_CHANGE_FIELD = 'TRADING_CHANGE_FIELD'
export const TRADING_GET_BIDDING = 'TRADING_GET_BIDDING'
// admin
export const ADMIN_GET_PROPERTIES = 'ADMIN_GET_PROPERTIES'
export const ADMIN_GET_PROPERTIES_SUCCESS = 'ADMIN_GET_PROPERTIES_SUCCESS'
export const ADMIN_GET_PROPERTIES_FAIL = 'ADMIN_GET_PROPERTIES_FAIL'

export const ADMIN_SEND_FEEDBACK = 'ADMIN_SEND_FEEDBACK'
export const ADMIN_SEND_FEEDBACK_SUCCESS = 'ADMIN_SEND_FEEDBACK_SUCCESS'
export const ADMIN_SEND_FEEDBACK_FAIL = 'ADMIN_SEND_FEEDBACK_FAIL'

export const ADMIN_CHANGE_FIELD = 'ADMIN_CHANGE_FIELD'

export const ADMIN_PUBLISH_PROPERTY = 'ADMIN_PUBLISH_PROPERTY'
export const ADMIN_PUBLISH_PROPERTY_SUCCESS = 'ADMIN_PUBLISH_PROPERTY_SUCCESS'
export const ADMIN_PUBLISH_PROPERTY_FAIL = 'ADMIN_PUBLISH_PROPERTY_FAIL'
// notification
export const NOTIFICATION_SHOW_NOTIFICATION = 'NOTIFICATION_SHOW_NOTIFICATION'
export const NOTIFICATION_CHANGE_CONFIRM_STATUS =
	'NOTIFICATION_CHANGE_CONFIRM_STATUS'
export const NOTIFICATION_HIDE_NOTIFICATION = 'NOTIFICATION_HIDE_NOTIFICATION'
// reset password
export const RESET_PASSWORD_CHANGE_FIELD = 'RESET_PASSWORD_CHANGE_FIELD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS'
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL'
export const RESET_PASSWORD_RESET_STATE = 'RESET_PASSWORD_RESET_STATE'
// user
export const USER_UPDATE_USER = 'USER_UPDATE_USER'
export const USER_UPDATE_USER_SUCCESS = 'USER_UPDATE_USER_SUCCESS'
export const USER_UPDATE_USER_FAIL = 'USER_UPDATE_USER_FAIL'

export const USER_GET_USER = 'USER_GET_USER'
export const USER_GET_USER_SUCCESS = 'USER_GET_USER_SUCCESS'
export const USER_GET_USER_FAIL = 'USER_GET_USER_FAIL'
// buyer bid
export const BUYER_BID_CHANGE_FIELD = 'BUYER_BID_CHANGE_FIELD'
export const BUYER_BID_CHANGE_BUYERS = 'BUYER_BID_CHANGE_BUYERS'
export const BUYER_BID_ADD_BUYER = 'BUYER_BID_ADD_BUYER'
export const BUYER_BID_REMOVE_BUYER = 'BUYER_BID_REMOVE_BUYER'
