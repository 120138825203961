import React from 'react'
import { withTranslation } from 'react-i18next'

// components
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import JethronTip from 'shared/components/JethronTip'

const PricePrivacy = ({ isPricePublic, handleChangeField, t }) => {
	return (
		<div className="pricePrivacy">
			<div className="pricePrivacy_header">
				<h4>{t('BUYER.BID_FORM.PRICE_PRIVACY.TITLE')}</h4>
				<h1>{t('BUYER.BID_FORM.PRICE_PRIVACY.DESC')}</h1>
			</div>
			<Checkbox
				description={t('BUYER.BID_FORM.PRICE_PRIVACY.CHECKBOX_DESC')}
				name="rememberPassword"
				value={isPricePublic}
				handleClickCheckbox={handleChangeField}
			/>
		</div>
	)
}
export default withTranslation()(PricePrivacy)
