import React from 'react'
const PreviewItems = ({ data }) => {
	const items = data.reduce((acc, item, key) => {
		if (item.value === '' || item.value == null || item.value == 0) {
			return acc
		} else {
			acc.push(
				<div className="preview-item_content" key={key}>
					<p>{item.name}</p>
					<p className={'value'}>{item.value}</p>
				</div>
			)
			return acc
		}
	}, [])
	if (!items || items.length == 0) {
		return null
	} else {
		const renderItems = () => {
			return items
		}
		return <div className="preview-item">{renderItems()}</div>
	}
}
export default PreviewItems
