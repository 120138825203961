import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect
} from 'react-router-dom'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
// utils
import { getAuthenticationData } from './../../utils'
//actions
import {
	formSaveForm,
	propertyGetProperty,
	propertyUpdate,
	propertyResetProperty
} from '@actions'
import i18next from 'i18next'

// Components
import Slider from 'shared/components/Slider'
import Loading from 'shared/components/Loading'
import BasicForm from 'pages/Property/Components/BasicForm'
import PriceForm from 'pages/Property/Components/PriceForm'
import MediaForm from 'pages/Property/Components/MediaForm'
import Publish from 'pages/Property/Components/Publish'
import NoMatch from 'pages/NoMatch'
//reducers
import {
	formSelectors,
	getPropertySelectors,
	mediaSelectors,
	priceSelectors
} from 'reducers'
import { paymentLogin } from '../../actions/payment'
import { navigationPath } from '@constants'
const propertyView = {
	basic: {
		id: 1,
		title: 'LISÄÄ KOHDE',
		key: 'PROPERTY.BASICFORM.MENU_LABEL',
		name: 'PERUSTIEDOT',
		description: 'Aloita kohteen lisäys tallentamalla perustiedot.',
		slider: true,
		menu: true,
		sliderValue: 33.33,
		view: 'basic'
	},
	media: {
		id: 2,
		title: 'LISÄÄ KOHDE',
		key: 'PROPERTY.MEDIA.MENU_LABEL',
		name: 'KUVAT/MEDIA',
		description:
			'Lisää asunnostasi edustavat kuvat myynnin parantamiseksi. Myös videot hyvä lisäys ilmoitukseen.',
		slider: true,
		menu: true,
		sliderValue: 66.66,
		view: 'media'
	},
	price: {
		id: 4,
		title: 'LISÄÄ KOHDE',
		key: 'PROPERTY.PRICE.MENU_LABEL',
		name: 'HINTAPYYNTÖ',
		description: 'Aloita kohteen lisäys tallentamalla perustiedot.',
		slider: true,
		menu: true,
		sliderValue: 99.99,
		view: 'price'
	},
	publish: {
		id: 5,
		title: 'Do you want to publish your property',
		name: '',
		description: '',
		slider: false,
		menu: false,
		sliderValue: 100,
		view: 'publish'
	}
}
class Property extends Component {
	constructor(props) {
		super(props)
		const { type, status } = this.props.match.params
		const { t } = this.props
		let viewType = type
		let baseValue = 33.33
		if (!propertyView[type]) {
			viewType = 'basic'
		}
		let id = propertyView[viewType].id

		this.state = {
			sliderValue: baseValue * id,
			step: 33.33,
			selectedView: viewType
		}
	}

	componentDidMount() {
		const { id } = this.props.match.params
		const propertyId = getAuthenticationData('selectedPropertyId')
		if (id !== 'new') {
			this.props.dispatch(propertyGetProperty(propertyId))
		}
	}
	componentWillUnmount() {
		this.props.dispatch(propertyResetProperty())
	}
	componentDidUpdate(prevProps) {
		const { type } = this.props.match.params
		if (prevProps.match.params.type != type) {
			this.setState({ view: type })
		}
	}

	handleSubmitForm = () => {
		let keys = Object.keys(propertyView)
		const propertyId = getAuthenticationData('selectedPropertyId')
		const userId = getAuthenticationData('userId')
		let id = localStorage.getItem('selectedPropertyId')
		const { type } = this.props.match.params
		const { formField, mediaFormData, priceFormData } = this.props

		let pos = keys.indexOf(type)
		let nextView = keys[pos + 1]
		switch (type) {
			case 'basic':
				this.props
					.dispatch(formSaveForm(propertyId, userId, formField))
					.then(res => {
						if (res.payload.data) {
							this.props.history.push(`/property/${propertyId}/media`)
						}
					})
				break
			case 'media':
				this.props
					.dispatch(propertyUpdate(propertyId, userId, mediaFormData))
					.then(res => {
						if (res.payload.data) {
							this.props.history.push(`/property/${propertyId}/price`)
						}
					})
				break
			case 'price':
				this.props
					.dispatch(formSaveForm(propertyId, userId, priceFormData))
					.then(res => {
						if (res.payload.data) {
							this.props.history.push(`/property/${propertyId}/publish`)
						}
					})
				break
			default:
				if (type == keys[keys.length - 1]) {
					return this.props.history.push('/dashboard')
				}

				this.props.history.push(`/property/${propertyId}/${nextView}`)
		}

		this.setState({
			selectedView: nextView,
			sliderValue: propertyView[nextView].sliderValue
		})
	}
	handleBackForm = () => {
		let keys = Object.keys(propertyView)
		const { type } = this.props.match.params
		let id = localStorage.getItem('selectedPropertyId')

		if (type == keys[0]) {
			console.log('stop')
		}
		let pos = keys.indexOf(type)
		let prevView = keys[pos - 1]
		this.setState({
			selectedView: prevView,
			sliderValue: propertyView[prevView].sliderValue
		})
		this.props.history.push(`/property/${id}/${prevView}`)
	}

	handleClickMenu = item => {
		const { history } = this.props
		let defaultValue = 33.33
		this.setState({
			sliderValue: defaultValue * item.id,
			selectedView: item.view
		})
	}

	isRouteValid = type => {
		let keys = Object.keys(propertyView)
		return keys.indexOf(type) == -1 ? false : true
	}
	renderForm = () => {
		const { type, status } = this.props.match.params
		const { t } = this.props
		switch (type) {
			case 'basic':
				return <BasicForm handleSubmitForm={this.handleSubmitForm} t={t} />
			case 'media':
				return (
					<MediaForm
						handleSubmitForm={this.handleSubmitForm}
						handleBackForm={this.handleBackForm}
						t={t}
					/>
				)
			case 'price':
				return (
					<PriceForm
						handleSubmitForm={this.handleSubmitForm}
						handleBackForm={this.handleBackForm}
						t={t}
					/>
				)
			case 'publish':
				return <Publish />
			default:
				return null
		}
	}
	renderMenu = () => {
		const views = ['basic', 'media', 'price']
		const { type } = this.props.match.params
		let id = localStorage.getItem('selectedPropertyId')

		const { t } = this.props
		return views.map((item, key) => {
			let view = propertyView[item]
			return (
				<Link
					key={key}
					className={
						this.state.selectedView == view.view
							? 'activeMenu menuItem'
							: 'menuItem'
					}
					onClick={() => this.handleClickMenu(view)}
					to={`/property/${id}/${view.view}`}
				>
					{t(`${view.key}`)}
				</Link>
			)
		})
	}
	render() {
		const { type } = this.props.match.params
		const { sliderValue, step } = this.state
		const { loading } = this.props
		let propertyId = localStorage.getItem('selectedPropertyId')
		const { t } = this.props
		if (!this.isRouteValid(type)) {
			return <Route component={NoMatch} />
		}
		if (!propertyId) {
			return (window.location.href = navigationPath.dashboard)
		}

		return (
			<div className="property-wrapper">
				{/* <div className="pink-layer"></div> */}
				<div className="property-container">
					<div className="property-container_header">
						<h1>{propertyView[type].title}</h1>
						<h3>{propertyView[type].description}</h3>
					</div>

					<div className="property-container_slider">
						{propertyView[type].slider && (
							<Slider
								marks={[
									{
										value: 33.33
									},

									{
										value: 66.66
									}
								]}
								value={sliderValue}
								step={step}
								// min={33}
							/>
						)}
					</div>

					{propertyView[type].menu && (
						<div className="property-container_menu">{this.renderMenu()}</div>
					)}

					<div className="property-container_content">{this.renderForm()}</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	formField: formSelectors.getBasicFields(state.formReducer),
	mediaFormData: mediaSelectors.getData(state.mediaFormReducer),
	priceFormData: priceSelectors.getData(state.priceFormReducer),
	loading: getPropertySelectors.getLoading(state.propertyReducer)
})
export default withTranslation()(withRouter(connect(mapStateToProps)(Property)))
