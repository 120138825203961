/**
 * Form action
 *
 */
import {
	FORM_CHANGE_FIELD,
	FORM_VALIDATE_FIELD,
	FORM_CHANGE_RADIO_FIELD,
	FORM_SAVE_FORM,
	FORM_IS_SAVED_SECTION,
	FORM_REMOVE_SAVE_SECTION,
	FORM_RESET_ERROR,
	FORM_ADD_ATTACHMENT,
	FORM_DELETE_ATTACHMENT,
	FORM_RESET_STATE
} from '@actions'
// utils
import { capitalizeFirstLetter } from './utils'
export const formChangeField = (name, value) => {
	if (typeof value == 'string' && value !== 'true' && value !== 'false') {
		value = capitalizeFirstLetter(value)
	}
	return {
		type: FORM_CHANGE_FIELD,
		name,
		value
	}
}

export const formChangeRadioField = result => {
	return {
		type: FORM_CHANGE_RADIO_FIELD,
		result
	}
}
export const formIsSavedSection = sectionName => {
	return {
		type: FORM_IS_SAVED_SECTION,
		sectionName
	}
}
export const formRemoveSavedSections = sectionName => {
	return {
		type: FORM_REMOVE_SAVE_SECTION,
		sectionName
	}
}
export const formResetError = () => {
	return {
		type: FORM_RESET_ERROR
	}
}
export const formResetState = () => {
	return {
		type: FORM_RESET_STATE
	}
}
export const formSaveForm = (propertyId, userId, data, sectionName) => {
	return {
		type: FORM_SAVE_FORM,
		payload: {
			request: {
				method: 'PATCH',
				url: `/propertyinfo/${propertyId}/${userId}`,
				data: data
			}
		},
		sectionName
	}
}
export const formValidateField = (nameField, hasError, error) => {
	return {
		type: FORM_VALIDATE_FIELD,
		nameFieldHasError: `${nameField}HasError`,
		hasError,
		nameFieldError: `${nameField}Error`,
		error
	}
}
export const formAddAttachment = data => {
	let propertyId = localStorage.getItem('selectedPropertyId')
	let userId = localStorage.getItem('userId')

	return {
		type: FORM_ADD_ATTACHMENT,
		payload: {
			request: {
				method: 'POST',
				url: `/propertyfiles/${propertyId}`,
				data: data,
				headers: {
					'content-type': 'multipart/form-data'
				}
			}
		}
	}
}
export const formDeleteAttachment = id => {
	return {
		type: FORM_DELETE_ATTACHMENT,
		id
	}
}
