/**
 * publish reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { PUBLISH_CHANGE_FIELD, PROPERTY_GET_PROPERTY_SUCCESS } from '@actions'
const initialState = {
	published: false,
	loading: false,
	error: null
}
export const publishReducer = (state = initialState, action) => {
	switch (action.type) {
		case PUBLISH_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case PROPERTY_GET_PROPERTY_SUCCESS:
			return {
				...state,
				published: action.payload.data.published
			}
		default:
			return state
	}
}
export const publishSelectors = {
	getPublished: (state = initialState) => state.published,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
