import React, { Component } from 'react'
import Items from 'shared/components/Items'
import Map from 'shared/components/Map'
import i18next from 'i18next'
const menuData = [
	i18next.t('PROPERTY.MEDIA.MENU_LABEL'),
	i18next.t('PROPERTY.MAP')
]

class TabMenu extends Component {
	constructor(props) {
		super(props)
		this.state = {
			selectedMenu: menuData[0]
		}
	}

	renderMenu = () => {
		const { selectedMenu } = this.state
		return menuData.map((item, key) => {
			return (
				<div
					key={key}
					className={
						selectedMenu == item ? 'menu-item isActiveMenu' : 'menu-item'
					}
					onClick={() => this.handleClickMenu(item)}
				>
					<span>{item}</span>
				</div>
			)
		})
	}

	handleClickMenu = item => {
		this.setState({ selectedMenu: item })
		const { handleSelectMenu } = this.props
		if (handleSelectMenu && typeof handleSelectMenu === 'function') {
			handleSelectMenu(item)
		}
	}
	renderItem = () => {
		switch (this.state.selectedMenu) {
			case i18next.t('PROPERTY.MEDIA.MENU_LABEL'):
				return (
					<div className="menu-item_content">
						<Items
							data={this.props.data}
							handleClickItem={this.props.handleClickItem}
						/>
					</div>
				)
			default:
				return null
		}
	}

	render() {
		return (
			<div className="tabMenu-container">
				<div className="menu-wrapper">{this.renderMenu()}</div>
				{this.props.mediaQty && this.props.mediaQty.length > 0
					? this.renderItem()
					: null}
			</div>
		)
	}
}

export default TabMenu
