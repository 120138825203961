/**
 * Define some frequent-use constants
 * for easier import inside shared
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import i18next from 'i18next'
import React from 'react'
// Path to images directory
export const IMAGE_PATH = `${process.cwd()}src/assets/images`
// Path to icons directory
export const ICON_PATH = `${process.cwd()}src/assets/icons`
// Key redux persist
export const PERSIST_KEY = 'root'

export const routerPath = {
	home: '/',
	properties: '/properties',
	login: '/login',
	bankAuth: '/bankAuth/:status',
	register: '/register',
	welcome: '/welcome',
	dashboard: '/dashboard',
	score: '/score',
	preview: '/preview/:id',
	publish: '/publish',
	presentationRequest: '/requestPresentation',
	about: '/about',
	form: '/form',
	property: '/property/:id/:type',
	apartment: '/asunto/:id',
	propertyPayment: '/property/:id/:type/:status',
	showingResults: '/showingResults/:action',
	showingRequest: '/showingRequest',
	showing: '/showing',
	draftList: '/drafts',
	verification: '/verification/:status',
	bidding: '/bidding',
	trading: '/trading',
	admin: '/admin/:id',
	password: '/user/password/:status/:bearer',
	rule: '/rule',
	documentCheck: '/documentCheck',
	newBid: '/newBid',
	pricePrivacy: '/pricePrivacy',
	conditions: '/conditions',
	buyerBid: '/buyer/bid/:nameId'
}
export const navigationPath = {
	home: '/',
	properties: '/properties',
	login: '/login',
	bankAuth: '/bankAuth/ ',
	register: '/register',
	welcome: '/welcome', // layer liian pitkä
	dashboard: '/dashboard',
	score: '/score',
	preview: '/preview',
	publish: '/publish',
	presentationRequest: '/requestPresentation',
	about: '/about',
	form: '/form',
	property: '/property',
	apartment: '/asunto',
	propertyPayment: '/property',
	showingResults: '/showingResults',
	showingRequest: '/showingRequest',
	showing: '/showing',
	draftList: '/drafts',
	verification: '/verification/ ',
	bidding: '/bidding',
	trading: '/trading',
	admin: '/admin/:id',
	password: '/user/password/:status/:bearer',
	rule: '/rule',
	documentCheck: '/documentCheck',
	newBid: '/newBid',
	pricePrivacy: '/pricePrivacy',
	conditions: '/conditions',
	buyerBid: '/buyer/bid/:nameId'
}
export const isShowingPublicData = [
	{
		text: i18next.t('SHOWING.PUBLIC.IS_PUBLIC.PUBLIC'),
		value: 'true'
	},
	{
		text: i18next.t('SHOWING.PUBLIC.IS_PUBLIC.PRIVATE'),
		value: 'false'
	}
]
export const formTopicsBasicForm = [
	{
		title: i18next.t('PROPERTY.BASICFORM.TOPICS.MANDATORY'),
		stateName: 'isMandatoryShown'
	},
	{
		title: i18next.t('PROPERTY.BASICFORM.TOPICS.OPTIONAL'),
		stateName: 'isOptionalShown'
	}
]
export const formTopicsOptinalForm = [
	{
		title: i18next.t('PROPERTY.BASICFORM.TOPICS.APARTMENT_INFORMATION'),
		stateName: 'isApartmentInfoShown'
	},
	{
		title: i18next.t('PROPERTY.BASICFORM.TOPICS.ROOMS_AND_MATERIAL'),
		stateName: 'isMaterialShown'
	},
	{
		title: i18next.t('PROPERTY.BASICFORM.TOPICS.PLOT'),
		stateName: 'isPlotShown'
	},
	{
		title: i18next.t('PROPERTY.BASICFORM.TOPICS.SERVICES_AND_COMMUNICATIONS'),
		stateName: 'isLinkShown'
	}
]
// data
export const categories = [
	{
		text: i18next.t('PROPERTY.BASICFORM.PROPERTY_CATEGORIES.BUILDING'),
		value: 'Building'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.PROPERTY_CATEGORIES.STORAGE'),
		value: 'Storage'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.PROPERTY_CATEGORIES.OFFICE'),
		value: 'Office'
	}
]
export const mainBuildingMaterial = [
	{
		text: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.BUILDING_MATERIALS.WOOD'),
		value: 'wood'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.BUILDING_MATERIALS.ROCK'),
		value: 'rock'
	}
]
export const properties = [
	// {
	// 	text: i18next.t('PROPERTY.BASICFORM.PROPERTY_TYPES.COTTAGE'),
	// 	value: 'Cottage'
	// },
	{
		text: i18next.t('PROPERTY.BASICFORM.PROPERTY_TYPES.HOME'),
		value: '1'
	}
	// {
	// 	text: i18next.t('PROPERTY.BASICFORM.PROPERTY_TYPES.VILLA'),
	// 	value: 'Villa'
	// }
]
export const apartments = [
	{
		value: '1',
		text: i18next.t('PROPERTY.BASICFORM.APARTMENT_TYPES.APARTMENT')
	},
	{
		value: '0',
		text: i18next.t('PROPERTY.BASICFORM.APARTMENT_TYPES.PROPERTY')
	}
]
export const energyCertificates = [
	{
		text: i18next.t('PROPERTY.BASICFORM.ENERGY_CERTIFICATE.VALUE.YES'),
		value: '1'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.ENERGY_CERTIFICATE.VALUE.NO'),
		value: '2'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.ENERGY_CERTIFICATE.VALUE.NOT_REQUIRED'),
		value: '3'
	}
]
export const beaches = [
	{
		text: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.NO_BEACH'),
		value: '5'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.OWN_BEACH'),
		value: '1'
	},
	{
		text: i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.WATER_RIGHT'),
		value: '2'
	},
	{
		text: i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.CO_OWN_BEACH'
		),
		value: '4'
	}
]
//apartmentInfoForm
export const apartmentPropertiesData = [
	i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.ADDITIONAL_FEATURES.SAUNA'),
	i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.ADDITIONAL_FEATURES.BALCONY'),
	i18next.t(
		'PROPERTY.BASICFORM.APARTMENTINFOFORM.ADDITIONAL_FEATURES.GLAZED_BALCONY'
	),
	i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.ADDITIONAL_FEATURES.ELEVATOR')
]
export const energyClasses = [
	{
		text: 'A2018',
		value: '16'
	},
	{
		text: 'B2018',
		value: '17'
	},
	{
		text: 'C2018',
		value: '18'
	},
	{
		text: 'D2018',
		value: '19'
	},
	{
		text: 'E2018',
		value: '20'
	},
	{
		text: 'F2018',
		value: '21'
	},
	{
		text: 'G2018',
		value: '22'
	},
	{
		text: 'A',
		value: '1'
	},
	{
		text: 'A2007',
		value: '2'
	},
	{
		text: 'B',
		value: '3'
	},
	{
		text: 'B2007',
		value: '4'
	},
	{
		text: 'C',
		value: '5'
	},
	{
		text: 'C2007',
		value: '6'
	},
	{
		text: 'D',
		value: '7'
	},
	{
		text: 'D2007',
		value: '8'
	},
	{
		text: 'E',
		value: '9'
	},
	{
		text: 'E2007',
		value: '10'
	},
	{
		text: 'F',
		value: '11'
	},
	{
		text: 'F2007',
		value: '12'
	},
	{
		text: 'G',
		value: '13'
	},
	{
		text: 'G2007',
		value: '14'
	},
	{
		text: 'H',
		value: '15'
	},
	{
		text: 'A2013',
		value: '23'
	},
	{
		text: 'B2013',
		value: '24'
	},
	{
		text: 'C2013',
		value: '25'
	},
	{
		text: 'D2013',
		value: '26'
	},
	{
		text: 'E2013',
		value: '27'
	},
	{
		text: 'F2013',
		value: '28'
	},
	{
		text: 'G2013',
		value: '29'
	}
]
export const translateVariable = {
	beach: {
		'1': i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.OWN_BEACH'),
		'2': i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.WATER_RIGHT'),

		'3': i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.OWN_BEACH'),

		'4': i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.CO_OWN_BEACH'),

		'5': i18next.t('PROPERTY.BASICFORM.APARTMENTINFOFORM.BEACHES.NO_BEACH')
	},
	houseType: {
		'0': i18next.t('PROPERTY.BASICFORM.APARTMENT_TYPES.PROPERTY'),
		'1': i18next.t('PROPERTY.BASICFORM.APARTMENT_TYPES.APARTMENT')
	},
	apartmentCondition: {
		'1': i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.GOOD'
		),
		'2': i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.SATISFYING'
		),
		'3': i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.PASSABLE'
		),
		'4': i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.EXCELLENT'
		),
		'5': i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.POOR'
		)
	},
	energyClass: {
		'1': 'A',
		'2': 'A2007',
		'3': 'B',
		'4': 'B2007',
		'5': 'C',
		'6': 'C2007',
		'7': 'D',
		'8': 'D2007',
		'9': 'E',
		'10': 'E2007',
		'11': 'F',
		'12': 'F2007',
		'13': 'G',
		'14': 'G2007',
		'15': 'H',
		'16': 'A2018',
		'17': 'B2018',
		'18': 'C2018',
		'19': 'D2018',
		'20': 'E2018',
		'21': 'F2018',
		'22': 'G2018',
		'23': 'A2013',
		'24': 'B2013',
		'25': 'C2013',
		'26': 'D2013',
		'27': 'E2013',
		'28': 'F2013',
		'29': 'G2013'
	},
	brandNew: {
		true: i18next.t('PROPERTY.BASICFORM.BRAND_NEW.BRAND_NEW'),
		false: i18next.t('PROPERTY.BASICFORM.BRAND_NEW.OLD')
	},
	electricityConnection: {
		true: i18next.t('TRANSLATE_VARIABLE.YES'),
		false: i18next.t('TRANSLATE_VARIABLE.NO')
	},
	plotOwnerShip: {
		true: i18next.t('TRANSLATE_VARIABLE.YES'),
		false: i18next.t('TRANSLATE_VARIABLE.NO')
	}
}
export const conditions = [
	{
		text: i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.EXCELLENT'
		),
		value: '4'
	},
	{
		text: i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.GOOD'
		),
		value: '1'
	},
	{
		text: i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.SATISFYING'
		),
		value: '2'
	},
	{
		text: i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.POOR'
		),
		value: '5'
	},
	{
		text: i18next.t(
			'PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITIONS.PASSABLE'
		),
		value: '3'
	}
]
export const heatingSystemData = [
	{
		text: 'Branch A',
		value: 'Branch A'
	},
	{
		text: 'Branch B',
		value: 'Branch B'
	},
	{
		text: 'Branch C',
		value: 'Branch C'
	}
]
export const waterPipeData = [
	{
		text: 'Branch A',
		value: 'Branch A'
	},
	{
		text: 'Branch B',
		value: 'Branch B'
	},
	{
		text: 'Branch C',
		value: 'Branch C'
	}
]
export const sewerData = [
	{
		text: 'Branch A',
		value: 'Branch A'
	},
	{
		text: 'Branch B',
		value: 'Branch B'
	},
	{
		text: 'Branch C',
		value: 'Branch C'
	}
]
export const stailation = [
	{
		value: 'yes',
		text: i18next.t('PROPERTY.MEDIA.STYLING_ACCEPT')
	},
	{
		value: 'no',
		text: i18next.t('PROPERTY.MEDIA.STYLING_REJECT')
	}
]
export const formField = [
	'housingCompany',
	'category',
	'propertyType',
	'houseType',
	'streetAddress',
	'zipCode',
	'postalCity',
	'city',
	'region',
	'state',
	'country',
	'showMap',
	'apartmentInfo',
	'roomQty',
	'areaApartment',
	'areaRest',
	'areaTotal',
	'areaInfo',
	'floorQty',
	'condition',
	'conditionInfo',
	'brandNew',
	'constYear',
	'energyClass',
	'energyInfo',
	'energyCertificate',
	'informationLabel',
	'freeForMoving',
	'name',
	'phone',
	'email',
	'query',
	'targetpriceFinancing',
	'targetpriceCare',
	'propertyTax',
	'targetpriceElectricuse',
	'targetpriceElectricCharge',
	'targetpriceWater',
	'targetpriceWaterspec',
	'targetpriceHeating',
	'priceInfo',
	'mortgages',
	'deptPart',
	'sauna',
	'balcony',
	'glazedBalcony',
	'elevator',
	'apartmentBelongs',
	'carPreservation',
	'gardenInfo',
	'ventilation',
	'landscape',
	'beach',
	'renovationsDone',
	'renovationsComing',
	'conditionInspection',
	'conditionInspectionDate',
	'humidityMeasure',
	'humidityMeasureDate',
	'asbestosInspection',
	'asbestosInspectionDate',
	'inspectionInfo',
	'heatingSystem',
	'waterPipe',
	'sewer',
	'electricityConnection',
	'mainBuildingMaterial',
	'surfaceMaterialDescription',
	'livingRoom',
	'kitchen',
	'hallway',
	'bedroom',
	'toilet',
	'bathroom',
	'plotOwn',
	'estateId',
	'plotArea',
	'buildingInfo',
	'plotInfo',
	'localServices',
	'commutes'
]
export const mandatoryFormField = [
	'housingCompany',
	'category',
	'propertyType',
	'houseType',
	//  location
	'streetAddress',
	'zipCode',
	'postalCity',
	'city',
	'region',
	'state',
	'country',
	'showMap',
	'apartmentInfo',
	'roomQty',
	'areaApartment',
	'areaRest',
	'areaTotal',
	'areaInfo',
	'floorQty',
	'condition',
	'conditionInfo',
	'brandNew',
	'constYear',
	'energyClass',
	'energyInfo',
	'informationLabel',
	'freeForMoving',
	'name',
	'phone',
	'email',
	'query'
]
export const optionalFormField = [
	'sauna',
	'balcony',
	'glazedBalcony',
	'elevator',
	'apartmentBelongs',
	'carPreservation',
	'gardenInfo',
	'ventilation',
	'landscape',
	'beach',
	'renovationsDone',
	'renovationsComing',
	'conditionInspection',
	'conditionInspectionDate',
	'humidityMeasure',
	'humidityMeasureDate',
	'asbestosInspection',
	'asbestosInspectionDate',
	'inspectionInfo',
	'heatingSystem',
	'waterPipe',
	'sewer',
	'electricityConnection',
	'mainBuildingMaterial',
	'surfaceMaterialDescription',
	'livingRoom',
	'hallway',
	'bedroom',
	'toilet',
	'bathroom',
	'plotOwn',
	'estateId',
	'plotArea',
	'buildingInfo',
	'plotInfo',
	'localServices',
	'commutes'
]
// priceForm
export const priceFormField = ['askingPrice', 'freeFromDebtPrice']
export const priceRequiredField = [
	'askingPriceHasError',
	'freeFromDebtPriceHasError',
	'deptPartHasError',
	'mortgagesHasError',
	'propertyTaxHasError',
	'rentPerYearHasError',
	'chargesInfoHasError',
	'targetpriceFinancingHasError',
	'targetpriceCareHasError',
	'targetpriceHeatingHasError',
	'targetpriceElectricuseHasError',
	'targetpriceWaterHasError',
	'targetpriceWaterspecHasError'
]
export const priceFieldError = [
	'askingPriceError',
	'freeFromDebtPriceError',
	'deptPartError',
	'mortgagesError',
	'propertyTaxError',
	'rentPerYearError',
	'chargesInfoError',
	'targetpriceFinancingError',
	'targetpriceCareError',
	'targetpriceHeatingError',
	'targetpriceElectricuseError',
	'targetpriceWaterError',
	'targetpriceWaterspecError'
]
export const formRequiredField = [
	'phoneHasError',
	'queryHasError',
	'nameHasError',
	'emailHasError',
	'streetAddressHasError',
	'zipCodeHasError',
	'postalCityHasError',
	'cityHasError',
	'regionHasError',
	'stateHasError',
	'countryHasError',
	'askingPriceHasError',
	'conditionInspectionDateHasError',
	'humidityMeasureDateHasError',
	'asbestosInspectionDateHasError'
]
export const formFieldError = [
	'nameError',
	'queryError',
	'emailError',
	'phoneError',
	'streetAddressError',
	'zipCodeError',
	'postalCityError',
	'cityError',
	'regionError',
	'stateError',
	'countryError',
	'askingPriceError',
	'conditionInspectionDateError',
	'humidityMeasureDateError',
	'asbestosInspectionDateError'
]
export const registerFormField = ['firstname', 'lastname', 'email', 'password']
export const mediaFormField = ['defaultImage']
export const publishPagesData = ['oikotie', 'etuovi', 'facebook']

export const saveField = {
	apartment: {
		name: 'apartment',
		field: ['category', 'propertyType', 'houseType', 'housingCompany']
	},
	location: {
		name: 'location',
		field: [
			'streetAddress',
			'zipCode',
			'postalCity',
			'city',
			'region',
			'state',
			'country',
			'showMap'
		]
	},
	roomDetail: {
		name: 'roomDetail',
		field: [
			'apartmentInfo',
			'roomQty',
			'areaApartment',
			'areaRest',
			'areaTotal',
			'areaInfo',
			'floorQty',
			'conditionInfo',
			'brandNew',
			'constYear',
			'energyClass',
			'energyInfo',
			'energyCertificate'
		]
	},
	movingAndInfo: {
		name: 'movingAndInfo',
		field: ['freeForMoving', 'informationLabel']
	},
	personalInfo: {
		name: 'personalInfo',
		field: ['name', 'phone', 'email', 'query']
	},
	expense: {
		name: 'expense',
		field: [
			'targetpriceFinancing',
			'targetpriceCare',
			'propertyTax',
			'targetpriceElectricuse',
			'targetpriceElectricCharge',
			'targetpriceWater',
			'targetpriceWaterspec',
			'targetpriceHeating',
			'priceInfo',
			'mortgages',
			'deptPart'
		]
	},
	propertyInspection: {
		name: 'propertyInspection',
		field: [
			'beach',
			'landscape',
			'gardenInfo',
			'carPreservation',
			'apartmentBelongs',
			'sauna',
			'balcony',
			'glazedBalcony',
			'elevator',
			'renovationsDone',
			'renovationsComing',
			'conditionInspection',
			'conditionInspectionDate',
			'asbestosInspection',
			'asbestosInspectionDate',
			'humidityMeasure',
			'humidityMeasureDate',
			'inspectionInfo'
		]
	},
	homeSystem: {
		name: 'homeSystem',
		field: ['heatingSystem', 'waterPipe', 'sewer', 'electricityConnection']
	},
	plots: {
		name: 'plots',
		field: [
			'plotOwn',
			'plotRental',
			'estateId',
			'plotArea',
			'buildingInfo',
			'plotInfo'
		]
	},
	materials: {
		name: 'materials',
		field: [
			'surfaceMaterialDescription',
			'mainBuildingMaterial',
			'kitchen',
			'livingRoom',
			'hallway',
			'estateId',
			'bedroom',
			'toilet',
			'bathroom'
		]
	},
	services: {
		name: 'services',
		field: ['localServices', 'commutes']
	}
}

export const formCategory = {
	mandatory: 'isMandatoryShown',
	expense: 'isExpenseShown',
	optional: 'isOptionalShown',
	apartment: 'isApartmentInfoShown',
	material: 'isMaterialShown',
	plot: 'isPlotShown',
	link: 'isLinkShown'
}
export const toCurrency = number => {
	if (!number) {
		return undefined
	}
	return new Intl.NumberFormat('fi-FI', {
		style: 'currency',
		currency: 'EUR'
	})
		.format(number)
		.toString()
}

export const fileEndpoint = 'https://kotidiili.ams3.cdn.digitaloceanspaces.com'
export const currencySign = '€'
export const monthlyPriceSign = '€/kk'
export const AreaUnit = () => (
	<span>
		{' '}
		m <sup> 2 </sup>{' '}
	</span>
)

export const defaultPropertiesShownHomepageView = 6
export const defaultPropertiesShownPropertiesView = 9
export const previewShowingRequest = [
	{
		text: i18next.t('PREVIEW.SHOWING_REQUEST.NEXT_SHOWING_NOTIFICATION.YES'),
		value: 'true'
	},
	{
		text: i18next.t('PREVIEW.SHOWING_REQUEST.NEXT_SHOWING_NOTIFICATION.NO'),
		value: 'false'
	}
]
export const pageRangeDisplayed = 5
export const notificationStatus = {
	success: 'SUCCESS',
	warning: 'WARNING',
	fail: 'FAIL'
}
export const certificateTypes = [
	{
		text: 'Manager Certificate',
		value: 'managerCertificate'
	},
	{
		text: 'Condition Certificate',
		value: 'conditionCertificate'
	},
	{
		text: 'Asbestos Certificate',
		value: 'asbestosCertificate'
	},
	{
		text: 'Humidity Certificate',
		value: 'humidityCertificate'
	},
	{
		text: 'Floor plan',
		value: 'floorPlan'
	}
]
export const ngrokUrl = 'https://65b6c87b.ngrok.io'
export const userRole = [
	{
		text: 'Buyer',
		value: 'true'
	},
	{
		text: 'Seller',
		value: 'false'
	}
]
export const normalizeUserRole = {
	buyer: 'buyer',
	seller: 'seller'
}
