import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

import english from '../locale/english.json'
import finnish from '../locale/finnish.json'

i18next
	// load translation using xhr -> see /public/locales
	// learn more: https://github.com/i18next/i18next-xhr-backend
	.use(Backend)
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		interpolation: {
			escapeValue: false
		},
		resources: {
			en: {
				translation: english
			},
			fi: {
				translation: finnish
			}
		},
		lng: 'en',
		fallbackLng: 'en',
		debug: false, //true,
		react: {
			wait: true
		}
	})
export default i18next
