import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { validateFieldByName } from '../utils'
// Component
import Inputs from 'shared/components/Inputs'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import Checkbox from 'shared/components/Checkbox'
import RadioButton from 'shared/components/RadioButton'

// actions
import {
	registerChangeField,
	registerValidateField,
	register,
	registerResetError
} from '@actions'
// constants
import { navigationPath, userRole } from '@constants'
// Reducers
import { registerSelectors } from 'reducers'

class Register extends React.Component {
	constructor(props) {
		super(props)
		const { t } = this.props
		this.state = {
			isPasswordShown: false,
			passwordToggleText: t('REGISTER.FORM.BUTTON.TOGGLE_SHOW_PASSWORD')
		}
	}

	handleChangeField = e => {
		const { name, value, checked, type } = e.target
		const { dispatch } = this.props
		dispatch(registerResetError())
		let normalizeValue = value
		if (value == 'true') {
			normalizeValue = true
		}
		if (value == 'false') {
			normalizeValue = false
		}
		if (type == 'checkbox') {
			return this.props.dispatch(registerChangeField(name, checked))
		}

		return this.props.dispatch(registerChangeField(name, normalizeValue))
	}
	handleClickInputIcon = () => {
		const { isPasswordShown, passwordToggleText } = this.state
		const { t } = this.props
		let toggleText = isPasswordShown
			? t('REGISTER.FORM.BUTTON.TOGGLE_SHOW_PASSWORD')
			: t('REGISTER.FORM.BUTTON.TOGGLE_HIDE_PASSWORD')
		this.setState({
			isPasswordShown: !this.state.isPasswordShown,
			passwordToggleText: toggleText
		})
	}
	handleFocusInput = e => {
		const { name, value } = e.target
		this.props.dispatch(registerValidateField(name, false, ''))
	}
	validateRequest = (name, value, text) => {
		this.props.dispatch(registerValidateField(name, value, text))
	}
	handleBlurInput = e => {
		const { name, value } = e.target
		const { dispatch } = this.props
		if (value == '') {
			dispatch(
				registerValidateField(name, true, this.props.t('ERRORS.REQUIRED'))
			)
		} else {
			validateFieldByName(name, value, this.validateRequest)
		}
	}
	normalizeInput = data => {
		const { t, requiredFieldHasError, validateError } = this.props
		const { passwordToggleText, isPasswordShown } = this.state
		let personalInfoInputs = []
		data.map(item => {
			if (item == 'password') {
				return personalInfoInputs.push({
					label: t(`REGISTER.FORM.${item.toUpperCase()}`),
					name: `${item}`,
					type: isPasswordShown ? 'text' : 'password',
					inputText: passwordToggleText,
					value: this.props[item],

					required: true,
					hasError: requiredFieldHasError[item],
					error: validateError[item]
				})
			}
			return personalInfoInputs.push({
				label: t(`REGISTER.FORM.${item.toUpperCase()}`),
				name: item,
				value: this.props[item],
				required: true,
				hasError: requiredFieldHasError[item],
				error: validateError[item]
			})
		})
		return personalInfoInputs
	}
	handleClickRegister = () => {
		const {
			history,
			dispatch,
			firstName,
			lastName,
			email,
			password,
			phoneNumber,
			streetAddress,
			postalCode,
			city,
			isBuyer
		} = this.props
		let data = {
			isBuyer,
			firstName,
			lastName,
			email,
			password,
			phoneNumber,
			address: streetAddress,
			postalCode,
			city
		}
		dispatch(register(data))
			.then(res => {
				if (res.payload.data) {
					history.push(navigationPath.welcome)
				}
			})
			.catch(error => {})
	}
	render() {
		const {
			t,
			isDisabledSubmit,
			isPolicyAgreed,
			loading,
			error,
			isBuyer
		} = this.props
		const dataInput = [
			'firstName',
			'lastName',
			'phoneNumber',
			'email',
			'password',
			'streetAddress',
			'postalCode',
			'city'
		]
		return (
			<div className="register-container">
				<h1>{t('REGISTER.TITLE')}</h1>
				<p>{t('REGISTER.DESCRIPTION')}</p>

				<div className="registerForm">
					<form onSubmit={this.handleClickRegister}>
						<div className="registerForm-fields">
							<div className=" field-content">
								<Inputs
									data={this.normalizeInput(dataInput)}
									onChange={this.handleChangeField}
									handleBlurInput={this.handleBlurInput}
									handleFocusInput={this.handleFocusInput}
									handleClickInputIcon={this.handleClickInputIcon}
								/>
								<RadioButton
									label="I am a..."
									options={userRole}
									value={`${isBuyer}`}
									name="isBuyer"
									handleCheckRadioButton={this.handleChangeField}
								/>
								<Checkbox
									description={`${t('REGISTER.FORM.AGREE_POLICY')}`}
									name="isPolicyAgreed"
									value={isPolicyAgreed}
									handleClickCheckbox={this.handleChangeField}
								/>
							</div>
						</div>
						<div className="formButton">
							{error ? <span className="error">{error.message}</span> : null}
							{loading ? (
								<Loading />
							) : (
								<Button
									title="Rekisteröidy"
									disabled={isDisabledSubmit}
									onClick={this.handleClickRegister}
								/>
							)}
						</div>
					</form>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	isBuyer: registerSelectors.getIsBuyer(state.registerReducer),
	streetAddress: registerSelectors.getStreetAddress(state.registerReducer),
	postalCode: registerSelectors.getPostalCode(state.registerReducer),
	city: registerSelectors.getCity(state.registerReducer),
	firstName: registerSelectors.getFirstName(state.registerReducer),
	lastName: registerSelectors.getLastName(state.registerReducer),
	email: registerSelectors.getEmail(state.registerReducer),
	password: registerSelectors.getPassword(state.registerReducer),
	requiredFieldHasError: registerSelectors.getRequiredFieldHasError(
		state.registerReducer
	),
	phoneNumber: registerSelectors.getPhoneNumber(state.registerReducer),
	validateError: registerSelectors.getValidateError(state.registerReducer),
	isDisabledSubmit: registerSelectors.getIsDisabledSubmit(
		state.registerReducer
	),
	isPolicyAgreed: registerSelectors.getIsPolicyAgreed(state.registerReducer),
	error: registerSelectors.getError(state.registerReducer),
	loading: registerSelectors.getLoading(state.registerReducer)
})

export default connect(mapStateToProps)(withRouter(withTranslation()(Register)))
