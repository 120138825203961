import { UPDATE_SEARCH_VALUE } from '../actions/types'

const initialState = {
	search: ''
}

const reducer = (state = initialState, action) => {
	switch (action.type) {
		case UPDATE_SEARCH_VALUE:
			return { ...state, search: action.payload }
		default:
			return state
	}
}

export const getSearch = (state = initialState) => state.search

export default reducer
