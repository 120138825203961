/**
 * Login reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import {
	LOGIN,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGIN_CHANGE_FIELD,
	LOGIN_VALIDATE_FIELD,
	LOGIN_RESET_ERROR,
	LOGIN_RESET_FORM
} from '@actions/types'
import { normalizeError } from './utils'
import { normalizeUserRole } from '@constants'
import { getAuthenticationData } from './../utils'

const initialState = {
	bearer: null,
	email: '',
	password: '',
	rememberPassword: false,
	user: null,
	requiredFieldHasError: {
		email: false,
		password: false
	},
	validateError: {
		email: '',
		password: ''
	},
	loading: false,
	error: null
}

export const loginReducer = (state = initialState, action) => {
	switch (action.type) {
		case LOGIN:
			return {
				...state,
				loading: true
			}
		case LOGIN_SUCCESS:
			const { bearer, user } = action.payload.data
			let role
			if (user.isBuyer) {
				role = normalizeUserRole.buyer
			} else {
				role = normalizeUserRole.seller
			}
			localStorage.setItem('bearer', bearer)
			localStorage.setItem('userId', user.id)
			localStorage.setItem('role', role)
			return {
				...state,
				loading: false,
				bearer,
				user
			}
		case LOGIN_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case LOGIN_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case LOGIN_VALIDATE_FIELD:
			const { nameField, hasError, error } = action
			return {
				...state,
				requiredFieldHasError: {
					...state.requiredFieldHasError,
					[nameField]: hasError
				},
				validateError: {
					...state.validateError,
					[nameField]: error
				}
			}
		case LOGIN_RESET_ERROR:
			return {
				...state,
				error: null
			}
		case LOGIN_RESET_FORM:
			return {
				...state,
				email: '',
				password: ''
			}
		default:
			return state
	}
}

export const getUser = (state = initialState) => {
	return state.user
}

export const getBearer = (state = initialState) => {
	return state.bearer
}

export const getLoginEmail = (state = initialState) => state.email
export const getLoginPassword = (state = initialState) => state.password
export const getLoginRememberPassword = (state = initialState) =>
	state.rememberPassword
export const getLoginLoading = (state = initialState) => state.loading
export const getLoginError = (state = initialState) => state.error
export const getLoginValidateError = (state = initialState) =>
	state.validateError
export const getLoginRequiredFieldHasError = (state = initialState) =>
	state.requiredFieldHasError
