/**
 * Protected route
 * Only let user access the route if user has been authenticated
 * Otherwise redirect user to login page
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { showNotification } from '@actions'
import { notificationStatus } from '@constants'
import { Route, Redirect } from 'react-router-dom'
import AuthChecker from './AuthChecker'
// utils
import { getAuthenticationData } from './../../../utils'
const ProtectedRoute = ({
	component: Component,
	role,
	showNoti,
	path,
	...rest
}) => {
	const isAccessible = () => {
		const userRole = getAuthenticationData('role')
		if (!role) {
			return true
		}
		return userRole == role
	}
	const redirectRoute = () => {
		showNoti()
		return <Redirect to="/login" />
	}
	return (
		<AuthChecker>
			{({ isAuthenticated, userRole }) => (
				<Route
					{...rest}
					render={props =>
						isAuthenticated && isAccessible() ? (
							<Component {...props} />
						) : (
							redirectRoute()
						)
					}
				/>
			)}
		</AuthChecker>
	)
}
const mapDispatchToProps = dispatch => ({
	showNoti: () =>
		dispatch(
			showNotification({
				status: notificationStatus.fail,
				message: i18next.t('NOTIFICATION.IS_NOT_ACCESSIBLE')
			})
		)
})
export default connect(
	null,
	mapDispatchToProps
)(ProtectedRoute)
