/* actions helpers
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 */

function capitalizeFirstLetter(string) {
	return string.charAt(0).toUpperCase() + string.substring(1)
}
export { capitalizeFirstLetter }
