/**
 * A Reusable wrapper component
 * Define authentication state and pass it to its children
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import { normalizeUserRole } from '@constants'
import { getUserRole } from 'reducers'
class AuthCheck extends Component {
	render() {
		let userId = localStorage.getItem('userId')
		const isAuthenticated = userId ? true : false
		const stronglyAuthenticated = this.props.stronglyAuthenticated
		const identificationVerified = this.props.identificationVerified
		return this.props.children({
			isAuthenticated,
			stronglyAuthenticated,
			identificationVerified
		})
	}
}

const mapStateToProps = state => ({
	stronglyAuthenticated: state.loginReducer.user
		? state.loginReducer.user.isStronglyAuthenticated
		: true,
	identificationVerified: state.loginReducer.user
		? state.loginReducer.user.identificationVerified
		: false
})

export default connect(mapStateToProps)(AuthCheck)
