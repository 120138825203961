import React from 'react'
import { withTranslation } from 'react-i18next'

const Service = ({ handleClickService, t }) => {
	const handleClick = type => {
		if (handleClickService && typeof handleClickService === 'function') {
			return handleClickService(type)
		}
	}
	return (
		<div className="dashboard-content_hover">
			<h2>{t('DASHBOARD.BOXES.SERVICE.TITLE')}</h2>
			<div className="content">
				<div className="item" onClick={() => handleClick('photography')}>
					{t('DASHBOARD.BOXES.SERVICE.PHOTOGRAPHY')}
				</div>
				<div className="item" onClick={() => handleClick('insurance')}>
					{t('DASHBOARD.BOXES.SERVICE.INSURANCE')}
				</div>
				<div className="item" onClick={() => handleClick('humidityMeasure')}>
					{t('DASHBOARD.BOXES.SERVICE.HUMIDITY_MEASUREMENT')}
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(Service)
