import React from 'react'
import { withTranslation } from 'react-i18next'

// component
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import JethronTip from 'shared/components/JethronTip'
const DocumentCheck = ({ property, handleDocumentRead, isDocumentRead, t }) => {
	const address = property
		? `${property.streetAddress} ${property.postalCode} ${property.city}`
		: 'address'
	const handleChangeField = e => {
		if (handleDocumentRead && typeof handleDocumentRead === 'function') {
			handleDocumentRead(e)
		}
	}
	return (
		<>
			<div className="bid-documentCheck">
				<div className="bid-documentCheck_header">
					<h4>{t('BUYER.BID_FORM.DOCUMENT_CHECK.TITLE')}</h4>
					<h1>{t('BUYER.BID_FORM.DOCUMENT_CHECK.DESC')}</h1>
				</div>
				<Checkbox
					description={t('BUYER.BID_FORM.DOCUMENT_CHECK.CHECKBOX_DESC')}
					name="isDocumentRead"
					value={isDocumentRead}
					handleClickCheckbox={handleChangeField}
				/>
				<div className="bid-documentCheck_description">
					{t('BUYER.BID_FORM.DOCUMENT_CHECK.DOCUMENT_CHECK_INFO')} {address}{' '}
					<span>{t('BUYER.BID_FORM.DOCUMENT_CHECK.DOCUMENTS_CHECKLIST')}</span>
				</div>
			</div>
		</>
	)
}
export default withTranslation()(DocumentCheck)
