/**
 * Generic select components
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React, { Fragment } from 'react'
import Textarea from 'shared/components/Textarea'
const Textareas = ({ data, handleChangeTextarea }) => {
	const renderTextAreas = () => {
		return data.map((item, key) => {
			if (item.name == '') {
				return <div key={key}></div>
			}
			return (
				<Textarea
					key={key}
					name={item.name}
					value={item.value}
					placeholder={item.placeholder}
					handleChangeTextarea={handleChangeTextarea}
					label={item.label}
				/>
			)
		})
	}
	if (!data || data.length == 0) {
		return null
	}
	return <Fragment>{renderTextAreas()}</Fragment>
}

export default Textareas
