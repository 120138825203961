import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

import { withRouter } from 'react-router-dom'
//actions
import { publishActions, propertyUpdate } from '@actions'
// reducers
import { publishSelectors } from 'reducers'
// components
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
// utils
import { getAuthenticationData } from './../../../utils'
//constants
class Publish extends Component {
	constructor(props) {
		super(props)
	}
	handlePublishLater = () => {
		this.props.history.push('/dashboard')
	}
	handlePublish = () => {
		// todo
		const propertyId = getAuthenticationData('selectedPropertyId')
		const userId = getAuthenticationData('userId')
		this.props
			.dispatch(propertyUpdate(propertyId, userId, { needToCheck: true }))
			.then(res => {
				if (res.payload.data) {
					this.props.history.push('/dashboard')
				}
			})
	}
	render() {
		const { loading, error, t } = this.props

		if (loading) {
			return <Loading />
		}
		return (
			<div className="form-topic_content publishForm">
				<div className="form-topic_content-center">
					<div className="header">
						<h1>{t('PROPERTY.PUBLISH.TITLE')}</h1>
						<p>{t('PROPERTY.PUBLISH.DESC')}</p>
					</div>
					<div className="formButton">
						<Button
							title={t('PROPERTY.PUBLISH.BUTTON.PUBLISH_NOW')}
							onClick={this.handlePublish}
						/>
						<Button
							title={t('PROPERTY.PUBLISH.BUTTON.PUBLISH_LATER')}
							onClick={this.handlePublishLater}
						/>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	loading: publishSelectors.getLoading(state.publishReducer),
	error: publishSelectors.getError(state.publishReducer)
})

export default withTranslation()(withRouter(connect(mapStateToProps)(Publish)))
