import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

// component
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import Modal from 'shared/components/Modal'
import ShowingList from 'pages/Preview/ShowingList'
import Certificate from 'pages/Property/Components/Certificate'

//utils
import { getAuthenticationData } from './../../utils'
import { routerPath, translateVariable, fileEndpoint } from '@constants'

// actions
import { propertyGetProperty, propertyDeleteProperty } from '@actions'
// reducers
import {
	getPropertySelectors,
	formSelectors,
	priceSelectors,
	mediaSelectors
} from 'reducers'
// img
import property_sample from 'assets/images/property_sample.png'

class DraftProperty extends React.Component {
	constructor(props) {
		super(props)
		this.state = {
			isCertificateShown: false
		}
	}

	componentDidMount() {
		const propertyId = getAuthenticationData('selectedPropertyId')
		this.props.dispatch(propertyGetProperty(propertyId))
	}

	handlePreviewProperty = () => {
		const propertyId = getAuthenticationData('selectedPropertyId')
		this.props.history.push(`preview/${propertyId}`)
	}

	handleEditProperty = id => {
		this.props.history.push(`/property/${id}/basic`)
	}

	handleDeleteProperty = id => {
		const userId = getAuthenticationData('userId')
		this.props.dispatch(propertyDeleteProperty(userId, id))
	}
	handleShowCertificate = () => {
		this.setState({
			isCertificateShown: true
		})
	}
	handleCloseCertificate = () => {
		this.setState({
			isCertificateShown: false
		})
	}

	renderDraft = () => {
		const {
			updatedAt,
			properties,
			t,
			history,
			loading,
			defaultImage,
			askingPrice,
			streetAddress,
			zipCode,
			city,
			region,
			areaTotal,
			roomQty,
			houseType
		} = this.props
		const { isCertificateShown } = this.state
		const selectedPropertyId = getAuthenticationData('selectedPropertyId')
		if (loading) return <Loading />

		return (
			<div className="draft-item">
				<div className="draft-item_header">
					<p>
						{(streetAddress || t('DRAFTS.ADDRESS_PLACEHOLDER')) +
							' ' +
							(zipCode || t('DRAFTS.ZIP_CODE_PLACEHOLDER')) +
							', ' +
							(city || t('DRAFTS.CITY_PLACEHOLDER'))}
					</p>
				</div>
				<div className="draft-item_apartmentinfo">
					<div className="apartmentinfo-container">
						<img
							src={
								defaultImage
									? `${fileEndpoint}/${defaultImage}`
									: property_sample
							}
						/>
						<div className="apartmentinfo-container-content">
							<div className="apartmentinfo-container-content_column">
								<p>{t('DRAFTS.PROPERTY_TYPE')}</p>
								<p>{t('DRAFTS.ROOM_COMPOSITION')}</p>
								<p>{t('DRAFTS.AREA')}</p>
								<p>{t('DRAFTS.PRICE')}</p>
							</div>
							<div className="apartmentinfo-container-content_column">
								<p>
									{translateVariable['houseType'][`${houseType}`] ||
										t('DRAFTS.UNKNOWN')}
								</p>
								<p>{roomQty || t('DRAFTS.UNKNOWN')}</p>
								<p>{areaTotal != 0 ? areaTotal : '?'} m2</p>
								<p>{askingPrice != 0 ? askingPrice : '?'} €</p>
							</div>
						</div>
					</div>
				</div>

				<div className="inComingShowing">
					<div className="showingList-container">
						<ShowingList />
					</div>
					<div className="saveTime">
						{moment(updatedAt).format('DD/MM HH:mm')}{' '}
						{t('DRAFTS.SAVE_INCOMPLETED')}
					</div>
				</div>
				<div className="draft-item_buttongroup">
					<Button
						className="button__pink"
						onClick={() => this.handlePreviewProperty()}
						title={t('DRAFTS.PREVIEW')}
					/>
					<Button
						className="button__black"
						onClick={() => this.handleEditProperty(selectedPropertyId)}
						title={t('DRAFTS.EDIT')}
					/>
					{/* <Button
						className="button__white"
						onClick={() => this.handleDeleteProperty(selectedPropertyId)}
						title={t('DRAFTS.DELETE')}
					/> */}
				</div>
			</div>
		)
	}
	render() {
		const { t, history, isManager } = this.props
		const { isCertificateShown } = this.state
		return (
			<div className="drafts-container">
				<h1>{t('DRAFTS.TITLE')}</h1>
				<div className="drafts-container_description">
					{isManager ? (
						<i className="material-icons isManager">done</i>
					) : (
						<i className="material-icons">done</i>
					)}
					<div className="certificate">
						<p onClick={this.handleShowCertificate}>
							{t('DRAFTS.CERTIFICATE.LABEL')}
						</p>
						{isCertificateShown && (
							<Modal
								title="certificate"
								handleCloseModal={this.handleCloseCertificate}
							>
								<Certificate />
							</Modal>
						)}
					</div>
				</div>

				{this.renderDraft()}

				<Button className="back-button" title={t('GENERAL.FORMS.BACK')} />
			</div>
		)
	}
}

const mapStateToProps = state => ({
	isManager: mediaSelectors.checkIsManager(state.mediaFormReducer),
	files: mediaSelectors.getFiles(state.mediaFormReducer),
	updatedAt: formSelectors.getUpdatedAt(state.formReducer),
	loading: getPropertySelectors.getLoading(state.propertyReducer),
	defaultImage: mediaSelectors.getDefaultImage(state.mediaFormReducer),
	askingPrice: priceSelectors.getAskingPrice(state.priceFormReducer),
	streetAddress: formSelectors.getStreetAddress(state.formReducer),
	zipCode: formSelectors.getZipCode(state.formReducer),
	city: formSelectors.getCity(state.formReducer),
	region: formSelectors.getRegion(state.formReducer),
	areaTotal: formSelectors.getAreaTotal(state.formReducer),
	roomQty: formSelectors.getRoomQty(state.formReducer),
	houseType: formSelectors.getHouseType(state.formReducer)
})

export default connect(mapStateToProps)(
	withRouter(withTranslation()(DraftProperty))
)
