import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
//actions
import { bankAuthConnectBank } from '@actions'
// components
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import Modal from 'shared/components/Modal'

// reducers
import { getBankAuthLoading, getBankAuthError } from 'reducers'
class BankAuth extends Component {
	handleBankConnect = () => {
		this.props
			.dispatch(bankAuthConnectBank())
			.then(res => {
				if (res.payload.data) {
					const { data: url } = res.payload
					window.location.href = url
				}
			})
			.catch(error => console.log(error))
	}
	goToDashboard = () => {
		this.props.history.push('/dashboard')
	}

	renderBankAuthSuccess = () => {
		const { t } = this.props
		return (
			<div className="bankAuth-container">
				<div className="bankAuth-header">
					<h1>{t('BANK_RECOGNISATION.SUCCESS_TITLE')}</h1>
					{this.renderContent}
					<p>{t('BANK_RECOGNISATION.SUCCESS_DESC')}</p>
				</div>
				<div className="bankAuth-button">
					<Button
						title={t('GENERAL.FORMS.NEXT')}
						onClick={this.goToDashboard}
					/>
				</div>
			</div>
		)
	}
	handleCloseConfirmEmail = () => {
		this.setState({
			isConfirmModalShown: false
		})
	}
	renderBankAuthFailed = () => {
		const { t } = this.props
		return (
			<div className="bankAuth-container">
				<div className="bankAuth-header">
					<h1>{t('BANK_RECOGNISATION.FAILED_TITLE')}</h1>
					{this.renderContent}
					<p>{t('BANK_RECOGNISATION.FAILED_DESC')}</p>
				</div>
			</div>
		)
	}
	handleVerifyEmail = () => {
		// this.props.dispatch()
	}
	renderBankAuthRequest = () => {
		const { t, loading, error, title, desc, connectLater } = this.props

		return (
			<div className="bankAuth-container">
				<div className="bankAuth-header">
					{title && <h1>{title}</h1>}
					{this.renderContent}
					{desc && <p>{desc}</p>}
				</div>
				<div className="bankAuth-button">
					{error ? <span className="error">{error.message}</span> : null}
					{loading ? (
						<Loading />
					) : (
						<Button
							className="connect"
							title={t('BANK_RECOGNISATION.BUTTON.BANK_CONNECT')}
							onClick={this.handleBankConnect}
						/>
					)}
					{connectLater && (
						<Button
							className="bankConnectLater"
							title={t('BANK_RECOGNISATION.BUTTON.RECOGNISATION_LATER')}
							onClick={this.goToDashboard}
						/>
					)}
				</div>
			</div>
		)
	}
	render() {
		const { status } = this.props.match.params
		switch (status) {
			case 'request':
				return this.renderBankAuthRequest()
			case 'success':
				return this.renderBankAuthSuccess()
			case 'failed':
				return this.renderBankAuthFailed()
			default:
				return this.renderBankAuthRequest()
		}
	}
}
const mapStateToProps = state => ({
	loading: getBankAuthLoading(state.bankAuthReducer),
	error: getBankAuthError(state.bankAuthReducer)
})
export default withRouter(connect(mapStateToProps)(withTranslation()(BankAuth)))
