import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

// components
import Button from 'shared/components/Button'

class Verification extends Component {
	goToWelcome = () => {
		this.props.history.push('/welcome')
	}

	renderVerificationSuccess = () => {
		const { t } = this.props
		return (
			<div className="verification-container">
				<div className="verification-header">
					<h1>{t('VERIFICATION.SUCCESS')}</h1>
					{this.renderContent}
					<p>{t('VERIFICATION.SUCCESS_DESC')}</p>
				</div>
				<div className="verification-button">
					<Button
						title={t('HOMEPAGE.SELLING.BUTTON')}
						onClick={this.goToWelcome}
					/>
				</div>
			</div>
		)
	}

	renderVerificationFailed = () => {
		const { t } = this.props
		return (
			<div className="verification-container">
				<div className="verification-header">
					<h1>{t('VERIFICATION.FAILED')}</h1>
					{this.renderContent}
					<p>{t('VERIFICATION.FAILED_DESC')}</p>
				</div>
			</div>
		)
	}

	render() {
		const { status } = this.props.match.params
		switch (status) {
			case 'success':
				return this.renderVerificationSuccess()
			case 'failed':
				return this.renderVerificationFailed()
			default:
				return this.renderVerificationFailed()
		}
	}
}
const mapStateToProps = state => ({})
export default withRouter(
	connect(mapStateToProps)(withTranslation()(Verification))
)
