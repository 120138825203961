/**
 * Reusable button component
 *
 * @author Anh Tu Le<anh.le@vertics.co>
 *
 */
import React from 'react'

const Button = props => {
	const { title, className, onClick, type, disabled } = props

	let buttonClass = ['button', 'button-wrapper']

	if (className) {
		buttonClass.push(className)
	}

	return (
		<div className="button-container">
			<button
				className={buttonClass.join(' ')}
				onClick={onClick}
				type={type}
				disabled={disabled}
			>
				<div className="button__content">{title}</div>
			</button>
		</div>
	)
}

export default Button
