import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'

// Component
import Input from 'shared/components/Input'
import Inputs from 'shared/components/Inputs'
import RadioButton from 'shared/components/RadioButton'
import Selects from 'shared/components/Selects'
import Select from 'shared/components/Select'
import Checkbox from 'shared/components/Checkbox'
import Textarea from 'shared/components/Textarea'
import JethronTip from 'shared/components/JethronTip'
import FormTopic from 'pages/Property/Forms/FormTopic'
import SaveBookmark from 'shared/components/SaveBookmark'
// Reducers
import { formSelectors, getFormCategory, getFormPhone } from 'reducers'
// constants
import {
	properties,
	AreaUnit,
	apartments,
	formCategory,
	conditions,
	saveField,
	energyClasses,
	energyCertificates
} from '@constants'
class MandatoryForm extends React.Component {
	constructor() {
		super()
	}

	render() {
		const {
			handleChangeRadio,
			isSectionSaved,
			handleClickSave,
			handleToggleFormTopic,
			t,
			isTopicActive,
			mandatoryFormData,
			requiredFieldHasError,
			handleBlurInput,
			handleFocusInput,
			handleChangeField,
			propertyType,
			category,
			houseType,
			streetAddress,
			zipCode,
			postalCity,
			city,
			state,
			region,
			country,
			showMap,
			informationLabel,
			energyCertificate,
			energyClass,
			apartmentInfo,
			roomQty,
			validateError,
			areaApartment,
			areaRest,
			areaTotal,
			areaInfo,
			floorQty,
			conditionInfo,
			phone,
			housingCompany,
			query,
			condition,
			isSectionLoading
		} = this.props
		const ItemCreationData = [
			// {
			// 	options: categories,
			// 	label: `${t('INFORMATION.BASICFORM.CATEGORY')}*`,
			// 	name: 'category',
			// 	value: category
			// },
			{
				options: properties,
				label: `${t('PROPERTY.BASICFORM.PROPERTY_TYPE')}*`,
				name: 'propertyType',
				value: propertyType
			}
		]
		const postiLocationInputs = [
			{
				label: `${t('PROPERTY.BASICFORM.STREET_ADDRESS')}*`,
				name: 'streetAddress',
				value: streetAddress,
				required: true,
				hasError: requiredFieldHasError['streetAddressHasError'],
				error: validateError['streetAddressError']
			},
			{ label: '', name: '', value: '' },
			{
				label: `${t('PROPERTY.BASICFORM.ZIPCODE')}*`,
				name: 'zipCode',
				value: zipCode,
				required: true,
				hasError: requiredFieldHasError['zipCodeHasError'],
				error: validateError['zipCodeError']
			},
			{
				label: `${t('PROPERTY.BASICFORM.POSTALCITY')}*`,
				name: 'postalCity',
				value: postalCity,
				required: true,
				hasError: requiredFieldHasError['postalCityHasError'],
				error: validateError['postalCityError']
			}
		]
		const normalLocationInputs = [
			{
				label: `${t('PROPERTY.BASICFORM.CITY')}*`,
				name: 'city',
				value: city,
				required: true,
				hasError: requiredFieldHasError['cityHasError'],
				error: validateError['cityError']
			},
			{
				label: `${t('PROPERTY.BASICFORM.REGION')}*`,
				name: 'region',
				value: region,
				required: true,
				hasError: requiredFieldHasError['regionHasError'],
				error: validateError['regionError']
			},
			{
				label: `${t('PROPERTY.BASICFORM.STATE')}*`,
				name: 'state',
				value: state,
				required: true,
				hasError: requiredFieldHasError['stateHasError'],
				error: validateError['stateError']
			},
			{
				label: `${t('PROPERTY.BASICFORM.COUNTRY')}*`,
				name: 'country',
				value: country,
				required: true,
				hasError: requiredFieldHasError['countryHasError'],
				error: validateError['countryError']
			}
		]
		const apartmentInfoInputs = [
			{
				label: `${t('PROPERTY.BASICFORM.APARTMENT_INFO')}*`,
				name: 'apartmentInfo',
				value: apartmentInfo,
				required: true,
				hasError: requiredFieldHasError['apartmentInfoHasError'],
				error: validateError['apartmentInfoError']
			},
			{
				label: `${t('PROPERTY.BASICFORM.ROOM_QTY')}*`,
				type: 'number',
				name: 'roomQty',
				value: roomQty,
				required: true,
				hasError: requiredFieldHasError['roomQtyHasError'],
				error: validateError['roomQtyError']
			}
		]
		const areaInfoInputs = [
			{
				label: `${t('PROPERTY.BASICFORM.APARTMENT_AREA')}*`,
				name: 'areaApartment',
				value: areaApartment,
				required: true,
				hasError: requiredFieldHasError['areaApartmentHasError'],
				error: validateError['areaApartmentError'],
				inputText: <AreaUnit />
			},
			{
				label: `${t('PROPERTY.BASICFORM.OTHER_AREA')}*`,
				name: 'areaRest',
				value: areaRest,
				inputText: <AreaUnit />
			},
			{
				label: `${t('PROPERTY.BASICFORM.TOTAL_AREA')}*`,
				name: 'areaTotal',
				value: areaTotal,
				inputText: <AreaUnit />
			}
		]
		const personalInfoInputs = [
			{
				label: `${t('PROPERTY.BASICFORM.NAME')}*`,
				name: 'name',
				value: mandatoryFormData.name,
				required: true,
				hasError: requiredFieldHasError['nameHasError'],
				error: validateError['nameError']
			},
			{ label: '', name: '', value: '' },
			{
				label: `${t('PROPERTY.BASICFORM.PHONE')}*`,
				name: 'phone',
				value: phone,
				required: true,
				hasError: requiredFieldHasError['phoneHasError'],
				error: validateError['phoneError']
			},
			{
				label: `${t('PROPERTY.BASICFORM.EMAIL')}*`,
				name: 'email',
				value: mandatoryFormData.email,
				required: true,
				hasError: requiredFieldHasError['emailHasError'],
				error: validateError['emailError']
			}
		]
		return (
			<div className="mandatoryForm-container">
				<FormTopic
					title={t('PROPERTY.BASICFORM.TOPICS.MANDATORY')}
					onClickAction={() => handleToggleFormTopic(formCategory.mandatory)}
					isActive={isTopicActive}
				/>
				<div
					className={
						'form-topic_content mandatoryForm' +
						(isTopicActive ? ' active' : ' hidden')
					}
				>
					<div className="form-topic_content-center">
						<div className="wrapper-with-tips field-content withSaved">
							<div className="field">
								<Selects
									data={ItemCreationData}
									handleSelectOption={handleChangeField}
								/>
								<RadioButton
									label={`${t('PROPERTY.BASICFORM.APARTMENT')}*`}
									options={apartments}
									value={`1`}
									disabled={true}
									name="houseType"
									// handleCheckQ={handleChangeField}
								/>
								<Input
									label={`${t('PROPERTY.BASICFORM.HOUSING_COMPANY')}`}
									name="housingCompany"
									value={housingCompany}
									onChange={handleChangeField}
								/>
							</div>
							<JethronTip />
							<SaveBookmark
								isSaved={isSectionSaved.apartment}
								sectionName={saveField.apartment.name}
								handleClickSave={handleClickSave}
								isLoading={isSectionLoading.apartment}
							/>
						</div>
						<div className="field-content">
							<Inputs
								data={postiLocationInputs}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className=" field-content">
							<Inputs
								data={normalLocationInputs}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
							<Checkbox
								description={`${t('PROPERTY.BASICFORM.SHOW_MAP')}`}
								name="showMap"
								value={showMap}
								handleClickCheckbox={handleChangeField}
								handleFocusInput={handleFocusInput}
							/>
							<div></div>
							<SaveBookmark
								isSaved={isSectionSaved.location}
								sectionName={saveField.location.name}
								handleClickSave={handleClickSave}
								isLoading={isSectionLoading.location}
							/>
						</div>

						<div className=" field-content">
							<Inputs
								data={apartmentInfoInputs}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className="field-content">
							<Inputs
								data={areaInfoInputs}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className=" field-content">
							<Textarea
								label={`${t('PROPERTY.BASICFORM.AREA_INFO')}`}
								value={areaInfo}
								name="areaInfo"
								handleChangeTextarea={handleChangeField}
							/>
						</div>
						<div className="field-content">
							<Input
								label={`${t('PROPERTY.BASICFORM.FLOOR_QTY.LABEL')}`}
								name="floorQty"
								value={floorQty}
								onChange={handleChangeField}
								placeholder={`${t(
									'PROPERTY.BASICFORM.FLOOR_QTY.PLACE_HOLDER'
								)}`}
							/>
						</div>

						<div className="condition">
							<RadioButton
								label="Asuntoni kunto on"
								options={conditions}
								value={`${condition}`}
								name="condition"
								handleCheckRadioButton={handleChangeRadio}
							/>
						</div>
						<div className="field-content">
							<Input
								label={t('PROPERTY.BASICFORM.CONDITION_INFO')}
								name="conditionInfo"
								value={conditionInfo}
								onChange={handleChangeField}
							/>
						</div>
						<div className="field-content">
							<Checkbox
								label={`${t('PROPERTY.BASICFORM.BRAND_NEW.LABEL')}`}
								description={`${t('PROPERTY.BASICFORM.BRAND_NEW.DESCRIPTION')}`}
								name="brandNew"
								value={mandatoryFormData.brandNew}
								handleClickCheckbox={handleChangeField}
								handleFocusInput={handleFocusInput}
							/>
						</div>
						<div className="field-content">
							<Input
								label={`${t('PROPERTY.BASICFORM.CONSTRUCTION_YEAR')}`}
								name="constYear"
								value={mandatoryFormData.constYear}
								onChange={handleChangeField}
							/>
							<div></div>
							<Select
								options={energyClasses}
								value={`${energyClass}`}
								label={`${t('PROPERTY.BASICFORM.ENERGY_CLASS')}`}
								name="energyClass"
								handleSelectOption={handleChangeField}
							/>
							<div></div>
							<Select
								options={energyCertificates}
								value={`${energyCertificate}`}
								label={`${t('PROPERTY.BASICFORM.ENERGY_CERTIFICATE.LABEL')}`}
								name="energyCertificate"
								handleSelectOption={handleChangeField}
							/>

							<div></div>
							<Textarea
								label={`${t('PROPERTY.BASICFORM.ENERGY_INFO')}`}
								value={mandatoryFormData.energyInfo}
								name="energyInfo"
								handleChangeTextarea={handleChangeField}
							/>
							<div></div>
							<SaveBookmark
								isSaved={isSectionSaved.roomDetail}
								sectionName={saveField.roomDetail.name}
								handleClickSave={handleClickSave}
								isLoading={isSectionLoading.roomDetail}
							/>
						</div>

						<div className="wrapper-with-tips field-content">
							<div className="extro-info">
								<Textarea
									label={`${t('PROPERTY.BASICFORM.INFORMATION_LABEL')}`}
									value={informationLabel}
									name="informationLabel"
									handleChangeTextarea={handleChangeField}
								/>
								<Textarea
									label={`${t('PROPERTY.BASICFORM.FREE_FOR_MOVING')}`}
									value={mandatoryFormData.freeForMoving}
									name="freeForMoving"
									handleChangeTextarea={handleChangeField}
								/>
								<SaveBookmark
									isSaved={isSectionSaved.movingAndInfo}
									sectionName={saveField.movingAndInfo.name}
									handleClickSave={handleClickSave}
									isLoading={isSectionLoading.movingAndInfo}
								/>
							</div>
							<div className="tips">
								<div className="tips-content">
									<h3>{t('GENERAL.FORMS.JETHROS_TIP')}</h3>
									<span>Esim. Miten kirjoittaa hyvä esittelyteksti?</span>
								</div>
							</div>
						</div>
					</div>
					<div className="personalInfo ">
						<div className="form-topic_content-center field-content">
							<h1>{t('PROPERTY.BASICFORM.CONTACT_INFORMATION')}</h1>
							<div></div>
							<Inputs
								data={personalInfoInputs}
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
							/>

							<Input
								label={`${t('PROPERTY.BASICFORM.INQUIRIES')}`}
								value={query}
								name="query"
								onChange={handleChangeField}
								handleBlurInput={handleBlurInput}
								handleFocusInput={handleFocusInput}
								required={true}
								hasError={requiredFieldHasError.queryHasError}
								error={validateError.queryError}
							/>
							<div></div>
							<SaveBookmark
								isSaved={isSectionSaved.personalInfo}
								sectionName={saveField.personalInfo.name}
								handleClickSave={handleClickSave}
								isLoading={isSectionLoading.personalInfo}
							/>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	// field
	mandatoryFormData: formSelectors.getMandatoryData(state.formReducer),
	propertyType: formSelectors.getPropertyType(state.formReducer),
	houseType: formSelectors.getHouseType(state.formReducer),
	housingCompany: formSelectors.getHousingCompany(state.formReducer),
	category: getFormCategory(state.formReducer),
	streetAddress: formSelectors.getStreetAddress(state.formReducer),
	zipCode: formSelectors.getZipCode(state.formReducer),
	postalCity: formSelectors.getPostalCity(state.formReducer),
	city: formSelectors.getCity(state.formReducer),
	state: formSelectors.getState(state.formReducer),
	region: formSelectors.getRegion(state.formReducer),
	country: formSelectors.getCountry(state.formReducer),
	showMap: formSelectors.getShowMap(state.formReducer),
	apartmentInfo: formSelectors.getApartmentInfo(state.formReducer),
	roomQty: formSelectors.getRoomQty(state.formReducer),
	areaApartment: formSelectors.getAreaApartment(state.formReducer),
	areaRest: formSelectors.getAreaRest(state.formReducer),
	areaTotal: formSelectors.getAreaTotal(state.formReducer),
	areaInfo: formSelectors.getAreaInfo(state.formReducer),
	floorQty: formSelectors.getFloorQty(state.formReducer),
	phone: formSelectors.getPhone(state.formReducer),
	energyClass: formSelectors.getEnergyClass(state.formReducer),
	energyCertificate: formSelectors.getEnergyCertificate(state.formReducer),
	informationLabel: formSelectors.getInformationLabel(state.formReducer),
	conditionInfo: formSelectors.getConditionInfo(state.formReducer),
	condition: formSelectors.getCondition(state.formReducer),
	query: formSelectors.getQuery(state.formReducer),
	requiredFieldHasError: formSelectors.getRequiredField(state.formReducer),
	validateError: formSelectors.getValidateError(state.formReducer)
})

export default withTranslation()(connect(mapStateToProps)(MandatoryForm))
