/**
 * Notification Reducer
 *
 * @author Anh Tu le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	NOTIFICATION_SHOW_NOTIFICATION,
	NOTIFICATION_HIDE_NOTIFICATION
} from '@actions'

const initialState = {
	isShown: false,
	status: null,
	message: null
}
export const notificationReducer = (state = initialState, action) => {
	switch (action.type) {
		case NOTIFICATION_SHOW_NOTIFICATION:
			return {
				...state,
				isShown: true,
				...action.payload
			}
		case NOTIFICATION_HIDE_NOTIFICATION:
			return {
				...initialState
			}
		default:
			return {
				...state
			}
	}
}
export const notificationSelectors = {
	getNotiShown: (state = initialState) => state.isShown,
	getNotiMessage: (state = initialState) => state.message,
	getNotiStatus: (state = initialState) => state.status
}
