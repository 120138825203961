import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import AttachmentPicker from 'shared/components/AttachmentPicker'
import Input from 'shared/components/Input'
import Loading from 'shared/components/Loading'
import RadioButton from 'shared/components/RadioButton'
import Button from 'shared/components/Button'
// constants
import { fileEndpoint } from '@constants'
// Reducer
import { mediaSelectors, getPropertySelectors } from 'reducers'
// actions
import {
	mediaFormAddAttachment,
	mediaFormDeleteAttachment,
	mediaFormChangeField
} from '@actions'
// constant
import { stailation, navigationPath } from '@constants'
class MediaForm extends Component {
	constructor(props) {
		super(props)
		this.state = {
			imageLoading: null,
			videoLoading: null
		}
	}
	componentDidUpdate(prevProps) {
		if (prevProps.loading != this.props.loading) {
			if (!this.props.loading) {
				this.setState({
					imageLoading: null,
					videoLoading: null
				})
			}
		}
	}
	handleLoadingImage = progressEvent => {
		const { loading } = this.props
		const percentCompleted = Math.floor(
			(progressEvent.loaded * 100) / progressEvent.total
		)
		this.setState({
			imageLoading: percentCompleted
		})
		if (percentCompleted == 100) {
			this.setState({
				imageLoading: 'File is rendering'
			})
		}
	}
	handleLoadingVideo = progressEvent => {
		const percentCompleted = Math.floor(
			(progressEvent.loaded * 100) / progressEvent.total
		)
		this.setState({
			videoLoading: percentCompleted
		})
		if (percentCompleted == 100) {
			this.setState({
				videoLoading: 'File is rendering'
			})
		}
	}
	handlePickImage = attachment => {
		let formData = new FormData()
		formData.append('file', attachment)
		this.props.dispatch(
			mediaFormAddAttachment(formData, this.handleLoadingImage, 'image')
		)
	}
	handlePickVideo = attachment => {
		let formData = new FormData()
		formData.append('file', attachment)
		this.props.dispatch(
			mediaFormAddAttachment(formData, this.handleLoadingVideo, 'video')
		)
	}
	handleDeleteFile = item => {
		const { defaultImage, dispatch } = this.props
		this.props.dispatch(mediaFormDeleteAttachment(item.id)).then(res => {
			// reset defaultImage field when delete it
			if (res.payload.data && item.key == defaultImage) {
				dispatch(mediaFormChangeField('defaultImage', ''))
			}
		})
	}
	handleSetDefault = item => {
		this.props.dispatch(mediaFormChangeField('defaultImage', item.key))
	}
	renderImages = (data, name) => {
		const { t, defaultImage } = this.props
		if (!data || data.length == 0) {
			return null
		}
		return data.map((item, key) => {
			const isDefaultImage = defaultImage == item.key
			if (item.contentType.includes(name) && name == 'image') {
				return (
					<div className="file-item" key={key}>
						<img
							className={
								isDefaultImage
									? 'add-icon-wrapper defaultImage'
									: 'add-icon-wrapper'
							}
							src={`${fileEndpoint}/${item.key}`}
							alt="image"
						/>
						<div className="file-control">
							{!isDefaultImage && (
								<i
									className="material-icons default"
									onClick={() => this.handleSetDefault(item)}
								>
									settings_applications
								</i>
							)}
							<div
								className="delete"
								onClick={() => this.handleDeleteFile(item)}
							>
								-
							</div>
						</div>
					</div>
				)
			} else if (item.contentType.includes(name) && name == 'video') {
				return (
					<div className="file-item" key={key}>
						<video
							preload="metadata"
							className="add-icon-wrapper"
							src={`${fileEndpoint}/${item.key}`}
							alt="video"
							controls
						></video>
						<div className="file-control">
							<Button
								title={t('PROPERTY.MEDIA.BUTTON.DELETE')}
								onClick={() => this.handleDeleteFile(item)}
							></Button>
						</div>
					</div>
				)
			}
		})
	}
	handleChangeField = e => {
		const { name, value } = e.target
		this.props.dispatch(mediaFormChangeField(name, value))
	}
	renderingLoadingNoti = loading => {
		const { t } = this.props
		if (typeof loading == 'number') {
			return (
				<div className="loadingProgress">
					{t('PROPERTY.MEDIA.NOTIFICATION.LOADING')} {loading} % ...
				</div>
			)
		} else {
			return (
				<div className="loadingProgress">
					{t('PROPERTY.MEDIA.NOTIFICATION.RENDERING')} ...
				</div>
			)
		}
	}
	render() {
		const {
			handleBackForm,
			extraLinks,
			files,
			isStailation,
			handleSubmitForm,
			handleBackMediaForm,
			loading,
			error,
			t,
			propertyLoading
		} = this.props
		const { imageLoading, videoLoading } = this.state
		const isButtonLoading = loading || propertyLoading
		return (
			<div className="form-topic_content mediaForm">
				<div className="attachment-content">
					<label>{t('PROPERTY.MEDIA.ADD_PHOTOS')}</label>
					<div className="files-picker">
						<div className="add-icon-wrapper">
							{imageLoading ? (
								this.renderingLoadingNoti(imageLoading)
							) : (
								<AttachmentPicker
									handlePickAttachment={this.handlePickImage}
									icon="add"
									multiple={true}
									accept="image/*"
									name="images"
								/>
							)}
						</div>
						{this.renderImages(files, 'image')}
					</div>
				</div>
				<div className="attachment-content">
					<label>{t('PROPERTY.MEDIA.ADD_VIDEOS')}</label>
					<div className="files-picker">
						<div className="add-icon-wrapper">
							{videoLoading ? (
								this.renderingLoadingNoti(videoLoading)
							) : (
								<AttachmentPicker
									id="video-picker"
									handlePickAttachment={this.handlePickVideo}
									icon="add"
									multiple={true}
									accept="video/*"
									name="videos"
								/>
							)}
						</div>
						{this.renderImages(files, 'video')}
					</div>
				</div>
				<div className="field-content">
					<Input
						name="extraLinks"
						value={extraLinks}
						label={t('PROPERTY.MEDIA.ADD_LINK')}
						onChange={this.handleChangeField}
					/>
				</div>
				<div className="field-content">
					<RadioButton
						label={t('PROPERTY.MEDIA.PHOTOGRAPHY_AND_STYLING_REQUEST')}
						options={stailation}
						value={isStailation}
						name="isStailation"
						handleCheckRadioButton={this.handleChangeField}
					/>
				</div>
				<div className="formButton">
					{error ? <span className="error">{error.message}</span> : null}
					{isButtonLoading ? (
						<Loading />
					) : (
						<Button
							title={t('GENERAL.FORMS.SAVE')}
							onClick={handleSubmitForm}
						/>
					)}
					<Button
						title={t('GENERAL.FORMS.BACK')}
						onClick={handleBackForm}
						className="back-button"
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	files: mediaSelectors.getFiles(state.mediaFormReducer),
	defaultImage: mediaSelectors.getDefaultImage(state.mediaFormReducer),
	extraLinks: mediaSelectors.getExtraLinks(state.mediaFormReducer),
	isStailation: mediaSelectors.getIsStailation(state.mediaFormReducer),
	loading: mediaSelectors.getLoading(state.mediaFormReducer),
	propertyLoading: getPropertySelectors.getLoading(state.propertyReducer),
	error: mediaSelectors.getError(state.mediaFormReducer)
})

export default connect(mapStateToProps)(MediaForm)
