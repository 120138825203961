/* Reducer helpers
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 */

/**
 * Find position of item in a array
 *
 * @param {Array} array in which we need to find
 * @param {Number} id
 * @return {Number} normalized attributes
 */
function findItembyId(array, id) {
	return array.findIndex(elem => elem.id == id)
}

function findFieldByName(array, findItem) {
	return array.some(item => item == findItem)
}
export {}

const defaultError = {
	status: null,
	title: 'Whoops! Something went wrong',
	message: 'Whoops! Something went wrong'
}

function checkManager(files) {
	for (let i = 0; i < files.length; i++) {
		if (files[i].type == 'managerCertificate') {
			return true
		}
	}
	return false
}

function normalizeError(error) {
	const errorData = error.response ? error.response.data : null
	if (!errorData) {
		return defaultError
	}

	return errorData
}

export { normalizeError, findFieldByName, findItembyId, checkManager }
