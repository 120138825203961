import React, { Component } from 'react'
import { connect } from 'react-redux'
// Components
import MyChart from 'shared/components/PieChart'
import Button from 'shared/components/Button'
// Reducers
import {
	formSelectors,
	mediaSelectors,
	getFormOptionalForm,
	priceSelectors
} from 'reducers'
class Score extends Component {
	renderFormChecking = data => {
		return data.map((item, key) => {
			let className = ['material-icons']
			if (item.isAvailable) {
				className.push('done')
			} else {
				className.push('unDone')
			}
			return (
				<div className="formChecking" key={key}>
					<i className={className.join(' ')}>
						{item.isAvailable ? 'done' : 'clear'}
					</i>
					<p>{item.name}</p>
				</div>
			)
		})
	}
	// calculate score of forms
	calculateStandardScore = formData => {
		let formFields = {
			...formData
		}
		let keys = Object.keys(formFields)

		return keys.length
	}
	calculateCurrentScore = formData => {
		let count = 0
		let formFields = {
			...formData
		}
		let keys = Object.keys(formFields)
		keys.map(item => {
			if (typeof formFields[item] == 'string') {
				if (formFields[item] !== '') {
					count++
				}
			}
			if (Array.isArray(formFields[item]) && formFields[item].length != 0) {
				count++
			}
			if (typeof formFields[item] == 'boolean') {
				count++
			}
		})
		return count
	}
	checkFormData = formData => {
		return (
			this.calculateStandardScore(formData) ==
			this.calculateCurrentScore(formData)
		)
	}
	calculateStatisticNumber = () => {
		const {
			mandatoryFormData,
			optionalFormData,
			mediaFormData,
			priceFormData
		} = this.props
		let standardScore =
			this.calculateStandardScore(mandatoryFormData) +
			this.calculateStandardScore(optionalFormData) +
			this.calculateStandardScore(mediaFormData) +
			this.calculateStandardScore(priceFormData)
		let currentScore =
			this.calculateCurrentScore(mandatoryFormData) +
			this.calculateCurrentScore(optionalFormData) +
			this.calculateCurrentScore(mediaFormData) +
			this.calculateCurrentScore(priceFormData)

		return `${Math.floor((currentScore / standardScore) * 100)}`
	}
	calculateStatisticNumberData = () => {
		let completedScore = this.calculateStatisticNumber()
		let unCompletedScore = 100 - completedScore
		return [completedScore, unCompletedScore]
	}

	render() {
		const {
			handleBackForm,
			handleSubmitForm,
			mandatoryFormData,
			optionalFormData,
			mediaFormData,
			priceFormData
		} = this.props
		const savingData = {
			labels: ['saving'],
			datasets: [
				{
					labels: ['saving'],
					data: [100],
					borderWidth: 0,
					hoverBorderWidth: 0,
					borderColor: 'gray',
					backgroundColor: 'gray',
					fill: true
				}
			],
			text: 'lorem'
		}
		const data = {
			labels: ['undone', 'done'],
			datasets: [
				{
					labels: ['undone', 'done'],
					data: this.calculateStatisticNumberData(),
					borderWidth: 0,
					hoverBorderWidth: 0,
					backgroundColor: ['#599bbe', 'rgba(98, 154, 41, 0.48)'],
					fill: true
				}
			],
			text: 'lorem'
		}
		const formCheckingData = [
			{
				name: 'Pakolliset perustiedot',
				isAvailable: this.checkFormData(mandatoryFormData)
			},
			{
				name: 'Valinnaiset perustiedot',
				isAvailable: this.checkFormData(optionalFormData)
			},
			{
				name: 'Kuvat/Media',
				isAvailable: this.checkFormData(mediaFormData)
			},
			{
				name: 'Hintapyyntö',
				isAvailable: this.checkFormData(priceFormData)
			}
		]

		return (
			<div className="form-topic_content informationScoring">
				<div className="form-topic_content-center">
					<div className="wrapper-with-tips field-content">
						<div className="field statisticBox">
							<h3>ILMOITUKSEN PISTEYTYS</h3>
							{this.renderFormChecking(formCheckingData)}
						</div>
						<div className="tips">
							<div className="tips-content">
								<h3>Jethron vinkki</h3>
								<span>ESIM. Mikä on kiinteistön ja huoneiston ero?</span>
							</div>
						</div>
					</div>
					<div className="field-content">
						<div className="field statisticBox">
							<h3>Yhteenveto</h3>
							<p>
								Hakemuksesi sai pisteitä X määrän. Vielä on parannettavaa! Täytä
								loput osiot nostaaksesi pisteitäsi.{' '}
							</p>
						</div>
						<MyChart
							data={data}
							statisticNumber={`${this.calculateStatisticNumber()}%`}
						/>
					</div>
				</div>
				<div className="savingSection">
					<div className="form-topic_content-center ">
						<div className="savingSection-content">
							<h1>MYY ITSE säästösi tällä hetkellä</h1>
							<p>Myy itse kiinteä hinta aina 600 €</p>
							<p>MYYNTIHINTA: 320 000 € </p>
							<p>VERRATTAVA VÄLITYSPALKKIO: 4,5 %</p>
						</div>
						<MyChart data={savingData} statisticNumber="11400e" />
					</div>
				</div>
				<div className="formButton">
					<Button title="TALLENNA" onClick={handleSubmitForm} />
					<Button
						title="TAKAISIN"
						onClick={handleBackForm}
						className="back-button"
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	mandatoryFormData: formSelectors.getMandatoryData(state.formReducer),
	optionalFormData: getFormOptionalForm(state.formReducer),
	mediaFormData: mediaSelectors.getData(state.mediaFormReducer),
	priceFormData: priceSelectors.getData(state.priceFormReducer)
})
export default connect(mapStateToProps)(Score)
