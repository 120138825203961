import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'
import { validateFieldByName } from '../utils'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment'
import { getAuthenticationData } from './../../utils'
// Component
import Inputs from 'shared/components/Inputs'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import PropertyItem from 'shared/components/PropertyItem'
// actions
import {
	showingRequestSendRequest,
	showingRequestChangeField,
	showingRequestResetError,
	showingRequestValidate
} from '@actions'
// constants
import { navigationPath } from '@constants'
// Reducers
import { showingRequestSelectors } from 'reducers'

// placeholder images
import property_sample from 'assets/images/property_sample.png'

class ShowingRequest extends React.Component {
	constructor(props) {
		super(props)
		this.state = {}
	}
	componentWillUnmount = () => {
		this.props.dispatch(showingRequestResetError())
	}
	handleChangeField = e => {
		const { name, value } = e.target
		const { dispatch } = this.props
		dispatch(showingRequestChangeField(name, value))
		// return this.props.dispatch(presentationRequestFormChangeField(name, value))
	}
	handleClickInputIcon = () => {}

	handleFocusInput = e => {
		const { name, value } = e.target
		this.props.dispatch(showingRequestValidate(name, false, ''))
	}
	validateRequest = (name, value, text) => {
		this.props.dispatch(showingRequestValidate(name, value, text))
	}
	handleBlurInput = e => {
		const { name, value } = e.target
		const { dispatch } = this.props
		if (value == '') {
			dispatch(
				showingRequestValidate(name, true, this.props.t('ERRORS.REQUIRED'))
			)
		} else {
			showingRequestValidate(name, value, this.validateFieldByName)
		}
	}
	normalizeInput = data => {
		const { t, requiredFieldHasError, validateError } = this.props
		let contactInfoInputs = []
		data.map(item => {
			return contactInfoInputs.push({
				label: t(`SHOWING_REQUEST.${item.toUpperCase()}`),
				name: item,
				value: this.props[item],
				required: true,
				hasError: requiredFieldHasError[item],
				error: validateError[item]
			})
		})
		return contactInfoInputs
	}
	handleClickSubmit = () => {
		const {
			history,
			dispatch,
			startDate,
			endDate,
			requestDate,
			name,
			email,
			phoneNumber
		} = this.props
		let data = {
			name,
			email,
			phoneNumber,
			startDate,
			endDate,
			requestDate
		}
		let propertyId = getAuthenticationData('selectedPropertyId')
		dispatch(showingRequestSendRequest(propertyId, data))
			.then(res => {
				if (res.payload.data) {
					history.push(navigationPath.dashboard)
				}
			})
			.catch(error => {})
	}
	handleChangeDate = (date, name) => {
		const { dispatch, startDate, endDate, t } = this.props
		dispatch(showingRequestValidate('startDate', false, ''))
		dispatch(showingRequestValidate('endDate', false, ''))
		switch (name) {
			case 'startDate':
				dispatch(showingRequestChangeField('requestDate', undefined))
				if (endDate && !moment(date).isBefore(endDate)) {
					dispatch(
						showingRequestValidate(
							name,
							true,
							t('SHOWING_REQUEST.NOTIFICATION.STARTDATE_ERROR')
						)
					)
				}
				break
			case 'endDate':
				dispatch(showingRequestChangeField('requestDate', undefined))

				if (startDate && !moment(date).isAfter(startDate)) {
					return dispatch(
						showingRequestValidate(
							name,
							true,
							t('SHOWING_REQUEST.NOTIFICATION.ENDDATE_ERROR')
						)
					)
				}
				break
			case 'requestDate':
				dispatch(showingRequestChangeField('startDate', undefined))
				dispatch(showingRequestChangeField('endDate', undefined))

				break
			default:
				break
		}
		if (!date) {
			return dispatch(showingRequestChangeField(name, undefined))
		}
		let newDateFormat = moment(date).format()
		dispatch(showingRequestChangeField(name, newDateFormat))
	}
	render() {
		const {
			t,
			isDisabledSubmit,
			loading,
			error,
			startDate,
			endDate,
			requestDate,
			requiredFieldHasError,
			validateError,
			name,
			phoneNumber,
			email
		} = this.props
		const isDisabledRequest =
			!name ||
			!phoneNumber ||
			!email ||
			!((startDate && endDate) || requestDate)
		return (
			<div className="showingRequest-container">
				<h1>{t('SHOWING_REQUEST.TITLE')}</h1>
				<div className="propertyInformation">
					<PropertyItem imageUrl={property_sample} />
				</div>
				<div className="showingRequestForm">
					<div className="showingTime-container">
						<h2>{t('SHOWING_REQUEST.CHOOSE')}</h2>
						{requiredFieldHasError.startDate ? (
							<span className="error">{validateError.startDate}</span>
						) : null}
						{requiredFieldHasError.endDate && (
							<span className="error">{validateError.endDate}</span>
						)}
						<div className="duration">
							<div className="pickDateContainer">
								<DatePicker
									selected={
										startDate
											? moment(startDate, moment.defaultFormat).toDate()
											: undefined
									}
									onChange={date => this.handleChangeDate(date, 'startDate')}
								/>
							</div>
							<div className="pickDateContainer">-</div>
							<div className="pickDateContainer">
								<DatePicker
									selected={
										endDate
											? moment(endDate, moment.defaultFormat).toDate()
											: undefined
									}
									onChange={date => this.handleChangeDate(date, 'endDate')}
								/>
							</div>
						</div>
						<h2>{t('SHOWING_REQUEST.OR')}</h2>
						<div className="pickDateContainer">
							<DatePicker
								selected={
									requestDate
										? moment(requestDate, moment.defaultFormat).toDate()
										: undefined
								}
								onChange={date => this.handleChangeDate(date, 'requestDate')}
								showTimeSelect
								timeFormat="HH:mm"
								timeIntervals={15}
								dateFormat="MMMM d, yyyy h:mm aa"
							/>
						</div>
					</div>
					<div className="contactInformation-container">
						<div className="contactInformation-container_content">
							<h2>{t('SHOWING_REQUEST.CONTACT_INFORMATION')}</h2>
							<Inputs
								data={this.normalizeInput(['name'])}
								onChange={this.handleChangeField}
								handleBlurInput={this.handleBlurInput}
								handleFocusInput={this.handleFocusInput}
								handleClickInputIcon={this.handleClickInputIcon}
							/>
							<div className="twoFieldRow">
								<Inputs
									data={this.normalizeInput(['phoneNumber', 'email'])}
									onChange={this.handleChangeField}
									handleBlurInput={this.handleBlurInput}
									handleFocusInput={this.handleFocusInput}
									handleClickInputIcon={this.handleClickInputIcon}
								/>
							</div>
						</div>
					</div>
				</div>
				{error ? <span className="error">{error.message}</span> : null}
				<div className="formButton">
					{loading ? (
						<Loading />
					) : (
						<div>
							<Button
								title={t('SHOWING_REQUEST.SEND')}
								disabled={isDisabledRequest}
								onClick={this.handleClickSubmit}
							/>

							<Button
								title={t('GENERAL.FORMS.BACK')}
								onClick={() => this.props.history.push('/dashboard')}
							/>
						</div>
					)}
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	name: showingRequestSelectors.getName(state.showingRequestReducer),
	email: showingRequestSelectors.getEmail(state.showingRequestReducer),
	phoneNumber: showingRequestSelectors.getPhoneNumber(
		state.showingRequestReducer
	),
	startDate: showingRequestSelectors.getStartDate(state.showingRequestReducer),
	endDate: showingRequestSelectors.getEndDate(state.showingRequestReducer),
	requestDate: showingRequestSelectors.getRequestDate(
		state.showingRequestReducer
	),
	requiredFieldHasError: showingRequestSelectors.getRequiredFieldHasError(
		state.showingRequestReducer
	),
	validateError: showingRequestSelectors.getValidateError(
		state.showingRequestReducer
	),
	error: showingRequestSelectors.getError(state.showingRequestReducer),
	loading: showingRequestSelectors.getLoading(state.showingRequestReducer)
})

export default connect(mapStateToProps)(
	withRouter(withTranslation()(ShowingRequest))
)
