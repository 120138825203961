import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import property_sample from 'assets/images/property_sample.png'

// components
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import PropertyItem from 'shared/components/PropertyItem'

// constants
import {
	fileEndpoint,
	defaultPropertiesShownPropertiesView,
	navigationPath
} from '@constants'
// reducers
import { getPropertySelectors } from 'reducers'
// actions
import { propertyChangeField, propertyGetPublishedProperties } from '@actions'
class PropertiesList extends Component {
	constructor(props) {
		super(props)
		const { properties } = this.props
		this.state = {
			propertiesAmount: defaultPropertiesShownPropertiesView,
			properties
		}
	}
	componentDidUpdate(prevProps) {
		const { keyword, properties } = this.props
		if (prevProps.keyword != keyword) {
			let newProperties = []
			if (properties && properties.length > 0) {
				properties.map(property => {
					const { streetAddress, askingPrice } = property.informationField
					if (
						streetAddress.toLowerCase().indexOf(keyword.toLowerCase()) != -1 ||
						askingPrice == Number(keyword)
					) {
						newProperties.push(property)
					}
				})
			}
			this.setState({
				properties: newProperties
			})
		}
	}
	componentDidMount() {
		this.fetchProperties(this.state.propertiesAmount)
	}
	handleSearch = () => {
		console.log('searching')
	}
	getPropertyImage = imageKey => {
		if (!imageKey) {
			return property_sample
		}
		return `${fileEndpoint}/${imageKey}`
	}
	handleChangeField = e => {
		const { name, value } = e.target
		this.props.dispatch(propertyChangeField(name, value))
	}
	fetchMoreProperties = () => {
		console.log('plus more property')
		this.setState({
			propertiesAmount: this.state.propertiesAmount + 6
		})
		this.fetchProperties()
	}
	handleSelectProperty = id => {
		localStorage.setItem('selectedPropertyId', id)
		this.props.history.push(`${navigationPath.apartment}/${id}`)
	}
	renderProperties = () => {
		const { properties } = this.state
		return (
			<div className="content">
				{properties.map(property => {
					if (!property.informationField) {
						return null
					}
					const {
						askingPrice,
						streetAddress,
						zipCode,
						city,
						region,
						areaTotal,
						apartmentInfo
					} = property.informationField
					const image = this.getPropertyImage(property.defaultImage)
					return (
						<PropertyItem
							apartmentInfo={apartmentInfo}
							id={property.id}
							key={property.id}
							price={askingPrice}
							address={streetAddress}
							region={zipCode + ' ' + city + ', ' + region}
							area={areaTotal}
							image={image}
							handleSelectProperty={this.handleSelectProperty}
						/>
					)
				})}
			</div>
		)
	}
	fetchProperties = () => {
		this.props.dispatch(
			propertyGetPublishedProperties(this.state.propertiesAmount)
		)
	}
	render() {
		const { t, keyword, error, loading } = this.props
		if (loading) {
			return <Loading />
		}
		return (
			<div className="properties-container">
				<div className="header">
					<h1>{t('PROPERTIES.TITLE')}</h1>
					<p>{t('PROPERTIES.DESC')}</p>

					<Input
						value={keyword}
						name="keyword"
						iconName="search"
						handleClickInputIcon={this.handleSearch}
						onChange={this.handleChangeField}
					/>
					{error ? <span className="error">{error.message}</span> : null}
				</div>
				{this.renderProperties()}
				{!keyword && (
					<div className="formButton">
						<Button
							title={t('PROPERTIES.ADD_MORE')}
							onClick={this.fetchMoreProperties}
						></Button>
					</div>
				)}
			</div>
		)
	}
}
const mapStateToProps = state => ({
	keyword: getPropertySelectors.getKeyWord(state.propertyReducer),
	properties: getPropertySelectors.getProperties(state.propertyReducer),
	loading: getPropertySelectors.getLoading(state.propertyReducer),
	error: getPropertySelectors.getError(state.propertyReducer)
})
export default withRouter(
	withTranslation()(connect(mapStateToProps)(PropertiesList))
)
