import React from 'react'
import { withTranslation } from 'react-i18next'

// component
import Input from 'shared/components/Input'
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
const Signature = ({ t, name }) => {
	return (
		<div className="signature">
			<div className="signature_header">
				<h1>{t('BUYER.BID_FORM.SIGNATURE.TITLE')}</h1>
				<p>{t('BUYER.BID_FORM.SIGNATURE.DESC')}</p>
			</div>
			<div className="signature_form">
				<div className="info">
					<div className="name">{name}</div>
					<Button title={t('BUYER.BID_FORM.SIGNATURE.BUTTON')} />
				</div>
				<div className="link">
					https://kotidiili.com/signatures/signicat/sign/95f7643f-5b06-4839-8e8b-3026ae103bac/1iG5o3/XVX1SFsaRjEcz-uL_gZeqEffr0M
				</div>
				<div className="note">
					<i className="material-icons">speaker_notes</i>
					<span>{t('BUYER.BID_FORM.SIGNATURE.NOTE')}</span>
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(Signature)
