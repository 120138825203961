/**
 * Generic select components
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 */

import React, { Component } from 'react'
import Slider from '@material-ui/lab/Slider'
import { withStyles } from '@material-ui/core/styles'

const customBoxShadow =
	'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.13),0 0 0 1px rgba(0,0,0,0.02)'

const CustomSlider = withStyles({
	root: {
		color: '#3880ff',
		height: 2,
		padding: '15px 0'
	},
	thumb: {
		height: 32,
		width: 32,
		backgroundColor: '#fff',
		boxShadow: customBoxShadow,
		marginTop: -16,
		marginLeft: -16,
		'&:focus,&:hover,&$active': {
			boxShadow:
				'0 3px 1px rgba(0,0,0,0.1),0 4px 8px rgba(0,0,0,0.3),0 0 0 1px rgba(0,0,0,0.02)',
			// Reset on touch devices, it doesn't add specificity
			'@media (hover: none)': {
				boxShadow: customBoxShadow
			}
		}
	},
	active: {},
	valueLabel: {
		left: 'calc(-50% + 16px)',
		top: 10,
		'& *': {
			background: 'transparent',
			color: '#000'
		}
	},
	track: {
		height: 6,
		backgroundColor: '#d7b9ae',
		opacity: 1.0
	},
	rail: {
		height: 6,
		opacity: 0.5,
		backgroundColor: '#d7b9ae'
	},
	mark: {
		backgroundColor: '#bfbfbf',
		height: 8,
		width: 1,
		marginTop: -3
	},
	markActive: {
		backgroundColor: 'currentColor'
	}
})(Slider)

const ScoreSlider = ({
	value,
	min = 1,
	max = 10,
	step = 1,
	onChange,
	disabled
}) => {
	return (
		<div className="scoreSlider-container">
			<CustomSlider
				defaultValue={min}
				value={value}
				aria-labelledby="discrete-slider-always"
				step={step}
				min={min}
				max={max}
				onChange={onChange}
				disabled={disabled}
				name="slider-menu"
				valueLabelDisplay="on"
			/>
		</div>
	)
}

export default ScoreSlider
