/**
 * Generic select components
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React, { Fragment } from 'react'
import { Checkbox } from '@material-ui/core'
import RadioButtonChecked from '@material-ui/icons/RadioButtonChecked'
import RadioButtonUnChecked from '@material-ui/icons/RadioButtonUnchecked'
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckBox from '@material-ui/icons/CheckBox'

const CustomCheckbox = ({
	value,
	label,
	name,
	handleClickCheckbox,
	description,
	customIcon = 'default'
}) => {
	const handleClick = e => {
		if (handleClickCheckbox && typeof handleClickCheckbox === 'function') {
			handleClickCheckbox(e)
		}
	}
	const renderInputLabel = () => (
		<label className="checkBox-field__label">{label}</label>
	)
	const renderDescription = () => (
		<label className="checkBox-field__label">{description}</label>
	)
	const icon = () => {
		if (customIcon == 'circle') {
			return <RadioButtonUnChecked />
		}
		return <CheckBoxOutlineBlank />
	}
	const checkedIcon = () => {
		if (customIcon == 'circle') {
			return <RadioButtonChecked />
		}
		return <CheckBox />
	}
	return (
		<div className="checkbox-container">
			{renderInputLabel()}
			<div className="checkbox-content">
				<Checkbox
					icon={icon()}
					checkedIcon={checkedIcon()}
					name={name}
					color="default"
					checked={value}
					inputProps={{
						'aria-label': 'checkbox with default color'
					}}
					onChange={handleClick}
				/>{' '}
				{/* <FormControlLabel
					control={
						<Checkbox
							icon={<FavoriteBorder />}
							checkedIcon={<Favorite />}
							value="checkedH"
						/>
					}
					label="Custom icon"
				/> */}
				{renderDescription()}
			</div>
		</div>
	)
}

export default CustomCheckbox
