import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Button from 'shared/components/Button'
import JehtronTip from 'shared/components/JethronTip'
import Select from 'shared/components/Select'
import Slider from 'shared/components/ScoreSlider'
import Input from 'shared/components/Input'

// actions
import {
	showingResultsSendResults,
	showingResultsChangeField,
	showingResultsResetError,
	showingResultsValidate
} from '@actions'
// constants
import { navigationPath } from '@constants'
// Reducers
import { showingResultsSelectors, showingSelectors } from 'reducers'
// utils
import { validateFieldByName } from '../../utils'

class AddShowingResults extends React.Component {
	constructor(props) {
		super(props)
	}
	componentDidMount() {
		const { selectedShowing } = this.props
		if (!selectedShowing) {
			this.props.history.push('/showingResults/view')
		}
	}
	componentWillUnmount = () => {
		this.props.dispatch(showingResultsResetError())
	}

	handleFocusInput = e => {
		const { dispatch } = this.props
		const { name, value } = e.target
		dispatch(showingResultsValidate(name, false, ''))
		dispatch(showingResultsResetError())
	}
	handleBlurInput = e => {
		const { name, value } = e.target
		const { dispatch } = this.props
		if (!value) {
			dispatch(
				showingResultsValidate(name, true, this.props.t('ERRORS.REQUIRED'))
			)
		} else {
			validateFieldByName(name, value, this.validateRequest)
		}
	}

	validateRequest = (name, value, text) => {
		this.props.dispatch(showingResultsValidate(name, value, text))
	}

	handleChangeField = e => {
		const { name, value } = e.target
		let valueField = value
		const { dispatch } = this.props
		dispatch(showingResultsChangeField(name, valueField))
	}

	handleChangeSlider = (e, val) => {
		const { dispatch } = this.props
		let valueField = val
		if (valueField >= 0 && valueField <= 10)
			dispatch(showingResultsChangeField('feeling', valueField))
	}
	handleClickSubmit = () => {
		const { feeling, attendees, bids, dispatch, selectedShowing } = this.props
		let data = {
			feeling,
			attendees,
			bids
		}
		dispatch(showingResultsSendResults(selectedShowing.id, data))
			.then(res => {
				if (res.payload.data) {
					this.props.history.push('/showingResults/view')
				}
			})
			.catch(error => {})
	}

	render() {
		const {
			t,
			history,
			feeling,
			attendees,
			bids,
			requiredFieldHasError,
			validateError
		} = this.props
		const visitorsData = [
			{
				text: '1',
				value: '1'
			},
			{
				text: '2',
				value: '2'
			},
			{
				text: '3',
				value: '3'
			},
			{
				text: '4',
				value: '4'
			}
		]
		return (
			<div className="addShowingResults-container">
				<h1>{t('SHOWING_RESULTS.ADD.SUBMIT_RESULTS')}</h1>
				<div className="addShowingResults-container_content">
					<div className="scoreMood-container">
						<p className="scoreMood-container_title">
							{t('SHOWING_RESULTS.ADD.MOOD')}
						</p>
						<Slider
							value={Number(feeling)}
							onChange={this.handleChangeSlider}
						/>
					</div>

					<div className="formfields-container">
						<Select
							handleSelectOption={this.handleChangeField}
							label={`${t('SHOWING_RESULTS.ADD.VISITORS')}`}
							name="attendees"
							value={attendees}
							options={visitorsData}
						/>
						<Select
							handleSelectOption={this.handleChangeField}
							label={`${t('SHOWING_RESULTS.ADD.OFFERS')}`}
							name="bids"
							value={bids}
							options={visitorsData}
						/>
						<Button
							title={t('GENERAL.FORMS.SAVE')}
							onClick={this.handleClickSubmit}
						/>
						<Button
							className="back-button"
							title={t('GENERAL.FORMS.BACK')}
							onClick={() => history.push('/showingResults/view')}
						/>
					</div>
				</div>

				<div className="jethrosTip-container">
					<JehtronTip
						title={t('GENERAL.JETHROS_TIP')}
						content="Miten saada enemmän kävijöitä?"
					/>
					<JehtronTip title={t('GENERAL.JETHROS_TIP')} content="Tarjoukset?" />
					<JehtronTip
						title={t('GENERAL.JETHROS_TIP')}
						content="Miten parantaa fiilistä?"
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	selectedShowing: showingSelectors.getSelectedShowing(state.showingReducer),
	feeling: showingResultsSelectors.getFeeling(state.showingResultsReducer),
	attendees: showingResultsSelectors.getAttendees(state.showingResultsReducer),
	bids: showingResultsSelectors.getBids(state.showingResultsReducer),
	requiredFieldHasError: showingResultsSelectors.getRequiredFieldHasError(
		state.showingResultsReducer
	),
	validateError: showingResultsSelectors.getValidateError(
		state.showingResultsReducer
	),
	error: showingResultsSelectors.getError(state.showingResultsReducer),
	loading: showingResultsSelectors.getLoading(state.showingResultsReducer)
})

export default connect(mapStateToProps)(
	withRouter(withTranslation()(AddShowingResults))
)
