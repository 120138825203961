/**
 * Bidding reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	BUYER_BID_CHANGE_FIELD,
	BUYER_BID_CHANGE_BUYERS,
	BUYER_BID_ADD_BUYER,
	BUYER_BID_REMOVE_BUYER
} from '@actions'
import { normalizeError } from './utils'

const initialState = {
	offerPrice: '',
	isRuleAgreed: false,
	isDocumentRead: false,
	validDate: undefined,
	payDate: undefined,
	moveDate: undefined,
	message: '',
	buyers: [
		{
			name: '',
			ownerShip: '',
			address: '',
			isPolitical: false,
			isFirstTimeBuyer: false
		}
	],
	name: '',
	email: '',
	phone: '',
	address1: '',
	address2: '',
	municipality: '',
	zipCode: '',
	loading: false,
	error: null
}
export const buyerBidReducer = (state = initialState, action) => {
	switch (action.type) {
		case BUYER_BID_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case BUYER_BID_CHANGE_BUYERS:
			const { pos, name, value } = action
			let newBuyers = [...state.buyers]
			newBuyers[pos][name] = value
			return {
				...state,
				buyers: newBuyers
			}
		case BUYER_BID_ADD_BUYER:
			let newBuyer = {
				name: '',
				ownerShip: '',
				address: '',
				isPolitical: false,
				isFirstTimeBuyer: false
			}
			return {
				...state,
				buyers: [...state.buyers, newBuyer]
			}
		case BUYER_BID_REMOVE_BUYER:
			let removeArray = [...state.buyers]
			removeArray.splice(action.pos, 1)
			return {
				...state,
				buyers: removeArray
			}
		default:
			return state
	}
}
export const buyerBidSelectors = {
	getIsDocumentRead: (state = initialState) => state.isDocumentRead,
	getIsRuleAgreed: (state = initialState) => state.isRuleAgreed,
	getValidDate: (state = initialState) => state.validDate,
	getPayDate: (state = initialState) => state.payDate,
	getMoveDate: (state = initialState) => state.moveDate,
	getOfferPrice: (state = initialState) => state.offerPrice,
	getMessage: (state = initialState) => state.message,
	getBuyers: (state = initialState) => state.buyers,
	getName: (state = initialState) => state.name,
	getEmail: (state = initialState) => state.email,
	getAddress1: (state = initialState) => state.address1,
	getAddress2: (state = initialState) => state.address2,
	getZipCode: (state = initialState) => state.zipCode,
	getPhone: (state = initialState) => state.phone,
	getMunicipality: (state = initialState) => state.municipality,

	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
