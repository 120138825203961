/**
 * Generic select components
 *
 * @author Anh Le <anh.le@vertics.co>
 *
 */

import React, { Fragment } from 'react'
const Textarea = ({
	name,
	value,
	placeholder,
	handleChangeTextarea,
	label
}) => {
	const handleChange = e => {
		if (handleChangeTextarea && typeof handleChangeTextarea === 'function') {
			handleChangeTextarea(e)
		}
	}
	const renderInputLabel = () => (
		<label className="textarea-field__label">{label}</label>
	)
	return (
		<div className="textarea-container">
			{renderInputLabel()}
			<textarea
				name={name}
				value={value ? value : ''}
				placeholder={placeholder}
				onChange={handleChange}
			/>
		</div>
	)
}

export default Textarea
