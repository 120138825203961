import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

// reducer
import { biddingSelectors, showingRequestSelectors } from 'reducers'
// components
import Loading from 'shared/components/Loading'
import Modal from 'shared/components/Modal'
import AttachmentPicker from 'shared/components/AttachmentPicker'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
import OfferItem from './OfferItem'
import RequestItem from './RequestItem'
// actions
import {
	biddingResetState,
	biddingChangeField,
	biddingChangeSelectedOffer,
	biddingGetBiddings,
	showingRequestGetRequests,
	biddingSendContractFile,
	showNotification
} from '@actions'
// constants
import { routerPath, notificationStatus } from '@constants'
// utils
import { getAuthenticationData } from './../../utils'
class Bidding extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isActivedTab: 'bids',
			isAttachModalShown: false,
			isConfirmShown: false,
			isAcceptRequestShown: false
		}
	}
	// life cycle
	componentWillUnmount() {
		this.props.dispatch(biddingResetState())
	}
	componentDidMount() {
		this.props.dispatch(biddingGetBiddings())
		this.props.dispatch(showingRequestGetRequests())
	}
	// bidding
	handleSelectTab = type => {
		this.setState({
			isActivedTab: type
		})
	}
	handleSelectShowing = e => {
		const { name, value } = e.target
		this.props.dispatch(biddingChangeField(name, value))
	}
	handleSelectBid = item => {
		this.props.dispatch(biddingChangeSelectedOffer(item))
	}
	// attachment
	handlePickAttachment = (attachment, e, url) => {
		this.props.dispatch(
			biddingChangeField('contractFilePreview', { attachment, url })
		)
	}
	handleAttachmentShow = value => {
		if (!value) {
			// reset contractFilePreview when close modal
			this.props.dispatch(biddingChangeField('contractFilePreview', null))
		}
		this.setState({
			isAttachModalShown: value
		})
	}
	handleChooseRequest = (value, request) => {
		const { dispatch } = this.props
		if (request) {
			if (value) {
				dispatch(biddingChangeField('selectedRequest', request))
			}
		} else {
			dispatch(biddingChangeField('selectedRequest', ''))
		}
		this.setState({
			isAcceptRequestShown: value
		})
	}

	// confirm
	handleSendContract = () => {
		const { selectedOffer, contractFilePreview, dispatch, t } = this.props
		const propertyId = getAuthenticationData('selectedPropertyId')
		let formData = new FormData()
		formData.append('file', contractFilePreview.attachment)
		dispatch(biddingSendContractFile(propertyId, selectedOffer.id, formData))
			.then(res => {
				if (res.payload.data) {
					dispatch(
						showNotification({
							message: t('NOTIFICATION.SUCCESS')
						})
					)
					this.setState({
						isAttachModalShown: false,
						isConfirmShown: false
					})
					this.props.dispatch(biddingChangeField('contractFilePreview', null))
				}
			})
			.catch(err =>
				dispatch(
					showNotification({
						message: t('NOTIFICATION.FAIL'),
						status: notificationStatus.fail
					})
				)
			)
	}
	handleShowConfirm = value => {
		this.setState({
			isConfirmShown: value
		})
	}
	renderOfferItems = () => {
		const { offers, selectedOffer } = this.props
		if (!offers || offers.length <= 0) {
			return null
		}
		return offers.map((offer, key) => (
			<OfferItem
				offer={offer}
				key={key}
				order={key}
				selectedOffer={selectedOffer}
				handleSelectBid={this.handleSelectBid}
				isShownDetail={offer.id == selectedOffer}
			/>
		))
	}
	renderRequests = () => {
		const { requests, selectedRequest } = this.props

		return requests.map((request, key) => (
			<RequestItem
				className={request.id == selectedRequest.id ? 'selectedItem' : ''}
				key={key}
				request={request}
				handleChooseItem={() => this.handleChooseRequest(true, request)}
			/>
		))
	}

	renderAttachmentModal = () => {
		const { contractFilePreview, t } = this.props
		const { isAttachModalShown } = this.state
		if (isAttachModalShown) {
			return (
				<Modal
					title={t('BIDDING.MODAL.ATTACHMENT.TITLE')}
					handleCloseModal={() => this.handleAttachmentShow(false)}
					className="attachment-modal"
				>
					<div className="description">
						{t('BIDDING.MODAL.ATTACHMENT.DESC')}
					</div>
					<AttachmentPicker
						handlePickAttachment={this.handlePickAttachment}
						accept="application/pdf"
					/>
					{contractFilePreview && contractFilePreview.url && (
						<>
							<div className="preview">
								<embed src={contractFilePreview.url} />
							</div>
							<Button
								title={t('BIDDING.MODAL.ATTACHMENT.BUTTON')}
								onClick={() => this.handleShowConfirm(true)}
							/>
						</>
					)}
				</Modal>
			)
		}
		return null
	}
	renderConfirmModal = () => {
		const { contractLoading, selectedOffer, t } = this.props
		const { isConfirmShown } = this.state
		if (isConfirmShown) {
			return (
				<Modal
					title={t('BIDDING.MODAL.CONFIRM.TITLE')}
					className="confirm-modal"
				>
					<div className="content">
						{t('BIDDING.MODAL.CONFIRM.DESC')} ${selectedOffer.price}e
					</div>
					{contractLoading ? (
						<Loading />
					) : (
						<div className="confirm-buttons">
							<Button
								title={t('BIDDING.MODAL.CONFIRM.BUTTON.YES')}
								onClick={this.handleSendContract}
							/>
							<Button
								title={t('BIDDING.MODAL.CONFIRM.BUTTON.NO')}
								onClick={() => this.handleShowConfirm(false)}
							/>
						</div>
					)}
				</Modal>
			)
		}
		return null
	}
	renderAcceptRequestModal = () => {
		const { t, selectedRequest } = this.props
		const { isAcceptRequestShown } = this.state
		if (isAcceptRequestShown) {
			return (
				<Modal
					title={t('BIDDING.MODAL.ACCEPT.TITLE')}
					className="accept-modal"
					handleCloseModal={() => this.handleChooseRequest(false)}
				>
					<div>
						{t('BIDDING.MODAL.ACCEPT.DESC')}{' '}
						{moment(selectedRequest.startDate).format('DD/MM')} -{' '}
						{moment(selectedRequest.endDate).format('DD/MM')}
					</div>
					<div className="confirm-buttons">
						<Button title={t('BIDDING.MODAL.ACCEPT.BUTTON.YES')} />
						<Button
							title={t('BIDDING.MODAL.ACCEPT.BUTTON.NO')}
							onClick={() => this.handleChooseRequest(false)}
						/>
					</div>
				</Modal>
			)
		}
		return null
	}
	render() {
		const {
			t,
			loading,
			error,
			showing,
			selectedOffer,
			contractFilePreview
		} = this.props
		const { isActivedTab, isAttachModalShown, isConfirmShown } = this.state
		if (loading) {
			return (
				<div className="bidding-container">
					<Loading />
				</div>
			)
		}

		return (
			<div className="bidding-container">
				<div className="header">
					<h1>{t('BIDDING.TITLE')}</h1>
				</div>
				<div className="content">
					<div className="label">
						<p
							className={isActivedTab == 'bids' ? 'bids activeLabel' : 'bids'}
							onClick={() => this.handleSelectTab('bids')}
						>
							{t('BIDDING.BIDDING_LIST.LABEL.BIDS').toUpperCase()}
						</p>
						<p
							className={
								isActivedTab == 'messages' ? 'messages activeLabel' : 'messages'
							}
							onClick={() => this.handleSelectTab('messages')}
						>
							{t('BIDDING.BIDDING_LIST.LABEL.MESSAGES').toUpperCase()}
						</p>
					</div>

					<div className="list">
						{isActivedTab == 'bids' && (
							<div className="offers">{this.renderOfferItems()}</div>
						)}{' '}
						{isActivedTab == 'messages' && (
							<div className="messages">{this.renderRequests()}</div>
						)}
					</div>
					<div className="control">
						{error ? <span className="error">{error.message}</span> : null}
						{isActivedTab == 'bids' && selectedOffer && (
							<Button
								title={t('BIDDING.BUTTON.SEND')}
								onClick={() => {
									this.handleAttachmentShow(true)
								}}
							/>
						)}

						<Button
							title={t('BIDDING.BUTTON.BACK')}
							onClick={() => this.props.history.push(routerPath.dashboard)}
						/>
					</div>
					{this.renderAttachmentModal()}
					{this.renderConfirmModal()}
					{this.renderAcceptRequestModal()}
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	selectedRequest: biddingSelectors.getSelectedRequest(state.biddingReducer),
	contractFilePreview: biddingSelectors.getContractFilePreview(
		state.biddingReducer
	),
	requests: showingRequestSelectors.getRequests(state.showingRequestReducer),
	messages: biddingSelectors.getMessages(state.biddingReducer),
	selectedOffer: biddingSelectors.getSelectedOffer(state.biddingReducer),
	offers: biddingSelectors.getOffers(state.biddingReducer),
	contractLoading: biddingSelectors.getContractLoading(state.biddingReducer),
	loading: biddingSelectors.getLoading(state.biddingReducer),
	error: biddingSelectors.getError(state.biddingReducer)
})
export default withRouter(withTranslation()(connect(mapStateToProps)(Bidding)))
