import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
// Component
import Inputs from 'shared/components/Inputs'
import RadioButton from 'shared/components/RadioButton'
import SaveBookmark from 'shared/components/SaveBookmark'
import Textareas from 'shared/components/Textareas'

//Actions
import { formChangeField } from '@actions'
// Reducers
import { formSelectors } from 'reducers'
// constants
import { saveField } from '@constants'
class PlotForm extends React.Component {
	constructor() {
		super()
	}

	render() {
		const {
			isSectionLoading,
			plotOwnerShips,
			isSectionSaved,
			handleClickSave,
			handleChangeField,
			plotOwn,
			estateId,
			plotArea,
			buildingInfo,
			plotInfo,
			isActive,
			t
		} = this.props
		const plotInputs = [
			{
				label: i18next.t('PROPERTY.BASICFORM.PLOTFORM.ESTATE_ID'),
				name: 'estateId',
				value: estateId
			},
			{
				name: ''
			},
			{
				label: i18next.t('PROPERTY.BASICFORM.PLOTFORM.PLOT_AREA'),
				name: 'plotArea',
				value: plotArea
			}
		]
		const plotTextarea = [
			{
				name: 'buildingInfo',
				value: buildingInfo,
				label: i18next.t('PROPERTY.BASICFORM.PLOTFORM.BUILDING_INFO')
			},
			{
				name: 'plotInfo',
				value: plotInfo,
				label: i18next.t('PROPERTY.BASICFORM.PLOTFORM.PLOT_INFO')
			}
		]
		return (
			<div
				className={
					'form-topic_content plotForm' + (isActive ? ' active' : ' hidden')
				}
			>
				<div className="form-topic_content-center">
					<div className="field-content">
						<RadioButton
							label={i18next.t('PROPERTY.BASICFORM.PLOTFORM.PLOT_OWNERSHIP')}
							options={plotOwnerShips}
							value={`${plotOwn}`}
							name="plotOwn"
							handleCheckRadioButton={handleChangeField}
						/>
					</div>
					<div className="field-content">
						<Inputs data={plotInputs} onChange={handleChangeField} />
					</div>
					<div className="field-content">
						<Textareas
							data={plotTextarea}
							handleChangeTextarea={handleChangeField}
						/>
					</div>
					<SaveBookmark
						isSaved={isSectionSaved.plots}
						sectionName={saveField.plots.name}
						handleClickSave={handleClickSave}
						isLoading={isSectionLoading.plots}
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	plotOwnerShips: formSelectors.getPlotOwnerShips(state.formReducer),
	plotOwn: formSelectors.getPlotOwn(state.formReducer),
	estateId: formSelectors.getEstateId(state.formReducer),
	plotArea: formSelectors.getPlotArea(state.formReducer),
	buildingInfo: formSelectors.getBuildingInfo(state.formReducer),
	plotInfo: formSelectors.getPlotInfo(state.formReducer)
})

export default connect(mapStateToProps)(PlotForm)
