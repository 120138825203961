import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import Pagination from 'react-js-pagination'
// component
import Button from 'shared/components/Button'
import Textarea from 'shared/components/Textarea'

import Modal from 'shared/components/Modal'
import Preview from 'pages/Preview'
import Loading from 'shared/components/Loading'
// actions
import {
	adminGetProperties,
	adminChangeField,
	adminSendFeedback,
	adminPublishProperty,
	showNotification
} from '@actions'
// reducers
import { adminSelectors } from 'reducers'
// constants
import { pageRangeDisplayed, notificationStatus } from '@constants'
// utils
import {
	saveAuthenticationData,
	removeTokens,
	getAuthenticationData
} from './../../utils'
class Admin extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isFeedbackShown: false
		}
	}
	componentDidMount() {
		const { pageNumber, entriesPerPage } = this.props
		this.props.dispatch(adminGetProperties(pageNumber, entriesPerPage))
	}
	handlePreview = id => {
		saveAuthenticationData('selectedPropertyId', id)
		this.props.history.push('preview')
	}
	handleCloseFeedback = () => {
		localStorage.removeItem('selectedPropertyId')
		this.setState({
			isFeedbackShown: false
		})
		this.props.dispatch(adminChangeField('content', ''))
	}
	handleOpenFeedback = id => {
		saveAuthenticationData('selectedPropertyId', id)
		this.setState({
			isFeedbackShown: true
		})
	}
	handlePublish = (id, isPublish) => {
		const { pageNumber, entriesPerPage, dispatch, t } = this.props
		dispatch(adminPublishProperty(id, { status: 2, published: isPublish }))
			.then(res => {
				if (res.payload.data) {
					dispatch(adminGetProperties(pageNumber, entriesPerPage))
				}
				dispatch(
					showNotification({
						message: t('NOTIFICATION.SUCCESS')
					})
				)
			})
			.catch(err =>
				dispatch(
					showNotification({
						message: t('NOTIFICATION.FAIL'),
						status: notificationStatus.fail
					})
				)
			)
	}
	handleChangeField = e => {
		const { name, value } = e.target
		this.props.dispatch(adminChangeField(name, value))
	}
	handleSendFeedback = () => {
		const { dispatch, content, t } = this.props
		const id = getAuthenticationData('selectedPropertyId')
		let data = { content }
		if (!content) {
			return dispatch(
				showNotification({
					message: t('NOTIFICATION.BLANK_FIELD'),
					status: notificationStatus.warning
				})
			)
		}
		dispatch(adminSendFeedback(id, data))
			.then(res => {
				if (res.payload.data) {
					this.setState({
						isFeedbackShown: false
					})
				}
				dispatch(
					showNotification({
						message: t('NOTIFICATION.SUCCESS')
					})
				)
			})
			.catch(err =>
				dispatch(
					showNotification({
						message: t('NOTIFICATION.FAIL'),
						status: notificationStatus.fail
					})
				)
			)
		dispatch(adminChangeField('content', ''))
	}
	renderList = () => {
		const { properties, t, content } = this.props
		const { isFeedbackShown } = this.state
		if (!properties || properties.length < 1) {
			return null
		}
		return properties.map((property, key) => {
			const {
				informationField: { streetAddress, zipCode, city }
			} = property
			return (
				<div className="propertyItem" key={key}>
					<div className="property-info">
						<div className="address">
							{!streetAddress && !zipCode && !city
								? `[${t('ADMIN.EMPTY_ADDRESS')}]`
								: `${streetAddress} ${zipCode} ${city}`}
						</div>

						{property.needToCheck && (
							<span className="status">{t('ADMIN.NEED_TO_CHECK')}</span>
						)}
					</div>
					<div className="property-status">
						{property.sold
							? `(${t('ADMIN.STATUS.SOLD')})`
							: `(${t('ADMIN.STATUS.SELLING')})`}
					</div>
					<div className="property-feedback">
						<span onClick={() => this.handleOpenFeedback(property.id)}>
							{t('ADMIN.BUTTON.FEEDBACK')}
						</span>
						{isFeedbackShown && (
							<Modal
								handleCloseModal={this.handleCloseFeedback}
								title={t('ADMIN.FEEDBACK.TITLE')}
							>
								<Textarea
									name="content"
									value={content}
									handleChangeTextarea={this.handleChangeField}
								/>
								<Button title="send" onClick={this.handleSendFeedback} />
							</Modal>
						)}
					</div>
					<div className="property-preview">
						<span onClick={() => this.handlePreview(property.id)}>
							{t('ADMIN.BUTTON.PREVIEW')}
						</span>
					</div>
					<div className="property-publish">
						<span
							onClick={() =>
								this.handlePublish(property.id, !property.published)
							}
						>
							{property.published
								? t('ADMIN.BUTTON.UN_PUBLISH')
								: t('ADMIN.BUTTON.PUBLISH')}
						</span>
					</div>
				</div>
			)
		})
	}
	handlePageChange = pageNumber => {
		const { entriesPerPage, dispatch } = this.props
		dispatch(adminGetProperties(pageNumber, entriesPerPage))
	}
	render() {
		const {
			loading,
			error,
			t,
			entriesCount,
			entriesPerPage,
			pageNumber,
			match: {
				params: { id }
			}
		} = this.props
		switch (id) {
			case 'all':
				return (
					<div className="admin-container">
						<h1 className="header">{t('ADMIN.TITLE')}</h1>
						{loading ? (
							<Loading />
						) : (
							<>
								<div className="list">{this.renderList()}</div>
								<div>
									<Pagination
										activePage={pageNumber}
										itemsCountPerPage={entriesPerPage}
										totalItemsCount={entriesCount}
										pageRangeDisplayed={3}
										onChange={this.handlePageChange}
										hideDisabled={true}
									/>
								</div>
							</>
						)}
					</div>
				)
			default:
				return <Preview />
		}
	}
}
const mapStateToProps = state => ({
	content: adminSelectors.getContent(state.adminReducer),
	properties: adminSelectors.getProperties(state.adminReducer),
	entriesCount: adminSelectors.getEntriesCount(state.adminReducer),
	entriesPerPage: adminSelectors.getEntriesPerPage(state.adminReducer),
	pageNumber: adminSelectors.getPageNumber(state.adminReducer),
	loading: adminSelectors.getLoading(state.adminReducer),
	error: adminSelectors.getError(state.adminReducer)
})
export default withRouter(withTranslation()(connect(mapStateToProps)(Admin)))
