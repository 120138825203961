import React, { useState } from 'react'
import { Slide } from 'react-slideshow-image'
import { fileEndpoint } from '@constants'
const Slideshow = ({ data }) => {
	const [currentItem, setCurrentItem] = useState(1)
	const properties = {
		duration: 5000,
		transitionDuration: 500,
		infinite: true,
		indicators: true,
		arrows: true,
		onChange: (oldIndex, newIndex) => {
			setCurrentItem(newIndex + 1)
		}
	}
	const renderItem = data => {
		let name = 'video'
		return data.map((item, key) => {
			if (item.contentType.includes('image')) {
				return (
					<div className="each-slide" key={key}>
						<img
							className="slide-item"
							src={`${fileEndpoint}/${item.key}`}
							alt="image"
						/>
					</div>
				)
			} else if (item.contentType.includes('video')) {
				return (
					<div className="each-slide" key={key}>
						<video
							preload="metadata"
							className="add-icon-wrapper"
							key={key}
							src={`${fileEndpoint}/${item.key}`}
							alt="abc"
							controls
						></video>
					</div>
				)
			}
		})
	}
	return (
		<div className="slideShow-container">
			<Slide {...properties}>{renderItem(data)}</Slide>
			<div className="slideShow-count">
				{currentItem}/{data.length}
			</div>
		</div>
	)
}
export default Slideshow
