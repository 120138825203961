/**
 * Generic select components
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 */

import React, { Component } from 'react'
import Slider from '@material-ui/lab/Slider'

const CustomSlider = ({
	marks = [
		{
			value: 0
		},
		{
			value: 100
		}
	],
	value,
	step = 50,
	onChange,
	disabled,
	max,
	min
}) => {
	return (
		<div className="slider-container">
			<Slider
				max={max}
				min={min}
				value={value}
				aria-labelledby="discrete-slider"
				step={step}
				marks={marks}
				onChange={onChange}
				disabled={disabled}
				name="slider-menu"
			/>
		</div>
	)
}

export default CustomSlider
