/**
 * Certificate reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	CERTIFICATE_CHANGE_FIELD,
	CERTIFICATE_SEND_REQUEST,
	CERTIFICATE_SEND_REQUEST_SUCCESS,
	CERTIFICATE_SEND_REQUEST_FAIL,
	CERTIFICATE_SEND_CERTIFICATE,
	CERTIFICATE_SEND_CERTIFICATE_SUCCESS,
	CERTIFICATE_SEND_CERTIFICATE_FAIL,
	CERTIFICATE_DELETE_CERTIFICATE,
	CERTIFICATE_DELETE_CERTIFICATE_SUCCESS,
	CERTIFICATE_DELETE_CERTIFICATE_FAIL
} from '@actions'
const initialState = {
	data: [
		{
			value: 'true',
			text: 'Olen isännöitsijä'
		},
		{
			value: 'false',
			text: 'En ole isännöitsijä'
		}
	],
	type: 'managerCertificate',
	certificate: 'false',
	managerEmail: '',
	loading: false,
	error: null
}
export const certificateReducer = (state = initialState, action) => {
	switch (action.type) {
		case CERTIFICATE_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case CERTIFICATE_SEND_REQUEST:
			return {
				...state,
				loading: true
			}
		case CERTIFICATE_SEND_REQUEST_SUCCESS:
			return {
				...state,
				loading: false
			}
		case CERTIFICATE_SEND_REQUEST_FAIL:
			return {
				...state,
				loading: false
			}
		case CERTIFICATE_SEND_CERTIFICATE:
			return {
				...state,
				loading: true
			}
		case CERTIFICATE_SEND_CERTIFICATE_SUCCESS:
			return {
				...state,
				loading: false
			}
		case CERTIFICATE_SEND_CERTIFICATE_FAIL:
			return {
				...state,
				loading: false
			}
		case CERTIFICATE_DELETE_CERTIFICATE:
			return {
				...state,
				loading: true
			}
		case CERTIFICATE_DELETE_CERTIFICATE_SUCCESS:
			return {
				...state,
				loading: false
			}
		case CERTIFICATE_DELETE_CERTIFICATE_FAIL:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}
export const certificateSelectors = {
	getType: (state = initialState) => state.type,
	getData: (state = initialState) => state.data,
	getCertificate: (state = initialState) => state.certificate,
	getManagerEmail: (state = initialState) => state.managerEmail,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
