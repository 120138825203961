/**
 * Notification actions
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	NOTIFICATION_SHOW_NOTIFICATION,
	NOTIFICATION_HIDE_NOTIFICATION
} from './types'
import { notificationStatus } from '@constants'
const initialNotification = {
	status: notificationStatus.success,
	message: ''
}
export const showNotification = params => ({
	type: NOTIFICATION_SHOW_NOTIFICATION,
	payload: {
		...initialNotification,
		...params
	}
})
export const hideNotification = () => ({
	type: NOTIFICATION_HIDE_NOTIFICATION
})
