import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import DatePicker from 'react-datepicker'

// reducer
import { showingSelectors } from 'reducers'
// components
import RadioButton from 'shared/components/RadioButton'
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import JethronTip from 'shared/components/JethronTip'

// constants
import {
	isShowingPublicData,
	navigationPath,
	notificationStatus
} from '@constants'
// actions
import {
	showingChangeField,
	showingSendShowing,
	showNotification,
	showingResetState
} from '@actions'
class Showing extends Component {
	componentWillUnmount() {
		this.props.dispatch(showingResetState())
	}
	handleChangeDate = (date, name) => {
		const { dispatch } = this.props
		let newDateFormat = moment(date).format()
		dispatch(showingChangeField(name, newDateFormat))
	}
	handleChangeField = e => {
		const { name, value, checked, type } = e.target
		const { dispatch } = this.props
		let valueField = value
		if (value == 'true') {
			valueField = true
		}
		if (value == 'false') {
			valueField = false
		}
		dispatch(showingChangeField(name, valueField))
	}
	handleSubmitForm = () => {
		const { dispatch, history, time, isPublic, info, t } = this.props
		const data = { time, isPublic, info }
		if (!time) {
			return dispatch(
				showNotification({
					message: t('NOTIFICATION.BLANK_FIELD'),
					status: notificationStatus.warning
				})
			)
		}
		dispatch(showingSendShowing(data))
			.then(res => {
				dispatch(
					showNotification({
						message: t('NOTIFICATION.SUCCESS')
					})
				)
				if (res.payload.data) {
					history.push(navigationPath.dashboard)
				}
			})
			.catch(err =>
				dispatch(
					showNotification({
						message: t('NOTIFICATION.FAIL'),
						status: notificationStatus.fail
					})
				)
			)
	}
	render() {
		const { t, time, isPublic, info, error, loading } = this.props
		let isPublicShowing
		if (isPublic == true) {
			isPublicShowing = 'true'
		} else {
			isPublicShowing = 'false'
		}
		return (
			<div className="showing-container">
				<div className="showing-header">
					<h1>{t('SHOWING.TITLE')}</h1>
					<p className="">{t('SHOWING.DESC')}</p>
				</div>
				<div className="field-content">
					<div className="time">
						<span>{t('SHOWING.TIME')}</span>
						<DatePicker
							selected={
								time ? moment(time, moment.defaultFormat).toDate() : undefined
							}
							onChange={date => this.handleChangeDate(date, 'time')}
							showTimeSelect
							timeFormat="HH:mm"
							timeIntervals={15}
							dateFormat="MMMM d, yyyy h:mm aa"
						/>
					</div>
					<JethronTip />
				</div>
				<div className="publish">
					<RadioButton
						label={t('SHOWING.PUBLIC.TITLE')}
						options={isShowingPublicData}
						value={isPublicShowing}
						name="isPublic"
						handleCheckRadioButton={this.handleChangeField}
					/>
				</div>
				<div className="field-content info">
					<Input
						value={info}
						name="info"
						onChange={this.handleChangeField}
						label={t('SHOWING.INFO')}
					/>
				</div>
				<div className="formButton">
					{error ? <span className="error">{error.message}</span> : null}
					{loading ? (
						<Loading />
					) : (
						<Button
							title={t('GENERAL.FORMS.SAVE')}
							onClick={this.handleSubmitForm}
						/>
					)}
					<Button
						title={t('GENERAL.FORMS.BACK')}
						onClick={() => {
							this.props.history.push(navigationPath.dashboard)
						}}
						className="back-button"
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	time: showingSelectors.getTime(state.showingReducer),
	info: showingSelectors.getInfo(state.showingReducer),
	isPublic: showingSelectors.getIsPublic(state.showingReducer),
	loading: showingSelectors.getLoading(state.showingReducer),
	error: showingSelectors.getError(state.showingReducer)
})
export default connect(mapStateToProps)(withRouter(withTranslation()(Showing)))
