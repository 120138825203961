import React from 'react'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

// component
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
import JethronTip from 'shared/components/JethronTip'
import DatePicker from 'react-datepicker'

const NewBid = ({
	property,
	debtFree,
	offerPrice,
	handleChangeField,
	handleChangeDate,
	validDate,
	payDate,
	moveDate,
	t
}) => {
	return (
		<div className="newBid">
			<div className="newBid_header">
				<h4>{t('BUYER.BID_FORM.NEW_BID.TITLE')}</h4>
				<h1>{t('BUYER.BID_FORM.NEW_BID.DESC')}</h1>
			</div>
			<div className="newBid_description">
				{t('BUYER.BID_FORM.NEW_BID.CONTENT_HEADER')}{' '}
				<span className="debtFree">€ {debtFree}</span>{' '}
				{t('BUYER.BID_FORM.NEW_BID.CONTENT_FOOTER')}
			</div>
			<div className="newBid_form">
				<Input
					label={t('BUYER.BID_FORM.NEW_BID.PRICE')}
					name="offerPrice"
					value={offerPrice}
					onChange={handleChangeField}
				/>
				<div className="validDate">
					<span className="title">
						{t('BUYER.BID_FORM.NEW_BID.VALID_DATE')}
					</span>
					<DatePicker
						selected={
							validDate
								? moment(validDate, moment.defaultFormat).toDate()
								: undefined
						}
						onChange={date => handleChangeDate(date, 'validDate')}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
				</div>

				<div className="payDate">
					<span className="title">{t('BUYER.BID_FORM.NEW_BID.PAY_DATE')}</span>
					<DatePicker
						selected={
							payDate
								? moment(payDate, moment.defaultFormat).toDate()
								: undefined
						}
						onChange={date => handleChangeDate(date, 'payDate')}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
				</div>

				<div className="moveDate">
					<span className="title">{t('BUYER.BID_FORM.NEW_BID.MOVE_DATE')}</span>
					<DatePicker
						selected={
							moveDate
								? moment(moveDate, moment.defaultFormat).toDate()
								: undefined
						}
						onChange={date => handleChangeDate(date, 'moveDate')}
						showTimeSelect
						timeFormat="HH:mm"
						timeIntervals={15}
						dateFormat="MMMM d, yyyy h:mm aa"
					/>
					<div className="note">
						<i className="material-icons">error_outline</i>
						<div className="content">{t('BUYER.BID_FORM.NEW_BID.NOTE')}</div>
					</div>
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(NewBid)
