/**
 * Presentation request form action
 *
 */
import {
	SHOWING_REQUEST_SEND_REQUEST,
	SHOWING_REQUEST_CHANGE_FIELD,
	SHOWING_REQUEST_VALIDATE_FIELD,
	SHOWING_REQUEST_RESET_ERROR,
	SHOWING_REQUEST_GET_REQUESTS
} from '@actions'

export const showingRequestSendRequest = (propertyId, data) => {
	return {
		type: SHOWING_REQUEST_SEND_REQUEST,
		payload: {
			request: {
				method: 'POST',
				url: `/requests/${propertyId}`,
				data
			}
		}
	}
}

export const showingRequestChangeField = (name, value) => {
	return {
		type: SHOWING_REQUEST_CHANGE_FIELD,
		name,
		value
	}
}
export const showingRequestResetError = () => {
	return {
		type: SHOWING_REQUEST_RESET_ERROR
	}
}
export const showingRequestValidate = (nameField, hasError, error) => {
	return {
		type: SHOWING_REQUEST_VALIDATE_FIELD,
		nameField,
		hasError,
		error
	}
}
export const showingRequestGetRequests = () => {
	return {
		type: SHOWING_REQUEST_GET_REQUESTS,
		payload: {
			request: {
				method: 'GET',
				url: `/requests`
			}
		}
	}
}
