import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
// Components
import RadioButton from 'shared/components/RadioButton'
import Loading from 'shared/components/Loading'
import Select from 'shared/components/Select'

import CertificateRequest from 'pages/Property/Components/CertificateRequest'
import Modal from 'shared/components/Modal'
import Button from 'shared/components/Button'

import JethronTip from 'shared/components/JethronTip'
// utils
import { getAuthenticationData } from './../../../utils'
//actions
import {
	certificateChangeField,
	certificateSendRequest,
	certificateSendCertificate,
	mediaFormAddAttachment,
	propertyGetProperty,
	certificateDeleteCertificate,
	showNotification
} from '@actions'
// reducers
import { mediaSelectors, formSelectors, certificateSelectors } from 'reducers'
// constants
import { fileEndpoint, notificationStatus, certificateTypes } from '@constants'
import AttachmentPicker from 'shared/components/AttachmentPicker'
class Certificate extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isAttachmentShown: false,
			isActiveTab: 'order'
		}
	}

	handleChangeField = e => {
		const { name, value } = e.target
		this.props.dispatch(certificateChangeField(name, value))
	}
	handleSubmitCertificateRequest = () => {
		const {
			dispatch,
			managerEmail,
			ownerEmail,
			requestDescription
		} = this.props
		let data = { managerEmail }
		return dispatch(certificateSendRequest(data))
	}

	handleDeletePdfFile = item => {
		const propertyId = getAuthenticationData('selectedPropertyId')
		this.props.dispatch(certificateDeleteCertificate(item.id)).then(res => {
			if (res.payload.data) {
				this.props.dispatch(propertyGetProperty(propertyId))
			}
		})
	}
	handlePickAttachment = (attachment, e) => {
		const { type, dispatch, t } = this.props
		const propertyId = getAuthenticationData('selectedPropertyId')
		let formData = new FormData()
		formData.append('file', attachment)
		dispatch(mediaFormAddAttachment(formData, null, type))
			.then(res => {
				if (res.payload.data) {
					dispatch(
						showNotification({
							message: t('NOTIFICATION.SUCCESS'),
							status: notificationStatus.success
						})
					)
					dispatch(propertyGetProperty(propertyId))
				}
			})
			.catch(err =>
				dispatch(
					showNotification({
						message: t('NOTIFICATION.FAIL'),
						status: notificationStatus.fail
					})
				)
			)
	}
	translatePdfType = type => {
		let translatedName = 'file'
		certificateTypes.map(item => {
			if (item.value == type) {
				return (translatedName = item.text)
			}
		})
		return translatedName
	}
	renderFiles = () => {
		const { files } = this.props
		let pdfArray = files.filter(item => item.contentType.includes('pdf'))
		return pdfArray.map((item, key) => {
			return (
				<div className="certificateFile" key={key}>
					<embed src={`${fileEndpoint}/${item.key}`} />
					<div
						className="certificateDelete"
						onClick={() => this.handleDeletePdfFile(item)}
					>
						-
					</div>
					<div className="type">{this.translatePdfType(item.type)}</div>
				</div>
			)
		})
	}
	handleCheckCertificateType = e => {
		const { name, value } = e.target
		this.props.dispatch(certificateChangeField(name, value))
	}

	renderCertificate = () => {
		const {
			type,
			certificate,
			managerEmail,
			ownerName,
			t,
			streetAddress,
			zipCode,
			postalCity,
			error,
			loading
		} = this.props
		const { isActiveTab } = this.state
		const managerEmailData = {
			label: t('PROPERTY.CERTIFICATE.REQUEST.MANAGER_EMAIL'),
			name: 'managerEmail',
			value: managerEmail
		}

		switch (isActiveTab) {
			case 'order':
				return (
					<CertificateRequest
						streetAddress={streetAddress}
						zipCode={zipCode}
						postalCity={postalCity}
						managerEmail={managerEmailData}
						handleChangeField={this.handleChangeField}
						handleSubmitForm={this.handleSubmitCertificateRequest}
						buttonTitle={t('PROPERTY.CERTIFICATE.REQUEST.BUTTON')}
						submitTitle={t('PROPERTY.CERTIFICATE.REQUEST.IS_SUBMITTED.TITLE')}
						submitDescription={t(
							'PROPERTY.CERTIFICATE.REQUEST.IS_SUBMITTED.TITLE'
						)}
						error={error}
						loading={loading}
					/>
				)
			case 'attach':
				return (
					<div>
						{loading ? (
							<Loading />
						) : (
							<>
								<div className="control">
									<RadioButton
										options={certificateTypes}
										handleCheckRadioButton={this.handleCheckCertificateType}
										name="type"
										value={type}
									/>
									<AttachmentPicker
										handlePickAttachment={this.handlePickAttachment}
										icon="add"
										accept="application/pdf"
									/>
								</div>
								<div className="list">{this.renderFiles()}</div>
							</>
						)}
					</div>
				)
			default:
				return <JethronTip />
		}
	}
	renderTabStatus = tab => {
		const { isActiveTab } = this.state
		let className = ['tab-item']
		if (isActiveTab == tab) {
			className.push('isActive')
		}
		return className.join(' ')
	}
	handleActiveTab = tab => {
		this.setState({
			isActiveTab: tab
		})
	}
	render() {
		const {
			certificateData,
			certificate,
			t,
			handleSubmitForm,
			handleBackForm
		} = this.props
		const { isAttachmentShown, isActiveTab } = this.state
		return (
			<div className="form-topic_content certificate-container">
				<div className="form-topic_content-center">
					<h1 className="header">
						{t('PROPERTY.CERTIFICATE.TITLE').toUpperCase()}
					</h1>
					<div className="tabs">
						<div
							className={this.renderTabStatus('order')}
							onClick={() => this.handleActiveTab('order')}
						>
							Order certificate
						</div>
						<div
							className={this.renderTabStatus('attach')}
							onClick={() => this.handleActiveTab('attach')}
						>
							Attach Certificate
						</div>
					</div>
				</div>
				{certificate && (
					<div className="certificate-request">{this.renderCertificate()}</div>
				)}
			</div>
		)
	}
}
const mapStateToProps = state => ({
	files: mediaSelectors.getFiles(state.mediaFormReducer),
	loading: certificateSelectors.getLoading(state.certificateReducer),
	error: certificateSelectors.getError(state.certificateReducer),
	streetAddress: formSelectors.getStreetAddress(state.formReducer),
	zipCode: formSelectors.getZipCode(state.formReducer),
	ownerName: formSelectors.getName(state.formReducer),
	postalCity: formSelectors.getPostalCity(state.formReducer),
	certificateData: certificateSelectors.getData(state.certificateReducer),
	certificate: certificateSelectors.getCertificate(state.certificateReducer),
	type: certificateSelectors.getType(state.certificateReducer),
	managerEmail: certificateSelectors.getManagerEmail(state.certificateReducer)
})
export default withTranslation()(connect(mapStateToProps)(Certificate))
