import React, { Component } from 'react'
import { Bar, Pie } from 'react-chartjs-2'
import { connect } from 'react-redux'
class MyChart extends Component {
	constructor(props) {
		super(props)
		this.state = {}
	}

	render() {
		const { statisticNumber } = this.props
		const options = {
			segmentShowStroke: false,
			maintainAspectRatio: false,
			responsive: true,
			legend: {
				position: 'center'
			}
		}
		return (
			<div className="customChart-container">
				<div className="chart">
					<Pie
						data={this.props.data}
						height={250}
						width={250}
						options={options}
					/>
				</div>
				{statisticNumber && (
					<div className="statisticNumber">
						<div className="content">{statisticNumber}</div>
					</div>
				)}
			</div>
		)
	}
}

export default connect()(MyChart)
