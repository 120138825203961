/**
 * admin action
 *
 */
import {
	ADMIN_GET_PROPERTIES,
	ADMIN_SEND_FEEDBACK,
	ADMIN_CHANGE_FIELD,
	ADMIN_PUBLISH_PROPERTY
} from '@actions'
export const adminChangeField = (name, value) => {
	return {
		type: ADMIN_CHANGE_FIELD,
		name,
		value
	}
}
export const adminGetProperties = (pageNumber = 1, entriesPerPage = 1) => {
	return {
		type: ADMIN_GET_PROPERTIES,
		payload: {
			request: {
				method: 'GET',
				url: `/publish/property/getAll?pageNumber=${pageNumber}&entriesPerPage=${entriesPerPage}`
			}
		}
	}
}
export const adminSendFeedback = (propertyId, data) => {
	return {
		type: ADMIN_SEND_FEEDBACK,
		payload: {
			request: {
				method: 'POST',
				url: `/publish/feedback/${propertyId}`,
				data
			}
		}
	}
}
export const adminPublishProperty = (propertyId, data) => {
	return {
		type: ADMIN_PUBLISH_PROPERTY,
		payload: {
			request: {
				method: 'POST',
				url: `/publish/${propertyId}`,
				data
			}
		}
	}
}
