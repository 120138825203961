/**
 * Home component
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import aninkainen from 'assets/images/aninkainen.png'
import property_sample from 'assets/images/property_sample.png'

// Components
import Button from 'shared/components/Button'
import PropertyItem from 'shared/components/PropertyItem'
import Saving from 'pages/Home/Saving'
import Loading from 'shared/components/Loading'
import ContactBox from 'shared/components/ContactBox'

// constants
import {
	routerPath,
	navigationPath,
	fileEndpoint,
	defaultPropertiesShownHomepageView
} from '@constants'

// actions
import { propertyGetPublishedProperties, propertyGetProperty } from '@actions'
// Reducers
import { getPropertySelectors } from 'reducers'

class Home extends Component {
	constructor(props) {
		super(props)
		this.state = {
			properties: []
		}
	}

	componentDidMount() {
		this.fetchProperties()
	}

	renderItem = text => {
		return (
			<div className="homepage-item">
				<i className="material-icons">done</i> <span>{text}</span>
			</div>
		)
	}
	handleClickRegister = () => {
		this.props.history.push(navigationPath.register)
	}

	fetchProperties = () => {
		this.props.dispatch(
			propertyGetPublishedProperties(defaultPropertiesShownHomepageView)
		)
	}
	handleSelectProperty = async id => {
		await localStorage.setItem('selectedPropertyId', id)
		this.props.history.push(`${navigationPath.apartment}/${id}`)
	}
	renderProperties = () => {
		const { properties } = this.props
		return (
			<div className="properties-content">
				{properties.map(property => {
					if (!property.informationField) {
						return null
					}
					const {
						askingPrice,
						streetAddress,
						zipCode,
						city,
						region,
						areaTotal,
						apartmentInfo
					} = property.informationField
					const image = this.getPropertyImage(property.defaultImage)
					return (
						<PropertyItem
							apartmentInfo={apartmentInfo}
							id={property.id}
							key={property.id}
							price={askingPrice}
							address={streetAddress}
							region={zipCode + ' ' + city + ', ' + region}
							area={areaTotal}
							image={image}
							handleSelectProperty={this.handleSelectProperty}
						/>
					)
				})}
			</div>
		)
	}

	getPropertyImage = imageKey => {
		if (!imageKey) {
			return property_sample
		} else {
			return `${fileEndpoint}/${imageKey}`
		}
		return null
	}

	render() {
		const { t, loading } = this.props
		if (loading) {
			return (
				<div className="home-container">
					<Loading />
				</div>
			)
		}
		return (
			<div className="home-container">
				<Helmet>
					<title>Vertics | Home</title>
					<meta name="description" content="Myy itse - Etusivu" />
				</Helmet>
				<div className="home-container_selling">
					<h1>{t('HOMEPAGE.SELLING.TITLE')}</h1>
					<Button
						title={t('HOMEPAGE.SELLING.BUTTON')}
						onClick={this.handleClickRegister}
					></Button>
				</div>
				<div className="home-container_saving">
					<div className="home-container_center">
						<Saving />
					</div>
				</div>
				<div className="home-container_center">
					<div className="home-container_intro">
						<div className="intro-img">
							<img src={aninkainen} alt="logo" />
						</div>
						<div className="intro-item intro-middle-item">
							<h3 className="intro-item_title">
								{t('HOMEPAGE.INTRODUCTION.SERVICE.TITLE')}
							</h3>
							{this.renderItem('Opastus sujuvaan asuntokauppaan')}
							{this.renderItem('Markkinointikanavat')}
							{this.renderItem('Tarjouspohjat')}
							{this.renderItem('Oikotie mainokset')}
							{this.renderItem('Jethron vinkit')}
							{this.renderItem('Ammattivälittäjän apu')}
						</div>
						<div className="intro-item">
							<h3 className="intro-item_title">
								{t('HOMEPAGE.INTRODUCTION.SELLING.TITLE')}
							</h3>
							{this.renderItem('Täytä tiedot ja kuvat asunnosta')}
							{this.renderItem('Valitse ilmoituskanavat')}
							{this.renderItem('Pankissa käyminen')}
							<Button title={t('HOMEPAGE.INTRODUCTION.SELLING.BUTTON')} />
						</div>
					</div>
					<div className="home-container_properties">
						<div className="properties-header">
							<h1>{t('HOMEPAGE.PROPERTIES.TITLE')}</h1>
							<p>{t('HOMEPAGE.PROPERTIES.DESC')}</p>
						</div>
						{this.renderProperties()}
						<Button
							title={t('HOMEPAGE.PROPERTIES.BUTTON')}
							onClick={() => {
								this.props.history.push(routerPath.properties)
							}}
						/>
					</div>
				</div>
				<div className="home-container_register">
					<div className="register-content">
						<h1>{t('HOMEPAGE.REGISTER.TITLE')}</h1>
						<p>{t('HOMEPAGE.REGISTER.INTRO')}</p>
						<p>{t('HOMEPAGE.REGISTER.DESC')}</p>
					</div>
					<Button
						className={'button-white'}
						title={t('HOMEPAGE.REGISTER.BUTTON')}
						onClick={this.handleClickRegister}
					></Button>
				</div>
				<ContactBox />
			</div>
		)
	}
}

// Making some uses of connected-react-router
const mapStateToProps = state => ({
	loading: getPropertySelectors.getLoading(state.propertyReducer),
	error: getPropertySelectors.getError(state.propertyReducer),
	properties: getPropertySelectors.getProperties(state.propertyReducer)
})

export default withRouter(withTranslation()(connect(mapStateToProps)(Home)))
