import React from 'react'
import { withTranslation } from 'react-i18next'
import i18next from 'i18next'

// icons
import phoneIcon from 'assets/images/phone.png'

class ContactBox extends React.Component {
	renderContactItem = (item, key) => {
		return (
			<div className="contact-content_item" key={key}>
				<img src={item.icon} alt="phone icon" />
				<h4>{item.title}</h4>
				<p>{item.phoneNumber}</p>
				<p>{item.email}</p>
			</div>
		)
	}

	render() {
		const { t } = this.props
		const contactItems = [
			{
				title: i18next.t('GENERAL.CONTACT.TITLE'),
				icon: phoneIcon,
				phoneNumber: '+358 1234567890',
				email: 'myynti@myyitse.fi'
			},
			{
				title: i18next.t('GENERAL.CONTACT.LAW_ADVICE'),
				icon: phoneIcon,
				phoneNumber: '+358 1234567890',
				email: 'neuvonta@myyitse.fi'
			},
			{
				title: i18next.t('GENERAL.CONTACT.HOT_LINE'),
				icon: phoneIcon,
				phoneNumber: '+358 1234567890',
				email: 'jethro@myyitse.fi'
			}
		]
		return (
			<div className="contact-container">
				<h1>{t('GENERAL.CONTACT.TITLE')}</h1>

				<div className="contact-content">
					{contactItems.map((item, key) => {
						return this.renderContactItem(item, key)
					})}
				</div>
			</div>
		)
	}
}

export default withTranslation()(ContactBox)
