import React from 'react'
// constants
import { fileEndpoint } from '@constants'
// component
import AttachmentPicker from 'shared/components/AttachmentPicker'
import Button from 'shared/components/Button'

const MaterialItem = ({ contentType, url, t, handlePickAttachment }) => {
	const handlePickFile = e => {
		if (handlePickAttachment && typeof handlePickAttachment === 'function') {
			handlePickAttachment(e)
		}
	}
	const renderFile = () => {
		switch (contentType) {
			case 'img':
				return <img src={`${fileEndpoint}/${url}`} alt="" />
			case 'pdf':
				return <embed src={`${fileEndpoint}/${url}`} />
			default:
				return null
		}
	}
	return (
		<div className="material-item">
			<div className="file">{renderFile()}</div>
			{!url && (
				<AttachmentPicker
					iconText={t('TRADING.MATERIALS.BUTTON')}
					handlePickAttachment={handlePickFile}
				/>
			)}
		</div>
	)
}
export default MaterialItem
