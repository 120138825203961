/**
 * Inputs component
 *
 * @author Anh Tu Le <anh.le @vertics.co>
 *
 */

import React from 'react'
import Input from 'shared/components/Input'
const Inputs = ({
	data,
	onChange,
	handleBlurInput,
	handleFocusInput,
	handleClickInputIcon
}) => {
	const renderInputs = () => {
		return data.map((item, key) => {
			if (item.name == '') {
				return <div key={key}></div>
			}

			return (
				<Input
					type={item.type}
					name={item.name}
					onChange={onChange}
					key={key}
					value={item.value}
					label={item.label}
					handleBlurInput={handleBlurInput}
					handleFocusInput={handleFocusInput}
					handleClickInputIcon={handleClickInputIcon}
					required={item.required}
					hasError={item.hasError}
					error={item.error}
					inputText={item.inputText}
					disabled={item.disabled}
				/>
			)
		})
	}
	if (!data || data.length == 0) {
		return null
	}
	return <>{renderInputs()}</>
}

export default Inputs
