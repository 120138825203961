import React from 'react'
import { withTranslation } from 'react-i18next'

const Materials = ({ t, handleClickMaterial }) => {
	const handleClick = type => {
		if (handleClickMaterial && typeof handleClickMaterial === 'function') {
			return handleClickMaterial(type)
		}
	}

	return (
		<div className="dashboard-content_hover">
			<h2>{t('DASHBOARD.BOXES.JETHRON_MATERIAL.TITLE')}</h2>
			<div className="content">
				<div className="item" onClick={() => handleClick('video')}>
					{t('DASHBOARD.BOXES.JETHRON_MATERIAL.VIDEO')}
				</div>
				<div className="item" onClick={() => handleClick('score')}>
					{t('DASHBOARD.BOXES.JETHRON_MATERIAL.SCORING')}
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(Materials)
