import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'

import ViewShowingResults from 'pages/ShowingResults/Components/ViewShowingResults'
import AddShowingResults from 'pages/ShowingResults/Components/AddShowingResults'

class ShowingResults extends React.Component {
	constructor(props) {
		super(props)
	}

	render() {
		const { action } = this.props.match.params
		switch (action) {
			case 'view':
				return <ViewShowingResults />
			case 'add':
				return <AddShowingResults />
			default:
				return <ViewShowingResults />
		}
	}
}

const mapStateToProps = state => ({})

export default connect(mapStateToProps)(
	withRouter(withTranslation()(ShowingResults))
)
