/**
 * Generic modal component
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'

const Modal = ({
	show,
	children,
	className,
	title,
	handleCloseModal,
	type = 'normal'
}) => {
	const handleClickOverlay = e => {
		if (e.defaultPrevented) {
			return
		}

		closeModal()
	}

	const handleClickModalContainer = e => {
		e.preventDefault()
		return
	}

	const closeModal = () => {
		if (handleCloseModal && typeof handleCloseModal === 'function') {
			handleCloseModal()
		}
	}

	const renderHeader = () => {
		let headerClass =
			type == 'normal' ? ['modal-header'] : ['modal-header-absolute']
		if (title) {
			headerClass.push('with-title')
		}

		return (
			<div className={headerClass.join(' ')}>
				{title && <div className="modal-header__title">{title}</div>}
				<div className="modal-header__close-button" onClick={closeModal}>
					<i className="material-icons">close</i>
				</div>
			</div>
		)
	}

	let modalClass = ['modal']

	if (className) {
		modalClass.push(className)
	}

	if (show) {
		modalClass.push('show')
	} else {
		modalClass.push('hide')
	}

	return (
		<div className={modalClass.join(' ')} onClick={handleClickOverlay}>
			<div className="modal__container" onClick={handleClickModalContainer}>
				{renderHeader()}
				<div className="modal-content">{children}</div>
			</div>
		</div>
	)
}

export default Modal
