import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'
// Component
import Select from 'shared/components/Select'
import SaveBookmark from 'shared/components/SaveBookmark'
import Textarea from 'shared/components/Textarea'
import Textareas from 'shared/components/Textareas'

// Reducers
import { formSelectors } from 'reducers'
// constants
import { saveField } from '@constants'
class MaterialForm extends React.Component {
	constructor() {
		super()
	}

	render() {
		const {
			isSectionLoading,
			isSectionSaved,
			handleClickSave,
			handleChangeField,
			mainBuildingMaterials,
			mainBuildingMaterial,
			surfaceMaterialDescription,
			livingRoom,
			hallway,
			bedroom,
			toilet,
			isActive,
			bathroom,
			kitchen,
			t
		} = this.props
		const rooms = [
			{
				name: 'kitchen',
				value: kitchen,
				label: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.KITCHEN')
			},
			{
				name: 'livingRoom',
				value: livingRoom,
				label: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.LIVINGROOM')
			},
			{
				name: 'hallway',
				value: hallway,
				label: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.HALLWAY')
			},
			{
				name: 'bedroom',
				value: bedroom,
				label: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.BEDROOM')
			},
			{
				name: 'toilet',
				value: toilet,
				label: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.TOILET')
			},
			{
				name: 'bathroom',
				value: bathroom,
				label: i18next.t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.BATHROOM')
			}
		]
		return (
			<div
				className={
					'form-topic_content materialsForm' +
					(isActive ? ' active' : ' hidden')
				}
			>
				<div className="form-topic_content-center">
					<div className="field-content">
						<Select
							label={t('PROPERTY.BASICFORM.MATERIALFORM.MAIN_BUILDINGMATERIAL')}
							options={mainBuildingMaterials}
							value={mainBuildingMaterial}
							handleSelectOption={handleChangeField}
							name="mainBuildingMaterial"
						/>
						<div></div>
						<Textarea
							value={surfaceMaterialDescription}
							name="surfaceMaterialDescription"
							handleChangeTextarea={handleChangeField}
							label={t(
								'PROPERTY.BASICFORM.MATERIALFORM.SURFACEMATERIAL_DESCRIPTION'
							)}
						/>
					</div>
					<div className="field-content">
						<label>
							{t('PROPERTY.BASICFORM.MATERIALFORM.ROOM_DESCRIPTION')}
						</label>
						<div></div>
						<Textareas data={rooms} handleChangeTextarea={handleChangeField} />
					</div>
					<SaveBookmark
						isSaved={isSectionSaved.materials}
						sectionName={saveField.materials.name}
						handleClickSave={handleClickSave}
						isLoading={isSectionLoading.services}
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	mainBuildingMaterials: formSelectors.getMainBuildingMaterials(
		state.formReducer
	),
	mainBuildingMaterial: formSelectors.getMainBuildingMaterial(
		state.formReducer
	),
	surfaceMaterialDescription: formSelectors.getSurfaceMaterialDescription(
		state.formReducer
	),
	kitchen: formSelectors.getKitchen(state.formReducer),
	livingRoom: formSelectors.getLivingRoom(state.formReducer),
	hallway: formSelectors.getHallway(state.formReducer),
	bedroom: formSelectors.getBedroom(state.formReducer),
	toilet: formSelectors.getToilet(state.formReducer),
	bathroom: formSelectors.getBathroom(state.formReducer)
})

export default connect(mapStateToProps)(MaterialForm)
