import React from 'react'
import { withTranslation } from 'react-i18next'

// component
import Input from 'shared/components/Input'
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
const Buyers = ({
	buyers,
	handleChangeBuyersInfo,
	handleAddBuyer,
	handleRemoveBuyer,
	t
}) => {
	const handleChangeField = (e, position) => {
		if (
			handleChangeBuyersInfo &&
			typeof handleChangeBuyersInfo === 'function'
		) {
			handleChangeBuyersInfo(e, position)
		}
	}
	const handleAdd = () => {
		if (handleAddBuyer && typeof handleAddBuyer === 'function') {
			handleAddBuyer()
		}
	}
	const handleCancel = key => {
		if (handleRemoveBuyer && typeof handleRemoveBuyer === 'function') {
			handleRemoveBuyer(key)
		}
	}
	const renderBuyers = () => {
		if (!buyers && buyers.length < 1) {
			return null
		}
		return buyers.map((buyer, key) => {
			return (
				<div className="bid-buyers_form" key={key}>
					<div className="section">
						<Input
							label={t('BUYER.BID_FORM.BUYERS.NAME')}
							value={buyer.name}
							name="name"
							onChange={e => handleChangeField(e, key)}
						/>
						<Input
							label={t('BUYER.BID_FORM.BUYERS.OWNER_SHIP')}
							value={buyer.ownerShip}
							name="ownerShip"
							onChange={e => handleChangeField(e, key)}
							inputText="%"
						/>
					</div>
					<Input
						label={t('BUYER.BID_FORM.BUYERS.ADDRESS')}
						value={buyer.address}
						name="address"
						onChange={e => handleChangeField(e, key)}
					/>
					<Checkbox
						description={t('BUYER.BID_FORM.BUYERS.IS_POLITICAL')}
						value={buyer.isPolitical}
						name="isPolitical"
						handleClickCheckbox={e => handleChangeField(e, key)}
					/>
					<Checkbox
						description={t('BUYER.BID_FORM.BUYERS.IS_FIRST_TIME_BUYER')}
						value={buyer.isFirstTimeBuyer}
						name="isFirstTimeBuyer"
						handleClickCheckbox={e => handleChangeField(e, key)}
					/>
					<div className="cancel">
						<i className="material-icons">delete_outline</i>
						<span onClick={() => handleCancel(key)}>
							{t('BUYER.BID_FORM.BUYERS.BUTTON.CANCEL')}
						</span>
					</div>
				</div>
			)
		})
	}
	return (
		<div className="bid-buyers">
			<div className="bid-buyers_header">
				<h4>{t('BUYER.BID_FORM.BUYERS.TITLE')}</h4>
				<h1>{t('BUYER.BID_FORM.BUYERS.DESC')}</h1>
				<p>{t('BUYER.BID_FORM.BUYERS.SUB_DESC')}</p>
			</div>
			{renderBuyers()}
			<Button
				title={t('BUYER.BID_FORM.BUYERS.BUTTON.ADD_ROW')}
				onClick={handleAdd}
			/>
		</div>
	)
}
export default withTranslation()(Buyers)
