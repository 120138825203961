/**
 * Bidding reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	BIDDING_CHANGE_FIELD,
	BIDDING_CHANGE_SELECTED_OFFER,
	BIDDING_GET_BIDDINGS,
	BIDDING_GET_BIDDINGS_SUCCESS,
	BIDDING_GET_BIDDINGS_FAIL,
	BIDDING_RESET_STATE,
	BIDDING_SEND_CONTRACT,
	BIDDING_SEND_CONTRACT_SUCCESS,
	BIDDING_SEND_CONTRACT_FAIL
} from '@actions'
import { normalizeError } from './utils'

const initialState = {
	messages: ['lorem Ipsum', 'abcdef'],
	contractFilePreview: null,
	offers: [],
	selectedOffer: '',
	selectedRequest: '',
	contractLoading: false,
	loading: false,
	error: null
}
export const biddingReducer = (state = initialState, action) => {
	switch (action.type) {
		case BIDDING_SEND_CONTRACT:
			return {
				...state,
				contractLoading: true
			}
		case BIDDING_SEND_CONTRACT_SUCCESS:
			return {
				...state,
				contractLoading: false
			}
		case BIDDING_SEND_CONTRACT_FAIL:
			return {
				...state,
				contractLoading: false
			}
		case BIDDING_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case BIDDING_GET_BIDDINGS:
			return {
				...state,
				loading: true
			}
		case BIDDING_GET_BIDDINGS_SUCCESS:
			return {
				...state,
				offers: action.payload.data,
				loading: false
			}
		case BIDDING_GET_BIDDINGS_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case BIDDING_RESET_STATE:
			return initialState
		case BIDDING_CHANGE_SELECTED_OFFER:
			return {
				...state,
				selectedOffer: action.offer
			}

		default:
			return state
	}
}
export const biddingSelectors = {
	getSelectedRequest: (state = initialState) => state.selectedRequest,
	getContractFilePreview: (state = initialState) => state.contractFilePreview,
	getMessages: (state = initialState) => state.messages,
	getOffers: (state = initialState) => state.offers,
	getSelectedOffer: (state = initialState) => state.selectedOffer,
	getContractLoading: (state = initialState) => state.contractLoading,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
