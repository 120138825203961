import React from 'react'

const PropertyItem = ({
	apartmentInfo,
	id,
	image,
	address = 'Koskitie 5 B, 12345 Pöytyä',
	region = 'Asuinalue, Kaupunki',
	price = '145 000€',
	area = '48.5',
	handleSelectProperty
}) => {
	const handleClickProperty = () => {
		if (handleSelectProperty && typeof handleSelectProperty === 'function') {
			handleSelectProperty(id)
		}
	}
	return (
		<div className="propertyItem-container" onClick={handleClickProperty}>
			<div className="thumbnail">
				<img src={image} alt="thumbnail" />
				<div className="info">{apartmentInfo}</div>
			</div>
			<div className="propertyItem-content">
				<div className="propertyItem-content_item">
					<h4>{address}</h4>
					<h4>{price} €</h4>
				</div>
				<div className="propertyItem-content_item">
					<span>{region}</span>
					<span>{area} m²</span>
				</div>
			</div>
		</div>
	)
}
export default PropertyItem
