import React, { Component } from 'react'
import { connect } from 'react-redux'
import frontpage_background from 'assets/images/frontpage_background.png'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { getAuthenticationData } from '../../utils'
// Icons and images
import aninkainenLogo from '../../assets/images/aninkainen_logo.png'
import defaultImage from '../../assets/images/image-default.png'

import facebookIcon from 'assets/icons/facebook.png'
import twitterIcon from 'assets/icons/twitter.png'
import whatsappIcon from 'assets/icons/whatsapp.png'

import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from 'react-share'
//Components
import PreviewItems from 'shared/components/PreviewItems'
import Map from 'shared/components/Map'
import Modal from 'shared/components/Modal'
import Slideshow from 'shared/components/Slideshow'
import Button from 'shared/components/Button'
import Card from 'shared/components/Card'
import Loading from 'shared/components/Loading'
import TabMenu from 'pages/Property/Components/TabMenu'
import ShowingList from './ShowingList'
import PreviewShowingRequest from './PreviewShowingRequest'

// constant
import {
	navigationPath,
	fileEndpoint,
	translateVariable,
	toCurrency,
	ngrokUrl
} from '@constants'
//actions
import { previewGetSelectedMedia, propertyGetProperty } from '@actions'
//Reducers
import {
	//form,
	getFormPropertyId,
	getPropertySelectors,
	formSelectors,
	getFormCategory,
	getFormOptionalForm,
	mediaSelectors,
	priceSelectors,
	previewSelector,
	publishSelectors
} from 'reducers'
class Preview extends Component {
	constructor(props) {
		super(props)
		const { t } = this.props
		this.state = {
			isModalShown: false,
			isBasicInfoCategoryOpen: true,
			isPriceCategoryOpen: false,
			isAdditionalInfoCategoryOpen: false,
			isMaterialsCategoryOpen: false,
			isPlotCategoryOpen: false,
			isServicesCategoryOpen: false,
			tabView: t('PROPERTY.MEDIA.MENU_LABEL'),
			isTextShorten: false
		}
	}
	async componentWillMount() {
		const savedPropertyId = getAuthenticationData('selectedPropertyId')
		const propertyId = this.props.match.params.id
		let id
		if (propertyId) {
			id = propertyId
		} else {
			id = savedPropertyId
		}
		await this.props.dispatch(propertyGetProperty(propertyId))
	}
	componentWillUnmount() {
		// const path = this.props.match.path
		// if (path.includes('/asunto')) {
		// 	localStorage.removeItem('selectedPropertyId')
		// }
	}

	handleShowAllImages = mediaQty => {
		if (mediaQty >= 1) {
			this.setState({
				isModalShown: true
			})
		}
	}
	handleCloseModal = () => {
		this.setState({
			isModalShown: false
		})
	}
	normalizeMediaData = files => {
		if (!files || files.length == 0) {
			return []
		}
		let newImages = files.filter(item => item.contentType.includes('image'))
		let newVideos = files.filter(item => item.contentType.includes('video'))
		return newImages.concat(newVideos)
	}
	handleClickItem = media => {
		this.props.dispatch(previewGetSelectedMedia(media))
	}
	handleSubmitForm = () => {
		this.props.history.push('/publish')
	}

	toggleShowCategory = category => {
		switch (category.id) {
			case 0:
				this.setState({
					isBasicInfoCategoryOpen: !this.state.isBasicInfoCategoryOpen
				})
				return
			case 1:
				this.setState({ isPriceCategoryOpen: !this.state.isPriceCategoryOpen })
				return
			case 2:
				this.setState({
					isAdditionalInfoCategoryOpen: !this.state.isAdditionalInfoCategoryOpen
				})
				return
			case 3:
				this.setState({
					isMaterialsCategoryOpen: !this.state.isMaterialsCategoryOpen
				})
				return
			case 4:
				this.setState({ isPlotCategoryOpen: !this.state.isPlotCategoryOpen })
				return
			case 5:
				this.setState({
					isServicesCategoryOpen: !this.state.isServicesCategoryOpen
				})
				return
			default:
				return
		}
	}

	isDisabled = data => {
		if (!data) {
			return true
		}
		const flattenDeep = arr => {
			return arr.reduce(
				(acc, val) =>
					Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val),
				[]
			)
		}
		data = flattenDeep(data)
		const mapped = data.reduce((acc, item) => {
			if (item.value === '' || item.value == null || item.value == 0) {
				return acc
			} else {
				acc.push(item)
				return acc
			}
		}, [])
		if (!mapped || mapped.length === 0 || mapped[0] == null) {
			return true
		} else {
			return false
		}
	}

	isCategoryOpen = category => {
		const {
			isBasicInfoCategoryOpen,
			isPriceCategoryOpen,
			isAdditionalInfoCategoryOpen,
			isMaterialsCategoryOpen,
			isPlotCategoryOpen,
			isServicesCategoryOpen
		} = this.state

		switch (category.id) {
			case 0:
				return isBasicInfoCategoryOpen
			case 1:
				return isPriceCategoryOpen
			case 2:
				return isAdditionalInfoCategoryOpen
			case 3:
				return isMaterialsCategoryOpen
			case 4:
				return isPlotCategoryOpen
			case 5:
				return isServicesCategoryOpen
			default:
				return false
		}
	}

	renderCategories = categoryList => {
		return (
			<div>
				{categoryList.map(category => {
					const isDisabled = this.isDisabled(category.data)
					const categoryOpen = this.isCategoryOpen(category)
					const categoryData = category.data.map((dataset, i) => {
						return <PreviewItems data={dataset} key={i} />
					})
					return (
						<div className="preview-section" key={category.id}>
							<div
								className="preview-section_head"
								onClick={() =>
									isDisabled ? null : this.toggleShowCategory(category)
								}
							>
								<h2
									className={`
										${
											categoryOpen
												? 'preview-section_head_title active'
												: 'preview-section_head_title'
										} 
										${isDisabled ? 'disabled' : null}`}
								>
									{category.name}
								</h2>
								{isDisabled ? null : categoryOpen ? (
									<i className="material-icons active">clear</i>
								) : (
									<i className="material-icons">{'add_circle_outline'}</i>
								)}
							</div>
							<div
								className={
									categoryOpen ? 'previewCategory' : 'previewCategory_hidden'
								}
							>
								{categoryData}
							</div>
						</div>
					)
				})}
			</div>
		)
	}

	renderSelectedFile = item => {
		const { defaultImage, t } = this.props
		if (!item || !item.contentType) {
			if (defaultImage) {
				return <img src={`${fileEndpoint}/${defaultImage}`} alt="image" />
			} else {
				return <img src={defaultImage} alt="image" />
			}
		}
		if (item.contentType.includes('image')) {
			return (
				<>
					{' '}
					<img src={`${fileEndpoint}/${item.key}`} alt="image" />{' '}
				</>
			)
		}
		if (item.contentType.includes('video')) {
			return (
				<video
					preload="metadata"
					src={`${fileEndpoint}/${item.key}`}
					alt="video"
					controls
				></video>
			)
		}
		return null
	}
	handleSelectMenu = item => {
		this.setState({
			tabView: item
		})
	}

	countImages = (files, images) => {
		if (!files || !Array.isArray(files) || files.length === 0) {
			return []
		} else if (images) {
			return files.filter(file => file.type === 'image')
		} else {
			return files.filter(
				file => file.type === 'image' || file.type === 'video'
			)
		}
	}
	renderThumbnail = () => {
		const { tabView } = this.state
		const { t, selectedMedia, files, streetAddress, zipCode, city } = this.props
		switch (tabView) {
			case t('PROPERTY.MEDIA.MENU_LABEL'):
				return (
					<>
						<div className="thumbnail-image">
							{this.renderSelectedFile(selectedMedia)}
						</div>

						{this.countImages(files, true).length > 1 && (
							<div
								className="seeAll-popup"
								onClick={() =>
									this.handleShowAllImages(this.countImages(files, true).length)
								}
							>
								{t('PREVIEW.ALL_PHOTOS')}({this.countImages(files, true).length}
								)
							</div>
						)}
					</>
				)
			case t('PROPERTY.MAP'):
				return (
					<div className="thumbnail-image">
						<Map address={streetAddress + zipCode + city} />
					</div>
				)
		}
	}
	handleTextLength = check => {
		this.setState({ isTextShorten: check })
	}
	renderText = text => {
		const { isTextShorten } = this.state
		const { t } = this.props
		if (text.length > 20 && !isTextShorten) {
			return (
				<p className="descriptionTitle">
					{text.substring(0, 20)}
					{'...'}
					<span onClick={() => this.handleTextLength(true)}>
						{t('PREVIEW.SHOW_ALL')}
					</span>
				</p>
			)
		} else if (text.length > 20 && isTextShorten) {
			return (
				<p className="descriptionTitle">
					{text}{' '}
					<span onClick={() => this.handleTextLength(false)}>
						{t('PREVIEW.SHOW_LESS')}
					</span>
				</p>
			)
		} else {
			return <p className="descriptionTitle">{text}</p>
		}
	}
	handleBackAdmin = () => {
		this.props.history.push('/admin/all')
	}
	render() {
		const propertyId = getAuthenticationData('selectedPropertyId')
		const userId = getAuthenticationData('userId')
		const { pathname } = this.props.location
		const { isModalShown } = this.state
		const {
			userProperty,
			propertyLoading,
			error,
			loading,
			t,
			askingPrice,
			selectedMedia,
			files,
			// mandatoryform
			query,
			email,
			name,
			phone,
			informationLabel,
			houseType,
			propertyType,
			category,
			streetAddress,
			zipCode,
			postalCity,
			city,
			state,
			region,
			country,
			showMap,
			apartmentInfo,
			roomQty,
			validateError,
			areaApartment,
			areaRest,
			areaTotal,
			areaInfo,
			floorQty,
			apartmentProperties,
			apartmentBelongs,
			carPreservation,
			gardenInfo,
			landscape,
			beach,
			apartmentCondition,
			isShownOnSite,
			conditionInfo,
			inspectionInfo,
			heatingSystem,
			waterPipe,
			sewer,
			electricityConnection,
			inspections,
			conditionInspection,
			asbestosInspection,
			humidityMeasure,
			// materials
			mainBuildingMaterials,
			mainBuildingMaterial,
			surfaceMaterialDescription,
			kitchen,
			livingRoom,
			hallWay,
			bedRoom,
			toilet,
			bathRoom,
			// plot
			plotOwnerShip,
			estateId,
			plotArea,
			buildingInfo,
			plotInfo,
			// price
			targetpriceFinancing,
			targetpriceCare,
			propertyTax,
			rentPerYear,
			targetpriceElectricuse,
			targetpriceWater,
			targetpriceWaterspec,
			targetpriceHeating,
			mortgages,
			deptPart,
			priceInfo,
			// services
			localServices,
			commutes,
			housingCompany,
			renovationsComing,
			renovationsDone,
			freeFromDebtPrice,
			freeForMoving,
			energyInfo,
			energyClass,
			constYear,
			brandNew,
			// published
			published
		} = this.props

		const locationData = [
			{
				name: t('PREVIEW.FORM.LOCATION'),
				value: `${streetAddress} ${zipCode} ${postalCity}`
			},
			{
				name: t('PREVIEW.FORM.DISTRICT'),
				value: postalCity
			}
		]
		const apartmentInfoData = [
			{
				name: t('PROPERTY.BASICFORM.APARTMENT'),
				value: translateVariable['houseType'][`${houseType}`]
			},
			{
				name: t('PREVIEW.FORM.HOUSING_COMPANY'),
				value: housingCompany
			}
		]

		const roomData = [
			{
				name: t('PROPERTY.BASICFORM.ROOM_QTY'),
				value: roomQty
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENT_AREA'),
				value: `${areaApartment} m²`
			},
			{
				name: t('PROPERTY.BASICFORM.OTHER_AREA'),
				value: `${areaRest} m²`
			},
			{
				name: t('PROPERTY.BASICFORM.TOTAL_AREA'),
				value: `${areaTotal} m²`
			},
			{
				name: t('PREVIEW.FORM.ADDITIONAL_AREAINFO'),
				value: areaInfo
			}
		]
		const buildingData = [
			{
				name: t('PROPERTY.BASICFORM.FLOOR_QTY.LABEL'),
				value: floorQty
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_CONDITION'),
				value: translateVariable['apartmentCondition'][apartmentCondition]
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.CONDITION_EXTRAINFO'),
				value: conditionInfo
			},
			{
				name: t('PREVIEW.FORM.NEW_BUILDING'),
				value: translateVariable['brandNew'][`${brandNew}`]
			},
			{
				name: t('PROPERTY.BASICFORM.CONSTRUCTION_YEAR'),
				value: constYear
			},
			{
				// TODO changes number to correct class
				name: t('PROPERTY.BASICFORM.ENERGY_CLASS'),
				value: translateVariable['energyClass'][`${energyClass}`]
			},
			{
				name: t('PROPERTY.BASICFORM.ENERGY_INFO'),
				value: energyInfo
			}
		]

		const availabilityData = [
			{
				name: t('PREVIEW.FORM.AVAILABILITY'),
				value: freeForMoving
			},
			{
				name: t('PREVIEW.FORM.INQUIRIES'),
				value: query
			}
		]

		const priceData = [
			{
				name: t('PREVIEW.FORM.ASKED_PRICE'),
				value: toCurrency(askingPrice)
			},
			{
				name: t('PREVIEW.FORM.DEBT_FREE_PRICE'),
				value: toCurrency(freeFromDebtPrice || askingPrice)
			},
			{
				name: t('PREVIEW.FORM.PRICE_PER_SQM'),
				value: toCurrency((Number(askingPrice) / Number(areaTotal)).toFixed(2))
			},
			{
				name: t('PREVIEW.FORM.TARGET_PRICE_FINANCING'),
				value: toCurrency(Number(targetpriceFinancing))
			},
			{
				name: t('PREVIEW.FORM.TARGET_PRICE_CARE'),
				value: toCurrency(Number(targetpriceCare))
			},
			{
				name: t('PREVIEW.FORM.PROPERTY_TAX'),
				value: toCurrency(Number(propertyTax))
			},
			{
				name: t('PREVIEW.FORM.RENT_PER_YEAR'),
				value: toCurrency(Number(rentPerYear))
			},
			{
				name: t('PREVIEW.FORM.ELECTRIC_USE'),
				value: toCurrency(Number(targetpriceElectricuse))
			},
			{
				name: t('PREVIEW.FORM.WATER_FEE'),
				value: toCurrency(Number(targetpriceWater))
			},
			{
				name: t('PREVIEW.FORM.WATER_INFO'),
				value: targetpriceWaterspec
			},
			{
				name: t('PREVIEW.FORM.HEATING_FEE'),
				value: toCurrency(Number(targetpriceHeating))
			},
			{
				name: t('PREVIEW.FORM.MORTGAGES'),
				value: toCurrency(Number(mortgages))
			},
			{
				name: t('PREVIEW.FORM.DEPT_PART'),
				value: toCurrency(Number(deptPart))
			},
			{
				name: t('PREVIEW.FORM.OTHER_COSTS'),
				value: priceInfo
			}
		]

		const generalFeaturesData = [
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_INCLUDES'),
				value: apartmentProperties
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.APARTMENT_ALSO_INCLUDES'),
				value: apartmentBelongs
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.R_RESERVATION'),
				value: carPreservation
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.GARDEN_INFO'),
				value: gardenInfo
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.LANDSCAPE'),
				value: landscape
			},
			{
				// TODO change number to beach string value
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.HAS_BEACH'),
				value: translateVariable['beach'][`${beach}`]
			}
		]

		const renovationsData = [
			{
				name: t('PREVIEW.FORM.UPCOMING_RENOVATIONS'),
				value: renovationsComing
			},
			{
				name: t('PREVIEW.FORM.COMPLETED_RENOVATIONS'),
				value: renovationsDone
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.INSPECTION_INFO'),
				value: inspectionInfo
			}
		]

		const extraFeaturesData = [
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.HEATING_SYSTEM'),
				value: heatingSystem
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.WATER_PIPE'),
				value: waterPipe
			},
			{
				name: t('PROPERTY.BASICFORM.APARTMENTINFOFORM.SEWER'),
				value: sewer
			}
			// {
			// 	name: t('PREVIEW.FORM.ELECTRICITY_CONNECTION'),
			// 	value:
			// 		translateVariable['electricityConnection'][`${electricityConnection}`]
			// }
		]
		// const extraAdditionalInfoData = [
		// 	{
		// 		name: t('PREVIEW.FORM.ADDITIONAL_INFORMATION'),
		// 		value: 'Need to add'
		// 	}
		// ]

		const materialsData = [
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.MAIN_BUILDINGMATERIAL'),
				value: mainBuildingMaterial
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.SURFACEMATERIAL_DESCRIPTION'),
				value: surfaceMaterialDescription
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.KITCHEN'),
				value: kitchen
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.LIVINGROOM'),
				value: livingRoom
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.HALLWAY'),
				value: hallWay
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.BEDROOM'),
				value: bedRoom
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.TOILET'),
				value: toilet
			},
			{
				name: t('PROPERTY.BASICFORM.MATERIALFORM.ROOMS.BATHROOM'),
				value: bathRoom
			}
		]
		const plotData = [
			{
				name: t('PROPERTY.BASICFORM.PLOTFORM.PLOT_OWNERSHIP'),
				value: translateVariable['plotOwnerShip'][`${plotOwnerShip}`]
			},
			{
				name: t('PROPERTY.BASICFORM.PLOTFORM.ESTATE_ID'),
				value: estateId
			},
			{
				name: t('PROPERTY.BASICFORM.PLOTFORM.PLOT_AREA'),
				value: plotArea
			},
			{
				name: t('PROPERTY.BASICFORM.PLOTFORM.BUILDING_INFO'),
				value: buildingInfo
			},
			{
				name: t('PROPERTY.BASICFORM.PLOTFORM.PLOT_INFO'),
				value: plotInfo
			}
		]

		const servicesData = [
			{
				name: t('PROPERTY.BASICFORM.SERVICESFORM.LOCAL_SERVICES'),
				value: localServices
			},
			{
				name: t('PROPERTY.BASICFORM.SERVICESFORM.COMMUTES'),
				value: commutes
			}
		]

		const categories = [
			{
				name: t('PREVIEW.FORM.BASIC_INFORMATION'),
				id: 0,
				data: [
					locationData,
					apartmentInfoData,
					roomData,
					buildingData,
					availabilityData
				]
			},
			{
				name: t('PREVIEW.FORM.ASKING_PRICE_AND_COSTS'),
				id: 1,
				data: [priceData]
			},
			{
				name: t('PROPERTY.BASICFORM.TOPICS.APARTMENT_INFORMATION'),
				id: 2,
				data: [
					generalFeaturesData,
					renovationsData,
					extraFeaturesData
					// extraAdditionalInfoData
				]
			},
			{
				name: t('PROPERTY.BASICFORM.TOPICS.ROOMS_AND_MATERIAL'),
				id: 3,
				data: [materialsData]
			},
			{
				name: t('PROPERTY.BASICFORM.TOPICS.PLOT'),
				id: 4,
				data: [plotData]
			},
			{
				name: t('PROPERTY.BASICFORM.TOPICS.SERVICES_AND_COMMUNICATIONS'),
				id: 5,
				data: [servicesData]
			}
		]

		const mediaQty = this.normalizeMediaData(files).length
		const selectedPropertyId = getAuthenticationData('selectedPropertyId')
		if (!selectedPropertyId) {
			window.location.href = navigationPath.dashboard
		}
		if (loading) {
			return <Loading />
		}
		if (error) {
			return <span>error.message</span>
		}
		const path = this.props.match.path
		return (
			<div className="form-topic_content preview">
				<div className="preview-header">
					<h1 className="preview-header-content">
						{toCurrency(askingPrice)} / {areaTotal} m2
					</h1>
					<p className="preview-header-content">
						{streetAddress} {zipCode} {postalCity} / {apartmentInfo} {roomQty}h
					</p>
				</div>
				<div className="form-topic_content-center preview-content">
					<div className="preview-content_thumbnail">
						{this.renderThumbnail()}
					</div>
					{isModalShown && (
						<Modal show={true} handleCloseModal={this.handleCloseModal}>
							<Slideshow data={this.normalizeMediaData(files)} />
						</Modal>
					)}
					<TabMenu
						mediaQty={this.countImages(files)}
						data={this.normalizeMediaData(files)}
						handleClickItem={this.handleClickItem}
						address={streetAddress}
						handleSelectMenu={this.handleSelectMenu}
					/>

					<img src={aninkainenLogo} className="logo" alt="Aninkainen" />
					<div className="description-container">
						<div className="description-container_left">
							{this.renderText(informationLabel)}
							<p className="info">{apartmentInfo}</p>
							{published && (
								<div className="socialSharing-container">
									<h2 className="socialSharing-container_title">
										{t('PREVIEW.SOCIAL_SHARE')}
									</h2>
									<div className="socialSharing-container_buttons">
										<FacebookShareButton
											url={`${ngrokUrl}/preview/${selectedPropertyId}`}
										>
											<a className="twitter">
												<img src={facebookIcon} alt="Facebook" />
											</a>
										</FacebookShareButton>

										<TwitterShareButton
											url={`${ngrokUrl}/preview/${selectedPropertyId}`}
											title="abc"
										>
											<a className="twitter">
												<img src={twitterIcon} alt="Twitter" />
											</a>
										</TwitterShareButton>
										<WhatsappShareButton
											url={`${ngrokUrl}/preview/${selectedPropertyId}`}
											title="abc"
										>
											<a className="twitter">
												<img src={whatsappIcon} alt="Twitter" />
											</a>
										</WhatsappShareButton>
									</div>
								</div>
							)}
							<div>{propertyLoading ? <Loading /> : <ShowingList />}</div>
						</div>

						{path.includes('asunto') && userProperty != propertyId && (
							<div className="description-container_right">
								<Card
									name={name || t('PREVIEW.CONTACT_SELLER')}
									phoneNumber={phone}
									email={email}
									handleClickButton={() =>
										this.props.history.push('/buyer/bid/rule')
									}
								/>
								{/* <div className="loanCalculator">
								<p className="loanCalculator_title">
									{t('PREVIEW.OPEN_LOANCALCULATOR')}
								</p>
								<Button title={t('PREVIEW.REQUEST_OFFER')} />
							</div> */}
							</div>
						)}
					</div>
					{!userId && <PreviewShowingRequest />}

					{this.renderCategories(categories)}
				</div>
				{!path.includes('asunto') && !path.includes('admin') && (
					<div className="form-button">
						<Button
							title={t('GENERAL.FORMS.EDIT')}
							onClick={() =>
								this.props.history.push(`/property/${propertyId}/basic`)
							}
							className="edit-button"
						/>
					</div>
				)}
				{path.includes('admin') && (
					<div className="form-button">
						<Button
							title={t('PREVIEW.BUTTON_ADMIN')}
							onClick={this.handleBackAdmin}
						/>
					</div>
				)}
			</div>
		)
	}
}
const mapStateToProps = state => ({
	userProperty: getPropertySelectors.getUserProperty(state.propertyReducer),
	mandatoryFormData: formSelectors.getMandatoryData(state.formReducer),
	category: getFormCategory(state.formReducer),
	propertyType: formSelectors.getPropertyType(state.formReducer),
	houseType: formSelectors.getHouseType(state.formReducer),
	housingCompany: formSelectors.getHousingCompany(state.formReducer),
	roomQty: formSelectors.getRoomQty(state.formReducer),
	streetAddress: formSelectors.getStreetAddress(state.formReducer),
	zipCode: formSelectors.getZipCode(state.formReducer),
	postalCity: formSelectors.getPostalCity(state.formReducer),
	city: formSelectors.getCity(state.formReducer),
	state: formSelectors.getState(state.formReducer),
	region: formSelectors.getRegion(state.formReducer),
	country: formSelectors.getCountry(state.formReducer),
	showMap: formSelectors.getShowMap(state.formReducer),
	apartmentInfo: formSelectors.getApartmentInfo(state.formReducer),
	areaApartment: formSelectors.getAreaApartment(state.formReducer),
	areaRest: formSelectors.getAreaRest(state.formReducer),
	areaTotal: formSelectors.getAreaTotal(state.formReducer),
	areaInfo: formSelectors.getAreaInfo(state.formReducer),
	floorQty: formSelectors.getFloorQty(state.formReducer),
	informationLabel: formSelectors.getInformationLabel(state.formReducer),
	name: formSelectors.getName(state.formReducer),
	phone: formSelectors.getPhone(state.formReducer),
	email: formSelectors.getEmail(state.formReducer),
	query: formSelectors.getQuery(state.formReducer),

	// apartmentInfo
	renovationsComing: formSelectors.getRenovationsComing(state.formReducer),
	renovationsDone: formSelectors.getRenovationsDone(state.formReducer),
	freeForMoving: formSelectors.getFreeForMoving(state.formReducer),
	energyInfo: formSelectors.getEnergyInfo(state.formReducer),
	constYear: formSelectors.getConstYear(state.formReducer),
	brandNew: formSelectors.getBrandNew(state.formReducer),
	energyClass: formSelectors.getEnergyClass(state.formReducer),
	conditionInfo: formSelectors.getConditionInfo(state.formReducer),
	apartmentCondition: formSelectors.getCondition(state.formReducer),
	carPreservation: formSelectors.getCarPreservation(state.formReducer),
	apartmentBelongs: formSelectors.getApartmentBelongs(state.formReducer),
	apartmentProperties: formSelectors.getApartmentProperties(state.formReducer),
	gardenInfo: formSelectors.getGardenInfo(state.formReducer),
	landscape: formSelectors.getLandscape(state.formReducer),
	beach: formSelectors.getBeach(state.formReducer),
	isShownOnSite: formSelectors.getIsShownOnSite(state.formReducer),
	inspectionInfo: formSelectors.getInspectionInfo(state.formReducer),
	heatingSystem: formSelectors.getHeatingSystem(state.formReducer),
	waterPipe: formSelectors.getWaterPipe(state.formReducer),
	sewer: formSelectors.getSewer(state.formReducer),
	electricityConnection: formSelectors.getElectricityConnection(
		state.formReducer
	),
	inspections: formSelectors.getInspections(state.formReducer),
	conditionInspection: formSelectors.getConditionInspection(state.formReducer),
	asbestosInspection: formSelectors.getAsbestosInspection(state.formReducer),
	humidityMeasure: formSelectors.getHumidityMeasure(state.formReducer),
	// materials
	mainBuildingMaterial: formSelectors.getMainBuildingMaterial(
		state.formReducer
	),
	mainBuildingMaterials: formSelectors.getMainBuildingMaterials(
		state.formReducer
	),
	surfaceMaterialDescription: formSelectors.getSurfaceMaterialDescription(
		state.formReducer
	),
	kitchen: formSelectors.getKitchen(state.formReducer),
	hallWay: formSelectors.getHallway(state.formReducer),
	bedRoom: formSelectors.getBedroom(state.formReducer),
	livingRoom: formSelectors.getLivingRoom(state.formReducer),
	toilet: formSelectors.getToilet(state.formReducer),
	bathRoom: formSelectors.getBathroom(state.formReducer),
	// plot
	plotOwnerShip: formSelectors.getPlotOwn(state.formReducer),
	plotOwnerShips: formSelectors.getPlotOwnerShips(state.formReducer),
	estateId: formSelectors.getEstateId(state.formReducer),
	plotArea: formSelectors.getPlotArea(state.formReducer),
	buildingInfo: formSelectors.getBuildingInfo(state.formReducer),
	plotInfo: formSelectors.getPlotInfo(state.formReducer),
	//price
	askingPrice: priceSelectors.getAskingPrice(state.priceFormReducer),
	freeFromDebtPrice: priceSelectors.getFreeFromDebtPrice(
		state.priceFormReducer
	),
	targetpriceFinancing: formSelectors.getTargetPriceFinancing(
		state.formReducer
	),
	targetpriceCare: formSelectors.getTargetPriceCare(state.formReducer),
	propertyTax: formSelectors.getPropertyTax(state.formReducer),
	rentPerYear: formSelectors.getRentPerYear(state.formReducer),
	targetpriceElectricuse: formSelectors.getTargetPriceElectricuse(
		state.formReducer
	),
	targetpriceWater: formSelectors.getTargetPriceWater(state.formReducer),
	targetpriceWaterspec: formSelectors.getTargetPriceWaterspec(
		state.formReducer
	),
	targetpriceHeating: formSelectors.getTargetPriceHeating(state.formReducer),
	priceInfo: formSelectors.getPriceInfo(state.formReducer),

	files: mediaSelectors.getFiles(state.mediaFormReducer),
	defaultImage: mediaSelectors.getDefaultImage(state.mediaFormReducer),

	// preview
	loading: previewSelector.getLoading(state.previewReducer),
	error: previewSelector.getError(state.previewReducer),
	selectedMedia: previewSelector.getSelectedMedia(state.previewReducer),

	// services
	localServices: formSelectors.getLocalServices(state.formReducer),
	commutes: formSelectors.getCommutes(state.formReducer),
	propertyLoading: getPropertySelectors.getLoading(state.propertyReducer),
	requiredFieldHasError: formSelectors.getRequiredField(state.formReducer),
	validateError: formSelectors.getValidateError(state.formReducer),
	// publish
	published: publishSelectors.getPublished(state.publishReducer)
})
export default withRouter(connect(mapStateToProps)(withTranslation()(Preview)))
