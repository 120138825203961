import React from 'react'

const FormTopic = ({ title, isActive, onClickAction }) => {
	return (
		<div
			className={'form-topic' + (isActive ? ' isActive_form-topic' : '')}
			onClick={onClickAction}
		>
			<span>{title}</span>
			<i className="material-icons">
				{isActive ? 'expand_less' : 'expand_more'}
			</i>
		</div>
	)
}

export default FormTopic
