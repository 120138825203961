import React from 'react'
import { withTranslation } from 'react-i18next'
// image
import defaultImage from 'assets/images/Aninkainen_9-min.jpg'
// Component
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
// constants
import { fileEndpoint } from '@constants'

const Rule = ({
	address,
	t,
	image,
	handleAgreeRule,
	handleNextView,
	isRuleAgreed
}) => {
	const imageUrl = image ? `${fileEndpoint}/${image}` : defaultImage
	const handleChangeField = e => {
		if (handleAgreeRule && typeof handleAgreeRule === 'function') {
			handleAgreeRule(e)
		}
	}
	const handleNext = () => {
		if (handleNextView && typeof handleNextView === 'function') {
			handleNextView(e)
		}
	}
	return (
		<div className="bid-rule">
			<div className="bid-rule_thumbnail">
				<img src={imageUrl} alt="img" />
			</div>
			<div className="bid-rule_content">
				<div className="bid-rule_header">
					<h1>{t('BUYER.BID_FORM.RULE.TITLE')}</h1>
					<p>{(address || 'address').toUpperCase()}</p>
				</div>
				<div className="bid-rule_description">
					{t('BUYER.BID_FORM.RULE.DESC')}
				</div>
				<div className="bid-rule_rule">
					<h3>{t('BUYER.BID_FORM.RULE.RULE_INFO.TITLE')}</h3>
					<p>{t('BUYER.BID_FORM.RULE.RULE_INFO.DESC')}</p>
				</div>
				<div className="bid-rule_footer">
					<Checkbox
						description={t('BUYER.BID_FORM.RULE.CHECKBOX_DESC')}
						name="isRuleAgreed"
						value={isRuleAgreed}
						handleClickCheckbox={handleChangeField}
					/>
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(Rule)
