/**
 * Generic Input component
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'

class Input extends React.Component {
	state = {
		focusClass: ''
	}

	renderLabel = () => (
		<label className="input-field__label">{this.props.label}</label>
	)

	handleClickInputIcon = () => {
		const { handleClickInputIcon } = this.props

		if (handleClickInputIcon && typeof handleClickInputIcon === 'function') {
			handleClickInputIcon()
		}
	}

	handleFocusInput = e => {
		this.setState({ focusClass: 'focus' })
		if (this.props.required) {
			const { handleFocusInput } = this.props
			if (handleFocusInput && typeof handleFocusInput === 'function') {
				handleFocusInput(e)
			}
		}
	}

	handleBlurInput = e => {
		this.setState({ focusClass: '' })
		if (this.props.required) {
			const { handleBlurInput } = this.props
			if (handleBlurInput && typeof handleBlurInput === 'function') {
				handleBlurInput(e)
			}
		}
	}

	renderIcon = () => {
		const { icon, iconName } = this.props

		if (iconName) {
			return (
				<i className="material-icons" onClick={this.handleClickInputIcon}>
					{iconName}
				</i>
			)
		}

		if (icon) {
			return icon
		}

		return null
	}
	renderText = () => {
		const { inputText } = this.props
		if (inputText) {
			return <span onClick={this.handleClickInputIcon}>{inputText}</span>
		}
	}

	render() {
		const {
			type,
			label,
			onChange,
			disabled,
			value,
			placeholder,
			name,
			className,
			icon,
			iconName,
			reference,
			id,
			inputProps,
			hasError,
			error
		} = this.props

		let inputClass = ['input-field']
		if (className) {
			inputClass.push(className)
		}

		if (this.state.focusClass) {
			inputClass.push(this.state.focusClass)
		}

		return (
			<div className={inputClass.join(' ')}>
				{label && this.renderLabel()}
				<div className="input-field__input-content">
					<input
						type={type ? type : 'text'}
						name={name}
						value={value ? value : ''}
						placeholder={placeholder}
						onChange={onChange}
						disabled={disabled}
						ref={reference}
						id={id}
						{...inputProps}
						onFocus={this.handleFocusInput}
						onBlur={this.handleBlurInput}
					/>
					<div className="input-field__icon-container">{this.renderIcon()}</div>
					<div className="input-field__text-container">{this.renderText()}</div>
				</div>
				{hasError ? <span className="error">{error}</span> : null}
			</div>
		)
	}
}

export default Input
