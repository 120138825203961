import React from 'react'
import { withTranslation } from 'react-i18next'

const Introduction = ({ handleClickIntro, t }) => {
	const handleClick = type => {
		if (handleClickIntro && typeof handleClickIntro === 'function') {
			return handleClickIntro(type)
		}
	}
	return (
		<div className="dashboard-content_hover">
			<h2>{t('DASHBOARD.BOXES.INTRODUCTION.TITLE')}</h2>
			<div className="content">
				<div className="item" onClick={() => handleClick('showing')}>
					{t('DASHBOARD.BOXES.INTRODUCTION.SCREEN')}
				</div>
				<div className="item" onClick={() => handleClick('material')}>
					{t('DASHBOARD.BOXES.INTRODUCTION.MONITORING_MATERIAL')}
				</div>
				<div className="item" onClick={() => handleClick('results')}>
					{t('DASHBOARD.BOXES.INTRODUCTION.RESULTS')}
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(Introduction)
