/**
 * Form reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	PREVIEW_CHANGE_FIELD,
	PREVIEW_SAVE_SELECTED_MEDIA,
	FORM_GET_SELECTED_PROPERTY,
	FORM_GET_SELECTED_PROPERTY_SUCCESS,
	FORM_GET_SELECTED_PROPERTY_FAIL
} from '@actions'
import { normalizeError } from './utils'
const initialState = {
	selectedMedia: {},
	nextShowingRequest: 'true',
	email: '',
	loading: false,
	error: null
}
export const previewReducer = (state = initialState, action) => {
	switch (action.type) {
		case PREVIEW_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case PREVIEW_SAVE_SELECTED_MEDIA:
			return {
				...state,
				selectedMedia: action.media
			}
		case FORM_GET_SELECTED_PROPERTY:
			return {
				...state,
				loading: true
			}
		case FORM_GET_SELECTED_PROPERTY_SUCCESS:
			return {
				...state,
				loading: false
			}
		case FORM_GET_SELECTED_PROPERTY_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		default:
			return state
	}
}
// selector
export const previewSelector = {
	getSelectedMedia: (state = initialState) => state.selectedMedia,
	getNextShowingRequest: (state = initialState) => state.nextShowingRequest,
	getEmail: (state = initialState) => state.email,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
