import React from 'react'
const Loading = ({}) => {
	return (
		<div className="loading">
			<div className="lds-hourglass"></div>
		</div>
	)
}

export default Loading
