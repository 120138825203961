/**
 * Showing results form action
 *
 */
import {
	SHOWING_RESULTS_SEND,
	SHOWING_RESULTS_CHANGE_FIELD,
	SHOWING_RESULTS_VALIDATE_FIELD,
	SHOWING_RESULTS_RESET_ERROR,
	SHOWING_RESULTS_GET_SHOWING_RESULTS
} from '@actions'
export const showingResultsSendResults = (showingId, data) => {
	return {
		type: SHOWING_RESULTS_SEND,
		payload: {
			request: {
				method: 'POST',
				url: `/results/${showingId}`,
				data
			}
		}
	}
}
export const showingResultsGetShowingResults = () => {
	return {
		type: SHOWING_RESULTS_GET_SHOWING_RESULTS,
		payload: {
			request: {
				method: 'GET',
				url: `/results`
			}
		}
	}
}
export const showingResultsChangeField = (name, value) => {
	return {
		type: SHOWING_RESULTS_CHANGE_FIELD,
		name,
		value
	}
}
export const showingResultsResetError = () => {
	return {
		type: SHOWING_RESULTS_RESET_ERROR
	}
}
export const showingResultsValidate = (nameField, hasError, error) => {
	return {
		type: SHOWING_RESULTS_VALIDATE_FIELD,
		nameField,
		hasError,
		error
	}
}
