/**
 * Showing actions
 *
 */
import {
	SHOWING_CHANGE_FIELD,
	SHOWING_SEND_SHOWING,
	SHOWING_GET_SHOWINGS,
	SHOWING_RESET_STATE
} from '@actions'
import { getAuthenticationData } from '../utils'

export const showingChangeField = (name, value) => {
	return {
		type: SHOWING_CHANGE_FIELD,
		name,
		value
	}
}
export const showingSendShowing = data => {
	const propertyId = getAuthenticationData('selectedPropertyId')

	return {
		type: SHOWING_SEND_SHOWING,
		payload: {
			request: {
				method: 'POST',
				url: `/showings/${propertyId}`,
				data
			}
		}
	}
}
export const showingGetShowings = () => {
	return {
		type: SHOWING_GET_SHOWINGS,
		payload: {
			request: {
				method: 'GET',
				url: `/showings`
			}
		}
	}
}
export const showingResetState = () => {
	return {
		type: SHOWING_RESET_STATE
	}
}
