/**
 * Trading actions
 *
 */
import { TRADING_CHANGE_FIELD } from '@actions'
import { getAuthenticationData } from '../utils'

export const tradingChangeField = (name, value) => {
	return {
		type: TRADING_CHANGE_FIELD,
		name,
		value
	}
}
