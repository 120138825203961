import React from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import moment from 'moment'

// component
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import Select from 'shared/components/Select'
// utils
import { getAuthenticationData } from './../../../utils'
// action
import {
	showingResultsGetShowingResults,
	showingResultsChangeField,
	showingChangeField,
	showingGetShowings
} from '@actions'
// constants
import { navigationPath, routerPath } from '@constants'
// reducer
import { showingSelectors, showingResultsSelectors } from 'reducers'

class ViewShowingResults extends React.Component {
	constructor(props) {
		super(props)
	}
	componentDidMount() {
		this.props.dispatch(showingGetShowings())
	}
	handleSelectedShowing = e => {
		const { name, value } = e.target
		this.props.dispatch(showingChangeField(name, value))
	}
	renderResults = () => {
		const { showings, t, selectedShowing, showingLoading } = this.props
		let options = []
		if (!showings || showings.length < 1) {
			return
		}
		showings.map((item, key) => {
			let data = {
				text: moment(item.time).format('hh:mm DD/MM/YYYY'),
				value: item
			}
			options.push(data)
		})
		return showingLoading ? (
			<Loading />
		) : (
			<Select
				label={'Select showing'}
				options={options}
				name="selectedShowing"
				value={selectedShowing}
				handleSelectOption={this.handleSelectedShowing}
			/>
		)
	}
	renderShowingResult = () => {
		const { selectedShowing, t } = this.props
		const result = selectedShowing.showingResult
		if (!selectedShowing || (selectedShowing && !result)) {
			return null
		}
		return (
			<div className="results-box">
				<div className="results-box-column">
					<div className="results-box-column_score">
						{result.attendees || 0}
					</div>
					<div className="results-box-column_title">
						{t('SHOWING_RESULTS.VIEW.VISITORS')}
					</div>
					<div className="results-box-column_description">
						Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
						dictum sollicitudin libero at pulvinar.
					</div>
				</div>
				<div className="results-box-column horizontal-borders">
					<div className="results-box-column_score">{result.bids || 0}</div>
					<div className="results-box-column_title">
						{t('SHOWING_RESULTS.VIEW.OFFERS')}
					</div>
					<div className="results-box-column_description">
						Loren ipsum dolor sit amet
						<Button
							className="viewOffers"
							title={t('SHOWING_RESULTS.VIEW.VIEW_OFFERS')}
							onClick={() => this.props.history.push(routerPath.bidding)}
						/>
					</div>
				</div>
				<div className="results-box-column">
					<p className="results-box-column_score">{result.feeling || 0}</p>
					<p className="results-box-column_title">
						{t('SHOWING_RESULTS.VIEW.MOOD')}
					</p>
					<p className="results-box-column_description">
						Loren ipsum dolor sit amet
					</p>
				</div>
			</div>
		)
	}
	render() {
		const { t, history, selectedShowing } = this.props
		let isSubmitResultShown = false

		if (
			(selectedShowing && selectedShowing.showingResult) ||
			!selectedShowing
		) {
			isSubmitResultShown = false
		} else {
			isSubmitResultShown = true
		}

		return (
			<div className="showing-results-container">
				<h1>{t('SHOWING_RESULTS.VIEW.TITLE')}</h1>
				{isSubmitResultShown && (
					<Button
						title={t('SHOWING_RESULTS.VIEW.SUBMIT_RESULTS')}
						onClick={() => history.push('/showingResults/add')}
					/>
				)}

				<div className="results-section">
					<div className="showing-list">{this.renderResults()}</div>
					{this.renderShowingResult()}
				</div>
				<Button
					className="back-button"
					title={t('GENERAL.FORMS.BACK')}
					onClick={() => history.push(navigationPath.dashboard)}
				/>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	showingLoading: showingSelectors.getLoading(state.showingReducer),
	selectedShowing: showingSelectors.getSelectedShowing(state.showingReducer),
	showings: showingSelectors.getShowing(state.showingReducer),
	showingResults: showingResultsSelectors.getShowingResults(
		state.showingResultsReducer
	)
})

export default connect(mapStateToProps)(
	withRouter(withTranslation()(ViewShowingResults))
)
