/**
 * Form action
 *
 */
import {
	MEDIA_FORM_CHANGE_FIELD,
	MEDIA_FORM_ADD_ATTACHMENT,
	MEDIA_FORM_DELETE_ATTACHMENT,
	PROPERTY_UPDATE
} from '@actions'
export const mediaFormChangeField = (name, value) => {
	return {
		type: MEDIA_FORM_CHANGE_FIELD,
		name,
		value
	}
}

// 	url: `/propertyfiles/${propertyId}/fileId`, ->DeleteFile
export const mediaFormAddAttachment = (data, onUploadProgress, type) => {
	let propertyId = localStorage.getItem('selectedPropertyId')
	return {
		type: MEDIA_FORM_ADD_ATTACHMENT,
		payload: {
			request: {
				method: 'POST',
				url: `/propertyfiles/${propertyId}/${type}`,
				data: data,
				headers: {
					'content-type': 'multipart/form-data'
				},
				onUploadProgress
			}
		}
	}
}
export const mediaFormDeleteAttachment = fileId => {
	let propertyId = localStorage.getItem('selectedPropertyId')

	return {
		type: MEDIA_FORM_DELETE_ATTACHMENT,
		payload: {
			request: {
				method: 'DELETE',
				url: `/propertyfiles/${propertyId}/${fileId}`
			}
		},
		fileId
	}
}
