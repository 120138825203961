/**
 * Login component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
import { validateFieldByName } from '../utils'
import { saveAuthenticationData, getAuthenticationData } from './../../utils'
//Component
import Button from 'shared/components/Button'
import Modal from 'shared/components/Modal'
import ResetPassword from './ResetPassword'
import Input from 'shared/components/Input'
import Checkbox from 'shared/components/Checkbox'
import Loading from 'shared/components/Loading'

//Reducers
import {
	getLoginLoading,
	getLoginError,
	getUser,
	getLoginEmail,
	getLoginPassword,
	getLoginRememberPassword,
	getLoginValidateError,
	getLoginRequiredFieldHasError
} from 'reducers'
// actions
import {
	loginChangeField,
	login,
	loginValidateField,
	loginResetError,
	loginVerifyEmail,
	loginResetForm
} from '@actions'
// constants
import { navigationPath } from '@constants'
class Login extends React.Component {
	constructor(props) {
		super(props)
		const { t } = this.props
		this.state = {
			isPasswordShown: false,
			passwordToggleText: this.props.t('LOGIN.TOGGLE_SHOW_PASSWORD'),
			isConfirmModalShown: false,
			verifyEmailContent: 'button',
			isResetPasswordShown: false
		}
	}
	componentDidMount() {
		const { dispatch } = this.props
		const email = getAuthenticationData('email')
		const password = getAuthenticationData('password')
		if (email) {
			dispatch(loginChangeField('email', email))
		}
		if (password) {
			dispatch(loginChangeField('password', password))
		}
	}
	componentWillUnmount() {
		const { email, password, rememberPassword } = this.props
		if (!rememberPassword) {
			localStorage.removeItem('email')
			localStorage.removeItem('password')
		} else {
			saveAuthenticationData('email', email)
			saveAuthenticationData('password', password)
		}
		this.props.dispatch(loginResetForm())
	}

	handleChangeField = e => {
		const { name, value, checked, type } = e.target
		let valueField = value
		const { dispatch } = this.props
		if (type == 'checkbox') {
			valueField = checked
		}
		dispatch(loginChangeField(name, valueField))
	}
	handleLogin = () => {
		const { dispatch, email, password, history } = this.props
		dispatch(login({ email, password }))
			.then(res => {
				if (res.payload.data) {
					const { isBuyer } = res.payload.data.user
					if (isBuyer) {
						return history.push('/')
					}
					history.push('/bankAuth/request')
				}
			})
			.catch(err => {
				if (err.error.data == `User is not verified`) {
					this.setState({
						isConfirmModalShown: true,
						verifyEmailContent: 'confirmText'
					})
				}
			})
	}
	validateRequest = (name, value, text) => {
		this.props.dispatch(loginValidateField(name, value, text))
	}
	handleFocusInput = e => {
		const { dispatch } = this.props
		const { name, value } = e.target
		dispatch(loginValidateField(name, false, ''))
		dispatch(loginResetError())
	}
	handleBlurInput = e => {
		const { name, value } = e.target
		const { dispatch, t } = this.props
		if (!value) {
			dispatch(loginValidateField(name, true, t('ERRORS.REQUIRED')))
		} else {
			validateFieldByName(name, value, this.validateRequest)
		}
	}
	handleClickInputIcon = () => {
		const { isPasswordShown, passwordToggleText } = this.state
		const { t } = this.props
		let toggleText = isPasswordShown
			? t('LOGIN.TOGGLE_SHOW_PASSWORD')
			: t('LOGIN.TOGGLE_HIDE_PASSWORD')
		this.setState({
			isPasswordShown: !this.state.isPasswordShown,
			passwordToggleText: toggleText
		})
	}
	handleBankLogin = () => {}
	handleVerifyEmail = () => {
		const { email, dispatch } = this.props
		dispatch(loginVerifyEmail(email))
	}
	handleCloseModal = () => {
		this.props.dispatch(loginResetError())
		this.setState({
			isConfirmModalShown: false
		})
	}
	handleShowResetModal = value => {
		this.setState({
			isResetPasswordShown: value
		})
	}
	render() {
		const {
			t,
			validateError,
			requiredFieldHasError,
			email,
			password,
			rememberPassword,
			loading,
			error
		} = this.props
		const {
			verifyEmailContent,
			isPasswordShown,
			passwordToggleText,
			isConfirmModalShown,
			isResetPasswordShown
		} = this.state
		const isDisabledButton = !email || !password
		return (
			<div className="login-container">
				<div className="login-header">
					<h1>{t('LOGIN.TITLE')}</h1>
					<h3>{t('LOGIN.DESC')}</h3>
				</div>
				<form onSubmit={this.handleLogin}>
					<div className="loginForm">
						<div className="loginForm-fields">
							<Input
								label={`${t('LOGIN.EMAIL')}`}
								name="email"
								value={email}
								onChange={this.handleChangeField}
								required={true}
								hasError={requiredFieldHasError.email}
								error={validateError.email}
								handleBlurInput={this.handleBlurInput}
								handleFocusInput={this.handleFocusInput}
							/>
							<Input
								type="password"
								label={`${t('LOGIN.PASSWORD')}`}
								name="password"
								value={password}
								onChange={this.handleChangeField}
								required={true}
								hasError={requiredFieldHasError.password}
								error={validateError.password}
								handleBlurInput={this.handleBlurInput}
								handleFocusInput={this.handleFocusInput}
								handleClickInputIcon={this.handleClickInputIcon}
								type={isPasswordShown ? 'text' : 'password'}
								inputText={passwordToggleText}
							/>
							<Checkbox
								description={`${t('LOGIN.REMEMBER_ME')}`}
								name="rememberPassword"
								value={rememberPassword}
								handleClickCheckbox={this.handleChangeField}
							/>
							<div
								className="forgetPassword"
								onClick={() => this.handleShowResetModal(true)}
							>
								Forget your password
							</div>
						</div>
						<div className="formButton">
							{error ? <span className="error">{error.message}</span> : null}
							{loading ? (
								<Loading />
							) : (
								<Button
									title="Kirjaudu sisään"
									disabled={isDisabledButton}
									onClick={this.handleLogin}
								/>
							)}
						</div>
					</div>
				</form>
				{isConfirmModalShown && (
					<Modal
						title={t('LOGIN.VERIFY_EMAIL.TITLE')}
						handleCloseModal={this.handleCloseModal}
					>
						{verifyEmailContent == 'button ' ? (
							<Button
								title={t('LOGIN.VERIFY_EMAIL.BUTTON')}
								onClick={this.handleVerifyEmail}
							/>
						) : (
							<div className="email-verification_content">
								{t('LOGIN.EMAIL_CONFIRM_NOTIFICATION')}
							</div>
						)}
					</Modal>
				)}
				{isResetPasswordShown && (
					<Modal
						title="reset your password"
						className="resetPassword-modal"
						handleCloseModal={() => this.handleShowResetModal(false)}
					>
						<ResetPassword
							handleCloseResetModal={() => this.handleShowResetModal(false)}
						/>
					</Modal>
				)}
				<Link className={'to-homepage'} to="/">
					{t('LOGIN.BACK_TO_HOMEPAGE')}
				</Link>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: getUser(state.loginReducer),
	email: getLoginEmail(state.loginReducer),
	password: getLoginPassword(state.loginReducer),
	rememberPassword: getLoginRememberPassword(state.loginReducer),
	validateError: getLoginValidateError(state.loginReducer),
	requiredFieldHasError: getLoginRequiredFieldHasError(state.loginReducer),
	loading: getLoginLoading(state.loginReducer),
	error: getLoginError(state.loginReducer)
})

export default withRouter(withTranslation()(connect(mapStateToProps)(Login)))
