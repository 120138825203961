/**
 * Property action
 *
 */
import {
	PROPERTY_ADD_PROPERTY,
	PROPERTY_GET_PROPERTIES,
	PROPERTY_GET_PROPERTY,
	PROPERTY_UPDATE,
	PROPERTY_RESET_PROPERTY,
	PROPERTY_GET_PUBLISHED_PROPERTIES,
	PROPERTY_CHANGE_FIELD,
	PROPERTY_DELETE_PROPERTY
} from '@actions'

export const propertyAddProperty = userId => {
	return {
		type: PROPERTY_ADD_PROPERTY,
		payload: {
			request: {
				method: 'POST',
				url: `/property/${userId}`
			}
		}
	}
}
export const propertyGetProperty = id => {
	return {
		type: PROPERTY_GET_PROPERTY,
		payload: {
			request: {
				method: 'GET',
				url: `/property/${id}`
			}
		}
	}
}

export const propertyGetProperties = userId => {
	return {
		type: PROPERTY_GET_PROPERTIES,
		payload: {
			request: {
				method: 'GET',
				url: `/properties/${userId}`
			}
		}
	}
}

export const propertyGetPublishedProperties = (limit = 6) => {
	return {
		type: PROPERTY_GET_PUBLISHED_PROPERTIES,
		payload: {
			request: {
				method: 'GET',
				url: `/properties?limit=${limit}`
			}
		}
	}
}

export const propertyUpdate = (propertyId, userId, data) => {
	return {
		type: PROPERTY_UPDATE,
		payload: {
			request: {
				method: 'PATCH',
				url: `/property/${propertyId}/${userId}`,
				data: data
			}
		}
	}
}
export const propertyResetProperty = () => {
	return {
		type: PROPERTY_RESET_PROPERTY
	}
}
export const propertyChangeField = (name, value) => {
	return {
		type: PROPERTY_CHANGE_FIELD,
		name,
		value
	}
}
export const propertyDeleteProperty = (userId, propertyId) => {
	return {
		type: PROPERTY_DELETE_PROPERTY,
		payload: {
			request: {
				method: 'DELETE',
				url: `/property/${propertyId}/${userId}`
			}
		}
	}
}
