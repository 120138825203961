import React from 'react'
import { connect } from 'react-redux'
// components
import Loading from 'shared/components/Loading'
import Input from 'shared/components/Input'
import Inputs from 'shared/components/Inputs'
import Button from 'shared/components/Button'
// constants
import { currencySign, monthlyPriceSign } from '@constants'
import PriceSuggestion from 'pages/Property/Components/PriceSuggestion'
//actions
import { priceFormChangeField, priceFormValidateField } from '@actions'
//reducers
import { priceSelectors, formSelectors } from 'reducers'
class PriceForm extends React.Component {
	handleChangeField = e => {
		const { name, value } = e.target
		this.props.dispatch(priceFormChangeField(name, value))
	}
	handleFocusInput = e => {
		const { name, value } = e.target
		this.props.dispatch(priceFormValidateField(name, true, ''))
	}
	handleBlurInput = e => {
		const { name, value } = e.target
		if (value == '') {
			this.props.dispatch(
				priceFormValidateField(name, true, this.props.t('ERRORS.REQUIRED'))
			)
		} else {
			this.props.dispatch(priceFormValidateField(name, false, ''))
		}
	}
	renderSwiperItem = data => {
		return data.map((item, key) => (
			<div className="swiper-item" key={key}>
				{item}
			</div>
		))
	}
	render() {
		const {
			handleSubmitForm,
			handleBackForm,
			askingPrice,
			freeFromDebtPrice,
			t,
			areaTotal,
			requiredField,
			validateError,
			isButtonDisabled,
			loading,
			error,
			formLoading
		} = this.props

		return (
			<>
				<div className="form-topic_content mandatoryForm">
					<div className="form-topic_content-center">
						<div className="wrapper-with-tips field-content">
							<div className="field">
								<Input
									label={t('PROPERTY.PRICE.ASKING_PRICE')}
									name="askingPrice"
									value={askingPrice}
									onChange={this.handleChangeField}
									required={true}
									hasError={requiredField.askingPriceHasError}
									error={validateError.askingPriceError}
									inputText={currencySign}
									handleFocusInput={this.handleFocusInput}
									handleBlurInput={this.handleBlurInput}
								/>
								<a href="#">{t('PROPERTY.PRICE.HELP_WITH_ASKING_PRICE')}</a>
								<Input
									label={t('PROPERTY.PRICE.FREE_FROM_DEBT_PRICE')}
									name="freeFromDebtPrice"
									value={freeFromDebtPrice}
									onChange={this.handleChangeField}
									className="tax"
									inputText={currencySign}
									required={true}
									hasError={requiredField.freeFromDebtPriceHasError}
									error={validateError.freeFromDebtPriceError}
									handleFocusInput={this.handleFocusInput}
									handleBlurInput={this.handleBlurInput}
								/>
								<Input
									label={t('PROPERTY.PRICE.PRICE_PER_SQUARE')}
									name="pricePerSquare"
									inputText={currencySign}
									value={(askingPrice / areaTotal).toFixed(2).toString()}
									onChange={this.handleChangeField}
								/>
							</div>
							<div className="tips">
								<div className="tips-content">
									<h3>{t('GENERAL.FORMS.JETHROS_TIP')}</h3>
									<span>Hintapyynnön vaikutus myyntiaikaan</span>
								</div>
							</div>
						</div>

						<div className="formButton">
							{formLoading ? (
								<Loading />
							) : (
								<Button
									title={t('GENERAL.FORMS.SAVE')}
									disabled={isButtonDisabled}
									onClick={handleSubmitForm}
								/>
							)}
							<Button
								title={t('GENERAL.FORMS.BACK')}
								onClick={handleBackForm}
								className="back-button"
							/>
						</div>
					</div>
				</div>
				<PriceSuggestion t={t} />
			</>
		)
	}
}
const mapStateToProps = state => ({
	askingPrice: priceSelectors.getAskingPrice(state.priceFormReducer),
	freeFromDebtPrice: priceSelectors.getFreeFromDebtPrice(
		state.priceFormReducer
	),
	areaTotal: formSelectors.getAreaTotal(state.formReducer),
	pendingItemData: priceSelectors.getPriceFormPendingItemData(
		state.priceFormReducer
	),
	soldItemData: priceSelectors.getPriceFormSoldItemData(state.priceFormReducer),
	requiredField: priceSelectors.getRequiredField(state.priceFormReducer),
	validateError: priceSelectors.getValidateError(state.priceFormReducer),
	loading: priceSelectors.getLoading(state.priceFormReducer),
	formLoading: formSelectors.getLoading(state.formReducer),
	error: priceSelectors.getError(state.priceFormReducer)
})
export default connect(mapStateToProps)(PriceForm)
