/**
 * Certificate action
 *
 */
import {
	CERTIFICATE_CHANGE_FIELD,
	CERTIFICATE_SEND_CERTIFICATE,
	CERTIFICATE_SEND_REQUEST,
	CERTIFICATE_DELETE_CERTIFICATE
} from '@actions'
import { getAuthenticationData } from './../utils'
const propertyId = getAuthenticationData('selectedPropertyId')

export const certificateChangeField = (name, value) => {
	return {
		type: CERTIFICATE_CHANGE_FIELD,
		name,
		value
	}
}
export const certificateSendCertificate = data => {
	return {
		type: CERTIFICATE_SEND_CERTIFICATE,
		payload: {
			request: {
				method: 'POST',
				url: `/certificates/${propertyId}`,
				data: data
			}
		}
	}
}
export const certificateSendRequest = data => {
	return {
		type: CERTIFICATE_SEND_REQUEST,
		payload: {
			request: {
				method: 'POST',
				url: `/request/certificate/${propertyId}`,
				data
			}
		}
	}
}
export const certificateDeleteCertificate = fileId => {
	return {
		type: CERTIFICATE_DELETE_CERTIFICATE,
		payload: {
			request: {
				method: 'DELETE',
				url: `/propertyfiles/${propertyId}/${fileId}`
			}
		}
	}
}
