import React, { Component } from 'react'
import { connect } from 'react-redux'

// Components
import MyChart from 'shared/components/PieChart'
import PriceInfo from 'shared/components/PriceInfo'

//reducers
import { priceSelectors } from 'reducers'
class PriceSuggestion extends Component {
	renderSuggestionInfo = data => {
		const colorArray = ['blue', 'gray', 'light-pink']
		return data.map((item, key) => {
			return <PriceInfo item={item} key={key} classname={colorArray[key]} />
		})
	}
	// normalizePieData

	calculateDataWeight = (dataArray, value) => {
		let sum = 0
		dataArray.map(item => (sum += item))
		return value / sum
	}
	normalizeDistancesToText = distances => {
		let normalizedDistances = []
		distances.map(distance => normalizedDistances.push(`${distance} meters`))
		return normalizedDistances
	}
	normalizeChartData = data => {
		let distances = data.map(item => item.distance)
		let itemWeight1 = this.calculateDataWeight(distances, distances[0])
		let itemWeight2 = this.calculateDataWeight(distances, distances[1])
		let itemWeight3 = this.calculateDataWeight(distances, distances[2])
		const normalizedDistances = this.normalizeDistancesToText(distances)
		return {
			labels: normalizedDistances,
			datasets: [
				{
					labels: [normalizedDistances[2]],
					data: [distances[2]],
					borderWidth: 0,
					hoverBorderWidth: 0,
					backgroundColor: ['#d7b9ae'],
					fill: true,
					weight: itemWeight3
				},
				{
					labels: [normalizedDistances[1]],
					data: [distances[1]],
					borderWidth: 0,
					hoverBorderWidth: 0,
					backgroundColor: ['rgb(122, 120, 120)'],
					fill: true,
					weight: itemWeight2
				},
				{
					labels: [normalizedDistances[0]],
					data: [distances[0]],
					borderWidth: 0,
					hoverBorderWidth: 0,
					backgroundColor: ['#599bbe'],
					fill: true,
					weight: itemWeight1
				}
			]
		}
	}
	render() {
		const { pendingItemData, soldItemData, t } = this.props
		return (
			<>
				<div className="price-suggestion">
					<h1>{t('PROPERTY.PRICE.SUGGESTION.TITLE')}</h1>
					<h6>{t('PROPERTY.PRICE.SUGGESTION.DESCRIPTION')}</h6>
				</div>
				<div className="form-topic_content-center price-content">
					<div className="price-content_stagingItem">
						<h2>{t('PROPERTY.PRICE.SUGGESTION.PRICES_IN_AREA')}</h2>
						<MyChart data={this.normalizeChartData(pendingItemData)} />
						{this.renderSuggestionInfo(pendingItemData)}
					</div>
					<div className="price-content_soldItem">
						<h2>{t('PROPERTY.PRICE.SUGGESTION.SALES_IN_AREA')}</h2>
						<MyChart data={this.normalizeChartData(soldItemData)} />
						{this.renderSuggestionInfo(soldItemData)}
					</div>
				</div>
			</>
		)
	}
}
const mapStateToProps = state => ({
	pendingItemData: priceSelectors.getPriceFormPendingItemData(
		state.priceFormReducer
	),
	soldItemData: priceSelectors.getPriceFormSoldItemData(state.priceFormReducer)
})
export default connect(mapStateToProps)(PriceSuggestion)
