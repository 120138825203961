/**
 * Navbar component
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React, { useState } from 'react'
import { BrowserRouter as Router, Link, Route } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
// images
import aninkainenLogo from '../../assets/images/aninkainen_logo.png'
import headerLogo from '../../assets/images/background_header.png'
// components
import Button from '../components/Button'
import AuthChecker from '../components/Authentication/AuthChecker'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MenuIcon from '@material-ui/icons/Menu'
// constants
import { navigationPath, S_IFREG } from '@constants'
// utils
import { getAuthenticationData } from '../../utils'

const Nav = props => {
	const { t } = useTranslation()
	const propertyId = getAuthenticationData('selectedPropertyId')
	const userRole = getAuthenticationData('role')
	let className = ['header-container']
	if (props.history.location.pathname == '/') {
		className.push('fullScreenHeader')
	}
	const handleLogout = () => {
		localStorage.removeItem('userId')
		localStorage.removeItem('selectedPropertyId')
		localStorage.removeItem('bearer')
		localStorage.removeItem('role')
	}

	const [anchorEl, setAnchorEl] = React.useState(null)

	function handleClick(event) {
		setAnchorEl(event.currentTarget)
	}

	function handleClose() {
		setAnchorEl(null)
	}
	if (userRole == 'buyer') {
		return (
			<div className={className.join(' ')}>
				<div className={`header-container_content ${props.path}`}>
					<div className="buyer_navbar ">
						<Link to="/" className="headerLogo">
							<img src={aninkainenLogo} alt="Aninkainen" />
						</Link>
						<Link to="/" className={'logout-button'} onClick={handleLogout}>
							{t('HEADER.LOGOUT')}
						</Link>
					</div>
				</div>
			</div>
		)
	}
	return (
		<div className={className.join(' ')}>
			<div className={`header-container_content ${props.path}`}>
				<AuthChecker>
					{({ isAuthenticated, stronglyAuthenticated }) =>
						isAuthenticated ? (
							<div className="header-container_navbar ">
								<div className="headerLogo ">
									<Link to="/">
										<img src={aninkainenLogo} alt="Aninkainen" />
									</Link>
								</div>
								<div className="navbar_navigationButtons">
									<nav className="navbar_container">
										{!stronglyAuthenticated ? (
											<Link
												to={navigationPath.bankAuth}
												className={'logout-button'}
											>
												{' '}
												{t('HEADER.NOTAUTHENTICATED')}
											</Link>
										) : (
											<a className={'logout-button authenticated'}>
												{t('HEADER.AUTHENTICATED')}
											</a>
										)}
										<Link to={navigationPath.dashboard}>
											{t('HEADER.DASHBOARD')}
										</Link>
										{/* Only for showing */}
										<a>{t('HEADER.GUIDES')}</a>
										{/* <Link to="/about">{t('HEADER.GUIDES')}</Link> */}
										<Link
											to={
												propertyId
													? `/property/${propertyId}/basic`
													: navigationPath.dashboard
											}
										>
											{t('HEADER.SELL')}
										</Link>
										{/* Only for showing */}
										<a>{t('HEADER.MATERIALS')}</a>
										{/* <Link to="/materials">{t('HEADER.MATERIALS')}</Link> */}
										{props.user && props.user.isAdmin && (
											<Link to={'/admin/all'}>{t('HEADER.ADMIN')}</Link>
										)}

										<Link
											to="/"
											className={'logout-button'}
											onClick={handleLogout}
										>
											{t('HEADER.LOGOUT')}
										</Link>
									</nav>
								</div>

								<div className="navbar_navigationButtons-small">
									<nav className="navbar_container">
										<Button
											className={'menu-button'}
											title={'menu'}
											aria-controls="simple-menu"
											aria-haspopup="true"
											onClick={handleClick}
										/>
										<MenuIcon
											className={'menu-button-small'}
											fontSize={'large'}
											onClick={handleClick}
										/>
										<Menu
											id="simple-menu"
											anchorEl={anchorEl}
											keepMounted
											open={Boolean(anchorEl)}
											onClose={handleClose}
										>
											<MenuItem>
												{!stronglyAuthenticated ? (
													<Link
														to={navigationPath.bankAuth}
														className={'logout-button'}
													>
														{' '}
														{t('HEADER.NOTAUTHENTICATED')}
													</Link>
												) : (
													<a className={'logout-button authenticated'}>
														{t('HEADER.AUTHENTICATED')}
													</a>
												)}
											</MenuItem>
											<MenuItem>
												<Link to={navigationPath.dashboard}>
													{t('HEADER.DASHBOARD')}
												</Link>
											</MenuItem>
											<MenuItem>
												<a>
													{' '}
													{t('HEADER.GUIDES')}
													{/* <Link to="/about">{t('HEADER.GUIDES')}</Link> */}
												</a>
											</MenuItem>
											<MenuItem>
												<Link
													to={
														propertyId
															? `/property/${propertyId}/basic`
															: navigationPath.dashboard
													}
												>
													{t('HEADER.SELL')}
												</Link>
											</MenuItem>
											<MenuItem>
												{/* Only for showing */}
												<a>{t('HEADER.MATERIALS')}</a>
												{/* <Link to="/materials">{t('HEADER.MATERIALS')}</Link> */}
											</MenuItem>
											<MenuItem>
												<Link
													to="/"
													className={'logout-button'}
													onClick={handleLogout}
												>
													{t('HEADER.LOGOUT')}
												</Link>
											</MenuItem>
										</Menu>
									</nav>
								</div>
							</div>
						) : (
							<div className="noauth">
								<div className="headerLogo">
									<Link to="/">
										<img src={aninkainenLogo} alt="Aninkainen" />
									</Link>
								</div>
								<div className="navbar_navigationButtons">
									<Link className="signin-button logout-button" to="/login">
										{t('HEADER.LOGIN')}
									</Link>
								</div>
							</div>
						)
					}
				</AuthChecker>
			</div>
		</div>
	)
}

export default withRouter(Nav)
