import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'

// component
import Select from 'shared/components/Select'
import Loading from 'shared/components/Loading'
import Button from 'shared/components/Button'
import JethronTip from 'shared/components/JethronTip'
import background_dashboard from 'assets/images/background_dashboard.png'
import MaterialItem from './MaterialItem'
// reducer
import { tradingSelectors, biddingSelectors } from 'reducers'
// actions
import { tradingChangeField, biddingGetBiddings } from '@actions'
class Trading extends Component {
	componentDidMount() {
		this.props.dispatch(biddingGetBiddings())
	}
	handleSelectBuyer = e => {
		const { name, value } = e.target
		this.props.dispatch(tradingChangeField(name, value))
	}
	handleDownLoadBooks = () => {
		// TODO
	}
	renderBuyersSelection = () => {
		const { t, buyer } = this.props
		const data = [
			{
				text: 'Anh Tu Le',
				value: '1'
			},
			{
				text: 'Janne',
				value: '2'
			}
		]
		if (data && data.length > 0) {
			return (
				<Select
					name="buyer"
					options={data}
					value={buyer}
					handleSelectOption={this.handleSelectBuyer}
				/>
			)
		}
		return <div>{t('TRADING.NO_BUYER')}</div>
	}
	handlePickAttachment = e => {
		console.log(e)
	}
	renderMaterials = () => {
		const { materials, t } = this.props
		return materials.map((item, key) => (
			<MaterialItem
				t={t}
				url={item.url}
				key={key}
				contentType={item.contentType}
				handlePickAttachment={this.handlePickAttachment}
			/>
		))
	}
	render() {
		const { t, loading, error, biddingLoading } = this.props
		return (
			<div className="trade-container">
				<div className="trade-container_header">
					<h1>{t('TRADING.TITLE').toUpperCase()}</h1>
				</div>
				<div className="trade-container_content">
					<div className="applicants">
						<div className="selection">
							{biddingLoading ? <Loading /> : this.renderBuyersSelection()}
							<Button title={t('TRADING.APPLICANTS.BUTTON')} />
						</div>
						<div className="tip">
							<JethronTip />
						</div>
					</div>
					<div className="download" onClick={this.handleDownLoadBooks}>
						{t('TRADING.DOWNLOAD_BOOKS').toUpperCase()}
					</div>
					<div className="materials">
						<h2 className="title">
							{t('TRADING.MATERIALS.TITLE').toUpperCase()}
						</h2>
						<div className="materials_list">
							{loading ? <Loading /> : this.renderMaterials()}
						</div>
					</div>
					{error ? <span className="error">{error.message}</span> : null}
					<Button
						title={t('GENERAL.FORMS.BACK')}
						onClick={() => this.props.history.push('/dashboard')}
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	materials: tradingSelectors.getMaterials(state.tradingReducer),
	buyer: tradingSelectors.getBuyer(state.tradingReducer),
	biddingLoading: biddingSelectors.getLoading(state.biddingReducer),
	loading: tradingSelectors.getLoading(state.tradingReducer),
	error: tradingSelectors.getError(state.tradingReducer)
})
export default withRouter(connect(mapStateToProps)(withTranslation()(Trading)))
