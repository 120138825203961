/**
 * Trading reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { TRADING_CHANGE_FIELD } from '@actions'
import { normalizeError } from './utils'

const initialState = {
	buyer: '',
	materials: [
		{
			contentType: 'img',
			url: '1055969f-9807-435f-b1da-db4b846e2013'
		}
	],
	loading: false,
	error: null
}
export const tradingReducer = (state = initialState, action) => {
	switch (action.type) {
		case TRADING_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		default:
			return state
	}
}
export const tradingSelectors = {
	getMaterials: (state = initialState) => {
		let newMaterials = [...state.materials]
		newMaterials.push({
			contentType: '',
			url: ''
		})
		return newMaterials
	},
	getBuyer: (state = initialState) => state.buyer,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
