/**
 * Generic select components
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React, { Fragment } from 'react'
import Select from 'shared/components/Select'
const Selects = ({ data, handleSelectOption }) => {
	const renderSelects = () => {
		return data.map((item, key) => {
			if (item.name == '') {
				return <div key={key}></div>
			}
			return (
				<Select
					key={key}
					options={item.options}
					value={item.value}
					label={item.label}
					name={item.name}
					handleSelectOption={handleSelectOption}
				/>
			)
		})
	}
	if (!data || data.length == 0) {
		return null
	}
	return <Fragment>{renderSelects()}</Fragment>
}

export default Selects
