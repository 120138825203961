/**
 * Form reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	PRICE_FORM_VALIDATE_FIELD,
	PRICE_FORM_CHANGE_FIELD,
	PROPERTY_RESET_PROPERTY,
	PROPERTY_GET_PROPERTY_SUCCESS
} from '@actions'
import { priceRequiredField, priceFieldError, priceFormField } from '@constants'
const initialState = {
	askingPrice: '0',
	freeFromDebtPrice: '0',
	suggestionData: [
		{
			area: '19m2',
			distance: 50,
			price: '111,000',
			status: 'pending'
		},
		{
			area: '30m2',
			distance: 100,
			price: '90,000',
			status: 'pending'
		},
		{
			area: '26m2',
			distance: 200,
			price: '112,000',
			status: 'pending'
		},
		{
			area: '23m2',
			distance: 50,
			price: '105,000',
			status: 'sold'
		},
		{
			area: '25m2',
			distance: 700,
			price: '101,000',
			status: 'sold'
		},
		{
			area: '27m2',
			distance: 1000,
			price: '100,999',
			status: 'sold'
		}
	],
	// has error
	askingPriceHasError: false,
	freeFromDebtPriceHasError: false,

	// error
	askingPriceError: true,
	freeFromDebtPriceError: true,

	loading: false,
	error: null
}
export const priceFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case PRICE_FORM_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case PROPERTY_GET_PROPERTY_SUCCESS:
			const {
				askingPrice,
				freeFromDebtPrice
			} = action.payload.data.informationField
			return {
				...state,
				askingPrice,
				freeFromDebtPrice
			}
		case PRICE_FORM_VALIDATE_FIELD:
			const { nameFieldHasError, hasError, nameFieldError, error } = action
			return {
				...state,
				[nameFieldHasError]: hasError,
				[nameFieldError]: error
			}
		case PROPERTY_GET_PROPERTY_SUCCESS:
			const { informationField: informationData } = action.payload.data
			return {
				...state,
				...informationData
			}
		case PROPERTY_RESET_PROPERTY:
			return initialState
		default:
			return state
	}
}
// selector
export const priceSelectors = {
	getData: (state = initialState) => {
		let data = {}
		priceFormField.map(item => (data[item] = state[item]))
		return data
	},
	getIsButtonDisabled: (state = initialState) => {
		let isDisabled = false
		priceRequiredField.map(item => {
			isDisabled = isDisabled || state[item]
		})
		return isDisabled
	},
	getPriceFormPendingItemData: (state = initialState) => {
		let pendingItemData = state.suggestionData.filter(
			item => item.status == 'pending'
		)
		return pendingItemData
	},
	getPriceFormSoldItemData: (state = initialState) => {
		let soldItemData = state.suggestionData.filter(
			item => item.status == 'sold'
		)
		return soldItemData
	},
	getRequiredField: (state = initialState) => {
		let requiredArray = {}
		priceRequiredField.map(item => (requiredArray[item] = state[item]))
		return requiredArray
	},
	getValidateError: (state = initialState) => {
		let validateErrorArray = {}
		priceFieldError.map(item => (validateErrorArray[item] = state[item]))
		return validateErrorArray
	},
	getAskingPrice: (state = initialState) => state.askingPrice,
	getFreeFromDebtPrice: (state = initialState) => state.freeFromDebtPrice,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
