/**
 * Reset password Form action
 *
 */
import {
	RESET_PASSWORD_CHANGE_FIELD,
	RESET_PASSWORD,
	RESET_PASSWORD_RESET_STATE
} from '@actions'
export const resetPasswordChangeField = (name, value) => {
	return {
		type: RESET_PASSWORD_CHANGE_FIELD,
		name,
		value
	}
}
export const resetPasswordResetState = (name, value) => {
	return {
		type: RESET_PASSWORD_RESET_STATE,
		name,
		value
	}
}
export const resetPassword = data => {
	return {
		type: RESET_PASSWORD,
		payload: {
			request: {
				method: 'POST',
				url: `/user/reset`,
				data
			}
		}
	}
}
