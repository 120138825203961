/**
 * Media form reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	MEDIA_FORM_CHANGE_FIELD,
	MEDIA_FORM_ADD_ATTACHMENT,
	MEDIA_FORM_ADD_ATTACHMENT_SUCCESS,
	MEDIA_FORM_ADD_ATTACHMENT_FAIL,
	MEDIA_FORM_DELETE_ATTACHMENT,
	MEDIA_FORM_DELETE_ATTACHMENT_SUCCESS,
	MEDIA_FORM_DELETE_ATTACHMENT_FAIL,
	PROPERTY_GET_PROPERTY_SUCCESS,
	PROPERTY_RESET_PROPERTY,
	PROPERTY_UPDATE,
	PROPERTY_UPDATE_SUCCESS,
	PROPERTY_UPDATE_FAIL
} from '@actions'
import { mediaFormField } from '@constants'
import { normalizeError, checkManager } from './utils'
const initialState = {
	file: [],
	defaultImage: null,
	extraLinks: '',
	isStailation: 'false',
	uploadProgress: 0,
	error: null,
	loading: false,
	buttonLoading: false
}
export const mediaFormReducer = (state = initialState, action) => {
	switch (action.type) {
		case MEDIA_FORM_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case PROPERTY_UPDATE:
			return {
				...state,
				mediaButtonLoading: true
			}
		case PROPERTY_UPDATE_SUCCESS:
			return {
				...state,
				mediaButtonLoading: false
			}
		case PROPERTY_UPDATE_FAIL:
			return {
				...state,
				mediaButtonLoading: false
			}
		case PROPERTY_GET_PROPERTY_SUCCESS:
			const { file, defaultImage } = action.payload.data
			return {
				...state,
				defaultImage,
				file
			}
		case MEDIA_FORM_ADD_ATTACHMENT:
			return {
				...state,
				loading: true
			}
		case MEDIA_FORM_ADD_ATTACHMENT_SUCCESS:
			let newFiles = [...state.file]
			newFiles.push(action.payload.data)
			return {
				...state,
				file: newFiles,
				loading: false
			}
		case MEDIA_FORM_ADD_ATTACHMENT_FAIL:
			return {
				...state,
				loading: normalizeError(action.error)
			}
		case MEDIA_FORM_DELETE_ATTACHMENT:
			return {
				...state,
				loading: true
			}
		case MEDIA_FORM_DELETE_ATTACHMENT_SUCCESS:
			const { fileId } = action.payload.config.reduxSourceAction
			const copiedFiles = [...state.file]
			let itemPos = copiedFiles.findIndex(item => item.id == fileId)
			if (itemPos != -1) {
				copiedFiles.splice(itemPos, 1)
			}
			return {
				...state,
				file: copiedFiles,
				loading: false
			}
		case MEDIA_FORM_DELETE_ATTACHMENT_FAIL:
			return {
				...state,
				loading: false
			}
		case PROPERTY_RESET_PROPERTY:
			return initialState
		default:
			return state
	}
}
// selector
export const mediaSelectors = {
	getData: (state = initialState) => {
		let mediaForm = {}
		mediaFormField.map(item => (mediaForm[item] = state[item]))
		return mediaForm
	},
	getDefaultImage: (state = initialState) => {
		const { defaultImage, file } = state
		let files = file.filter(item => item.contentType.includes('image'))
		if (!files || files.length < 1) {
			return null
		}
		if (defaultImage) {
			return defaultImage
		} else if (!defaultImage && files.length > 0) {
			return file[0].key
		}
		return null
	},
	getFiles: (state = initialState) => state.file,
	getExtraLinks: (state = initialState) => state.extraLinks,
	getIsStailation: (state = initialState) => state.isStailation,
	checkIsManager: (state = initialState) => checkManager(state.file),
	getButtonLoading: (state = initialState) => state.buttonLoading,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
