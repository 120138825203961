import React from 'react'
import { connect } from 'react-redux'
import i18next from 'i18next'

// Component
import SaveBookmark from 'shared/components/SaveBookmark'
import Textareas from 'shared/components/Textareas'

//Actions
import {} from '@actions'
// Reducers
import { formSelectors } from 'reducers'
// constants
import { saveField } from '@constants'
class LinksForm extends React.Component {
	render() {
		const {
			handleChangeField,
			localServices,
			commutes,
			isActive,
			t,
			isSectionSaved,
			isSectionLoading,
			handleClickSave
		} = this.props
		const linksData = [
			{
				name: 'localServices',
				value: localServices,
				label: i18next.t('PROPERTY.BASICFORM.SERVICESFORM.LOCAL_SERVICES')
			},
			{
				name: 'commutes',
				value: commutes,
				label: i18next.t('PROPERTY.BASICFORM.SERVICESFORM.COMMUTES')
			}
		]
		if (!isActive) {
			return null
		}
		return (
			<div className={'form-topic_content linksForm' + (isActive && ' active')}>
				<div className="form-topic_content-center">
					<div className="field-content">
						<Textareas
							data={linksData}
							handleChangeTextarea={handleChangeField}
						/>
					</div>
					<SaveBookmark
						isSaved={isSectionSaved.services}
						sectionName={saveField.services.name}
						handleClickSave={handleClickSave}
						isLoading={isSectionLoading.services}
					/>
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	commutes: formSelectors.getCommutes(state.formReducer),
	localServices: formSelectors.getLocalServices(state.formReducer)
})

export default connect(mapStateToProps)(LinksForm)
