/**
 * Bank Auth action
 *
 */
import { BANK_CONNECT } from '@actions'
export const bankAuthConnectBank = () => {
	return {
		type: BANK_CONNECT,
		payload: {
			request: {
				method: 'GET',
				url: '/authorization/get_url'
			}
		}
	}
}
