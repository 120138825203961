/**
 * Login action creator
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import {
	LOGIN,
	LOGIN_CHANGE_FIELD,
	LOGIN_VALIDATE_FIELD,
	LOGIN_RESET_ERROR,
	VERIFY_EMAIL,
	LOGIN_RESET_FORM
} from './types'
import { getBearer } from './helpers'

/**
 * Dispatched when user submits login form
 *
 * @param  {object} repos The user object
 *
 * @return {object} The created login action
 */

export const login = user => {
	return {
		type: LOGIN,
		payload: {
			request: {
				method: 'POST',
				url: '/bearers',
				data: user
			}
		}
	}
}
export const loginResetError = () => {
	return {
		type: LOGIN_RESET_ERROR
	}
}
export const loginResetForm = () => {
	return {
		type: LOGIN_RESET_FORM
	}
}
export const loginChangeField = (name, value) => {
	return {
		type: LOGIN_CHANGE_FIELD,
		name,
		value
	}
}
export const loginValidateField = (nameField, hasError, error) => {
	return {
		type: LOGIN_VALIDATE_FIELD,
		nameField,
		hasError,
		error
	}
}
export const loginVerifyEmail = email => {
	return {
		type: VERIFY_EMAIL,
		payload: {
			request: {
				method: 'GET',
				url: `/user/reset/${email}`
			}
		}
	}
}
