/**
 * Generic select components
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'
import { Select, MenuItem, InputLabel } from '@material-ui/core'

const CustomSelect = ({
	options,
	value,
	label,
	name,
	fullWidth,
	handleSelectOption,
	menuProps
}) => {
	const handleChange = e => {
		if (handleSelectOption && typeof handleSelectOption === 'function') {
			handleSelectOption(e)
		}
	}

	const renderInputLabel = () => (
		<InputLabel htmlFor="custom-select">{label}</InputLabel>
	)

	const renderOptions = () => {
		if (!options || options.length === 0) {
			return null
		}

		return options.map((option, index) => {
			if (!option) {
				return null
			}
			const { value, text } = option

			return (
				<MenuItem key={index} value={value}>
					{text}
				</MenuItem>
			)
		})
	}

	return (
		<div
			className="select-container"
			onClick={e => {
				e.stopPropagation()
			}}
		>
			{label && renderInputLabel()}
			<Select
				value={value}
				onChange={handleChange}
				inputProps={{
					name,
					id: 'custom-select'
				}}
				fullWidth={fullWidth !== undefined ? fullWidth : true}
				className="select"
				MenuProps={menuProps}
			>
				{renderOptions()}
			</Select>
		</div>
	)
}

export default CustomSelect
