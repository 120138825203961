/**
 * Root reducer
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory()

// error handling reducer
// return error message if there is error returned from action
const errorReducer = (state = null, action) => {
	const { type, error } = action
	// Because there might be different forms of error
	// returned from the server
	// Just return error and handle ErrorMessage res (eg. message) inside component
	// If we came up with a "global" error return
	// then return error message here
	if (error && error.response && error.response.data) {
		// console.log(error.response.data)
		return error.response.data
	}
	if (error) {
		// Check for third-party errors
		if (/^@@/.test(action.type)) {
			if (typeof error === 'string') {
				return {
					message: error
				}
			} else {
				return {
					message: `Error: ${action.type}`
				}
			}
		}
		if (typeof error === 'string') {
			return {
				message: error
			}
		} else {
			return {
				message: 'Oops! Something went wrong'
			}
		}
	}
	if (type === 'CLEAR_ERROR_MESSAGE') {
		return null
	}
	return state
}

// login reducer
import {
	loginReducer,
	getUser,
	getBearer,
	getLoginEmail,
	getLoginPassword,
	getLoginRememberPassword,
	getLoginLoading,
	getLoginError,
	getLoginValidateError,
	getLoginRequiredFieldHasError
} from './login'
// search reducer
import search, { getSearch } from './search'

// registration reducer
import { registerReducer, registerSelectors } from './register'

//form reducer
import {
	formSelectors,
	formReducer,
	getFormPropertyId,
	getFormProperties,
	getFormCategories,
	getFormCategory,
	getFormName,
	getFormPhone,
	getFormEmail,
	//optional Form
	getFormOptionalForm
} from './form'
// Media From Reducer
import { mediaSelectors, mediaFormReducer } from './mediaForm'
//preview
import { previewReducer, previewSelector } from './preview'
import {
	showingRequestReducer,
	showingRequestSelectors
} from './showingRequest'
import {
	showingResultsReducer,
	showingResultsSelectors
} from './showingResults'
// showing
import { showingReducer, showingSelectors } from './showing'

//Price Form Reducer
import { priceSelectors, priceFormReducer } from './priceForm'

//publish
import { publishReducer, publishSelectors } from './publish'
import { certificateReducer, certificateSelectors } from './certificate'
// dashboard
import { propertyReducer, getPropertySelectors } from './property'
// bank auth
import {
	bankAuthReducer,
	getBankAuthLoading,
	getBankAuthError
} from './bankAuth'
// admin
import { adminReducer, adminSelectors } from './admin'
// bidding
import { biddingReducer, biddingSelectors } from './bidding'
import { tradingReducer, tradingSelectors } from './trading'
import { notificationReducer, notificationSelectors } from './notification'
import { resetPasswordReducer, resetPasswordSelectors } from './resetPassword'
import { userReducer, userSelectors } from './user'
import { bidReducer, bidSelectors } from './bid'
import { buyerBidReducer, buyerBidSelectors } from './buyerBid'
// export root reducer
export const rootReducer = combineReducers({
	buyerBidReducer,
	userReducer,
	adminReducer,
	biddingReducer,
	formReducer,
	propertyReducer,
	bankAuthReducer,
	publishReducer,
	previewReducer,
	certificateReducer,
	mediaFormReducer,
	priceFormReducer,
	notificationReducer,
	router: connectRouter(history),
	errorReducer,
	resetPasswordReducer,
	search,
	// test,
	loginReducer,
	registerReducer,
	showingReducer,
	showingRequestReducer,
	showingResultsReducer,
	tradingReducer
})

// export history
export { history }

// export getters/ selectors
// these will be used in individual react component
export {
	getSearch,
	// login selectors
	getLoginEmail,
	getLoginPassword,
	getUser,
	getBearer,
	getLoginRememberPassword,
	getLoginLoading,
	getLoginError,
	getLoginValidateError,
	getLoginRequiredFieldHasError,
	// register
	registerSelectors,
	//bankRecognise
	getBankAuthLoading,
	getBankAuthError,
	// property
	getPropertySelectors,
	//form,
	formSelectors,
	getFormPropertyId,
	getFormCategories,
	getFormProperties,
	getFormCategory,
	// apartment info
	getFormOptionalForm,
	//mediaForm
	mediaSelectors,
	//price
	priceSelectors,
	// certificate
	certificateSelectors,
	//pulishForm
	publishSelectors,
	// preview
	previewSelector,
	showingRequestSelectors,
	showingResultsSelectors,
	showingSelectors,
	biddingSelectors,
	tradingSelectors,
	adminSelectors,
	notificationSelectors,
	resetPasswordSelectors,
	userSelectors,
	bidSelectors,
	buyerBidSelectors
}
