import {
	SHOWING_REQUEST_SEND_REQUEST,
	SHOWING_REQUEST_SEND_REQUEST_SUCCESS,
	SHOWING_REQUEST_SEND_REQUEST_FAIL,
	SHOWING_REQUEST_CHANGE_FIELD,
	SHOWING_REQUEST_VALIDATE_FIELD,
	SHOWING_REQUEST_RESET_ERROR,
	SHOWING_REQUEST_GET_REQUESTS,
	SHOWING_REQUEST_GET_REQUESTS_SUCCESS,
	SHOWING_REQUEST_GET_REQUESTS_FAIL
} from '@actions'
import { normalizeError } from './utils'

const initialState = {
	requests: [],
	name: '',
	email: '',
	phoneNumber: '+358',
	startDate: undefined,
	endDate: undefined,
	requestDate: undefined,
	requiredFieldHasError: {
		name: false,
		email: false,
		phoneNumber: false,
		startDate: false,
		endDate: false
	},
	validateError: {
		name: '',
		email: '',
		phoneNumber: '',
		startDate: '',
		endDate: ''
	},
	error: 'null',
	loading: false
}

export const showingRequestReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOWING_REQUEST_GET_REQUESTS:
			return {
				...state,
				loading: true
			}
		case SHOWING_REQUEST_GET_REQUESTS_SUCCESS:
			return {
				...state,
				requests: action.payload.data,
				loading: false
			}
		case SHOWING_REQUEST_GET_REQUESTS_FAIL:
			return {
				...state,
				loading: false
			}
		case SHOWING_REQUEST_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case SHOWING_REQUEST_VALIDATE_FIELD:
			const { nameField, hasError, error } = action
			return {
				...state,
				requiredFieldHasError: {
					...state.requiredFieldHasError,
					[nameField]: hasError
				},
				validateError: {
					...state.validateError,
					[nameField]: error
				}
			}
		case SHOWING_REQUEST_SEND_REQUEST:
			return {
				...state,
				loading: true
			}
		case SHOWING_REQUEST_SEND_REQUEST_SUCCESS:
			return {
				...state,
				loading: false
			}
		case SHOWING_REQUEST_SEND_REQUEST_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case SHOWING_REQUEST_RESET_ERROR:
			return {
				...state,
				requiredFieldHasError: initialState.requiredFieldHasError,
				validateError: initialState.validateError,
				error: null
			}
		default:
			return state
	}
}
export const showingRequestSelectors = {
	getRequests: (state = initialState) => state.requests,
	getName: (state = initialState) => state.name,
	getEmail: (state = initialState) => state.email,
	getPhoneNumber: (state = initialState) => state.phoneNumber,
	getStartDate: (state = initialState) => state.startDate,
	getEndDate: (state = initialState) => state.endDate,
	getRequestDate: (state = initialState) => state.requestDate,
	getEndDate: (state = initialState) => state.endDate,
	getError: (state = initialState) => state.error,
	getLoading: (state = initialState) => state.loading,
	getRequiredFieldHasError: (state = initialState) =>
		state.requiredFieldHasError,
	getValidateError: (state = initialState) => state.validateError
}
