/**
 * Buyer Bid action
 *
 */
import {
	BUYER_BID_CHANGE_FIELD,
	BUYER_BID_CHANGE_BUYERS,
	BUYER_BID_ADD_BUYER,
	BUYER_BID_REMOVE_BUYER
} from '@actions'
export const buyerBidChangeField = (name, value) => {
	return {
		type: BUYER_BID_CHANGE_FIELD,
		name,
		value
	}
}
export const buyerBidChangeBuyers = (name, value, pos) => {
	return {
		type: BUYER_BID_CHANGE_BUYERS,
		name,
		value,
		pos
	}
}
export const buyerBidAddBuyer = () => {
	return {
		type: BUYER_BID_ADD_BUYER
	}
}
export const buyerBidRemoveBuyer = pos => {
	return {
		type: BUYER_BID_REMOVE_BUYER,
		pos
	}
}
