/**
 * Utils method using in top-level
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

// Helper methods for saving/retrieving data from localStorage
function saveAuthenticationData(key, value) {
	localStorage.setItem(key, value)
}

function getAuthenticationData(key) {
	return localStorage.getItem(key)
}

function refreshAuthenticationData(headers) {
	// Save tokens from response headers to localStorage
	// so that we will add them later in next request
	const accessToken = headers['access-token']

	const oldAccessToken = getAuthenticationData('access-token')
	if (accessToken !== oldAccessToken && accessToken !== '') {
		saveAuthenticationData('access-token', accessToken)
	}
}

function removeTokens() {
	// Remove user and authentication tokens from localStorage
	localStorage.removeItem('userId')
}

export {
	getAuthenticationData,
	refreshAuthenticationData,
	removeTokens,
	saveAuthenticationData
}
