import React from 'react'
import { connect } from 'react-redux'
import Header from './Header'
import Footer from './Footer'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
// component
import GuideMedia from 'shared/components/GuideMedia'
import Notification from 'shared/components/Notification'
// media
import guideMedia from 'assets/images/guideMedia.png'
// reducer
import { getUser, notificationSelectors } from 'reducers'

class CoreLayout extends React.Component {
	componentDidUpdate(prevProps) {
		const { pathname } = this.props.location
		if (
			!pathname.includes('/property') &&
			this.props.location !== prevProps.location
		) {
			window.scrollTo(0, 0)
		}
	}
	routeToClass(path) {
		//TODO add all pages with custom header images and add classes to _header.scss
		let route = path
		if (path.length === 1) {
			route = 'home'
			return route
		} else {
			return path
		}
	}
	renderGuideMedia = () => {
		const { pathname } = this.props.location
		if (
			!pathname.includes('/dashboard') &&
			pathname != '/' &&
			!pathname.includes('/payment') &&
			!pathname.includes('/verification') &&
			!pathname.includes('/preview') &&
			!pathname.includes('/showingResults') &&
			!pathname.includes('/drafts') &&
			!pathname.includes('/bidding') &&
			!pathname.includes('/admin')
		) {
			return (
				<div className="guideMedia">
					<GuideMedia type="image" url={guideMedia} />
				</div>
			)
		}
		return null
	}
	renderPinkLayer = () => {
		const { pathname } = this.props.location
		if (pathname !== '/') {
			return <div className="pink-layer"></div>
		}
		return null
	}
	renderWhiteLayer = () => {
		const { pathname } = this.props.location
		if (pathname == '/dashboard') {
			return <div className="white-layer"></div>
		}
		return null
	}
	render() {
		const { t, user, notiShown, notiStatus, notiMessage } = this.props
		const { pathname } = this.props.history.location
		const isBuyerBidForm = pathname.includes('/buyer/bid')
		if (isBuyerBidForm) {
			return (
				<div className="main-container">
					<main>
						<div className="main-content">{this.props.children}</div>
						<Notification
							notiShown={notiShown}
							notiStatus={notiStatus}
							notiMessage={notiMessage}
						/>
					</main>
				</div>
			)
		}

		return (
			<div className="main-container">
				<header>
					<Header path={this.routeToClass(pathname)} user={user} />
				</header>
				<main>
					<div className="layer">
						{this.renderWhiteLayer()}

						{this.renderPinkLayer()}
					</div>
					<div className="main-content">
						{this.renderGuideMedia()}

						{this.props.children}
					</div>
					<Notification
						notiShown={notiShown}
						notiStatus={notiStatus}
						notiMessage={notiMessage}
					/>
				</main>
				{pathname !== '/' && (
					<div className="main-image">
						<h1>{t('CORELAYOUT.IMAGE_BACKGROUND.TITLE')}</h1>
						<p>{t('CORELAYOUT.IMAGE_BACKGROUND.INTRO')}</p>
						<p>{t('CORELAYOUT.IMAGE_BACKGROUND.DESC')}</p>
					</div>
				)}

				<footer>
					<Footer />
				</footer>
			</div>
		)
	}
}

const mapStateToProps = state => ({
	user: getUser(state.loginReducer),
	notiShown: notificationSelectors.getNotiShown(state.notificationReducer),
	notiStatus: notificationSelectors.getNotiStatus(state.notificationReducer),
	notiMessage: notificationSelectors.getNotiMessage(state.notificationReducer)
})

export default withRouter(
	withTranslation()(
		connect(
			mapStateToProps,
			null,
			null,
			{ pure: false }
		)(CoreLayout)
	)
)
