import React from 'react'
import { fileEndpoint } from '@constants'
const Items = ({ data, handleClickItem }) => {
	if (!data || data.length == 0) {
		return null
	}
	const handleClick = item => {
		if (handleClickItem && typeof handleClickItem === 'function') {
			handleClickItem(item)
		}
	}
	const renderItem = () => {
		return data.map((item, key) => {
			if (item.contentType.includes('video')) {
				return (
					<video
						preload="metadata"
						key={key}
						className="item"
						src={`${fileEndpoint}/${item.key}`}
						alt="video"
						controls
						onClick={() => handleClick(item)}
					></video>
				)
			}
			if (item.contentType.includes('image')) {
				return (
					<img
						src={`${fileEndpoint}/${item.key}`}
						alt="image"
						className="item"
						key={key}
						onClick={() => handleClick(item)}
					/>
				)
			}
			return null
		})
	}
	return <div className="itemList-container">{renderItem()} </div>
}
export default Items
