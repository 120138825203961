import React from 'react'
import { withTranslation } from 'react-i18next'
// components
import Loading from 'shared/components/Loading'
const SaveBookmark = ({
	t,
	isSaved = false,
	handleClickSave,
	sectionName,
	isLoading = false
}) => {
	let iconClassName = ['material-icons']
	let textClassName = ['']
	if (!isSaved) {
		textClassName.push('clickable')
	}
	if (isSaved) {
		iconClassName.push('saved')
	}
	const handleClickSpan = () => {
		if (handleClickSave && typeof handleClickSave === 'function') {
			handleClickSave(sectionName)
		}
	}
	if (isSaved) {
		return (
			<div className="saveBookmark-container">
				<i className="material-icons saved">bookmark</i>
				<span>{t('PROPERTY.SAVED')}</span>
			</div>
		)
	}
	if (isLoading) {
		return (
			<div className="saveBookmark-container">
				<Loading />
			</div>
		)
	}
	return (
		<div className="saveBookmark-container">
			<i className="material-icons">bookmark</i>
			<span className="clickable" onClick={handleClickSpan}>
				{t('PROPERTY.UNSAVED')}
			</span>
		</div>
	)
}
export default withTranslation()(SaveBookmark)
