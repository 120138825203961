/**
 * Configure redux store
 *
 * @author name <name@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import { createStore, applyMiddleware, compose } from 'redux'
import { persistStore, persistReducer } from 'redux-persist'
import thunk from 'redux-thunk'
import storage from 'redux-persist/lib/storage'
import { routerMiddleware } from 'connected-react-router'
import axios from 'axios'
import { multiClientMiddleware } from 'redux-axios-middleware'
// Helpers
import {
	getAuthenticationData,
	refreshAuthenticationData,
	removeTokens
} from './utils'
import { createLogger } from 'redux-logger'

import { rootReducer, history } from 'reducers'
import { PERSIST_KEY } from '@constants'

// Create client alias
// Used in action creators

const clients = {
	default: {
		client: axios.create({
			baseURL: process.env.TEST_API || 'https://kotidiili.appspot.com/',
			responseType: 'json'
		})
	}
}

// Axios interceptor configs
const options = {
	returnRejectedPromiseOnError: true,
	interceptors: {
		request: [
			({}, config) => {
				if (config.headers.bearer) {
					return config
				}
				// Add authentication tokens to request header
				const bearer = getAuthenticationData('bearer')
				config.headers.bearer = bearer

				return config
			}
		],
		response: [
			{
				success: ({}, response) => response,
				error: ({}, error) => {
					if (error.response.status === 401) {
						const isLoggedIn = localStorage.getItem('userId') ? true : false

						// Remove userId so that user is logged out
						// if (isLoggedIn) {
						// 	removeTokens()
						// 	window.location.href = '/'
						// }
					}

					let headers = error.response.headers
					if (headers) {
						refreshAuthenticationData(headers)
					}

					return Promise.reject(error)
				}
			}
		]
	}
}
// Config redux-persist
const persistConfig = {
	key: PERSIST_KEY,
	storage,
	blacklist: [
		'formReducer',
		'priceFormReducer',
		'mediaFormReducer',
		'registerReducer',
		'router',
		'error',
		'loginReducer',
		'showingRequest',
		'previewReducer',
		'showingReducer',
		'certificateReducer',
		'biddingReducer',
		'showingRequestReducer',
		'adminReducer',
		'showingResultsReducer',
		'notificationReducer',
		'resetPasswordReducer',
		'buyerBidReducer'
	]
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

let middleware = [
	thunk,
	routerMiddleware(history),
	multiClientMiddleware(clients, options)
]
//Setup redux logger
const isProduction = process.env.NODE_ENV === 'production'
if (!isProduction) {
	const logger = createLogger()
	middleware.push(logger)
}
// Define middleware to use
const tools = [applyMiddleware(...middleware)]
if (window.__REDUX_DEVTOOLS_EXTENSION__)
	tools.push(window.__REDUX_DEVTOOLS_EXTENSION__())

// Create redux store
const store = createStore(persistedReducer, compose(...tools))
let persistor = persistStore(store)

export { store, persistor }
