/**
 * reset password reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	RESET_PASSWORD_CHANGE_FIELD,
	RESET_PASSWORD,
	RESET_PASSWORD_SUCCESS,
	RESET_PASSWORD_FAIL,
	RESET_PASSWORD_RESET_STATE
} from '@actions'
const initialState = {
	email: '',
	isEmailHasError: false,
	isEmailError: null,
	loading: false,
	error: null
}
export const resetPasswordReducer = (state = initialState, action) => {
	switch (action.type) {
		case RESET_PASSWORD_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case RESET_PASSWORD:
			return {
				...state,
				loading: true
			}
		case RESET_PASSWORD_SUCCESS:
			return {
				...state,
				loading: false
			}
		case RESET_PASSWORD_FAIL:
			return {
				...state,
				loading: false
			}
		case RESET_PASSWORD_RESET_STATE:
			return initialState
		default:
			return state
	}
}
export const resetPasswordSelectors = {
	getEmail: (state = initialState) => state.email,
	getEmailHasError: (state = initialState) => state.isEmailHasError,
	getEmailError: (state = initialState) => state.emailError,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
