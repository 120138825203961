import React, { Component } from 'react'

import ApartmentInfoForm from 'pages/Property/Forms/ApartmentInfoForm'
import MaterialForm from 'pages/Property/Forms/MaterialForm'
import PlotForm from 'pages/Property/Forms/PlotForm'
import LinksForm from 'pages/Property/Forms/LinksForm'
import FormTopic from 'pages/Property/Forms/FormTopic'

import { formCategory } from '@constants'

class OptionalForm extends Component {
	render() {
		const {
			handleChangeInspectionDate,
			handleCheckInspection,
			isSectionLoading,
			handleChangeField,
			handleClickSave,
			isSectionSaved,
			handleChangeRadio,
			handleBlurInput,
			handleFocusInput,
			handleCheckBoxGroup,
			t,
			isTopicActive,
			handleToggleFormTopic,
			isServicesSaved
		} = this.props

		return (
			<div className="optionalForm-container">
				<FormTopic
					title={t('PROPERTY.BASICFORM.TOPICS.OPTIONAL')}
					onClickAction={() => handleToggleFormTopic(formCategory.optional)}
					isActive={isTopicActive(formCategory.optional)}
				/>
				<div
					className={
						'optionalForm-container_form' +
						(isTopicActive(formCategory.optional) ? ' active' : ' hidden')
					}
				>
					<FormTopic
						title={t('PROPERTY.BASICFORM.TOPICS.APARTMENT_INFORMATION')}
						isActive={isTopicActive(formCategory.apartmentInfo)}
						onClickAction={() =>
							handleToggleFormTopic(formCategory.apartmentInfo)
						}
					/>
					<ApartmentInfoForm
						handleChangeTextarea={handleChangeField}
						handleChangeInspectionDate={handleChangeInspectionDate}
						handleCheckInspection={handleCheckInspection}
						handleChangeField={handleChangeField}
						handleBlurInput={handleBlurInput}
						handleFocusInput={handleFocusInput}
						handleCheckBoxGroup={handleCheckBoxGroup}
						isActive={isTopicActive(formCategory.apartmentInfo)}
						t={t}
						handleClickSave={handleClickSave}
						isSectionSaved={isSectionSaved}
						handleChangeField={handleChangeField}
						isSectionLoading={isSectionLoading}
					/>

					<FormTopic
						title={t('PROPERTY.BASICFORM.TOPICS.ROOMS_AND_MATERIAL')}
						isActive={isTopicActive(formCategory.material)}
						onClickAction={() => handleToggleFormTopic(formCategory.material)}
					/>
					<MaterialForm
						isActive={isTopicActive(formCategory.material)}
						handleChangeField={handleChangeField}
						t={t}
						handleClickSave={handleClickSave}
						isSectionSaved={isSectionSaved}
						isSectionLoading={isSectionLoading}
					/>

					<FormTopic
						title={t('PROPERTY.BASICFORM.TOPICS.PLOT')}
						isActive={isTopicActive(formCategory.plot)}
						onClickAction={() => handleToggleFormTopic(formCategory.plot)}
					/>
					<PlotForm
						handleChangeField={handleChangeField}
						isActive={isTopicActive(formCategory.plot)}
						t={t}
						handleClickSave={handleClickSave}
						isSectionSaved={isSectionSaved}
						handleChangeRadio={handleChangeRadio}
						isSectionLoading={isSectionLoading}
					/>

					<FormTopic
						title={t('PROPERTY.BASICFORM.TOPICS.SERVICES_AND_COMMUNICATIONS')}
						isActive={isTopicActive(formCategory.link)}
						onClickAction={() => handleToggleFormTopic(formCategory.link)}
					/>
					<LinksForm
						handleChangeField={handleChangeField}
						isActive={isTopicActive(formCategory.link)}
						t={t}
						handleClickSave={handleClickSave}
						isSectionSaved={isSectionSaved}
						isServicesSaved={isServicesSaved}
						isSectionLoading={isSectionLoading}
					/>
				</div>
			</div>
		)
	}
}
export default OptionalForm
