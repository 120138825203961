import React from 'react'
// component
import Inputs from 'shared/components/Inputs'
import Checkbox from 'shared/components/Checkbox'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'
const BuyerInfo = ({
	handleChangeField,
	name,
	email,
	phone,
	address1,
	address2,
	zipCode,
	municipality
}) => {
	const data = [
		{
			label: 'Name',
			name: 'name',
			value: name
		},
		{
			label: 'Email',
			name: 'email',
			value: email
		},
		{
			label: 'Phone',
			name: 'phone',
			value: phone
		},
		{
			label: 'Address1',
			name: 'address1',
			value: address1
		},
		{
			label: 'Address2',
			name: 'address2',
			value: address2
		},
		{
			label: 'Zip code',
			name: 'zipCode',
			value: zipCode
		},
		{
			label: 'Municipality',
			name: 'municipality',
			value: municipality
		}
	]
	return (
		<div className="buyer-info">
			<div className="buyer-info_header">
				<h4>MAKE A BID</h4>
				<h1>Buyers</h1>
			</div>
			<div className="buyer-info_form">
				<Inputs data={data} onChange={handleChangeField} />
			</div>
		</div>
	)
}
export default BuyerInfo
