/**
 * Bank Auth reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import { BANK_CONNECT, BANK_CONNECT_SUCCESS, BANK_CONNECT_FAIL } from '@actions'
import { normalizeError } from './utils'
const initialState = {
	loading: false,
	error: null
}
export const bankAuthReducer = (state = initialState, action) => {
	switch (action.type) {
		case BANK_CONNECT:
			return {
				...state,
				loading: true
			}
		case BANK_CONNECT_SUCCESS:
			return {
				...state,
				loading: false
			}
		case BANK_CONNECT_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		default:
			return state
	}
}

export const getBankAuthLoading = (state = initialState) => state.loading
export const getBankAuthError = (state = initialState) => state.error
