/**
 * Notification component
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */

import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { routerPath, notificationStatus } from '@constants'
import { hideNotification } from '@actions'

class Notification extends React.Component {
	renderIcon = () => {
		const { notiStatus } = this.props
		switch (notiStatus) {
			case notificationStatus.success:
				return (
					<div className="notification__icon">
						<i className="material-icons">done</i>
					</div>
				)
			case notificationStatus.warning:
				return (
					<div className="notification__icon">
						<i className="material-icons">warning</i>
					</div>
				)
				return (
					<div className="notification__icon">
						<i className="material-icons">done</i>
					</div>
				)
			case notificationStatus.fail:
				return (
					<div className="notification__icon">
						<i className="material-icons">clear</i>
					</div>
				)
			default:
				return null
		}
	}
	renderMessage = () => {
		const { notiMessage } = this.props
		return <div className="notification__message">{notiMessage}</div>
	}

	componentDidUpdate(prevProps) {
		if (prevProps.notiShown !== this.props.notiShown && this.props.notiShown) {
			window.setTimeout(() => this.props.dispatch(hideNotification()), 3000)
		}
	}

	render() {
		const { notiShown, notiStatus } = this.props

		let className = ['notification']
		if (notiShown) {
			className.push('show')
		} else {
			className.push('hide')
		}
		switch (notiStatus) {
			case notificationStatus.success:
				className.push('success')
				break
			case notificationStatus.fail:
				className.push('fail')
				break
			case notificationStatus.warning:
				className.push('warning')
				break
			default:
				break
		}

		return (
			<div className={className.join(' ')}>
				{this.renderIcon()}
				{this.renderMessage()}
			</div>
		)
	}
}

export default withRouter(connect()(Notification))
