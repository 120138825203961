import React from 'react'
export const GuideMedia = ({ type, url }) => {
	switch (type) {
		case 'video':
			return (
				<iframe
					src={url}
					className="guideMedia-container"
					frameborder="0"
				></iframe>
			)
		case 'image':
			return <img src={url} className="guideMedia-container" alt="guide" />
		default:
			return <img src={url} className="guideMedia-container" alt="guide" />
	}
}
export default GuideMedia
