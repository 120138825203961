import React from 'react'
import Button from 'shared/components/Button'
import person_icon from 'assets/icons/person.svg'
const Card = ({ logoUrl, name, phoneNumber, email, handleClickButton }) => {
	const renderLogo = () => {
		if (logoUrl) {
			return <img src={logoUrl} alt="user logo" />
		}
		//return <i className="material-icons"> person_outline</i>
		return <img src={person_icon} />
	}
	const handleClick = () => {
		if (handleClickButton && typeof handleClickButton === 'function') {
			handleClickButton()
		}
	}
	// if (!name) {
	// 	return null
	// }
	return (
		<div className="card-container">
			<div className="card-item">{renderLogo()}</div>
			{(name || phoneNumber) && (
				<div className="card-item">
					<h3 className="card-name">{name}</h3>
					<p className="card-phoneNumber">{phoneNumber}</p>
				</div>
			)}
			{email && <div className="card-item">{email}</div>}
			<div className="card-button">
				<Button title="TEE TARJOUS" onClick={handleClick} />
			</div>
		</div>
	)
}
export default Card
