import React from 'react'
import footer_logo from 'assets/images/footer_logo.png'
import facebook_icon from 'assets/icons/facebook_round.svg'
import instagram_icon from 'assets/icons/instagram_round.svg'
import youtube_icon from 'assets/icons/youtube_round.svg'
import { withTranslation } from 'react-i18next'
import facebookIcon from 'assets/icons/facebook.png'
import twitterIcon from 'assets/icons/twitter.png'
import whatsappIcon from 'assets/icons/whatsapp.png'
import {
	FacebookShareButton,
	TwitterShareButton,
	WhatsappShareButton
} from 'react-share'
import { ngrokUrl } from '@constants'
class Footer extends React.Component {
	constructor() {
		super()
		this.state = {
			email: ''
		}
	}

	renderSocialNetwork = () => {
		return (
			<div className="main-footer__social">
				<div className="main-footer__social_column">
					<i className={'fab fa-facebook-f main-footer__icon'} />
					<i className={'fab fa-twitter main-footer__icon'} />
					<i className={'fab fa-instagram main-footer__icon'} />
				</div>
				<div className="main-footer__social_column">
					<div className="main-footer__text">Facebook</div>
					<div className="main-footer__text">Twitter</div>
					<div className="main-footer__text">Instagram</div>
				</div>
			</div>
		)
	}

	sendEmail = () => {
		console.log(this.state.email)
	}

	render() {
		const { t } = this.props
		return (
			<div className="footer-container">
				<div className="footer-container_content">
					<div className="footer-container_upperFooter">
						<div className="upperFooter_logo">
							<img src={footer_logo} alt="Myy itse" />
						</div>
						<p>{t('FOOTER.DESCRIPTION')}</p>
					</div>
					<div className="footer-container_lowerFooter">
						<div className="lowerFooter_linkContainer">
							<div className="linkContainer_column">
								<p>Ratapihankatu 53C, 4. krs</p>
								<p>20100 Turku</p>
								<p>0197403-4</p>
								<p>info@myyitse.fi</p>
								<p>02 282 7777</p>
							</div>

							<div className="linkContainer_column">
								<a href="">{t('FOOTER.PRIVACY_POLICY')}</a>
								<a href="">{t('FOOTER.TERMS')}</a>
								<a href="">{t('FOOTER.WITHDRAW_CONSENT')}</a>
								<a href="">{t('FOOTER.DATA_PROTECTION')}</a>
							</div>

							<div className="linkContainer_column">
								<a href="">{t('FOOTER.PRIVACY_POLICY')}</a>
								<a href="">{t('FOOTER.TERMS')}</a>
								<a href="">{t('FOOTER.WITHDRAW_CONSENT')}</a>
								<a href="">{t('FOOTER.DATA_PROTECTION')}</a>
							</div>

							<div className="linkContainer_column">
								<a href="">{t('FOOTER.PRIVACY_POLICY')}</a>
								<a href="">{t('FOOTER.TERMS')}</a>
								<a href="">{t('FOOTER.WITHDRAW_CONSENT')}</a>
								<a href="">{t('FOOTER.DATA_PROTECTION')}</a>
							</div>
						</div>
						<div className="lowerFooter_socialMedia">
							<FacebookShareButton url={ngrokUrl}>
								<a className="twitter">
									<img src={facebookIcon} alt="Facebook" />
								</a>
							</FacebookShareButton>

							<TwitterShareButton url={ngrokUrl} title="abc">
								<a className="twitter">
									<img src={twitterIcon} alt="Twitter" />
								</a>
							</TwitterShareButton>
							<WhatsappShareButton url={ngrokUrl} title="abc">
								<a className="twitter">
									<img src={whatsappIcon} alt="Twitter" />
								</a>
							</WhatsappShareButton>
						</div>
						<p className="lowerFooter_copyright">
							© Aninkaisten kiinteistönvälitys Oy 2019
						</p>
					</div>
				</div>
			</div>
		)
	}
}

export default withTranslation()(Footer)
