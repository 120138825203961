import {
	SHOWING_RESULTS_SEND,
	SHOWING_RESULTS_CHANGE_FIELD,
	SHOWING_RESULTS_VALIDATE_FIELD,
	SHOWING_RESULTS_RESET_ERROR,
	SHOWING_RESULTS_SUCCESS,
	SHOWING_RESULTS_FAIL,
	SHOWING_RESULTS_GET_SHOWING_RESULTS,
	SHOWING_RESULTS_GET_SHOWING_RESULTS_SUCCESS,
	SHOWING_RESULTS_GET_SHOWING_RESULTS_FAIL
} from '@actions'
import { normalizeError } from './utils'

const initialState = {
	showingResults: [],
	feeling: '',
	attendees: '',
	bids: '',
	requiredFieldHasError: {
		mood: false,
		visitorsQty: false,
		offersQty: false
	},
	validateError: {
		mood: '',
		visitorsQty: '',
		offersQty: ''
	},
	error: null,
	loading: false
}

export const showingResultsReducer = (state = initialState, action) => {
	switch (action.type) {
		case SHOWING_RESULTS_GET_SHOWING_RESULTS:
			return {
				...state,
				loading: true
			}
		case SHOWING_RESULTS_GET_SHOWING_RESULTS_SUCCESS:
			return {
				...state,
				showingResults: action.payload.data,
				loading: false
			}
		case SHOWING_RESULTS_GET_SHOWING_RESULTS_FAIL:
			return {
				...state,
				loading: false
			}
		case SHOWING_RESULTS_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case SHOWING_RESULTS_VALIDATE_FIELD:
			const { nameField, hasError, error } = action
			return {
				...state,
				requiredFieldHasError: {
					...state.requiredFieldHasError,
					[nameField]: hasError
				},
				validateError: {
					...state.validateError,
					[nameField]: error
				}
			}
		case SHOWING_RESULTS_SEND:
			return {
				...state,
				loading: true
			}
		case SHOWING_RESULTS_SUCCESS:
			return {
				...state,
				loading: false
			}
		case SHOWING_RESULTS_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case SHOWING_RESULTS_RESET_ERROR:
			return {
				...state,
				requiredFieldHasError: initialState.requiredFieldHasError,
				validateError: initialState.validateError,
				error: null
			}
		default:
			return state
	}
}
export const showingResultsSelectors = {
	getShowingResults: (state = initialState) => state.showingResults,
	getFeeling: (state = initialState) => state.feeling,
	getAttendees: (state = initialState) => state.attendees,
	getBids: (state = initialState) => state.bids,
	getError: (state = initialState) => state.error,
	getLoading: (state = initialState) => state.loading,
	getRequiredFieldHasError: (state = initialState) =>
		state.requiredFieldHasError,
	getValidateError: (state = initialState) => state.validateError
}
