/**
 * Admin reducer
 *
 * @author Anh Tu Le <anh.le@vertics.co>
 *
 * @copyright Vertics Co 2019
 */
import {
	ADMIN_GET_PROPERTIES,
	ADMIN_GET_PROPERTIES_SUCCESS,
	ADMIN_GET_PROPERTIES_FAIL,
	ADMIN_SEND_FEEDBACK,
	ADMIN_SEND_FEEDBACK_SUCCESS,
	ADMIN_SEND_FEEDBACK_FAIL,
	ADMIN_CHANGE_FIELD,
	ADMIN_PUBLISH_PROPERTY,
	ADMIN_PUBLISH_PROPERTY_SUCCESS,
	ADMIN_PUBLISH_PROPERTY_FAIL
} from '@actions'
import { normalizeError } from './utils'
const initialState = {
	properties: null,
	entriesCount: 0,
	entriesPerPage: 3,
	content: '',
	pageNumber: 1,
	loading: false,
	error: null
}
export const adminReducer = (state = initialState, action) => {
	switch (action.type) {
		case ADMIN_CHANGE_FIELD:
			return {
				...state,
				[action.name]: action.value
			}
		case ADMIN_GET_PROPERTIES:
			return {
				...state,
				loading: true
			}
		case ADMIN_GET_PROPERTIES_SUCCESS:
			const {
				entriesCount,
				entriesPerPage,
				pageNumber,
				results
			} = action.payload.data
			return {
				...state,
				properties: results,
				entriesCount,
				entriesPerPage,
				pageNumber,
				loading: false
			}
		case ADMIN_GET_PROPERTIES_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case ADMIN_SEND_FEEDBACK:
			return {
				...state,
				loading: true
			}
		case ADMIN_SEND_FEEDBACK_SUCCESS:
			return {
				...state,
				loading: false
			}
		case ADMIN_SEND_FEEDBACK_FAIL:
			return {
				...state,
				loading: false,
				error: normalizeError(action.error)
			}
		case ADMIN_PUBLISH_PROPERTY:
			return {
				...state,
				loading: true
			}
		case ADMIN_PUBLISH_PROPERTY_SUCCESS:
			return {
				...state,
				loading: false
			}
		case ADMIN_PUBLISH_PROPERTY_FAIL:
			return {
				...state,
				loading: false
			}
		default:
			return state
	}
}

export const adminSelectors = {
	getContent: (state = initialState) => state.content,
	getProperties: (state = initialState) => state.properties,
	getEntriesCount: (state = initialState) => state.entriesCount,
	getEntriesPerPage: (state = initialState) => state.entriesPerPage,
	getPageNumber: (state = initialState) => state.pageNumber,
	getLoading: (state = initialState) => state.loading,
	getError: (state = initialState) => state.error
}
