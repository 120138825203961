import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withTranslation } from 'react-i18next'
import { withRouter } from 'react-router-dom'
// component
import Input from 'shared/components/Input'
import Button from 'shared/components/Button'
import Loading from 'shared/components/Loading'

// reducer
import { resetPasswordSelectors } from 'reducers'
// action
import {
	resetPassword,
	resetPasswordChangeField,
	resetPasswordResetState
} from '@actions'
class ResetPassword extends Component {
	constructor(props) {
		super(props)
		this.state = {
			isResetted: false
		}
	}
	componentWillUnmount() {
		this.props.dispatch(resetPasswordResetState())
	}
	handleResetPassword = () => {
		const { email, isEmailHasError } = this.props
		if (email && !isEmailHasError) {
			this.props.dispatch(resetPassword({ email })).then(res => {
				if (res.payload.data) {
					this.setState({
						isResetted: true
					})
				}
			})
		}
	}
	handleFocusInput = () => {
		const { dispatch } = this.props
		dispatch(resetPasswordChangeField('isEmailHasError', false))
		dispatch(resetPasswordChangeField('emailError', null))
	}
	handleBlurInput = () => {
		const { dispatch, email } = this.props
		if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && email) {
			dispatch(resetPasswordChangeField('isEmailHasError', true))
			dispatch(resetPasswordChangeField('emailError', 'Its not an email'))
		}
	}
	renderResetForm = () => {
		const { email, loading, t, isEmailHasError, emailError } = this.props
		return (
			<>
				<div className="header">
					{t('LOGIN.RESET_PASSWORD.RESET_FORM.TITLE')}
				</div>
				<Input
					required={true}
					name="email"
					value={email}
					onChange={this.handleChangeEmail}
					hasError={isEmailHasError}
					error={emailError}
					handleFocusInput={this.handleFocusInput}
					handleBlurInput={this.handleBlurInput}
				/>
				{loading ? (
					<Loading />
				) : (
					<Button
						title={t('LOGIN.RESET_PASSWORD.RESET_FORM.BUTTON')}
						onClick={this.handleResetPassword}
					/>
				)}
			</>
		)
	}
	renderSuccesView = () => {
		const { email, handleCloseResetModal, t } = this.props
		return (
			<>
				<div className="header">
					{t('LOGIN.RESET_PASSWORD.RESET_SUCCESS.TITLE')} {email}{' '}
				</div>
				<Button
					title={t('LOGIN.RESET_PASSWORD.RESET_SUCCESS.BUTTON')}
					onClick={handleCloseResetModal}
				/>
			</>
		)
	}
	handleChangeEmail = e => {
		const { name, value } = e.target
		this.props.dispatch(resetPasswordChangeField(name, value))
	}
	render() {
		const { isResetted, t } = this.state
		return (
			<div className="resetPassword-container ">
				<div className="content">
					{isResetted ? this.renderSuccesView() : this.renderResetForm()}
				</div>
			</div>
		)
	}
}
const mapStateToProps = state => ({
	email: resetPasswordSelectors.getEmail(state.resetPasswordReducer),
	isEmailHasError: resetPasswordSelectors.getEmailHasError(
		state.resetPasswordReducer
	),
	emailError: resetPasswordSelectors.getEmailError(state.resetPasswordReducer),
	loading: resetPasswordSelectors.getLoading(state.resetPasswordReducer),
	error: resetPasswordSelectors.getError(state.resetPasswordReducer)
})

export default withRouter(
	withTranslation()(connect(mapStateToProps)(ResetPassword))
)
