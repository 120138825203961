import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'

// Components
import Slider from 'shared/components/Slider'
import MyChart from 'shared/components/PieChart'
// import Slider from '@material-ui/lab/Slider'

class Saving extends Component {
	constructor(props) {
		super(props)
		this.state = {
			priceSlider: 18.3,
			savingAmount: 8255,
			rate: 4.5
		}
	}

	handleOnChangePrice = (event, newValue) => {
		const { priceSlider, savingAmount, rate } = this.state
		let saving = (priceSlider * 10000 * rate) / 100
		this.setState({
			priceSlider: newValue,
			savingAmount: saving.toFixed()
		})
	}
	handleOnChangeRate = (event, newValue) => {
		const { priceSlider } = this.state
		let saving = (priceSlider * 10000 * newValue) / 100
		this.setState({
			rate: newValue.toFixed(1),
			savingAmount: saving.toFixed()
		})
	}
	render() {
		const { t } = this.props
		const { priceSlider, savingAmount, rate } = this.state
		const savingData = {
			labels: ['saving'],
			datasets: [
				{
					labels: ['saving'],
					data: [100],
					borderWidth: 0,
					hoverBorderWidth: 0,
					borderColor: '#707070',
					backgroundColor: '#D7B9AE',
					fontColor: 'black',
					fill: true
				}
			],
			text: 'lorem'
		}
		return (
			<div className="saving">
				<div className="saving-content">
					<h1>{t('HOMEPAGE.SAVING.TITLE')}</h1>
					<h4 className={'saving-description'}>{t('HOMEPAGE.SAVING.DESC')}</h4>
					<h4>
						{t('HOMEPAGE.SAVING.SALE')}{' '}
						{Number((priceSlider * 10000).toFixed()).toLocaleString('fi')} €
					</h4>
					<Slider
						marks={[
							{
								value: 0
							},
							{
								value: 100
							}
						]}
						max={100}
						value={priceSlider}
						step={0.1}
						onChange={this.handleOnChangePrice}
					/>
					<h4>
						{t('HOMEPAGE.SAVING.RATE')} {this.state.rate} %{' '}
					</h4>
					<Slider
						marks={[
							{
								value: 0
							},
							{
								value: 10
							}
						]}
						max={10}
						value={rate}
						step={0.1}
						onChange={this.handleOnChangeRate}
					/>
				</div>
				<div className="saving-pie">
					<MyChart
						data={savingData}
						statisticNumber={`${t('HOMEPAGE.SAVING.LABEL')} ${
							savingAmount == 0
								? '0 €'
								: `${Number(savingAmount - 795).toLocaleString('fi')} €`
						}`}
					/>
				</div>
			</div>
		)
	}
}
export default withTranslation()(Saving)
