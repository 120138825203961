/**
 * Generic select components
 *
 * @author Vinh Le <vinh@vertics.co>
 *
 */

import React from 'react'
import {
	Radio,
	FormControlLabel,
	RadioGroup,
	InputLabel
} from '@material-ui/core'

const CustomRadioButton = ({
	options,
	value,
	label,
	name,
	handleCheckRadioButton,
	disabled
}) => {
	const handleChange = e => {
		if (
			handleCheckRadioButton &&
			typeof handleCheckRadioButton === 'function'
		) {
			handleCheckRadioButton(e)
		}
	}

	const renderInputLabel = () => (
		<InputLabel htmlFor="custom-select">{label}</InputLabel>
	)

	const renderOptions = () => {
		if (!options || options.length === 0) {
			return null
		}

		return options.map((option, index) => {
			if (!option) {
				return null
			}
			const { value, text } = option
			return (
				<FormControlLabel
					control={<Radio />}
					key={index}
					value={value}
					label={text}
				/>
			)
		})
	}
	return (
		<div className="radioButton-container">
			{label && renderInputLabel()}
			<RadioGroup
				aria-label="Gender"
				name={name}
				className={'radioButton'}
				value={value}
				onChange={handleChange}
				disabled={disabled}
			>
				<div className="radioButton-content">{renderOptions()}</div>
			</RadioGroup>
		</div>
	)
}

export default CustomRadioButton
