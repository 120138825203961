/**
 * Preview action
 *
 */
import { PREVIEW_SAVE_SELECTED_MEDIA, PREVIEW_CHANGE_FIELD } from '@actions'
export const previewGetSelectedMedia = media => {
	return {
		type: PREVIEW_SAVE_SELECTED_MEDIA,
		media
	}
}
export const previewChangeField = (name, value) => {
	return {
		type: PREVIEW_CHANGE_FIELD,
		name,
		value
	}
}
