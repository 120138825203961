import React from 'react'
import { withTranslation } from 'react-i18next'

const Trade = ({ handleClickTrade, t }) => {
	const handleClick = type => {
		if (handleClickTrade && typeof handleClickTrade === 'function') {
			return handleClickTrade(type)
		}
	}
	return (
		<div className="dashboard-content_hover">
			<h2>{t('DASHBOARD.BOXES.TRADE.TITLE')}</h2>
			<div className="content">
				<div className="item" onClick={() => handleClick('order')}>
					{t('DASHBOARD.BOXES.TRADE.ORDER')}
				</div>
				<div className="item" onClick={() => handleClick('opportunity')}>
					{t('DASHBOARD.BOXES.TRADE.OPPORTUNITY')}
				</div>
			</div>
		</div>
	)
}
export default withTranslation()(Trade)
