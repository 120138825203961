/**
 * Form action
 *
 */
import { PRICE_FORM_CHANGE_FIELD, PRICE_FORM_VALIDATE_FIELD } from '@actions'
export const priceFormChangeField = (name, value) => {
	return {
		type: PRICE_FORM_CHANGE_FIELD,
		name,
		value
	}
}
export const priceFormValidateField = (nameField, hasError, error) => {
	return {
		type: PRICE_FORM_VALIDATE_FIELD,
		nameFieldHasError: `${nameField}HasError`,
		hasError,
		nameFieldError: `${nameField}Error`,
		error
	}
}
